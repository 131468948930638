import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Context from './context/curbsideContext';
import { SettingsIcon } from '../Common/Icons';

const Navigation = ({ onSettingsClick }) => {
  const context = useContext(Context);
  const { clinic } = context;
  //const googleMap = `https://maps.google.com/?q=${clinic.address}`;
  const appleMap = `https://maps.apple.com/maps?q=${clinic.address}`;

  return (
    <StyledContainer>
      <VetClinicHeader>
        <Avatar>
          <img src={clinic.logo} alt="clinic logo" />
        </Avatar>
        <ClinicHeaderContent>
          <StyledText href={appleMap}> {clinic.name}</StyledText>
        </ClinicHeaderContent>
        <ActionsContainer>
          <SettingsIcon onClick={onSettingsClick} />
        </ActionsContainer>
      </VetClinicHeader>
    </StyledContainer>
  );
};

Navigation.propTypes = {
  onSettingsClick: PropTypes.func.isRequired,
};

export default Navigation;

const StyledContainer = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #e8e9e9;
  height: 60px;
  position: relative;
  z-index: 99;
`;

const StyledText = styled.a`
  color: #575d7c;
  font-family: LinearSans;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: normal;
  text-decoration: none;
`;

const ClinicHeaderContent = styled.div`
  width: 75%;
`;

const ActionsContainer = styled.div`
  text-align: right;
  width: 25%;

  svg {
    cursor: pointer;
  }
`;

const Avatar = styled.div`
  align-items: center;
  border-radius: 6px;
  border: 1px solid #ccc;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 2.5em;
  margin-right: 12px;
  overflow: hidden;
  width: 2.5em;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const VetClinicHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 10px;
  height: 100%;
`;
