import axios from 'axios';

import paymentActionTypes from '../paymentActionTypes';

const getCustomerToken = customerId => {
  return async (dispatch, getState) => {
    try {
      if (!customerId) {
        customerId = getState().user.info.private.customerId;
      }

      let restURL = getState().variable.restURL;
      if (customerId) {
        let url =
          restURL +
          '/payment/customer_token' +
          (customerId ? '?customerId=' + customerId : '');

        const response = await axios.get(url);
        dispatch({
          type: paymentActionTypes.getCustomerToken,
          payload: response.data,
        });

        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      throw CustomerIDException(error);
    }
  };
};

const CustomerIDException = ({ type, message }) => {
  return { type, message: `CustomerIDException: "${message}"` };
};

export default getCustomerToken;
