import { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import flattenDeep from 'lodash/flattenDeep';
import firebase from '../../../../firebase/firebase';
import { createUniqueId } from '../../utilities';
import { CREATE_CHANNEL_MESSAGE } from '../mutations';
import CurbsideContext from '../../context/curbsideContext';
import { ChannelMessageAttachmentType } from '../../enums';

const firebaseStorage = firebase.storage();

const useSendMessage = ({ onError }) => {
  const { headers, user, channel } = useContext(CurbsideContext);
  const [createChannelMessage] = useMutation(CREATE_CHANNEL_MESSAGE, {
    context: { headers },
  });

  const uploadFile = useCallback(
    async attachment => {
      if (!attachment.file || !channel) return null;
      const file = attachment.file;

      const route = `conversations/${channel.id}/${createUniqueId()}/${
        file.name
      }`;
      const storageRef = firebaseStorage.ref(route);
      await storageRef.put(file);
      const fileUrlResponse = await storageRef.getDownloadURL();

      let attachmentType = ChannelMessageAttachmentType.File;

      if (file.type.includes('image')) {
        attachmentType = ChannelMessageAttachmentType.Image;
      } else if (file.type.includes('video')) {
        attachmentType = ChannelMessageAttachmentType.Video;
      }

      return {
        id: createUniqueId(),
        value: {
          attachmentType,
          filename: file.name,
          url: fileUrlResponse,
        },
      };
    },
    [channel]
  );

  const prepareAttachments = useCallback(
    async attachments => {
      const preparedAttachments = await Promise.all(
        attachments.map(async attachment => {
          switch (attachment.value.attachmentType) {
            case ChannelMessageAttachmentType.Image:
            case ChannelMessageAttachmentType.Video:
            case ChannelMessageAttachmentType.File:
              return uploadFile(attachment);
            default:
              return attachment;
          }
        })
      );

      return flattenDeep(preparedAttachments.filter(a => !!a));
    },
    [uploadFile]
  );

  const sendMessage = useCallback(
    async ({ text, attachments, attributes = null }) => {
      if (!channel && !user?.uid) return;
      try {
        const preparedAttachments = (await prepareAttachments(attachments)).map(
          ({ value }) => value
        );
        const author = channel.channelMembers?.find(
          m => m.clinicPetParent?.id === user.uid
        );
        if (!author) throw Error('Unable to find author!');
        await createChannelMessage({
          variables: {
            data: {
              body: text,
              attachments: { create: preparedAttachments },
              author: {
                connect: { id: author.id },
              },
              index: channel?.lastMessage?.index || 0,
              twilioMessageSid: '',
              channel: { connect: { id: channel.id } },
              attributes,
            },
          },
        });
      } catch (e) {
        onError(e);
      }
    },
    [channel, createChannelMessage, onError, prepareAttachments, user]
  );

  return { sendMessage };
};

export default useSendMessage;
