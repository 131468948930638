import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Prompt from './Shared/Prompt';
import QuestionWrapper from './Shared/QuestionWrapper';
import RequiredQuestionMessage from './Shared/RequiredQuestionMessage';

interface Props {
  index: number;
  prompt: string;
  answer: string;
  options: string[];
  setIsDraftSaved: Function;
  setAnswerAtIndex: Function;
  isRequired: boolean;
  isInvalid: boolean;
}

const MultipleSelection = ({
  index,
  prompt,
  answer,
  options,
  setIsDraftSaved,
  setAnswerAtIndex,
  isRequired,
  isInvalid,
}: Props): ReactElement => {
  return (
    <QuestionWrapper index={index}>
      <Prompt prompt={prompt} isRequired={isRequired} />
      <br />
      <StyledSelect
        value={answer}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const answer = e.currentTarget.value;

          setIsDraftSaved(false);
          setAnswerAtIndex(answer, index);
        }}
      >
        <option value={''}></option>
        {options.map((option, optionIndex) => {
          return (
            <option
              key={`multiple-selection-options-preview-${optionIndex}`}
              value={option}
              data-mixpanel-name="Dropdown form option"
            >
              {option}
            </option>
          );
        })}
      </StyledSelect>
      {isInvalid ? <RequiredQuestionMessage /> : null}
    </QuestionWrapper>
  );
};

export default MultipleSelection;

const StyledSelect = styled.select`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: 1em;
  font-family: LinearSans;
  border-radius: 7px;
  border: solid 1px #d2d2d2;
`;
