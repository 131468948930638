export const createUniqueId = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const MAX_FILENAME_LENGTH = 20;

export const truncateFilename = fileName => {
  if (!fileName) return;
  const name = fileName.slice().substring(0, fileName.lastIndexOf('.') + 1);
  const extension = fileName.slice().substring(fileName.lastIndexOf('.') + 1);
  if (name.length > MAX_FILENAME_LENGTH)
    return `${fileName.substring(0, MAX_FILENAME_LENGTH)}... .${extension}`;
  else return fileName;
};
