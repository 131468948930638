import axios from 'axios';
import { pushSnackbar } from '../../snackbar/snackbarActions';
import paymentActionTypes from '../paymentActionTypes';
import { Mixpanel as mixpanel } from '../../mixpanel';

const setDefaultPaymentMethod = token => {
  return (dispatch, getState) => {
    const { restURL } = getState().variable;
    const url = restURL + '/payment/update_payment_method';

    return axios
      .put(url, { token })
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: paymentActionTypes.setDefaultPaymentMethod,
          });
        }
        mixpanel.track('Set default payment method');
      })
      .catch(error => {
        console.error(error);
        dispatch(pushSnackbar({ type: 'error', message: error.message }));
      });
  };
};

export default setDefaultPaymentMethod;
