import produce from 'immer';
import clinicSearchActionTypes from './clinicSearchActionTypes';

const initialState = {
  clinicList: {},
  clinicData: {},
};

let clinicSearchReducer = produce((draft, action) => {
  switch (action.type) {
    case clinicSearchActionTypes.getClinicList:
      draft.clinicList = action.payload;
      break;
    case clinicSearchActionTypes.getClinicData:
      draft.clinicData[action.payload.key] = action.payload.value;
      break;
    default:
      break;
  }
}, initialState);

export default clinicSearchReducer;
