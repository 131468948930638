import styled from 'styled-components/macro';

const Text = styled.p`
  font-family: Roboto;
  line-height: ${({ theme }) => theme.font.fontSizes.xl};
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.fontSizes.sm};
  color: ${({ theme }) => theme.palette.black};
  margin: 12px 0;
`;

export default Text;
