import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import CurbsideContext from '../../context/curbsideContext';
import { CalendarIcon, CheckIcon } from '../../icons';

import { AppointmentConfirmation } from '../../enums';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_APPOINTMENT_INFO,
  CONFIRM_APPOINTMENT,
  RESCHEDULE_APPOINTMENT,
} from '../../queries';
import { appointmentDate } from '../../../../utilities';

const AppointmentConfirm = ({ appointmentId }) => {
  const context = useContext(CurbsideContext);
  const { headers } = context;

  const [getAppointmentInfo, { data }] = useLazyQuery(GET_APPOINTMENT_INFO, {
    variables: { appointmentId },
    context: { headers },
  });

  useEffect(() => {
    getAppointmentInfo();
  }, [getAppointmentInfo]);

  const isConfirmed =
    data?.appointment?.status === AppointmentConfirmation.Confirmed;

  const isRescheduled =
    data?.appointment?.status === AppointmentConfirmation.Rescheduled;

  const [confirmAppointment] = useMutation(CONFIRM_APPOINTMENT, {
    context: { headers },
  });

  const [updateAppointment] = useMutation(RESCHEDULE_APPOINTMENT, {
    context: { headers },
  });

  const setAppointment = () => {
    confirmAppointment({ variables: { where: { id: appointmentId } } });
  };

  const rescheduleAppointment = () => {
    updateAppointment({
      variables: {
        data: { status: 'Reschedule' },
        where: { id: appointmentId },
      },
    });
  };

  return (
    <div>
      {!data ? null : (
        <MessageContainer>
          <IntroContainer>
            <AppointmentContainer>
              <IconContainer>
                <CalendarIcon />
              </IconContainer>

              <AppointmentHeader>
                <AppointmentName>
                  {data.appointment.appointmentType.name}
                  {!!isConfirmed && <StyledCheckIcon />}
                </AppointmentName>
                <AppointmentDate>
                  {appointmentDate(
                    data.appointment.startAt,
                    data.appointment.clinic.timezoneName,
                    'dddd MM/DD/YY [at] h:mma z'
                  )}
                </AppointmentDate>
              </AppointmentHeader>
            </AppointmentContainer>
            {!!isConfirmed && (
              <ConfirmationText>
                You are confirmed! We look forward to seeing{' '}
                {data.appointment.clinicPet.name}.
              </ConfirmationText>
            )}
            {!!isRescheduled && (
              <ConfirmationText>
                You requested to reschedule this appointment for{' '}
                {data.appointment.clinicPet.name}.
              </ConfirmationText>
            )}

            {!isRescheduled && (
              <ButtonContainer>
                {isRescheduled ? null : (
                  <RescheduleButton
                    data-mixpanel-name="Reschedule appointment button"
                    onClick={rescheduleAppointment}
                  >
                    Reschedule
                  </RescheduleButton>
                )}

                {isConfirmed || isRescheduled ? null : (
                  <ConfirmButton
                    data-mixpanel-name="Confirm appointment button"
                    onClick={setAppointment}
                  >
                    Confirm
                  </ConfirmButton>
                )}
              </ButtonContainer>
            )}
          </IntroContainer>
        </MessageContainer>
      )}
    </div>
  );
};

export default AppointmentConfirm;

const StyledCheckIcon = styled(CheckIcon)`
  margin-left: 8px;
`;

const IconContainer = styled.div`
  width: 38px;
  min-width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid #ccc;
  background: hsl(56deg 34% 98%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppointmentHeader = styled.div`
  padding: 6px 12px;
  font-size: 16px;
`;

const MessageContainer = styled.div`
  padding: 0 4px 12px;
  font-family: LinearSans;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3rem;
`;

const ConfirmationText = styled.div`
  color: hsl(56deg 34% 97%);
  margin: 10px 0 0;
`;

const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 18px 0 0 0;
  button {
    @media (max-width: 375px) {
      width: 90px;
      margin-right: 12px;
    }
    font-weight: 600;
    font-size: 14px;
    font-family: LinearSans;
    padding: 4px;
    width: 100px;
    height: 30px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    margin-right: 36px;
  }
`;

const ConfirmButton = styled.button`
  background: hsl(190 60% 40%);
  color: hsl(56deg 34% 99%);
`;

const RescheduleButton = styled.button`
  background-color: #eeeff1;
  color: #575d7c;
`;

const AppointmentContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: hsl(56deg 34% 97%);
`;

const AppointmentName = styled.div`
  font-weight: 600;
`;

const AppointmentDate = styled.div`
  font-weight: normal;
`;
