import authActionTypes from '../authActionTypes';

const loadUser = user => {
  return {
    type: authActionTypes.loadUser,
    payload: { user },
  };
};

export default loadUser;
