import styled from 'styled-components/macro';
import {
  Button,
  ResponsiveDeviceMaxWidth,
  BounceLoader,
} from '@televet/televet-ui';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';

import Text from '../theme/Text';
import { H1, H4 } from '../Common/Headers';
import NotificationList from './NotificationList';

import { getTimeAgo, createdOnDate } from '../../utilities';
import { pushSnackbar } from '../../redux/snackbar/snackbarActions';
import { markNotifications } from '../../redux/notifications/actions/markNotifications';
import { Mixpanel } from '../../redux/mixpanel';

const sortNotifications = ([keyA, a], [keyB, b]) => {
  const notificationTimeA = a.created || a.received || a.assigned;
  const notificationTimeB = b.created || b.received || b.assigned;
  return notificationTimeB - notificationTimeA;
};

const transformNotifications = notificationsList => {
  return notificationsList.map(([key, singleNotification]) => {
    return {
      ...singleNotification,
      key,
      assigned: getTimeAgo(singleNotification.assigned),
      created: getTimeAgo(singleNotification.created),
      received: getTimeAgo(singleNotification.received),
      read: getTimeAgo(singleNotification.read),
      createdOnDate: createdOnDate(singleNotification.created),
    };
  });
};

const NotificationsView = () => {
  const user = useSelector(state => state.user);
  const history = useHistory();
  const dispatch = useDispatch();

  const notificationObject = user.info.private.clientNotifs;
  const [newNotificationsList, setNewNotificationsList] = useState([]);
  const [pastNotificationsList, setPastNotificationsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //create timer on component mount

    const timer = setInterval(() => {
      setNotificationLists(notificationObject);
    }, 1000 * 60);

    // returned function will be called on component unmount
    return () => {
      clearInterval(timer);
    };
  }, [notificationObject]);

  const setNotificationLists = notificationKeymap => {
    const list = transformNotifications(
      Object.entries(notificationKeymap || [])
        .sort(sortNotifications)
        .filter(([_, { pet, consult, type, created, read, received }]) => {
          return (
            pet &&
            consult &&
            type &&
            created &&
            read !== undefined &&
            received !== undefined
          );
        })
    );

    setNewNotificationsList(
      list.filter(notification => notification.read === false)
    );

    setPastNotificationsList(
      list.filter(notification => notification.read !== false)
    );
  };

  const onItemClick = async (notification, message) => {
    setIsLoading(true);
    await markAsRead(notification.key);
    setIsLoading(false);
    Mixpanel.track('Notification clicked', { message });
    history.push(`/consultation/${notification.consult}`);
  };

  const markAsRead = async notificationId => {
    if (!notificationObject[notificationId].read) {
      const newObject = await dispatch(
        markNotifications('read', notificationId)
      );
      setNotificationLists(newObject);
    }
  };

  const markAllAsRead = async () => {
    setIsLoading(true);
    const newObject = await dispatch(markNotifications('read'));
    setNotificationLists(newObject);
    setIsLoading(false);
    Mixpanel.track('Marked all notifications as read');

    dispatch(
      pushSnackbar({
        type: 'success',
        message: 'All notifications marked as read',
      })
    );
  };

  const markAllAsReceived = () => {
    if (
      notificationObject &&
      Object.keys(notificationObject).some(
        key => notificationObject[key].received === false
      )
    )
      dispatch(markNotifications('received'));
  };

  useEffect(() => {
    setNotificationLists(notificationObject);
  }, [notificationObject]);

  useEffect(markAllAsReceived, []);

  return (
    <ContainerDiv>
      <CenterDiv>
        <Header>
          <H1>Notifications</H1>
          <ClearAllButton
            disabled={!newNotificationsList.some(element => !element.read)}
            onClick={() => {
              markAllAsRead();
            }}
          >
            Mark all as read
          </ClearAllButton>
        </Header>
        <div>
          {isLoading ? (
            <CenteredLoading>
              <Text>Loading...</Text>
              <BounceLoader loading={isLoading} />
            </CenteredLoading>
          ) : (
            <>
              {newNotificationsList.length > 0 && (
                <NotificationList
                  list={newNotificationsList}
                  onItemClick={onItemClick}
                />
              )}

              {newNotificationsList.length === 0 && (
                <H4>No new notifications</H4>
              )}

              {pastNotificationsList.length > 0 && (
                <NotificationList
                  list={pastNotificationsList}
                  onItemClick={onItemClick}
                />
              )}
            </>
          )}
        </div>
      </CenterDiv>
    </ContainerDiv>
  );
};

const CenteredLoading = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const Header = styled.div`
  width: 93%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ContainerDiv = styled.div`
  margin: auto;
  max-width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  overflow-y: scroll;
  @media ${ResponsiveDeviceMaxWidth.tablet} {
    max-width: 100%;
    display: block;
    text-align: center;
    padding-bottom: 30px;
  }
`;

const CenterDiv = styled.div`
  display: block;
  text-align: left;
  width: 100%;
  padding: 24px 0 48px;

  @media ${ResponsiveDeviceMaxWidth.tablet} {
    margin-left: 12px;
  }
`;

const ClearAllButton = styled(Button)`
  align-self: center;
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};
  background: transparent;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

export default NotificationsView;
