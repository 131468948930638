import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { ResponsiveDeviceMinWidth } from '@televet/televet-ui';

import BackButton from '../BackButton';
import TeleVetLogoSplashscreen from '../../../assets/png/televet-logo-splashscreen.png';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => {
    return theme.palette.grayLightest;
  }};
  display: flex;

  height: 100%;
  width: 100%;
  justify-content: center;
`;

const Container = styled.div`
  background-color: ${({ theme }) => {
    return theme.palette.white;
  }};
  border: ${({ theme }) => {
    return `1px solid ${theme.palette.grayLightest}`;
  }};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @media ${ResponsiveDeviceMinWidth.tablet} {
    flex-direction: row;
    height: 65%;
    max-width: 1024px;
    width: 85%;
  }

  @media ${ResponsiveDeviceMinWidth.laptop} {
    height: 75%;
    max-width: 1440px;
    width: 85%;
  }
`;

const Banner = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  display: none;
  display: flex;
  flex: 1 1 20%;

  @media ${ResponsiveDeviceMinWidth.tablet} {
    display: block;
    background-color: ${({ theme }) => {
      return theme.palette.primary;
    }};
    flex: 2;
    height: 100%;
  }
`;

const TitleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 30%;
`;

const Content = styled.div`
  box-sizing: border-box;
  flex: 3;
  overflow: auto;
  padding: 0 1em 1em 1em;

  @media ${ResponsiveDeviceMinWidth.tablet} {
    padding: 0 4em 4em 4em;
  }
`;

const ContentHeader = styled.div`
  align-items: flex-end;
  display: flex;
  height: 2em;
  width: 100%;

  @media ${ResponsiveDeviceMinWidth.tablet} {
    height: 4em;
  }
`;

const CenteredBanner = ({ children, onBack }) => {
  return (
    <Wrapper>
      <Container>
        <Banner>
          <TitleImage src={TeleVetLogoSplashscreen} alt="TeleVet" />
        </Banner>
        <Content>
          <ContentHeader>
            {onBack && (
              <BackButton onClick={onBack}>
                <svg
                  width="23"
                  height="16"
                  viewBox="0 0 23 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.5 8H1.5"
                    stroke="#3C69E5"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.5 1L1.5 8L8.5 15"
                    stroke="#3C69E5"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </BackButton>
            )}
          </ContentHeader>
          {children}
        </Content>
      </Container>
    </Wrapper>
  );
};

CenteredBanner.propTypes = {
  children: PropTypes.node.isRequired,
  onBack: PropTypes.func,
};

export default CenteredBanner;
