import { gql } from '@apollo/client';

export const UPDATE_PET_PARENT_SETTINGS = gql`
  mutation updatePetParentSetting(
    $data: PetParentSettingUpdateInput!
    $where: PetParentSettingWhereUniqueInput!
  ) {
    updatePetParentSetting(data: $data, where: $where) {
      id
    }
  }
`;
