import axios from 'axios';

import getUser from '../../user/actions/getUser';
import firebase from '../../../firebase/firebase';
import { pushSnackbar } from '../../snackbar/snackbarActions';

/**
 * @function
 * @param {object} consultation Full consultation data object (only the relevant properties listed as params)
 * @param {string} consultation.clinicId String containing the consultation's associated clinicId
 * @param {string} consultation.consultationId String containing the consultation's associated consultationId
 * @param {string} consultation.transactionId String containing the consultation's associated transactionId
 * @returns {Promise} A promise that resolves with the server's response to the cancellation request
 */
const cancelConsultation = consultation => {
  return (dispatch, getState) => {
    const db = firebase.database();
    const restURL = getState().variable.restURL;
    const url = `${restURL}/payment/owner_cancel`;
    const { transactionId, clinicId, consultationId } = consultation;
    const consultationData = {
      transactionId,
      clinicId,
      recordId: consultationId,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url, consultationData)
        .then(response => {
          if (response?.data?.transaction?.status === 'voided') {
            Promise.all([
              db.ref(`consults/${consultationId}`).update({
                state: 'CANCELLED',
                cancelledAt: new Date().getTime(),
              }),
              db
                .ref(
                  `clinics/${clinicId}/info/private/consults/active/${consultationId}`
                )
                .set(null),
              db
                .ref(
                  `clinics/${clinicId}/info/private/consults/inactive/${consultationId}`
                )
                .set(true),
            ])
              .then(() => {
                dispatch(getUser()).then(() => resolve(response));
              })
              .catch(error => {
                console.error(error);
                dispatch(
                  pushSnackbar({
                    type: 'error',
                    message:
                      'An error occured when cancelling the consultation: ' +
                      error.message,
                  })
                );
              });
          } else {
            reject(response);
            dispatch(
              pushSnackbar({
                type: 'error',
                message:
                  'Something went wrong cancelling this consultation: ' +
                  (response?.data?.message || 'unknown error'),
                timeoutMs: 10000,
              })
            );
          }
        })
        .catch(error => {
          console.error(error);
          dispatch(
            pushSnackbar({
              type: 'error',
              message:
                'Something went wrong cancelling this consultation: ' +
                error.message,
            })
          );
          reject(error);
          throw ('Error creating transaction: ', error);
        });
    });
  };
};

export default cancelConsultation;
