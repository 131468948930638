import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { ResponsiveDeviceMaxWidth } from '@televet/televet-ui';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import { H5 } from '../Common/Headers';
import PetAvatar from '../Common/PetAvatar';
import Avatar from '../Common/Avatar';
import { showNotificationAlert, getTimeAgo } from '../../utilities';

const tooltipTheme = makeStyles(theme => ({
  arrow: {
    color: '#38447E',
  },
  tooltip: {
    backgroundColor: '#38447E',
    fontSize: 12,
  },
}));

function DateTooltip(props) {
  const classes = tooltipTheme();

  return <Tooltip arrow classes={classes} {...props} />;
}

const NotificationList = ({ list, onItemClick }) => {
  const pets = useSelector(state => state.pets);
  const consultations = useSelector(state => state.consultations);
  const vets = useSelector(state => state.vets);

  return (
    list.length > 0 &&
    list.map((notification, index) => {
      const data = showNotificationAlert(notification);
      const date = notification.createdOnDate;
      const consultation = consultations[notification.consult];
      const assignedVeterinarian = vets[consultation?.assignor];
      const petId = notification.pet;
      const pet = pets[petId];

      if (Object.keys(pets).includes(petId) && notification && consultation) {
        return (
          <StyledNotification
            read={notification.read}
            key={index}
            onClick={() => onItemClick(notification, data.message)}
          >
            <InfoContainer>
              <Circle read={notification.read} />
              <ImageContent>
                {notification.type === 'BOOKED' && assignedVeterinarian ? (
                  <Avatar height="36px" src={assignedVeterinarian.profilePic} />
                ) : (
                  <PetAvatar pet={pet} size="small" />
                )}
              </ImageContent>
              <InfoContent>
                <MessageHeader>{data.message}</MessageHeader>
                {notification.messages && (
                  <Message>
                    A new message was sent to you by the {pet.clinicDisplay}{' '}
                    team
                  </Message>
                )}
                {notification.type === 'BOOKED' && consultation.scheduledAt && (
                  <Message>{getTimeAgo(consultation.scheduledAt)}</Message>
                )}
              </InfoContent>
              <div>
                <DateTooltip arrow title={date}>
                  <TimeStamp>{data.timeAgo}</TimeStamp>
                </DateTooltip>
              </div>
            </InfoContainer>
          </StyledNotification>
        );
      }

      return null;
    })
  );
};

const Circle = styled.div`
  display: inline-block;
  height: 6px;
  width: 6px;
  border-radius: 20px;
  margin: 0 10px 0 0;
  background-color: #3c69e5;
  display: ${({ read }) => (read ? 'none' : 'inherit')};
`;

const StyledNotification = styled.div`
  max-width: 93%;
  background-color: ${({ read }) => (read ? '#F4F4F4' : '#D9E8FF')};
  border-radius: 12px;
  cursor: pointer;
  height: 87px;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2),
    6px 6px 10px 0 rgba(0, 0, 0, 0.19);
  margin: 12px 0 24px 0;
  border: 1px solid transparent;

  &:hover {
    background-color: ${({ read, theme: { palette } }) =>
      read ? '#EFEFEF' : `${palette.white}`};
    border: 1px solid
      ${({ theme: { palette } }) => `${palette.primaryLightest}`};
  }

  &:active {
    background-color: ${({ read, theme: { palette } }) =>
      read ? '#EAEAEA' : `${palette.white}`};
    border: 1px solid
      ${({ theme: { palette } }) => `${palette.primaryLightest}`};
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: baseline;
  padding: 12px 0 0 0;

  @media ${ResponsiveDeviceMaxWidth.tablet} {
    padding-left: 2%;
  }
`;

const ImageContent = styled.div`
  display: inline-block;
  align-self: center;
`;

const InfoContent = styled.div`
  width: 70%;
  p {
    font-size: ${({ theme }) => theme.font.fontSizes.sm};
  }
`;

const Message = styled.p`
  color: ${({ theme: { palette } }) => `${palette.gray300}`};
  padding-left: 2%;
  font-size: 12px !important;

  @media ${ResponsiveDeviceMaxWidth.tablet} {
    font-size: 8px;
  }
`;

const TimeStamp = styled.p`
  color: ${({ theme: { palette } }) => `${palette.gray300}`};
  font-size: 12px;
`;

const MessageHeader = styled(H5)`
  padding-left: 2%;
  font-size: 18px;

  @media ${ResponsiveDeviceMaxWidth.tablet} {
    font-size: 16px;
  }
`;

export default NotificationList;
