import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Button } from '@televet/televet-ui';

interface IQuestion {
  prompt: string;
  answer?: string | String[];
  noAnswer?: boolean;
  type?: string;
}

interface ICompletedContent {
  formName: string;
  introMessage: string;
  questions: IQuestion[];
}

interface Props {
  completedContent: ICompletedContent;
}

const SubmittedForm = ({ completedContent }: Props): ReactElement => {
  const history = useHistory();

  const { questions } = completedContent;

  const handleClose = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      window.close();
    }
  };

  return (
    <SubmittedMessage>
      <MessageText>This form has been already submitted,</MessageText>{' '}
      <MessageText>Thank you!</MessageText>
      <br />
      <BackButton
        fullwidth={true}
        onClick={() => handleClose()}
        data-html2canvas-ignore
      >
        Return to Chat
      </BackButton>
      <QuestionsAndAnswers>
        {questions?.map((question, index) => {
          return (
            <Question key={`question_${index}`} index={index}>
              {!question?.noAnswer ? (
                <>
                  <Prompt>{question?.prompt}</Prompt>

                  {typeof question?.answer === 'object' ? (
                    <Answer>
                      {question?.answer.map((element, answerIndex) => {
                        return (
                          <p
                            key={`multiple-choice-question-${index}-answer-${answerIndex}`}
                          >
                            {element}
                          </p>
                        );
                      })}
                    </Answer>
                  ) : (
                    <Answer>{question?.answer || 'N/A'}</Answer>
                  )}
                </>
              ) : (
                <>
                  {question?.prompt.split('\n').map((line, lineIndex) => {
                    return (
                      <StyledParagraph
                        key={`question-${index}-line-${lineIndex}`}
                      >
                        {line}
                      </StyledParagraph>
                    );
                  })}
                </>
              )}
            </Question>
          );
        })}
      </QuestionsAndAnswers>
    </SubmittedMessage>
  );
};

export default SubmittedForm;

const SubmittedMessage = styled.div`
  font-size: 1.25em;
  line-height: 1.5em;
  text-align: center;
  padding-top: 20px;
`;

const BackButton = styled(Button)`
  width: 365px;
  height: 45px;
  border-radius: 15px;
  background-color: #39adc3;
  font-family: LinearSans;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;

  color: #ffffff;
`;

const MessageText = styled.p`
  font-family: LinearSans;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #73747d;
`;

const QuestionsAndAnswers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Question = styled.div<any>`
  width: 100%;
  padding: 40px 20px;

  background-color: ${props =>
    props.index % 2 === 0 ? 'white;' : 'transparent'};
`;

const Prompt = styled.p`
  font-family: LinearSans;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #73747d;
`;
const Answer = styled.pre`
  width: 100%;
  flex-wrap: wrap;
  white-space: normal;
  font-family: LinearSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #73747d;
  margin-left: 20px;
  margin-top: 10px;
`;

const StyledParagraph = styled.p`
  font-size: 20px;
  min-height: 20px;
  font-family: LinearSans;
  letter-spacing: 0.16px;
  color: #73747d;
`;
