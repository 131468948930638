import React from 'react';
import styled from 'styled-components/macro';

import Background from '../../assets/svg/chat-background.svg';
import CenterImage from '../../assets/svg/empty-chat.svg';
import { requiresPayment, isClosed } from '../../utilities/consultations';
import { H5, H6 } from '../Common/Headers';

export default function EmptyChat({ consultation }) {
  let callToAction = 'Type a message below to begin.';
  if (isClosed(consultation)) {
    callToAction =
      'Create a new consultation to chat with your veterinary care team.';
  } else if (requiresPayment(consultation)) {
    callToAction = 'Confirm this consultation to begin.';
  }

  return (
    <Wrapper>
      <StyledCenterImage src={CenterImage} alt="Chat with your care team" />
      {!isClosed(consultation) && <H5>Chat with your care team!</H5>}
      <H6>{callToAction}</H6>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  text-align: center;

  & ${H5}, ${H6} {
    padding: 0 2rem;
    line-height: 1.5;
  }
`;

const StyledCenterImage = styled.img`
  width: 40%;
`;
