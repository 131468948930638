import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Prompt from './Shared/Prompt';
import QuestionWrapper from './Shared/QuestionWrapper';
import RequiredQuestionMessage from './Shared/RequiredQuestionMessage';

interface IDate {
  index: number;
  prompt: string;
  answer: string;
  setIsDraftSaved: Function;
  setAnswerAtIndex: Function;
  isRequired: boolean;
  isInvalid: boolean;
}

const Date = ({
  index,
  prompt,
  answer,
  setIsDraftSaved,
  setAnswerAtIndex,
  isRequired,
  isInvalid,
}: IDate): ReactElement => {
  return (
    <QuestionWrapper index={index}>
      <Prompt prompt={prompt} isRequired={isRequired} />
      <br />
      <StyledInput
        type="date"
        value={answer}
        data-mixpanel-name="Date input form field"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const answer = e.currentTarget.value;

          setIsDraftSaved(false);
          setAnswerAtIndex(answer, index);
        }}
      />
      {isInvalid ? <RequiredQuestionMessage /> : null}
    </QuestionWrapper>
  );
};

export default Date;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: 1em;
  font-family: LinearSans;
  border-radius: 7px;
  border: solid 1px #d2d2d2;
`;
