import appActionTypes from '../appActionTypes';

const setIsImpersonatingTo = boolean => {
  return {
    type: appActionTypes.setIsImpersonatingTo,
    payload: boolean,
  };
};

export default setIsImpersonatingTo;
