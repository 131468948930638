import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Container } from '@material-ui/core';

import getClientToken from '../../redux/payment/actions/getClientToken';
import getCustomerToken from '../../redux/payment/actions/getCustomerToken';

const Home = () => {
  const dispatch = useDispatch();
  const customerId = useSelector(state => state.user.info.private.customerId);

  useEffect(() => {
    if (customerId) {
      dispatch(getClientToken());
      dispatch(getCustomerToken(customerId));
    }
  });

  return (
    <div style={{ width: '100%' }}>
      <Container style={{ width: '100%', height: '100%' }}>
        <Redirect to="/pets" />
      </Container>
    </div>
  );
};

export default Home;
