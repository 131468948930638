import { gql } from '@apollo/client';

export const SUBSCRIBE_TO_CHANNEL = gql`
  subscription subscribeToChannel($where: ChannelSubscriptionWhereInput!) {
    channel(where: $where) {
      node {
        id
        lastMessage {
          id
          createdAt
          index
          body
        }
        channelStatus {
          id
          channelStatusAction
        }
      }
    }
  }
`;

export const SUBSCRIBE_TO_CHANNEL_MEMBERS = gql`
  subscription subscribeToChannelMembers(
    $where: ChannelMemberSubscriptionWhereInput!
  ) {
    channelMember(where: $where) {
      node {
        id
        lastConsumedMessageIndex
      }
    }
  }
`;

export const SUBSCRIBE_TO_CHANNEL_MESSAGES = gql`
  subscription subscribeToChannelMessages(
    $where: ChannelMessageSubscriptionWhereInput!
  ) {
    channelMessage(where: $where) {
      node {
        id
        createdAt
        updatedAt
        body
        index
        messageType
        attachments {
          id
          attachmentType
          entityType
          entityId
          filename
          url
        }
        author {
          id
          twilioIdentity
          user {
            id
            firstName
            lastName
            profilePic
          }
          clinicPetParent {
            id
            firstName
            lastName
          }
        }
        attributes
        channel {
          id
        }
      }
    }
  }
`;
