import styled from 'styled-components/macro';
import React from 'react';
import { Modal, ModalSizes } from '@televet/televet-ui';
import { modalNames } from '../constants/modalNames';
import { ChannelMessageAttachmentType } from '../../enums';

const ChatMediaPreviewModal = ({
  fileURL,
  stagedFile,
  attachmentType,
  fileName,
}) => {
  if (fileURL && attachmentType) {
    return (
      <Modal
        name={modalNames.chatMediaPreview}
        size={ModalSizes.lg}
        overlayOpacity={0.5}
      >
        <Alignment>
          {attachmentType === ChannelMessageAttachmentType.Image && (
            <PreviewImage
              src={fileURL}
              alt={
                stagedFile?.name || fileName || 'File name could not be found'
              }
            />
          )}
          {attachmentType === ChannelMessageAttachmentType.Video && (
            <PreviewVideo src={fileURL} controls>
              Your browser does not support the video tag.
            </PreviewVideo>
          )}
          {attachmentType === ChannelMessageAttachmentType.File && (
            <PreviewIframe
              allow="fullscreen"
              title={stagedFile?.name || attachmentType}
              src={fileURL}
            />
          )}
        </Alignment>
      </Modal>
    );
  }
  return null;
};

export default ChatMediaPreviewModal;

const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 600px;
`;

const PreviewVideo = styled.video`
  max-width: 100%;
  max-height: 600px;
`;

const PreviewIframe = styled.iframe`
  width: 100%;
  height: auto;
  max-height: 600px;
  min-height: 500px;
  max-height: 600px;
`;

const Alignment = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
