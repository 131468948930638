import produce from 'immer';
// import variableActionTypes from './userActionTypes';

const initialState = {
  restURL:
    process.env.REACT_APP_PRODUCTION === 'true'
      ? 'https://api.gettelevet.com'
      : 'https://testapi.gettelevet.com',
  graphQLurl:
    process.env.REACT_APP_PRODUCTION === 'true'
      ? 'https://prod.gettelevet.com/core-api'
      : 'https://dev.gettelevet.com/core-api',
};

let VariableReducer = produce((draft, action) => {
  switch (action.type) {
    case 'null':
      break;

    default:
      break;
  }
}, initialState);

export default VariableReducer;
