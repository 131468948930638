import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Text from '../theme/Text';
import sendReceipt from '../../redux/app/actions/sendReceipt';
import ActionButton from '../Common/ActionButton';
import { getTimeAgo } from '../../utilities';
import resetIsReceiptSent from '../../redux/app/actions/resetIsReceiptSent';

const Resolved = props => {
  const {
    consultation,
    clinic,
    isReceiptSent,
    receiptError,
    resetIsReceiptSent,
    resolvedVetImage,
  } = props;
  const { consultationId } = props.match.params;

  const [timeAgo, setTimeAgo] = useState(getTimeAgo(consultation?.resolvedAt));

  useEffect(() => {
    //create timer on component mount

    const timer = setInterval(() => {
      setTimeAgo(getTimeAgo(consultation?.resolvedAt));
    }, 1000 * 60);

    // returned function will be called on component unmount
    return () => {
      clearInterval(timer);
    };
  }, [consultation]);

  useEffect(() => {
    resetIsReceiptSent();
  }, [resetIsReceiptSent]);

  if (!consultation) return null;

  return (
    <Container>
      <div>
        <InfoRow>
          <Text style={{ width: '80%', fontSize: 18, color: '' }}>
            Your consultation was resolved
          </Text>
          <Text style={{ margin: '6px 0 12px', fontSize: 12 }}>{timeAgo}</Text>
        </InfoRow>
        <Text>
          Response from{' '}
          <span style={{ color: 'hsl(224,76%,57%)' }}>{clinic?.info.name}</span>
        </Text>
        <ContentDiv>
          <div>
            <VetAvatar src={resolvedVetImage} alt="your vet's avatar" />
          </div>
          <div>
            <IntroText style={{ lineHeight: '1.3rem', marginLeft: 12 }}>
              {consultation.treat}
            </IntroText>
          </div>
        </ContentDiv>
        {consultation.clientCost !== undefined && (
          <>
            <Text>
              Your payment summary with{' '}
              <span style={{ color: 'hsl(224,76%,57%)' }}>
                {clinic?.info.name}
              </span>
            </Text>
            <ContentDiv style={{ alignItems: 'end' }}>
              <div style={{ width: '80%' }}>
                <Text style={{ fontSize: 16 }}>Final Charge</Text>
                <GreenText style={{ fontSize: 16 }}>
                  {' '}
                  ${consultation.clientCost.toFixed(2)}
                </GreenText>
              </div>
              <div>
                {isReceiptSent && (
                  <Text>We sent you an email with your receipt...</Text>
                )}
                {receiptError && <Text color="secondary">{receiptError}</Text>}

                <SendReceiptButton
                  name="send-receipt-button"
                  onClick={() => props.sendReceipt(consultationId)}
                >
                  Send Receipt
                </SendReceiptButton>
                <Text>{props.app?.receipt.status}</Text>
              </div>
            </ContentDiv>
          </>
        )}
      </div>
    </Container>
  );
};

const IntroText = styled(Text)`
  color: ${({ theme }) => theme.palette.black} !important;
`;

const ContentDiv = styled.div`
  display: flex;
  margin: 12px 0 24px;
  padding: 16px 20px;
  background: #ebf0f9;
  border-radius: 12px;
  @media (max-width: 445px) {
    display: block;
    text-align: center;
  }
`;

const SendReceiptButton = styled(ActionButton)`
  min-width: 128px;
`;

const GreenText = styled(Text)`
  color: ${({ theme }) => theme.palette.success};
`;

const InfoRow = styled.div`
  width: 100%;
  display: flex;
  margin: 12px 0;
  justify-content: space-between;
  p {
    color: ${({ theme }) => theme.palette.black};
  }
`;
const Container = styled.div`
  padding-bottom: 8px;
  width: 100%;
`;

const VetAvatar = styled.img`
  height: 60px;
  border-radius: 30px;
`;

const mapStateToProps = state => ({
  consultations: state.consultations,
  isReceiptSent: state.app.receipt.isReceiptSent,
  receiptError: state.app.receipt.errors,
});
const mapDispatchToProps = { sendReceipt, resetIsReceiptSent };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Resolved));
