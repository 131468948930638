import styled from 'styled-components/macro';
import { ResponsiveDeviceMinWidth } from '@televet/televet-ui';

const H1 = styled.h1`
  font-size: ${({ theme }) => {
    return theme.font.headlineSizes.h2;
  }};
  margin: ${({ theme }) => {
    return `${theme.spacing.spacingSizes.md} 0`;
  }};

  @media ${ResponsiveDeviceMinWidth.tablet} {
    font-size: ${({ theme }) => {
      return theme.font.headlineSizes.h1;
    }};
    margin: ${({ theme }) => {
      return `${theme.spacing.spacingSizes.lg} 0`;
    }};
  }
  color: ${({ theme }) => theme.palette.black};
`;

export default H1;
