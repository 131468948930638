export const PET_NAME_SUBMIT = 'PET_NAME_SUBMIT';
export const PET_PRACTICE_SUBMIT = 'PET_PRACTICE_SUBMIT';
export const PET_PHOTO_SUBMIT = 'PET_PHOTO_SUBMIT';
export const PET_INFO_SUBMIT = 'PET_INFO_SUBMIT';
export const PET_SET_STATE = 'PET_SET_STATE';
export const PET_RESET_STATE = 'PET_RESET_STATE';

export const onPetNameSubmit = payload => {
  return {
    type: PET_NAME_SUBMIT,
    payload,
  };
};

export const onPetPracticeSubmit = payload => {
  return {
    type: PET_PRACTICE_SUBMIT,
    payload,
  };
};

export const onPetPhotoSubmit = payload => {
  return {
    type: PET_PHOTO_SUBMIT,
    payload,
  };
};

export const onPetInfoSubmit = payload => {
  return {
    type: PET_INFO_SUBMIT,
    payload,
  };
};

export const onPetSetState = payload => {
  return {
    type: PET_SET_STATE,
    payload,
  };
};

export const petResetState = () => {
  return {
    type: PET_RESET_STATE,
  };
};
