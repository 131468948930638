import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { ResponsiveDeviceMinWidth } from '@televet/televet-ui';

import ActionButton from '../../Common/ActionButton';
import { formatPossessiveName } from '../../../utilities';
import ClinicPlaceHolderLogo from '../../../assets/svg/clinic-logo-placeholder.svg';

const ClinicListItem = ({ clinic, onSelect }) => (
  <StyledClinic>
    <InfoContainer>
      <ImageContent>
        <ClinicImage
          src={clinic.info.logo || ClinicPlaceHolderLogo}
          alt={`${formatPossessiveName(clinic.info.name) ||
            "your clinic's"} logo`}
        />
      </ImageContent>
      <InfoContent>
        <h5>{clinic.info.name}</h5>
        {clinic.info.city && (
          <p>{`${clinic.info.city}, ${clinic.info.state}`}</p>
        )}
      </InfoContent>
    </InfoContainer>
    <ActionContainer>
      <StyledButton buttonType="ghost" onClick={() => onSelect(clinic)}>
        Select
      </StyledButton>
    </ActionContainer>
  </StyledClinic>
);

ClinicListItem.propTypes = {
  clinic: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ClinicListItem;

const StyledButton = styled(ActionButton)`
  background-color: white;
  height: 30px;
  margin-bottom: 0;
  min-width: 66px;
  padding-top: 0;
  padding-bottom: 0;

  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const ClinicImage = styled.img`
  border-radius: 4px;
  height: 36px;
  margin-right: ${({ theme }) => theme.spacing.spacingSizes.sm};
  width: 36px;
`;

const StyledClinic = styled.div`
  align-items: flex-start;
  background-color: rgba(60, 105, 229, 0.05);
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: auto;
  margin: ${({ theme }) => theme.spacing.spacingSizes.sm} 0;
  padding: ${({ theme }) => theme.spacing.spacingSizes.lg};
`;

const InfoContainer = styled.div`
  flex: 2 0 0;
  text-align: left;
`;

const ActionContainer = styled.div`
  flex: 1 0 0;
  text-align: right;
`;

const InfoContent = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 80%;

  p {
    font-size: ${({ theme }) => theme.font.fontSizes.sm};
  }
`;

const ImageContent = styled.div`
  display: none;
  @media ${ResponsiveDeviceMinWidth.mobileL} {
    display: inline-block;
    vertical-align: top;
    width: 20%;
  }
`;
