import * as moment from 'moment-timezone';
import notificationSet from './notificationSet';
import { getPromoCodes } from '../api/getPromoCodes';

/**
 * @module Utilities
 */

/**
 * @function
 * @param {string} name - A string to be capitalized
 * @returns {string} - The string with the first character in upper case
 */
export const capitalizeString = name => {
  if (!name) return '';
  return name.charAt(0).toUpperCase() + name.slice(1);
};

/**
 * @function
 * @param {number} timestamp - Unix timestamp in milliseconds
 * @returns {string} - String with absolute time
 */
export const getTime = timestamp => {
  return [moment(timestamp).format('dddd'), moment(timestamp).format('ll')];
};

/**
 * @function
 * @param {number} timestamp - Unix timestamp in milliseconds
 * @returns {string} - String with relative time to timestamp in human readable words, ie. "2 hours ago"
 */
export const getRelativeTime = timestamp => {
  moment.locale('en', {
    sameDay: '[Today at] LT',
    nextDay: '[Tomorrow at] LT',
    nextWeek: 'dddd [at] LT',
    lastDay: '[Yesterday at] LT',
    lastWeek: 'dddd [last week at] LT',
    sameElse: 'dddd d MMM YYYY',
  });

  if (!timestamp) {
    return false;
  }
  return moment(timestamp).calendar();
};

/**
 * @function
 * @param {number} timestamp - Unix timestamp in milliseconds
 * @returns {string} - String with relative time to timestamp in human readable words, ie. "2 hours ago"
 */
export const createdOnDate = timestamp => {
  return moment(timestamp).format('LLL');
};

/**
 * @function
 * @param {number} timestamp - Unix timestamp in milliseconds
 * @param {number} timezone - Timezone number from clinic to be converted
 * @returns {string} - String with relative time to timestamp in human readable words, ie. "2 hours ago"
 */
export const appointmentDate = (timestamp, timezone, format) => {
  let stringTimezone =
    typeof timezone === 'string' ? timezone : 'America/New_York';
  switch (timezone) {
    case 5:
      stringTimezone = 'America/New_York';
      break;
    case 6:
      stringTimezone = 'America/Chicago';
      break;
    case 7:
      stringTimezone = 'America/Denver';
      break;
    case 8:
      stringTimezone = 'America/Los_Angeles';
      break;
    case 9:
      stringTimezone = 'America/Anchorage';
      break;
    case 10:
      stringTimezone = 'Pacific/Honolulu';
      break;
    default:
      break;
  }
  return moment.tz(timestamp, stringTimezone).format(format);
};
/**
 * @function
 * @param {number} timestamp - Unix timestamp in milliseconds
 * @returns {string} - String with time elapsed since timestamp in human readable words, ie. "2 hours ago"
 */
export const getTimeAgo = timestamp => {
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'a few seconds',
      ss: '%d seconds',
      m: '1 minute',
      mm: '%d minutes',
      h: '1 hour',
      hh: '%d hours',
      d: '1 day',
      dd: '%d days',
      M: '1 month',
      MM: '%d months',
      y: '1 year',
      yy: '%d years',
    },
  });

  if (!timestamp) {
    return false;
  }
  return moment(timestamp).fromNow();
};

/**
 * @function
 * @param {object} DOB Object containing the date of birth
 * @param {number} DOB.day Day of birth
 * @param {number} DOB.month Month of birth
 * @param {number} DOB.year Year of birth
 * @returns {string} String with age in human readable words ie. "7 years old"
 */
export const getAge = DOB => {
  return moment(`${DOB?.year}${DOB?.month}${DOB?.day}`, 'YYYYMMDD')
    .fromNow()
    .replace('ago', 'old');
};

/**
 * @function
 * @param {object} notification Object containing the data for a consultation notification
 * @param {string} notification.type String containing the type of the notification, ie. 'opened', 'cancelled' (all lowercase)
 * @param {string} notification.message String containing the appropriate message to display in the list of notifications
 * @returns {string} String with the proper notification message
 */
export const showNotificationAlert = notification => {
  const timeStampKeys = ['created', 'assigned', 'received', 'read'];
  const notificationTimestamps = {};
  timeStampKeys.forEach(key => {
    notificationTimestamps[key] =
      notification[key] === false ? null : notification[key];
  });

  const timeAgo =
    notificationTimestamps.received ||
    notificationTimestamps.assigned ||
    notificationTimestamps.created;

  if (!notification.type)
    console.error('No notification type found for: ' + notification);

  const notificationString =
    notificationSet[notification?.type?.toLowerCase()]?.message ||
    'New Notification';

  return {
    message: notificationString,
    timeAgo,
  };
};

/**
 * @function
 * @param {object} consultation Object containing the data for a consultation
 * @param {string} consultation.state String containing the status of the consultation, ie. 'opened', 'cancelled' (all lowercase)
 * @param {string} notification.message String containing the appropriate message to display in the list of notifications
 * @returns {string} String with the proper notification message
 */
export const showConsultationType = consultation => {
  return notificationSet[consultation.state.toLowerCase()]?.message;
};

/**
 * @function
 * @param {moment} pastTime Unix timestamp with the earlier of the two times
 * @param {string} timeScale String with the scale of time to return (i.e. seconds, hours, days)
 * @param {timestamp} recentTime (Optional, defaults to current second) Unix timestamp with the more recent of the two timestamps
 */
export const getTimeDifference = (pastTime, timeScale, recentTime) => {
  recentTime = moment(recentTime) || moment();
  return recentTime.diff(moment(pastTime), timeScale);
};

/**
 * @function getPromoCodesByClinic Given a clinicId and a users promos object, returns the user's promo codes
 * that match that clinic, sorted by time added, most recent added first
 * @param clinicId - id of the clinic you want to find promo codes for
 * @param userPromos - object containing user's promo codes
 * @returns An array of any found promo code objects, or empty array if none found
 */
export const getPromoCodesByClinic = (clinicId, userPromos) => {
  const byTimeAdded = (a, b) => {
    return a.added < b.added ? 1 : -1;
  };
  return userPromos
    ? objectToArray(userPromos)
        .filter(promo => promo.clinicId === clinicId)
        .sort(byTimeAdded)
    : [];
};

/**
 * @function isCodeUsable Determines if a promo code has been used or not based on these rules:
 * available = how many times a promo code can be used per account
 * if available === 0 it means it has unlimited uses (ex. wellness plan member)
 * used = how many times a promo code has been used
 * When a promo code is used, we increment the used field by 1. "used" means applied at checkout
 * If used === available, the promo code can no longer be applied and is listed as used, unless
 * available === 0. Codes can only be added to the account once, even those with unlimited uses
 * @param {used} count of times code has been used by this customer
 * @param {available} count of how many times a promo code can be used per account
 * returns true or false
 */
export const isCodeUsable = ({ used, available, exp }) => {
  if (available === 0 || available > used) {
    return true;
  }
  return false;
};

/**
 * Determines if the current date is past the expiration date and therefore expired
 * @param {exp} expiration date as a time stamp
 * In order to allow promo codes that expire today to still be added today,
 * today's date is set to the beginning of the day.
 * returns true or false
 */
export const isCodeExpired = ({ exp }) => {
  const today = moment().startOf('day');
  return today.valueOf() >= exp;
};

export const checkNewPromoCode = async (
  promoCode,
  clinicId,
  userPromoCodes
) => {
  const foundCodes = await getPromoCodes(promoCode);

  // Does code exist for any clinic
  if (!foundCodes) {
    return {
      isValid: false,
      codeId: null,
      codeObj: null,
      message: { text: 'Code not valid', severity: 'error' },
    };
  }
  // is code from the selected clinic
  const codeId = Object.keys(foundCodes)[0];
  const codeObj = Object.values(foundCodes)[0];
  if (codeObj.clinic !== clinicId) {
    return {
      isValid: false,
      codeId,
      codeObj,
      message: { text: 'Code not valid for this clinic', severity: 'error' },
    };
  }
  // does user already have this code
  const userPromoCodesForThisClinic = getPromoCodesByClinic(
    clinicId,
    userPromoCodes
  );
  if (
    userPromoCodesForThisClinic.find(
      userPromoCode => userPromoCode.code === promoCode
    )
  ) {
    return {
      isValid: false,
      codeId,
      codeObj,
      message: { text: 'Code already added!', severity: 'error' },
    };
  }
  // is code expired
  if (isCodeExpired(codeObj)) {
    return {
      isValid: false,
      codeId,
      codeObj,
      message: { text: 'Code has expired', severity: 'error' },
    };
  }
  // has code already been used
  if (!isCodeUsable(codeObj)) {
    return {
      isValid: false,
      codeId,
      codeObj,
      message: { text: 'Code has already been used', severity: 'error' },
    };
  }
  // code is valid
  return {
    isValid: true,
    codeId,
    codeObj,
    message: { text: 'Code is valid', severity: 'success' },
  };
};

/**
 * @function filterClinicsByName
 * Searches through the names of all clinics passed, doing a case insensitive
 * search for the given searchTerm.
 * @param {string} searchTerm - name or partial name of clinic to search for
 * @param {object} clinics - object containing all clinics
 * @returns array containing objects of clinics with matching name.
 * Note that the returned array contains a list of clinic objects with an
 * additional property of `id` since we lose the id when converting the shape into an array
 */
export const filterClinicsByName = (searchTerm, clinics) => {
  if (!searchTerm) {
    return [];
  }
  return Object.keys(clinics).reduce((acc, curr) => {
    return clinics[curr]?.info.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
      ? acc.concat({ ...clinics[curr], id: curr })
      : acc;
  }, []);
};

/**
 * @function filterClinicsByLocation - Searches through given clinics, filtering by those that are within
 * a given number of miles from the user's location
 * @param {object} userLocation
 * @param {object} clinics
 * @param {number} miles
 * @returns array containing objects of clinics withing the given distance.
 * Note that the returned array contains a list of clinic objects with an
 * additional property of `id` since we lose the id when converting the shape into an array
 */
export const filterClinicsByLocation = (userCoords, clinics, miles) => {
  if (!userCoords) {
    return [];
  }

  const result = Object.keys(clinics).reduce((acc, curr) => {
    const clinicInfo = clinics[curr]?.info;
    if (!clinicInfo || !clinicInfo.lat || !clinicInfo.long) {
      return acc;
    }
    const distance = getDistanceBetweenPoints(
      userCoords,
      { lat: clinicInfo.lat, long: clinicInfo.long },
      'miles'
    );
    return distance < miles
      ? acc.concat({ ...clinics[curr], id: curr, distance })
      : acc;
  }, []);

  return result.sort((a, b) => (a.distance > b.distance ? 1 : -1));
};

/**
 * @function getDistanceBetweenPoints
 * This calculates the distance between two points given the
 * latitude/longitude of those points and the unit type to use for
 * measurement
 * @param {object} start - contains lat and long properties
 * @param {object} end - contains lat and long properties
 * @param {string} units - 'miles' (default) or 'km'
 * @returns the distance in the given units ( miles or km.)
 */
const getDistanceBetweenPoints = (start, end, userUnits) => {
  const earthRadius = {
    miles: 3958.8,
    km: 6371,
  };
  const units =
    userUnits === 'miles' || userUnits === 'km' ? userUnits : 'miles';
  const R = earthRadius[units];
  const lat1 = start.lat;
  const lon1 = start.long;
  const lat2 = end.lat;
  const lon2 = end.long;

  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  return d;
};

const toRad = x => {
  return (x * Math.PI) / 180;
};

/**
 * @function getFinalCost Calculates the cost of consultations after the TeleVet Fee
 * @param {number} cost
 * @param {boolean} selectedPromo
 * @param {number} discount
 * @returns {number} Amount to be charged
 */
export const getFinalCost = (cost, selectedPromo = false, discount = 0) => {
  const fee = 5;
  cost = parseInt(cost);
  cost =
    cost +
    fee -
    (cost + fee) * ((selectedPromo ? parseInt(discount) : 0) / 100);
  return cost;
};

/**
 * @function numberFormat - formats a string as a number with up to one decimal point
 * @param {*} str - containing number to be formatted
 * @returns formatted string or null
 */
export const numberFormat = str => {
  const regex = /([1-9][0-9]*([.][0-9])?)/gm;
  const found = str && str.match(regex);
  return found ? found[0] : null;
};

export const objectToArray = obj => {
  const keys = Object.keys(obj);
  return keys.map(key => ({ ...obj[key], id: key }));
};

/**
 * @function formatPhoneNumber - formats a string as a phone number
 * @param {*} phoneNumberString - containing phone number to be formatted
 * @returns formatted string or null
 */
export const formatPhoneNumber = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

/**
 * @function formatPossessiveName - formats name for possessive cases of names (eg. "Sam's" vs "Titus'")
 * @param {string} name - name to be transformed
 */
export const formatPossessiveName = name => {
  if (name)
    if (name[name.length - 1] === 's') return capitalizeString(name) + "'";
    else return capitalizeString(name) + "'s";
  else return null;
};

export const formatStringDate = (month, day, year) => {
  return `${month || ''} ${day ? getDayOrdinal(day) : ''} ${year || ''}`;
};

const getDayOrdinal = day => {
  day = day.toString();
  if (day) {
    const dayAsNumber = Number(day);
    const suffix = dayAsNumber < 20 ? dayAsNumber : Number('' + day.slice(-1));

    switch (suffix) {
      case 1:
        return `${dayAsNumber}st`;
      case 2:
        return `${dayAsNumber}nd`;
      case 3:
        return `${dayAsNumber}rd`;
      default:
        return `${dayAsNumber}th`;
    }
  }
  return '';
};

export const getDayOptions = (month, year) => {
  if (month < 0 || !year) return [];

  const numberOfDays = new Date(year, month, 0).getDate();
  const days = [];

  for (let i = 1; i <= numberOfDays; i++) {
    let value = i.toString();
    value = value.length === 1 ? `0${value}` : value;

    days.push({
      label: value,
      value: value,
    });
  }

  return days;
};

export const getYearOptions = () => {
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= 1970; i--) {
    years.push({
      label: `${i}`,
      value: i,
    });
  }
  return years;
};

export const getMonth = monthAsNumber => {
  const monthLiteral = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
  };

  if (isNaN(monthAsNumber)) return monthAsNumber;
  return monthLiteral[monthAsNumber];
};

export const getDOBFromDate = dateSource => {
  const numericDateSource = Number(dateSource);
  if (isNaN(numericDateSource)) {
    return {};
  }
  const date = new Date(numericDateSource);
  if (!date || isNaN(date)) {
    return {};
  }
  return {
    month: String(getMonth(date.getMonth())),
    day: String(date.getDate()),
    year: String(date.getFullYear()),
  };
};

export const removeEmptyValues = obj => {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    const value = obj[key];
    if (value && typeof value === 'object') {
      newObj[key] = removeEmptyValues(value);
    } else if (value !== undefined && value !== null) {
      newObj[key] = value;
    }
  });
  return newObj;
};
