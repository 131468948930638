import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  TextInput,
  Radio as DefaultRadio,
  ResponsiveDeviceMinWidth,
} from '@televet/televet-ui';
import styled from 'styled-components/macro';
import ActionButton from '../../Common/ActionButton';
import Message from '../../Common/Message';
import { H1, H5 as DefaultH5 } from '../../Common/Headers';
import { Dog, Cat, Horse, AnimalPrint } from '../../Common/Icons';
import { Mixpanel as mixpanel } from '../../../redux/mixpanel';

const types = [
  { value: 'Dog', image: <Dog title="Dog" width="48" height="48" /> },
  { value: 'Cat', image: <Cat title="Cat" width="48" height="48" /> },
  { value: 'Horse', image: <Horse title="Horse" width="48" height="48" /> },
  {
    value: 'Other',
    image: <AnimalPrint title="Other" width="48" height="48" />,
  },
];

const PetNameForm = ({
  name,
  type,
  otherType,
  isClinicPreselected,
  onSubmit,
  isOnboarding,
}) => {
  const { register, handleSubmit, watch, setValue, errors } = useForm({
    defaultValues: {
      name,
      type,
      otherType,
    },
    mode: 'onBlur',
  });

  const currentType = watch('type');

  const onHandleSubmit = values => {
    const { name, type, otherType } = values;
    mixpanel.track('Pet name added', { isOnboarding, name });
    mixpanel.track('Pet type selected', {
      isOnboarding,
      type: otherType ? otherType : type,
    });
    mixpanel.track('Pet name and type submitted', { isOnboarding });
    onSubmit({ name, type, otherType });
  };

  return (
    <>
      <H1>Tell us about your pet</H1>
      <Message>
        Let’s get started by adding your first pet. You’ll be able to add more
        pets later if you have them.
      </Message>

      <form onSubmit={handleSubmit(onHandleSubmit)}>
        <H5>Select your pet type</H5>
        <RadioGroupContainer>
          {types.map(type => {
            const { value, image } = type;
            return (
              <RadioContainer
                key={value}
                onClick={() => setValue('type', value)}
                className={currentType === value ? 'selected' : ''}
              >
                <RadioContent>
                  {image}
                  <Radio name="type" value={value} register={register} />
                  <span>{value}</span>
                </RadioContent>
              </RadioContainer>
            );
          })}
        </RadioGroupContainer>

        {currentType === 'Other' && (
          <TextInput
            name="otherType"
            label="What type of animal?"
            register={register}
            validation={{ required: true }}
            error={errors.name && 'Please enter the type of pet.'}
          />
        )}
        <TextInput
          name="name"
          label="Your pet's name"
          register={register}
          validation={{ required: true }}
          error={errors.name && "Please enter your pet's name"}
        />
        <ActionButton>
          {isClinicPreselected
            ? 'Next: Share a photo'
            : 'Next: Add your practice'}
        </ActionButton>
      </form>
    </>
  );
};

PetNameForm.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  otherType: PropTypes.string,
  isClinicPreselected: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default PetNameForm;

const H5 = styled(DefaultH5)`
  margin-bottom: 0.3rem;
`;

const RadioGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1em 0 2em 0;
`;

const RadioContainer = styled.div`
  border-radius: ${({ theme }) => {
    return theme.borderRadius.medium;
  }};
  box-shadow: ${({ theme }) => {
    return theme.boxShadow.small;
  }};
  cursor: pointer;
  height: 135px;
  margin: 0 0 1em 0;
  position: relative;
  min-width: 100%;
  width: 100%;
  text-align: center;

  @media ${ResponsiveDeviceMinWidth.mobileM} {
    margin: 1em 0.5em;
    min-width: 135px;
    width: 135px;
  }

  &:hover,
  &.selected {
    box-shadow: ${({ theme }) => {
      return theme.boxShadow.large;
    }};

    svg {
      stroke: ${({ theme }) => {
        return theme.palette.primary;
      }};
    }
  }
`;

const RadioContent = styled.div`
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Radio = styled(DefaultRadio)`
  display: inline-block;
  padding: 0.5em 0;
`;
