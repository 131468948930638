import React from 'react';
import { isEmpty } from 'lodash';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import MainLayout from '../theme/Layout/MainLayout';
import ImpersonationBanner from '../ImpersonationBanner';
import logout from '../../redux/auth/actions/logout';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { pathname, search } = useLocation();
  const authenticated = useSelector(
    state => !isEmpty(state.auth.user && state.user.info.private)
  );
  const dispatch = useDispatch();

  const impersonate = () => {
    return <Redirect to={`/login${search}`} />; // Impersonation exception
  };

  if (authenticated) {
    if (search.includes('?token=')) {
      dispatch(logout());
      return impersonate();
    } else
      return (
        <Route
          {...rest}
          render={props => (
            <MainLayout>
              <ImpersonationBanner />
              <Component {...props} />
            </MainLayout>
          )}
        />
      );
  } else {
    if (search.includes('?token=')) {
      return impersonate();
    } else return <Redirect to={`/login?redirect=${pathname}${search}`} />;
  }
};

export default ProtectedRoute;
