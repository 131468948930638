import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
  useRef,
} from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import html2pdf from 'html2pdf.js';

import Context from '../context/curbsideContext';
import Question from './Question';
import { Button } from '@televet/televet-ui';
import styled, { keyframes } from 'styled-components/macro';
import jwt from 'jsonwebtoken';

import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_CLINIC_PET_PARENT,
  GET_FORM_SUBMISSION,
  UPDATE_FORM_SUBMISSION,
} from '../queries';

import Canine from '../../../assets/svg/canine.svg';
import Feline from '../../../assets/svg/canine.svg';
import OtherSpecies from '../../../assets/svg/pets-paw-hospital.svg';
import SubmittedForm from './SubmittedForm';
import { TRIGGER_WORKFLOW_EVENT } from '../Conversations/mutations';

import * as FullStory from '@fullstory/browser';

const Forms = () => {
  const context = useContext(Context);
  const [, setClinicPetParent] = useState({});
  const [clinic, setClinic] = useState({});
  const [pets, setPets] = useState([]);
  const [clinicPet, setClinicPet] = useState(null);
  const { token } = context;
  const { clinicPetParentId } = jwt.decode(token);
  const [draftContent, setDraftContent] = useState({});
  const [isDraftSaved, setIsDraftSaved] = useState(true);
  const [submittedAt, setSubmittedAt] = useState('');
  const [completedContent, setCompletedContent] = useState({});
  const [invalidQuestionIndexes, setInvalidQuestionIndexes] = useState([]);
  const formSubmissionId = useParams().formSubmissionId;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const forceEdit = !!params.get('forceEdit');

  const headers = {
    'auth-type': 'clinic_pet_parent_token',
    authorization: `Bearer ${token}`,
  };

  const workflowEventTrigger = params.get('workflowEventTrigger');
  const workflowEventTriggerActionNumber = parseInt(
    params.get('workflowEventTriggerActionNumber')
  );
  const workflowEventTriggerDelayInMilleseconds = parseInt(
    params.get('workflowEventTriggerDelayInMilleseconds')
  );
  const entityId = params.get('entityId');

  const [triggerWorkFlowEvent] = useMutation(TRIGGER_WORKFLOW_EVENT, {
    context: { headers },
  });

  const bodyRef = useRef(null);
  const history = useHistory();

  const { data: clinicPetParentData } = useQuery(GET_CLINIC_PET_PARENT, {
    variables: { where: { id: clinicPetParentId } },
    context: { headers },
    fetchPolicy: 'no-cache',
  });

  const { data: formSubmissionData } = useQuery(GET_FORM_SUBMISSION, {
    variables: { where: { id: formSubmissionId } },
    context: { headers },
    fetchPolicy: 'no-cache',
  });

  const [uploadDraftContent] = useMutation(UPDATE_FORM_SUBMISSION, {
    variables: {
      data: {
        draftContent,
      },
      where: {
        id: formSubmissionId,
      },
    },
    context: { headers },
    fetchPolicy: 'no-cache',
  });

  const [uploadSubmissionContent] = useMutation(UPDATE_FORM_SUBMISSION, {
    variables: {
      data: {
        completedContent: draftContent,
        submittedAt: new Date().toISOString(),
      },
      where: {
        id: formSubmissionId,
      },
    },

    context: { headers },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (clinicPetParentData) {
      const {
        clinic,
        email,
        firstName,
        lastName,
        pets,
      } = clinicPetParentData.clinicPetParent;

      setClinic(clinic);
      setClinicPetParent({ email, firstName, lastName });
      setPets(pets);
    }
  }, [clinicPetParentData]);

  useEffect(() => {
    const saveInterval = setInterval(() => {
      if (!isDraftSaved) {
        uploadDraftContent();
        setIsDraftSaved(true);
      }
    }, 1000 * 2);
    return () => {
      clearInterval(saveInterval);
    };
  });

  const validateRequiredFields = () => {
    let isSubmittable = true;
    setInvalidQuestionIndexes([]);
    const newInvalidQuestionsArray = [];
    draftContent.questions.forEach((question, index) => {
      const isRequired = question.isRequired;
      const isAnswer =
        !!question.answer &&
        question.answer[0] !== '' &&
        question.answer !== 'false';
      if (isRequired) {
        isSubmittable = isSubmittable && isAnswer;
        if (
          !question.answer ||
          question.answer === 'false' ||
          !question.answer[0]
        ) {
          newInvalidQuestionsArray.push(index);
        }
      }
    });
    setInvalidQuestionIndexes(newInvalidQuestionsArray);

    return isSubmittable;
  };

  const handleSubmit = async () => {
    if (validateRequiredFields()) {
      await uploadSubmissionContent();
      setSubmittedAt(new Date().toISOString());
      if (
        entityId &&
        workflowEventTrigger &&
        workflowEventTriggerActionNumber
      ) {
        await triggerWorkFlowEvent({
          variables: {
            data: {
              entityId: entityId,
              event: workflowEventTrigger,
              delay: workflowEventTriggerDelayInMilleseconds | 1000,
              actionNumber: workflowEventTriggerActionNumber,
            },
          },
        });
        if (
          workflowEventTriggerActionNumber === 1 &&
          process.env.REACT_APP_PRODUCTION === 'true'
        ) {
          FullStory.event('Workflow Started', {
            clinic: clinic?.name,
            appointmentId: entityId,
          });
        }
      }
      handleClose();
    }
  };

  const setAnswerAtIndex = (answer, index) => {
    // To remove validation in a single question when answering
    if (
      answer[0] &&
      invalidQuestionIndexes.indexOf(index) > -1 &&
      answer !== 'false'
    ) {
      const newInvalidQuestionIndexes = [...invalidQuestionIndexes];
      const indexToRemove = newInvalidQuestionIndexes.indexOf(index);
      newInvalidQuestionIndexes.splice(indexToRemove, 1);
      setInvalidQuestionIndexes(newInvalidQuestionIndexes);
    }
    let newDraft = JSON.stringify(draftContent);
    newDraft = JSON.parse(newDraft);
    newDraft.questions[index].answer = answer;
    setDraftContent(newDraft);
  };

  const getFormSubmission = useCallback(() => {
    const formSubmission = formSubmissionData?.formSubmission;
    if (formSubmission && formSubmission.draftContent) {
      setDraftContent({ ...formSubmission.draftContent });
    } else {
      setDraftContent(formSubmission && formSubmission.formTemplate.content);
    }

    if (formSubmission && formSubmission.submittedAt) {
      setSubmittedAt(formSubmission.submittedAt);
    }

    if (formSubmission && formSubmission.completedContent) {
      setCompletedContent(formSubmission.completedContent);
    }
    if (formSubmission && formSubmission.clinicPet) {
      setClinicPet(formSubmission.clinicPet);
    }
  }, [formSubmissionData]);

  useEffect(() => {
    getFormSubmission();
  }, [getFormSubmission]);

  const renderPetIcon = species => {
    switch (species?.toLowerCase()) {
      case 'canine':
        return Canine;
      case 'feline':
        return Feline;
      default:
        return OtherSpecies;
    }
  };

  const handleClose = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      window.close();
    }
  };

  const onExportToPdf = () => {
    if (bodyRef?.current) {
      const petName = clinicPet?.name ? `${clinicPet.name}-` : '';
      const filename = `${petName}${
        draftContent?.formName
      }-${new Date().toLocaleDateString()}.pdf`;

      const opt = {
        margin: 1,
        filename,
        image: { type: 'png', quality: 1 },
        html2canvas: { scale: 1, useCORS: true },
        pagebreak: { mode: 'avoid-all' },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      };

      html2pdf()
        .set(opt)
        .from(bodyRef.current)
        .save();
    }
  };

  return (
    <Wrapper ref={bodyRef}>
      <ClinicInfo>
        <ClinicInfoContent>
          {clinic.logo ? (
            <ClinicLogo src={clinic.logo} alt="Clinic logo" />
          ) : (
            <Spinner />
          )}

          <ClinicName>{clinic.name}</ClinicName>
        </ClinicInfoContent>
        {forceEdit && (
          <ExportAsPdf onClick={onExportToPdf} data-html2canvas-ignore>
            Export to PDF
          </ExportAsPdf>
        )}
      </ClinicInfo>
      <FormTitle>
        {draftContent?.formName}{' '}
        <StyledCloseIcon
          alt=""
          onClick={() => handleClose()}
          data-html2canvas-ignore
        />
      </FormTitle>

      {submittedAt && !forceEdit ? (
        <SubmittedForm completedContent={completedContent} />
      ) : (
        <>
          {clinicPet ? (
            <PetDisplay>
              {pets
                .filter(pet => {
                  if (pet.name === clinicPet.name) {
                    return true;
                  }
                  return false;
                })
                .map(pet => {
                  return (
                    <Pet key={pet.id}>
                      <PetIcon
                        src={renderPetIcon(pet.species)}
                        alt={`${pet.species} icon`}
                      />
                      <PetName>{pet.name}</PetName>
                    </Pet>
                  );
                })}
            </PetDisplay>
          ) : null}
          <FormDescription>{draftContent?.introMessage}</FormDescription>
          <QuestionsArea>
            {draftContent?.questions?.map((question, index) => {
              return (
                <Question
                  key={`form_submission_${formSubmissionId}_question_${index}`}
                  question={{ index, ...question }}
                  setIsDraftSaved={setIsDraftSaved}
                  setAnswerAtIndex={setAnswerAtIndex}
                  isInvalid={invalidQuestionIndexes.includes(index)}
                />
              );
            })}
          </QuestionsArea>
          <StyledSubmission>
            <SubmitButton
              fullWidth
              disabled={!isDraftSaved}
              onClick={handleSubmit}
              data-mixpanel-name="Submit form button"
              data-html2canvas-ignore
            >
              {isDraftSaved ? 'Submit Form' : 'Saving Form'}
            </SubmitButton>
            {invalidQuestionIndexes.length ? (
              <ValidationMessage>
                Please answer all required questions above.
              </ValidationMessage>
            ) : null}
          </StyledSubmission>
        </>
      )}
    </Wrapper>
  );
};

export default Forms;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
`;

const FormTitle = styled.p`
  font-family: LinearSans;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #575d7c;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  text-align: center;
  padding: 28px;
  background-color: white;
  margin-bottom: 5px;
`;

const FormDescription = styled.p`
  font-family: LinearSans;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #73747d;
  margin: 20px 50px;
`;

const QuestionsArea = styled.div`
  margin: 10px 0;
`;

const StyledSubmission = styled.div`
  width: 365px;
  align-self: center;
  text-align: center;
  margin-bottom: 50px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;

  & * {
    fill: #050f5f;
  }
`;

const ClinicInfo = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  margin-bottom: 5px;
  min-height: 71px;
`;

const ClinicInfoContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

const ClinicLogo = styled.img`
  width: 36px;
  margin: 18px;
  vertical-align: bottom;
`;

const ClinicName = styled.p`
  font-family: LinearSans;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #575d7c;
  align-self: center;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 36px;
  height: 36px;
  margin: 18px;
  border-radius: 50%;
`;

const PetDisplay = styled.div`
  display: Flex;
  min-height: 100px;
  justify-content: space-evenly;
  background-color: white;
  padding: 25px 0;
`;

const Pet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PetIcon = styled.img`
  width: 44px;
  height: 44px;
  border: solid 11px #eeeff1;
  background-color: #eeeff1;
  border-radius: 22px;
`;

const PetName = styled.p`
  font-family: LinearSans;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.18px;
  color: #050f5f;
`;

const SubmitButton = styled(Button)`
  width: 365px;
  height: 45px;
  border-radius: 15px;
  background-color: #39adc3;
  font-family: LinearSans;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;

  color: #ffffff;
`;

const ExportAsPdf = styled.span`
  cursor: pointer;
  margin-right: 20px;
  color: #050f5f;

  &:hover {
    text-decoration: underline;
  }
`;

const ValidationMessage = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: LinearSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.13px;
  color: #da4167;
`;
