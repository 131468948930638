import React, { useMemo, useCallback, useContext, useRef } from 'react';
import styled from 'styled-components/macro';
import Linkify from 'linkifyjs/react';
import { useHistory } from 'react-router-dom';
import { ChannelMessageAttachmentType, TippyPlacement } from '../../enums';
import { USER_TYPES } from '../constants/userTypes';
import Tooltip from '../../Tooltip';
import { appointmentDate } from '../../../../utilities';
import { Avatar, AvatarIconContainer, AvatarSize } from '../../Avatar';
import Forms from '../../../../assets/svg/forms.svg';
import { CheckIcon, EyeIcon, SlashEyeIcon } from '../../icons';
import { VideoCallIcon } from '../../../Common/Icons';
import AppointmentConfirm from './AppointmentConfirm';
import InvoiceAttachment from '../../attachments/InvoiceAttachment';
import WidgetRequestAttachment from './WidgetRequestAttachment';
import { Mixpanel } from '../../../../redux/mixpanel';
import CurbsideContext from '../../context/curbsideContext';
import FileAttachment from '../../attachments/FileAttachment';
import he from 'he';

const LinkifyOptions = { target: '_blank' };

const MessageBody = ({
  userId,
  message,
  televetChannel,
  clinic,
  formNames,
  submittedAt,
  joinCall,
  loading,
  onFirstMessageMount,
  onLastMessageMount,
  isFirstMessage,
  isLastMessage,
  setViewAttachment,
}) => {
  const history = useHistory();
  const { token } = useContext(CurbsideContext);

  const shouldCallFirstMessageMount = useRef(isFirstMessage);
  const shouldCallLastMessageMount = useRef(isLastMessage);
  const containerRef = element => {
    if (isFirstMessage && element && shouldCallFirstMessageMount.current) {
      shouldCallFirstMessageMount.current = false;
      onFirstMessageMount();
    }

    if (isLastMessage && element && shouldCallLastMessageMount.current) {
      shouldCallLastMessageMount.current = false;
      onLastMessageMount(message, element);
    }
  };

  const author = useMemo(() => {
    const channelMember = message.author;
    const isPetParent =
      !!channelMember?.clinicPetParent ||
      channelMember?.user?.type === USER_TYPES.Owner;
    return {
      ...(channelMember?.user || channelMember?.clinicPetParent),
      type: isPetParent ? USER_TYPES.PetParent : USER_TYPES.Vet,
    };
  }, [message]);

  const isSystemMessage = message.author?.twilioIdentity === 'system';
  const isAuthor = userId === author.id;
  const messageDate = appointmentDate(
    message.dateCreated,
    clinic.timezoneName,
    'dddd M/DD/YY [at] h:mma z'
  );

  const handleFormRequest = useCallback(
    submissionId => {
      let path = `/forms/${submissionId}?token=${token}`;

      const workflowEventTrigger = message?.attributes?.workflowEventTrigger;
      const workflowEventTriggerActionNumber =
        message?.attributes?.workflowEventTriggerActionNumber;
      const workflowEventTriggerDelayInMilleseconds =
        message?.attributes?.workflowEventTriggerDelayInMilleseconds;
      const entityId = message?.attributes?.entityId;

      if (
        workflowEventTrigger &&
        workflowEventTriggerActionNumber &&
        entityId
      ) {
        path = `/forms/${submissionId}?token=${token}&workflowEventTrigger=${workflowEventTrigger}&workflowEventTriggerActionNumber=${workflowEventTriggerActionNumber}&workflowEventTriggerDelayInMilleseconds=${workflowEventTriggerDelayInMilleseconds}&entityId=${entityId}`;
      }
      history.push(path);
      Mixpanel.track('Form link clicked');
    },
    [history, message, token]
  );

  const seenByNames = useMemo(() => {
    if (!televetChannel?.channelMembers) return [];
    return televetChannel.channelMembers
      .filter(member => {
        const isClinicPetParent = member.clinicPetParent;
        const isAssignedClinicUser =
          member.user &&
          televetChannel?.assignees?.some(a => a.id === member.user?.id);
        return (
          (isClinicPetParent || isAssignedClinicUser) &&
          member.clinicPetParent?.id !== userId &&
          member.id !== author.id &&
          member.lastConsumedMessageIndex === message.index
        );
      })
      .map(member => {
        if (member?.user) {
          return (
            member.user.nameDisplay ||
            [member.user.firstName, member.user.lastName].join(' ').trim()
          );
        } else {
          return [
            member.clinicPetParent?.firstName,
            member.clinicPetParent?.lastName,
          ]
            .join(' ')
            .trim();
        }
      });
  }, [message, televetChannel, userId, author]);

  const renderAttachments = useCallback(() => {
    const attachments = message?.attachments || [];
    if (!!attachments.length) {
      return (
        <AttachmentContainer
          isAuthor={isAuthor}
          isSystemMessage={isSystemMessage}
        >
          {attachments.map((attachment, index) => {
            const { attachmentType, filename, url } = attachment;
            switch (attachmentType) {
              case ChannelMessageAttachmentType.FormRequest:
                if (attachment.entityId && submittedAt) {
                  return (
                    <FormRequest
                      key={`${attachment.entityId}-formRequest`}
                      onClick={() => handleFormRequest(attachment.entityId)}
                    >
                      <FormName>
                        <Icon src={Forms} alt="forms icon"></Icon>
                        <p>
                          {loading
                            ? 'Loading...'
                            : formNames[attachment.entityId]}
                        </p>
                      </FormName>
                      <div>
                        {submittedAt[attachment.entityId] ? (
                          <CompletedIndicator>
                            <CheckIcon />
                          </CompletedIndicator>
                        ) : null}
                      </div>
                    </FormRequest>
                  );
                }
                return null;
              case ChannelMessageAttachmentType.Image:
                if (url) {
                  return (
                    <MessageBubbleContainer key={url}>
                      <MediaPreview>
                        <img
                          src={url}
                          alt={filename || 'Alternate text not available'}
                          onClick={() => setViewAttachment(url, attachmentType)}
                        />
                      </MediaPreview>
                    </MessageBubbleContainer>
                  );
                } else return null;
              case ChannelMessageAttachmentType.Video:
                if (url) {
                  return (
                    <MessageBubbleContainer key={url}>
                      <MediaPreview>
                        <video
                          src={url}
                          controls
                          onClick={() => setViewAttachment(url, attachmentType)}
                        />
                      </MediaPreview>
                    </MessageBubbleContainer>
                  );
                } else return null;
              case ChannelMessageAttachmentType.CallRequest:
                return (
                  <MessageBubbleContainer key={index}>
                    <VideoCallRequestDiv>
                      <VideoCallRequestHeader>
                        <VideoIconContainer>
                          <VideoIconContent>
                            <VideoCallIcon />
                          </VideoIconContent>
                        </VideoIconContainer>
                        <VideoHeadlineContainer>
                          <VideoHeadlineText>
                            Video Chat Room Created
                          </VideoHeadlineText>
                        </VideoHeadlineContainer>
                      </VideoCallRequestHeader>

                      <ButtonDiv>
                        <VideoJoinButton onClick={joinCall}>
                          <VideoCallIcon /> Join Now
                        </VideoJoinButton>
                      </ButtonDiv>
                    </VideoCallRequestDiv>
                  </MessageBubbleContainer>
                );
              case ChannelMessageAttachmentType.AppointmentConfirmation:
                return (
                  <AppointmentContainer key={index}>
                    <AppointmentConfirm appointmentId={attachment.entityId} />
                  </AppointmentContainer>
                );
              case ChannelMessageAttachmentType.File:
                return (
                  <FileAttachment
                    key={url + Date.now()}
                    onSelect={() =>
                      setViewAttachment(url, attachmentType, filename)
                    }
                    filename={filename}
                  />
                );
              case ChannelMessageAttachmentType.Invoice:
                return (
                  <InvoiceAttachment key={index} attachment={attachment} />
                );
              case ChannelMessageAttachmentType.WidgetRequest:
                return <WidgetRequestAttachment attachment={attachment} />;
              default:
                return null;
            }
          })}
        </AttachmentContainer>
      );
    }
  }, [
    message,
    isAuthor,
    isSystemMessage,
    submittedAt,
    joinCall,
    loading,
    formNames,
    handleFormRequest,
    setViewAttachment,
  ]);

  return (
    <>
      <MessageBodyContainer ref={containerRef}>
        {isSystemMessage && !!message.body && !message.attachments.length ? (
          <>
            <Tooltip content={`${messageDate}`} delay={[300, 0]}>
              <SystemMessage>
                <Linkify tagName="span" options={LinkifyOptions}>
                  {he.decode(message.body)}
                </Linkify>
              </SystemMessage>
            </Tooltip>
            {renderAttachments()}
          </>
        ) : (
          <>
            <Tooltip
              placement={
                isAuthor ? TippyPlacement.TopEnd : TippyPlacement.TopStart
              }
              content={`Sent ${messageDate}`}
              delay={[300, 0]}
            >
              <MessageContainer isAuthor={isAuthor}>
                {isSystemMessage && message.attachments.length ? (
                  <AvatarIconContainer
                    squared={message?.author?.twilioIdentity === 'clinic'}
                  >
                    <Avatar
                      squared={message?.author?.twilioIdentity === 'clinic'}
                      isAuthor={isAuthor}
                      firstName={author.firstName || ''}
                      lastName={author.lastName || ''}
                      nameDisplay={author.nameDisplay}
                      photoUrl={author.profilePic || ''}
                      clinic={clinic}
                      size={AvatarSize.Large}
                    />
                  </AvatarIconContainer>
                ) : null}
                {isAuthor || isSystemMessage ? null : (
                  <AvatarIconContainer
                    squared={message?.author?.twilioIdentity === 'clinic'}
                  >
                    <Avatar
                      squared={message?.author?.twilioIdentity === 'clinic'}
                      isAuthor={isAuthor}
                      firstName={author.firstName || ''}
                      lastName={author.lastName || ''}
                      nameDisplay={author.nameDisplay}
                      photoUrl={author.profilePic || ''}
                      clinic={clinic}
                      size={AvatarSize.Large}
                    />
                  </AvatarIconContainer>
                )}
                <MessageBubblesContainer isAuthor={isAuthor}>
                  {!!message.body && !isSystemMessage && (
                    <SpeechBubble isAuthor={isAuthor}>
                      <SpeechBubbleText isAuthor={isAuthor}>
                        <Linkify tagName="span" options={LinkifyOptions}>
                          {he.decode(message.body)}
                        </Linkify>
                      </SpeechBubbleText>
                    </SpeechBubble>
                  )}
                  {renderAttachments()}
                </MessageBubblesContainer>
              </MessageContainer>
            </Tooltip>
            {!!seenByNames.length && (
              <SeenBy isAuthor={isAuthor}>
                <span>
                  <StyledEyeIcon />
                </span>
                Seen by {seenByNames.join(', ')}
              </SeenBy>
            )}
            {isLastMessage && !seenByNames.length && isAuthor && (
              <SeenBy isAuthor={isAuthor}>
                <span>
                  <StyledSlashEyeIcon />
                </span>
                Not Seen Yet
              </SeenBy>
            )}
          </>
        )}
      </MessageBodyContainer>
    </>
  );
};

export default MessageBody;

const MessageBodyContainer = styled.div`
  margin: 0 16px;

  & + & {
    margin-top: 12px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: ${({ isAuthor }) => (isAuthor ? 'row-reverse' : 'row')};
  width: 100%;
  justify-content: flex-start;
`;

const MessageBubblesContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: ${({ isAuthor }) => (isAuthor ? 'flex-end' : 'flex-start')};
  justify-content: flex-start;
  margin: ${({ isAuthor }) => (isAuthor ? '0 8px 0 0' : '0 0 0 8px')};
  width: 100%;
`;

const SpeechBubble = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: ${({ isAuthor }) => (isAuthor ? 'flex-end' : 'flex-start')};
  justify-content: flex-start;
  background: ${({ isAuthor }) => (isAuthor ? '#ffffff' : '#575d7c')};
  color: ${({ isAuthor }) => (isAuthor ? '#575d7c' : '#ffffff')};
  border-radius: 15px;
  border: ${({ isAuthor }) => (isAuthor ? '1px solid #dedede' : 'none')};
  max-width: 320px;
  min-width: 25px;
  word-break: break-word;

  & + & {
    margin-top: 8px;
  }
`;
const SpeechBubbleText = styled.div`
  font-family: LinearSans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  a {
    color: ${({ isAuthor }) =>
      isAuthor ? 'hsl(205deg 84% 40%)' : 'hsl(205deg 84% 85%)'};
  }
`;

const FormRequest = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 6px;
  border-radius: 6px;
  border: solid 1px #ffffff;
  min-width: 258px;
  min-height: 45px;
  margin: 8px;
  @media screen and (max-width: 435px) {
    min-width: 175px;
  }

  img {
    padding-top: 10px;
  }
`;

const AttachmentContainer = styled(SpeechBubble)`
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 85%;
  @media (max-width: 375px) {
    max-width: 90%;
  }
`;

const FormName = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  img {
    padding-top: 0;
    max-width: 30px;
  }
`;

const Icon = styled.img`
  padding-right: 10px;
`;

const SystemMessage = styled.div`
  color: hsl(230 13% 47%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 20%;
  font-size: 13px;
  text-align: center;
  line-height: 1.3rem;
`;

const MessageBubbleContainer = styled.div`
  border-radius: 8px;
  max-width: 100%;
  font-size: ${({ theme }) => theme.font.fontSizes.sm};
  height: auto;

  & + & {
    margin-top: 4px;
  }
`;

const MediaPreview = styled.div`
  margin: 3px 0;

  img,
  video {
    border-radius: 10px;
    border: 1px solid #bbb;
    cursor: zoom-in;
    height: 320px;
    max-width: 100%;
    width: auto;
    object-fit: contain;
  }
`;

const VideoCallRequestDiv = styled.div``;

const VideoCallRequestHeader = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const VideoIconContainer = styled.div`
  flex: 1;
  margin-right: 5px;
  max-width: 19%;
`;

const VideoIconContent = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  height: 38px;
  justify-content: center;
  width: 38px;

  svg {
    fill: #52b0c3;
    width: 20px;
  }
`;

const VideoHeadlineContainer = styled.div`
  flex: 2;
  max-width: 80%;
`;

const VideoHeadlineText = styled.h2`
  color: #fff;
  font-size: 16px;
  font-weight: 600;
`;

const VideoJoinButton = styled.button`
  background-color: hsl(190 60% 40%);
  border-radius: 8px;
  border: none;
  color: #fff;
  cursor: pointer;
  flex: 0 0 auto;
  font-family: LinearSans, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  margin-right: 8px;
  min-width: 126px;
  padding: 8px 12px;
  transition: background-color 0.1s ease-in-out;

  svg {
    fill: #fff;
    position: relative;
    top: 1px;
    width: 15px;
  }

  & > * {
    padding: 0;
  }
`;

const ButtonDiv = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
`;

const SeenBy = styled.div`
  display: flex;
  margin-top: 8px;
  font-size: 12px;
  justify-content: ${({ isAuthor }) => (isAuthor ? 'flex-end' : 'flex-start')};
  margin-right: 12px;
  color: hsl(0 0% 40%);
  line-height: 1;
`;

const StyledSlashEyeIcon = styled(SlashEyeIcon)`
  margin-right: 8px;
`;

const StyledEyeIcon = styled(EyeIcon)`
  margin-right: 8px;
`;

const CompletedIndicator = styled.div`
  padding-left: 5px;
  display: flex;
  height: 100%;
  align-items: center;
`;

const AppointmentContainer = styled.div`
  min-height: 140px;
`;
