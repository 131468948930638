import {
  PET_NAME_SUBMIT,
  PET_PRACTICE_SUBMIT,
  PET_PHOTO_SUBMIT,
  PET_INFO_SUBMIT,
  PET_SET_STATE,
  PET_RESET_STATE,
} from '../actions';

export const initialState = {
  type: 'Dog',
  otherType: null,
  name: '',
  practiceId: '',
  practiceName: '',
  image: null,
  gender: '',
  reproductiveStatus: '',
  environment: '',
  breed: '',
  weight: '',
  birthday: '',
  pimsId: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case PET_NAME_SUBMIT:
      const { name, type, otherType } = action.payload;
      return {
        ...state,
        name,
        type,
        otherType,
      };
    case PET_PRACTICE_SUBMIT:
      const { practiceName, practiceId } = action.payload;
      return {
        ...state,
        practiceName,
        practiceId,
      };
    case PET_PHOTO_SUBMIT:
      return {
        ...state,
        image: action.payload,
      };
    case PET_INFO_SUBMIT:
      const {
        gender,
        reproductiveStatus,
        environment,
        breed,
        weight,
        birthday,
      } = action.payload;
      return {
        ...state,
        gender,
        reproductiveStatus,
        environment,
        breed,
        weight,
        birthday,
      };
    case PET_SET_STATE:
      return {
        ...action.payload,
      };
    case PET_RESET_STATE:
      return initialState;
    default:
      throw new Error();
  }
};

export default reducer;
