import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Button, BounceLoader } from '@televet/televet-ui';

import Text from '../theme/Text';
import ActionButton from '../Common/ActionButton';
import getClientToken from '../../redux/payment/actions/getClientToken';
import BraintreeDropIn from '../Common/BraintreeDropIn';
import getCustomerToken from '../../redux/payment/actions/getCustomerToken';
import addPaymentMethod from '../../redux/payment/actions/addPaymentMethod';
import { pushSnackbar } from '../../redux/snackbar/snackbarActions.js';

class AddPaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientToken: null,
      paymentHasBeenAdded: false,
      customerTokenHasLoaded: false,
      paymentMethods: [],
      isLoading: false,
    };
  }

  instance;

  setPayments = () => {
    const { getClientToken, getCustomerToken, setMethods } = this.props;

    return Promise.all([
      getClientToken(),
      getCustomerToken(this.props.customerId),
    ])
      .then(([clientTokenResult, customerTokenResult]) => {
        const paymentMethods = customerTokenResult;

        if (setMethods) setMethods(paymentMethods);

        this.setState({
          paymentMethods,
          customerTokenHasLoaded: true,
          clientToken: clientTokenResult.payload,
        });
      })
      .catch(error => {
        console.error(error);
        pushSnackbar({ type: 'error', message: error.message });
      });
  };

  componentDidMount() {
    this.setPayments();
  }

  addPaymentMethod = async () => {
    if (this.instance) {
      const { nonce } = await this.instance.requestPaymentMethod();

      try {
        this.setState({ isLoading: true });
        await this.props.addPaymentMethod(nonce);
        this.setState({ paymentHasBeenAdded: true });
        await this.setPayments();
        if (this.props.onFinish) this.props.onFinish();
        this.setState({ isLoading: false });
      } catch (error) {
        console.error('Error adding payment method', error);
        this.props.pushSnackbar({
          type: 'error',
          message: 'Error adding payment method' + error.message,
        });
      }
    }
  };

  render() {
    if (this.state.clientToken) {
      return (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            marginTop: 50,
            marginBottom: 100,
          }}
        >
          <div style={{ width: 500 }}>
            <div>
              <div>
                <p style={{ margin: 25 }}>
                  Please enter your payment information to continue.
                </p>
                {this.state.customerTokenHasLoaded && (
                  <BraintreeDropIn
                    options={{
                      authorization: this.state.clientToken,
                      vaultManager: true,
                    }}
                    onInstance={instance => {
                      this.instance = instance;
                    }}
                  />
                )}
                {this.state.isLoading ? (
                  <div style={{ textAlign: 'center' }}>
                    <Text>Confirming your payment method...</Text>
                    <BounceLoader loading={this.state.isLoading} />
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <CancelButton
                      style={{ marginTop: 25 }}
                      onClick={() => {
                        this.props.onCancel();
                      }}
                      buttonType="ghost"
                      id="cancel-consultation-button"
                    >
                      Cancel
                    </CancelButton>
                    <ActionButton
                      disabled={
                        !this.state.customerTokenHasLoaded ||
                        this.state.isLoading
                      }
                      style={{
                        marginTop: 25,
                        marginBottom: '0.75rem !important',
                      }}
                      buttonType="primary"
                      id="continue-consultation-button"
                      onClick={this.addPaymentMethod}
                    >
                      Submit Card
                    </ActionButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      this.props.getClientToken().then(clientTokenResult => {
        this.setState({
          customerTokenHasLoaded: true,
          clientToken: clientTokenResult.payload,
        });
      });
      return null;
    }
  }
}

const CancelButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const mapStateToProps = state => ({
  clientToken: state.payment.clientToken,
  paymentMethods: state.payment.methods,
  customerId: state.user.info.private.customerId,
});

const mapDispatchToProps = {
  getClientToken,
  getCustomerToken,
  addPaymentMethod,
  pushSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethod);
