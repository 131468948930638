import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { ChevronLeftIcon } from '../../../Common/Icons';

const ModalHeader = ({ title, onBack, onDone }) => {
  return (
    <ModalHeaderContainer>
      {onBack && (
        <BackButtonContainer>
          <ChevronLeftIcon onClick={onBack} />
        </BackButtonContainer>
      )}
      <TitleContainer>{title || ''}</TitleContainer>
      <ModalButton onClick={onDone}>Done</ModalButton>
    </ModalHeaderContainer>
  );
};

export default ModalHeader;

ModalHeader.propTypes = {
  title: PropTypes.string,
  onBack: PropTypes.func,
  onDone: PropTypes.func.isRequired,
};

const ModalHeaderContainer = styled.div`
  height: 20px;
  margin: 10px 0 10px 0;
  position: relative;
  text-align: center;
  width: 100%;
`;

const ModalButton = styled.span`
  color: #39adc3;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  right: 0;
  text-decoration: underline;
`;

const BackButtonContainer = styled.div`
  left: 0;
  position: absolute;

  svg {
    cursor: pointer;
  }
`;

const TitleContainer = styled.span`
  color: #575d7c;
  font-size: 18px;
  font-weight: bold;
`;
