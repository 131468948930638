import React from 'react';
import styled from 'styled-components/macro';
import { Modal } from '@televet/televet-ui';

import ConfirmPermission from './ConfirmPermission';

const ConfirmPermissionModal = ({ onAccept }) => {
  return (
    <Modal size="lg" name="confirm-permission">
      <ModalContent>
        <ConfirmPermission onAccept={onAccept} />
      </ModalContent>
    </Modal>
  );
};

export default ConfirmPermissionModal;

const ModalContent = styled.div`
  box-sizing: border-box;
  padding: 0 1em 1em 1em;
`;
