export default {
  clinic: '',
  cost: '',
  createdAt: 0,
  details: '',
  consultationContainsMedia: false,
  uploadedImages: [],
  uploadedVideos: [],
  uploadedFiles: [],
  owner: '',
  pet: '',
  phonePref: true,
  state: 'NEW',
  summary: '',
  transactionId: '',
  type: 'CONSULT',
  videoPref: true,
  nonce: '',
  paymentMethodAdded: false,
};
