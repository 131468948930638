import React from 'react';
import styled from 'styled-components/macro';

import DogIcon from '../../assets/svg/dog_avatar.svg';
import CatIcon from '../../assets/svg/cat_avatar.svg';
import HorseIcon from '../../assets/svg/horse_avatar.svg';
import OtherIcon from '../../assets/svg/other_avatar.svg';

const PetAvatar = ({ pet, petType, size }) => {
  const renderPetIcon = petType => {
    switch (petType?.toLowerCase()) {
      case 'dog':
        return DogIcon;

      case 'cat':
        return CatIcon;

      case 'horse':
        return HorseIcon;

      default:
        return OtherIcon;
    }
  };

  const petTypeToRender = pet?.type || petType;
  const petImage = pet?.profilePic;
  return (
    <DefaultAvatar
      size={size}
      src={petImage || renderPetIcon(petTypeToRender)}
      alt=""
    />
  );
};

const getHeightFromSize = size => {
  switch (size) {
    case 'small':
      return '36px';
    case 'medium':
    default:
      return '108px';
    case 'large':
      return '200px';
  }
};

const DefaultAvatar = styled.img`
  height: ${({ size }) => getHeightFromSize(size)};
  border-radius: 50%;
`;

export default PetAvatar;
