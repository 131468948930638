import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { TextInput, BounceLoader } from '@televet/televet-ui';
import loginAction from '../../redux/auth/actions/login';
import logout from '../../redux/auth/actions/logout';
import impersonate from '../../redux/auth/actions/impersonate';
import { email } from '../../utilities/validation';
import CenteredBanner from '../Common/Layouts/CenteredBanner';
import CallToAction from './components/CallToAction';
import SubmissionMessage from '../Common/Message';
import ResponsiveButton from '../Common/ResponsiveButton';
import { H1 } from '../Common/Headers';
import Text from '../theme/Text';
import Link from '../Common/Link';
import { Mixpanel as mixpanel } from '../../redux/mixpanel';

const ERROR_MESSAGE =
  'The email address or password you entered did not match our records. Please double-check and try again.';

const hasActivePets = pets => {
  return pets ? Object.values(pets).some(petIsActive => petIsActive) : false;
};

const Login = ({ login }) => {
  const [state, setState] = useState({ error: null, loading: false });
  const [hasEnteredEmail, setHasEnteredEmail] = useState(false);
  const [hasEnteredPassword, setHasEnteredPassword] = useState(false);
  const { loading, error } = state;
  const history = useHistory();
  const location = useLocation();
  const deeplink = location.state?.deeplink;
  const { register, handleSubmit, watch, errors } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: deeplink?.clientEmail,
    },
  });

  const emailWatch = watch('email');
  const passwordWatch = watch('password');

  useEffect(() => {
    if (emailWatch && !hasEnteredEmail) {
      mixpanel.track('Email entered', { source: 'Login' });
      setHasEnteredEmail(true);
    }
  }, [emailWatch, hasEnteredEmail]);

  useEffect(() => {
    if (passwordWatch && !hasEnteredPassword) {
      mixpanel.track('Password entered', { source: 'Login' });
      setHasEnteredPassword(true);
    }
  }, [passwordWatch, hasEnteredPassword]);

  const dispatch = useDispatch();
  const authenticated = useSelector(
    state => !isEmpty(state.auth.user && state.user.info.private)
  );

  const searchParams = new URLSearchParams(location.search);

  const impersonateFromToken = useCallback(async () => {
    if (searchParams.has('token')) {
      dispatch(logout());
      localStorage.clear();

      await dispatch(impersonate(searchParams.get('token')));
      history.push('/account');
    }
  }, [dispatch, history, searchParams]);

  const navigate = useCallback(() => {
    const redirect = searchParams.has('redirect')
      ? searchParams.get('redirect')
      : '/';
    history.push(redirect);
  }, [history, searchParams]);

  const onSubmit = async values => {
    const { email, password } = values;
    try {
      setState({ error: null, loading: true });
      const user = await login(email, password);

      if (hasActivePets(user.info.pets)) {
        navigate();
      } else {
        history.push('/onboarding');
      }
    } catch (e) {
      setState({ error: ERROR_MESSAGE, loading: false });
    }
  };

  const redirectAuthenticatedUser = useCallback(() => {
    if (!searchParams.has('token') && authenticated) {
      navigate();
    }
  }, [searchParams, authenticated, navigate]);

  useEffect(() => {
    impersonateFromToken();
    redirectAuthenticatedUser();
  }, [impersonateFromToken, redirectAuthenticatedUser]);

  return (
    <CenteredBanner>
      <H1>Login</H1>
      <CallToAction>
        New here?&nbsp;
        <AuthLinks
          onClick={() =>
            mixpanel.track('Clicked register link', { source: 'Login' })
          }
          to={{ pathname: '/register', state: { deeplink } }}
        >
          Register
        </AuthLinks>
      </CallToAction>
      {error && <SubmissionMessage type="error">{error}</SubmissionMessage>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          name="email"
          label="Email"
          register={register}
          validation={{ required: true, pattern: email }}
          error={errors.email && 'Please enter a valid email'}
        />
        <TextInput
          name="password"
          type="password"
          label="Password"
          register={register}
          validation={{ required: true }}
          error={errors.password && 'This field is required'}
        />
        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <Text>Logging you in...</Text>
            <BounceLoader loading={loading} />
          </div>
        ) : (
          <ResponsiveButton>Login</ResponsiveButton>
        )}
      </form>
      <CallToAction>
        Trouble logging in? &nbsp;
        <AuthLinks
          onClick={() =>
            mixpanel.track('Clicked password reset link', { source: 'Login' })
          }
          to="/reset-password"
        >
          Reset your password
        </AuthLinks>
      </CallToAction>
    </CenteredBanner>
  );
};

const AuthLinks = styled(Link)`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

const mapDispatchToProps = { login: loginAction };

export default connect(null, mapDispatchToProps)(Login);
