import React from 'react';
import styled from 'styled-components/macro';

import FilePreview from './FilePreview';

const FilePreviewList = ({ uploadedFiles, isUploading }) => {
  return (
    <Container>
      {uploadedFiles.map(file => {
        const fileURL = URL.createObjectURL(file);
        const fileType = file.type.split('/')[0];

        return (
          <FilePreview
            key={file.name}
            fileURL={fileURL}
            fileType={fileType}
            fileName={file.name}
            isUploading={isUploading}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

export default FilePreviewList;
