import produce from 'immer';
import petsActionTypes from './petsActionTypes';

const initialState = {};

let petsReducer = produce((draft, action) => {
  switch (action.type) {
    case petsActionTypes.addPet:
      draft[action.payload.petId] = action.payload.petObject;
      break;
    case petsActionTypes.removePet:
      delete draft[action.payload];
      break;

    default:
      break;
  }
}, initialState);

export default petsReducer;
