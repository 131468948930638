import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';

import Text from '../components/theme/Text';
import BackButton from './Common/BackButton';
import { H1, H5 } from '../components/Common/Headers';

import faqArray from '../assets/data/faqs.json';

const FAQ = () => {
  const history = useHistory();
  const componentDidMount = () => {
    topRef.current.scrollIntoView(true);
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  const topRef = useRef(null);

  return (
    <Wrapper id="top" ref={topRef}>
      <StyledContainer>
        <BackButton onClick={() => history.goBack()} />
        <H1>Frequently Asked Questions</H1>
        {faqArray.map((item, index) => {
          return (
            <StyledContainer key={`faq-${index}`}>
              <H5>{`${item.title}`}</H5>
              <StyledContainer>
                <Text>{item.content}</Text>
              </StyledContainer>
            </StyledContainer>
          );
        })}
      </StyledContainer>
      <div style={{ height: 200 }}></div>
    </Wrapper>
  );
};

export default FAQ;

const Wrapper = styled.div`
  padding: 24px 24px 96px;
  overflow-y: scroll;
  height: 100%;
`;

const StyledContainer = styled.div`
  margin-top: 25px;
`;
