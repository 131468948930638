import authActionTypes from '../authActionTypes';
import firebase from '../../../firebase/firebase';

const sendPasswordResetEmail = email => {
  return dispatch => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch({
          type: authActionTypes.resetPassword,
          payload: { success: true },
        });
      })
      .catch(error => {
        dispatch({
          type: authActionTypes.resetPassword,
          payload: { error: error, status: 'error' },
        });
      });
  };
};

export default sendPasswordResetEmail;
