import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DeviceSizes } from '@televet/televet-ui';
import React, { useState, useEffect } from 'react';

import Text from '../theme/Text';
import ActionButton from '../Common/ActionButton';
import PersonAvatar from '../../assets/svg/client_avatar.svg';
import { CalendarIcon } from '../Common/Icons';
import GooglePlayIcon from '../../assets/branding/google-play-icon.svg';
import AppStoreIcon from '../../assets/branding/app-store-icon.svg';
import {
  getTime,
  getTimeAgo,
  getRelativeTime,
  capitalizeString,
  formatPossessiveName,
  getTimeDifference,
} from '../../utilities';
import { Mixpanel } from '../../redux/mixpanel';

const Scheduled = props => {
  const { consultation, consultationId, history, vets } = props;

  const { state, callType, transactionId, scheduledAt } = consultation;
  const [relativeTime, setRelativeTime] = useState(getTimeAgo(scheduledAt));
  const [timeUntilAppointment, setTimeUntilAppointment] = useState(
    getTimeDifference(Date.now(), 'minutes', scheduledAt)
  );
  const [absoluteWeekday, absoluteTime] = getTime(scheduledAt);

  useEffect(() => {
    //create timer on component mount

    const timer = setInterval(() => {
      setRelativeTime(getRelativeTime(scheduledAt));
      setTimeUntilAppointment(
        getTimeDifference(Date.now(), 'minutes', scheduledAt)
      );
    }, 1000 * 60);

    // returned function will be called on component unmount
    return () => {
      clearInterval(timer);
    };
  }, [scheduledAt, timeUntilAppointment]);

  if (!consultation) return null;

  return (
    <Container>
      <div>
        <div>
          <Text style={{ fontWeight: 400, fontSize: 18 }}>
            {capitalizeString(callType || '')} call scheduled
          </Text>
          <span style={{ color: 'rgba(16, 22, 38, 0.6)' }}>
            {relativeTime}
            {timeUntilAppointment <= 5 && timeUntilAppointment >= -20 && (
              <span style={{ color: '#30B80E' }}> Now </span>
            )}
          </span>
          <SpaceDiv>
            {(state === 'PAID' || (state === 'SCHEDULED' && transactionId)) &&
              callType === 'video' && (
                <FlexDiv>
                  <ContentDiv>
                    <VetDiv>
                      {consultation?.vet && (
                        <FlexStartDiv>
                          <VetAvatarDiv>
                            <VetAvatar
                              src={
                                vets[consultation?.vet]?.profilePic ||
                                PersonAvatar
                              }
                              alt={`${formatPossessiveName(
                                vets[consultation?.vet]?.fname
                              ) || "your vet's"} avatar`}
                            />
                          </VetAvatarDiv>
                          <LeftDiv>
                            <Text>
                              {`${capitalizeString(
                                vets[consultation?.vet]?.fname
                              )} ${capitalizeString(
                                vets[consultation?.vet]?.lname
                              )}`}
                            </Text>
                            <Label>Veterinarian</Label>
                          </LeftDiv>
                        </FlexStartDiv>
                      )}
                      {consultation?.nurse && (
                        <FlexStartDiv>
                          <VetAvatarDiv>
                            <VetAvatar
                              src={
                                vets[consultation?.nurse]?.profilePic ||
                                PersonAvatar
                              }
                              alt={`${formatPossessiveName(
                                vets[consultation?.nurse]?.fname
                              ) || "your nurse's"} avatar`}
                            />
                          </VetAvatarDiv>
                          <LeftDiv>
                            <Text>
                              {capitalizeString(
                                vets[consultation?.nurse]?.fname
                              )}{' '}
                              {capitalizeString(
                                vets[consultation?.nurse]?.lname
                              )}
                            </Text>
                            <Label>Nurse</Label>
                          </LeftDiv>
                        </FlexStartDiv>
                      )}
                    </VetDiv>
                    <FlexStartDiv>
                      <div>
                        <CalendarIcon title="calendar-icon" />
                      </div>
                      <div
                        style={{
                          paddingLeft: 8,
                          opacity: 0.8,
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ margin: 0 }}>{absoluteWeekday}</Text>
                        <Text style={{ margin: 0 }}>{absoluteTime}</Text>
                      </div>
                    </FlexStartDiv>
                  </ContentDiv>
                  <FlexStartDiv>
                    <JoinCallButton
                      onClick={() => {
                        history.push(`/video/${consultationId}`);
                        Mixpanel.track('Join call clicked', {
                          source: 'Consultation details',
                        });
                      }}
                    >
                      Join Call
                    </JoinCallButton>
                  </FlexStartDiv>
                </FlexDiv>
              )}
            <MobileMessageDiv>
              <Text style={{ fontSize: 16, marginTop: 0 }}>
                If using a mobile device, please download our mobile app for the
                best video experience.
              </Text>
              <MobileIconDiv>
                <a
                  href="https://itunes.apple.com/us/app/televet-remote-pet-care/id1159226331?mt=8"
                  onClick={() => Mixpanel.track('Clicked iOS App Store link')}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src={AppStoreIcon}
                    alt="Download TeleVet on the App Store"
                  />
                </a>
              </MobileIconDiv>
              <MobileIconDiv>
                <a
                  href="https://play.google.com/store/apps/details?id=com.televet.project"
                  onClick={() =>
                    Mixpanel.track('Clicked Google Play Store link')
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src={GooglePlayIcon}
                    alt="Download TeleVet on the Google Play Store"
                  />
                </a>
              </MobileIconDiv>
            </MobileMessageDiv>
          </SpaceDiv>
        </div>
      </div>
    </Container>
  );
};

const MobileIconDiv = styled.div`
  margin: 12px 0;
`;

const MobileMessageDiv = styled.div`
  margin: 30px 0 24px;
  padding: 24px;
  background: #ebf0f9;
  border-radius: 12px;
  @media (min-width: ${DeviceSizes.tablet}) {
    display: none;
  }
`;

const VetDiv = styled.div`
  display: flex;
  @media (max-width: 612px) {
    display: block;
  }
  @media (min-width: 769px) {
    display: block;
  }
  @media (min-width: 1218px) {
    display: flex;
  }
`;

const ContentDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const VetAvatarDiv = styled.div`
  display: flex;
  align-items: center;
`;

const JoinCallButton = styled(ActionButton)`
  width: 128px;
`;

const FlexStartDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 12px 24px;
`;

const LeftDiv = styled.div`
  margin-left: 10px;
  text-align: left;
  p {
    margin: 0;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  margin: 30px 0 24px;
  padding: 24px 24px 8px;
  background: #ebf0f9;
  border-radius: 12px;
  flex-wrap: wrap;
  @media (max-width: 463px) {
    padding: 16px;
    display: block;
    text-align: center;
  }
`;

const VetAvatar = styled.img`
  height: 36px;
  border-radius: 30px;
`;

const SpaceDiv = styled.div`
  margin-top: 20px;
`;

const Container = styled.div`
  padding-bottom: 8px;
  width: 100%;
`;

const Label = styled(Text)`
  font-size: 12px;
  color: rgba(16, 22, 38, 0.6);
`;

const mapStateToProps = state => ({
  consultations: state.consultations,
  clinics: state.clinics,
  pets: state.pets,
  vets: state.vets,
});

export default connect(mapStateToProps, null)(withRouter(Scheduled));
