import firebase from '../../../firebase/firebase';
import setIsImpersonatingTo from '../../app/actions/setIsImpersonatingTo';
import loadUser from './loadUser';
import getUser from '../../user/actions/getUser';

const impersonate = token => {
  return async dispatch => {
    try {
      const authUser = await firebase.auth().signInWithCustomToken(token);
      loadUser(authUser);
      const user = await dispatch(getUser(authUser.uid));
      dispatch(setIsImpersonatingTo(true));
      return user;
    } catch (e) {}
  };
};

export default impersonate;
