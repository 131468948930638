import firebaseApp from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

import firebaseConfig from './firebaseConfig';

let firebase = firebaseApp.initializeApp(firebaseConfig);

export default firebase;
