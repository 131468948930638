import styled from 'styled-components/macro';

const QuestionWrapper = styled.div<any>`
  width: 100%;
  padding: 40px 20px;
  background-color: ${props =>
    props.index % 2 === 0 ? 'white;' : 'transparent'};
`;

export default QuestionWrapper;
