import React, { ReactElement } from 'react';
import Checkbox from './QuestionComponents/Checkbox';
import MultipleSelection from './QuestionComponents/MultipleSelection';
import TextInput from './QuestionComponents/TextInput';
import Rating from './QuestionComponents/Rating';
import DropDown from './QuestionComponents/DropDown';
import Date from './QuestionComponents/Date';
import Time from './QuestionComponents/Time';
import MultiLineTextInput from './QuestionComponents/MultiLineTextInput';
import TextBlock from './QuestionComponents/TextBlock';

export interface IQuestion {
  question: {
    index: number;
    type?: string;
    prompt: string;
    answer?: string;
    defaultValue?: boolean | null;
    options?: string[];
    minValue?: number;
    maxValue: number;
    isRequired: boolean;
  };
  setIsDraftSaved: Function;
  setAnswerAtIndex: Function;
  isInvalid: boolean;
}

const Question = ({
  question,
  setIsDraftSaved,
  setAnswerAtIndex,
  isInvalid,
}: IQuestion): ReactElement => {
  const {
    index,
    type,
    prompt,
    answer,
    defaultValue,
    options,
    minValue,
    maxValue,
    isRequired,
  } = question;

  switch (type) {
    case 'checkbox':
      return (
        <Checkbox
          index={index}
          prompt={prompt || ''}
          answer={answer || undefined}
          defaultValue={defaultValue || false}
          setIsDraftSaved={setIsDraftSaved}
          setAnswerAtIndex={setAnswerAtIndex}
          isRequired={isRequired || false}
          isInvalid={isInvalid}
        />
      );
    case 'multipleSelection':
      return (
        <MultipleSelection
          index={index}
          prompt={prompt || ''}
          answer={answer || ''}
          options={options || []}
          setIsDraftSaved={setIsDraftSaved}
          setAnswerAtIndex={setAnswerAtIndex}
          isRequired={isRequired || false}
          isInvalid={isInvalid}
        />
      );
    case 'textInput':
      return (
        <TextInput
          index={index}
          prompt={prompt || ''}
          answer={answer || ''}
          setIsDraftSaved={setIsDraftSaved}
          setAnswerAtIndex={setAnswerAtIndex}
          isRequired={isRequired || false}
          isInvalid={isInvalid}
        />
      );
    case 'multiLineTextInput':
      return (
        <MultiLineTextInput
          index={index}
          prompt={prompt || ''}
          answer={answer || ''}
          setIsDraftSaved={setIsDraftSaved}
          setAnswerAtIndex={setAnswerAtIndex}
          isRequired={isRequired || false}
          isInvalid={isInvalid}
        />
      );
    case 'ratingInput':
      return (
        <Rating
          index={index}
          prompt={prompt || ''}
          answer={answer || ''}
          minValue={minValue || 0}
          maxValue={maxValue || 5}
          setIsDraftSaved={setIsDraftSaved}
          setAnswerAtIndex={setAnswerAtIndex}
          isRequired={isRequired || false}
          isInvalid={isInvalid}
        />
      );
    case 'dropDown':
      return (
        <DropDown
          index={index}
          prompt={prompt || ''}
          answer={answer || ''}
          options={options || []}
          setIsDraftSaved={setIsDraftSaved}
          setAnswerAtIndex={setAnswerAtIndex}
          isRequired={isRequired || false}
          isInvalid={isInvalid}
        />
      );
    case 'dateInput':
      return (
        <Date
          index={index}
          prompt={prompt || ''}
          answer={answer || ''}
          setIsDraftSaved={setIsDraftSaved}
          setAnswerAtIndex={setAnswerAtIndex}
          isRequired={isRequired || false}
          isInvalid={isInvalid}
        />
      );
    case 'timeInput':
      return (
        <Time
          index={index}
          prompt={prompt || ''}
          answer={answer || ''}
          setIsDraftSaved={setIsDraftSaved}
          setAnswerAtIndex={setAnswerAtIndex}
          isRequired={isRequired || false}
          isInvalid={isInvalid}
        />
      );
    case 'textBlock':
      return <TextBlock index={index} prompt={prompt || ''} />;
    default:
      return <></>;
  }
};

export default Question;
