import produce from 'immer';
import authActionTypes from './authActionTypes';

const initialState = {
  user: null,
  error: { code: null, message: null },
  resetPassword: false,
};

let authReducer = produce((draft, action) => {
  switch (action.type) {
    case authActionTypes.login:
      draft.user = action.payload.user || null;
      if (action.payload.error) {
        draft.error.code = action.payload.error.code || null;
        draft.error.message = action.payload.error.message || null;
      }
      break;
    case authActionTypes.loadUser:
      draft.user = action.payload.user || null;
      if (action.payload.error) {
        draft.error.code = action.payload.code || null;
        draft.error.message = action.payload.message || null;
      }
      break;
    case authActionTypes.logout:
      draft.user = null;
      draft.error.code = null;
      draft.error.message = null;
      break;
    case authActionTypes.resetPassword:
      draft.user = null;
      draft.error.code = null;
      draft.error.message = null;
      if (action.payload.success) {
        draft.resetPassword = true;
      }
      if (action.payload.error) {
        draft.error.code = action.payload.error.code;
        draft.error.message = action.payload.error.message;
      }
      break;
    default:
      break;
  }
}, initialState);

export default authReducer;
