import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components/macro';
import { Modal, ModalSizes } from '@televet/televet-ui';
import { GET_CHANNEL_MEMBERS, GET_PET_PARENT_SETTINGS } from './queries';
import { UPDATE_PET_PARENT_SETTINGS } from './mutations';
import CurbsideContext from '../context/curbsideContext';
import { modalNames } from '../../../constants/modalNames';
import { views } from './constants/views';
import SettingsMenu from './components/SettingsMenu';
import ClinicAssignees from './components/ClinicAssignees';
import ParentMembers from './components/ParentMembers';
import NotificationsSettings from './components/NotificationsSettings';

const SettingsModal = ({ channelId, onDone }) => {
  const { user, token } = useContext(CurbsideContext);
  const headers = {
    'auth-type': 'clinic_pet_parent_token',
    authorization: `Bearer ${token}`,
  };
  const [currentView, setCurrentView] = useState(views.settingsMenu);
  const { data: channelMembersData } = useQuery(GET_CHANNEL_MEMBERS, {
    variables: { whereInput: { id: channelId } },
    context: { headers },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });
  const { data: clinicPetParentData } = useQuery(GET_PET_PARENT_SETTINGS, {
    variables: { whereInput: { id: user.uid } },
    context: { headers },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });
  const [updateSettings] = useMutation(UPDATE_PET_PARENT_SETTINGS, {
    context: { headers },
    errorPolicy: 'all',
  });

  const clinicAssignees = useMemo(() => {
    if (!channelMembersData?.channel?.assignees) return [];
    return channelMembersData.channel.assignees;
  }, [channelMembersData]);

  const parentMembers = useMemo(() => {
    if (!channelMembersData?.channel?.channelMembers) return [];
    const members = [];
    channelMembersData.channel.channelMembers.forEach(member => {
      if (member.clinicPetParent) {
        members.push(member.clinicPetParent);
      }
    });
    return members;
  }, [channelMembersData]);

  const renderView = () => {
    switch (currentView) {
      case views.settingsMenu:
        return (
          <SettingsMenu
            onDone={onDone}
            parentMembers={parentMembers}
            clinicAssignees={clinicAssignees}
            firstName={user.firstName}
            lastName={user.lastName}
            onSetView={setCurrentView}
          />
        );
      case views.clinicAssignees:
        return (
          <ClinicAssignees
            onDone={onDone}
            onBack={() => setCurrentView(views.settingsMenu)}
            clinicAssignees={clinicAssignees}
          />
        );
      case views.parentMembers:
        return (
          <ParentMembers
            onDone={onDone}
            onBack={() => setCurrentView(views.settingsMenu)}
            parentMembers={parentMembers}
          />
        );
      case views.notificationSettings:
        return (
          <NotificationsSettings
            onDone={onDone}
            onBack={() => setCurrentView(views.settingsMenu)}
            petParentSetting={
              clinicPetParentData?.clinicPetParent?.petParentSetting
            }
            updateSettings={updateSettings}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      name={modalNames.settingsModal}
      size={ModalSizes.md}
      overlayOpacity={0.11}
      includeCloseButton={false}
    >
      <ModalContent>{renderView()}</ModalContent>
    </Modal>
  );
};

SettingsModal.propTypes = {
  channelId: PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired,
};

export default SettingsModal;

const ModalContent = styled.div`
  box-sizing: border-box;
  height: calc(82vh);
`;
