import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const StyledBackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: auto;
  margin: 0;
  padding: 0;
  width: auto;

  &:focus {
    outline: none;
  }
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
  &::-moz-focus-inner {
    border: 0;
  }
  svg {
    outline: none;
    transition: transform 0.15s linear;
  }
`;

const BackButton = ({ onClick }) => {
  return (
    <StyledBackButton onClick={onClick}>
      <svg
        width="23"
        height="16"
        viewBox="0 0 23 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.5 8H1.5"
          stroke="#3C69E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 1L1.5 8L8.5 15"
          stroke="#3C69E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </StyledBackButton>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
