export enum ChannelMessageAttachmentType {
  Image = 'Image',
  Video = 'Video',
  File = 'File',
  FormRequest = 'FormRequest',
  AppointmentConfirmation = 'AppointmentConfirmation',
  Invoice = 'Invoice',
  CallRequest = 'CallRequest',
  PhoneCall = 'PhoneCall',
  WidgetRequest = 'WidgetRequest',
}

export enum AppointmentConfirmation {
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Rescheduled = 'Reschedule',
}

export enum ChannelMessageAttachmentEntityType {
  Form = 'Form',
  Appointment = 'Appointment',
  Transaction = 'Transaction',
}

export enum TippyPlacement {
  Top = 'top',
  TopStart = 'top-start',
  TopEnd = 'top-end',
  Right = 'right',
  RightStart = 'right-start',
  RightEnd = 'right-end',
  Bottom = 'bottom',
  BottomStart = 'bottom-start',
  BottomEnd = 'bottom-end',
  Left = 'left',
  LeftStart = 'left-start',
  LeftEnd = 'left-end',
  Auto = 'auto',
  AutoStart = 'auto-start',
  AutoEnd = 'auto-end',
}

export enum ChannelStatusAction {
  Active = 'Active',
  Inactive = 'Inactive',
  InactivePermanently = 'InactivePermanently',
}
