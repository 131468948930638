import axios from 'axios';
import firebase from '../../../firebase/firebase';

import getUser from '../../user/actions/getUser';
import appActionTypes from '../appActionTypes';
import { pushSnackbar } from '../../snackbar/snackbarActions';

const updateConsultation = (
  { cost, promo, type, owner, clinic, consultationId, ...consultation },
  nonce
) => {
  return async (dispatch, getState) => {
    try {
      const {
        variable: { restURL },
      } = getState();

      let data = {
        cost: (
          cost +
          5 -
          (cost + 5) * ((promo ? promo.discount : 0) / 100)
        ).toFixed(2),
        type,
        clinic,
        client: owner,
        consult: consultationId,
        nonce,
      };

      if (promo) data.promo = { ...promo, key: promo.id };

      const response = await axios.post(
        `${restURL}/payment/create_transaction`,
        data
      );

      if (response.data?.transaction?.status === 'authorized') {
        const transactionId = response.data.transaction.id;

        await firebase
          .database()
          .ref(`consults/${consultationId}`)
          .update({
            ...consultation,
            transactionId,
            state: 'PAID',
            paidAt: Date.now(),
          });

        dispatch({
          type: appActionTypes.updateConsultation,
          payload: consultationId,
        });

        await dispatch(getUser());
        return transactionId;
      }
    } catch (error) {
      console.error(
        'The consultation was unable to be confirmed: ',
        error.message || 'an unknown error occurred'
      );
      dispatch(
        pushSnackbar({
          type: 'error',
          message:
            'The consultation was unable to be confirmed: ' +
            (error.message || 'an unknown error occurred'),
        })
      );
    }
  };
};

export default updateConsultation;
