import React, { useState, useRef } from 'react';
import { TextField } from '@material-ui/core';
import { Button } from '@televet/televet-ui';
import styled from 'styled-components/macro';
import Row from './Row';

import ActionButton from '../../Common/ActionButton';

const PromoCodeInput = ({ onClick, finishAddPromoCode }) => {
  const textField = useRef(null);
  const [inputText, setInputText] = useState('');

  const handleAddClick = () => {
    onClick(inputText);
    textField.current.value = '';
    setInputText('');
    finishAddPromoCode(false);
  };

  const handleCancel = () => {
    finishAddPromoCode(false);
  };

  return (
    <Row top="20px">
      <TextField
        label="Add Promo Code"
        variant="outlined"
        fullWidth
        style={{ padding: '2px 0 0 0' }}
        inputRef={textField}
        onChange={e => setInputText(e.target.value)}
      />
      <CancelButton onClick={handleCancel} buttonType="ghost">
        Cancel
      </CancelButton>
      <AddButton
        disabled={!inputText}
        onClick={handleAddClick}
        buttonType="primary"
      >
        Add
      </AddButton>
    </Row>
  );
};

const CancelButton = styled(Button)`
  margin: 8px 0 4px 0;
  height: 42px;
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const AddButton = styled(ActionButton)`
  margin: 8px 0 4px 0;
`;

export default PromoCodeInput;
