import axios from 'axios';
import firebase from '../../../firebase/firebase';

import { pushSnackbar } from '../../snackbar/snackbarActions';
import { capitalizeString } from '../../../utilities';

/**
 * @function
 * @param {*} consultationId The consultation's ID
 * @param {*} data The message to be sent: either a text string or a firebase storage url
 * @param {*} messageType Type of message being sent
 * @param {*} consultation Full consultation data relevant to message being sent
 * @param {*} sender User info object of the pet owner
 */
const sendMessage = ({
  consultationId,
  data,
  messageType,
  thumbnail = null,
  consultation,
  sender,
}) => {
  return async (dispatch, getState) => {
    try {
      if (consultation) {
        const userId = getState().auth.user.uid;
        const restURL = getState().variable.restURL;

        const filename = `${data.name?.split('.')[0]}-${Date.now()}.${
          data.name?.split('.')[1]
        }`;

        const chatStorageRef = firebase
          .storage()
          .ref(`chats/${consultationId}/${filename}`);

        const chatDatabaseRef = firebase
          .database()
          .ref(`chats/${consultationId}`);

        const lastMessageRef = firebase
          .database()
          .ref(`consults/${consultationId}/last_message`);

        let chatObj = { read: false, timestamp: Date.now(), user: userId };

        const notification = {
          data: {
            type: 'MESSAGED',
            consult: consultationId,
          },
          from: userId,
          time: chatObj.timestamp,
          nurseId: consultation.nurse ? consultation.nurse : null,
          vetId: consultation.vet ? consultation.vet : null,
          recId: consultation.rec ? consultation.rec : null,
          senderName: `${capitalizeString(
            sender.info.public.fname
          )} ${capitalizeString(sender.info.public.lname)}`,
          summary: consultation.summary,
        };

        switch (messageType) {
          case 'text':
            chatObj[messageType] = data;
            notification.text = data;
            await chatDatabaseRef.push(chatObj, () => {});
            lastMessageRef.set(chatObj);
            break;

          case 'pic':
            await chatStorageRef.put(data);
            const imageUrlresponse = await chatStorageRef.getDownloadURL();

            // Response is the media storage URL
            chatObj[messageType] = imageUrlresponse;
            notification.pic = imageUrlresponse;

            chatDatabaseRef.push(chatObj, () => {});
            lastMessageRef.set(chatObj);
            break;

          case 'video':
            const chatThumbnailStorageRef = firebase
              .storage()
              .ref(
                `chats/${consultationId}/${thumbnail?.name ||
                  filename + Date.now()}`
              );

            await chatStorageRef.put(data);

            const videoUrlresponse = await chatStorageRef.getDownloadURL();

            // Response is the media storage URL
            chatObj[messageType] = videoUrlresponse;
            notification.video = videoUrlresponse;

            await chatThumbnailStorageRef.put(thumbnail);
            const thumbnailResponse = await chatThumbnailStorageRef.getDownloadURL();

            chatObj.thumbnail = thumbnailResponse;
            notification.thumbnail = thumbnailResponse;
            chatDatabaseRef.push(chatObj, () => {});
            lastMessageRef.set(chatObj);
            break;

          default:
            break;
        }

        const response = await axios.post(
          `${restURL}/notif/create`,
          notification
        );

        dispatch({
          type: `chats/sendMessage/${messageType}`,
          payload: response,
        });
      }
    } catch (error) {
      console.error('Unable to send message: ', error.message);
      dispatch(pushSnackbar({ type: 'error', message: error.message }));
    }
  };
};

export default sendMessage;
