import React from 'react';
import styled from 'styled-components/macro';
import ClinicPlaceHolderLogo from '../../assets/svg/clinic-logo-placeholder.svg';

const ClinicLogo = ({ clinic }) => (
  <Logo src={clinic.logo || ClinicPlaceHolderLogo} alt={clinic.name} />
);

const Logo = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  margin-right: ${({ theme }) => theme.spacing.spacingSizes.sm};
`;

export default ClinicLogo;
