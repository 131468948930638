import React, { useState, useEffect } from 'react';
import ApolloProvider from './providers/ApolloProvider';
import LogRocket from 'logrocket';
import { useDispatch } from 'react-redux';
import { ThemeProvider, ModalProvider } from '@televet/televet-ui';
import { setUser } from './utilities/tracking';
import theme from './lib/theme';
import './lib/theme/fonts.css';
import firebase from './firebase/firebase';
import Routes from './Routes';
import Snackbars from './components/Snackbars';
import loadUser from './redux/auth/actions/loadUser';
import logout from './redux/auth/actions/logout';
import getUser from './redux/user/actions/getUser';
import * as FullStory from '@fullstory/browser';

const App = () => {
  const [authenticating, setAuthenticating] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user && !user.isAnonymous) {
        dispatch(loadUser(user));
        dispatch(getUser());
        LogRocket.identify(user.uid, {
          name: user.displayName,
          email: user.email,
        });
        if (process.env.REACT_APP_PRODUCTION === 'true') {
          LogRocket.identify(user.uid, {
            name: user.displayName,
            email: user.email,
          });

          FullStory.identify(user.uid, {
            displayName: user.displayName,
            email: user.email,
          });

          setUser(user.uid);
        }
      }
      if (!user && !user?.isAnonymous) {
        dispatch(logout());
      }
      setAuthenticating(false);
    });
  });

  const getAuthHeaders = async () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    return {
      'auth-type': 'clinic_pet_parent_token',
      authorization: `Bearer ${token}`,
    };
  };

  if (authenticating) {
    return <Authenticating />;
  }

  return (
    <ApolloProvider
      getAuthHeaders={getAuthHeaders}
      uri={process.env.REACT_APP_CORE_API_URL}
    >
      <ThemeProvider theme={theme}>
        <Snackbars>
          <ModalProvider>
            <Routes />
          </ModalProvider>
        </Snackbars>
      </ThemeProvider>
    </ApolloProvider>
  );
};

const Authenticating = () => {
  return <div></div>;
};

export default App;
