export const fertilityOptions = {
  Female: [
    { label: 'Spayed', value: 'Spayed' },
    { label: 'Fertile', value: 'Fertile' },
  ],
  Male: [
    { label: 'Neutered', value: 'Neutered' },
    { label: 'Fertile', value: 'Fertile' },
  ],
  Default: [
    { label: 'Fertile', value: 'Fertile' },
    { label: 'Infertile', value: 'Infertile' },
  ],
};
