import styled from 'styled-components/macro';

const H5 = styled.h5`
  font-size: ${({ theme }) => {
    return theme.font.headlineSizes.h5;
  }};
  margin: ${({ theme }) => {
    return `${theme.spacing.spacingSizes.sm} 0`;
  }};
  color: ${({ theme }) => theme.palette.black};
`;

export default H5;
