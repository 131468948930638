import styled from 'styled-components/macro';
import { ResponsiveDeviceMinWidth } from '@televet/televet-ui';

const H3 = styled.h3`
  font-size: ${({ theme }) => {
    return theme.font.headlineSizes.h4;
  }};
  margin: ${({ theme }) => {
    return `${theme.spacing.spacingSizes.sm} 0`;
  }};

  @media ${ResponsiveDeviceMinWidth.mobileM} {
    font-size: ${({ theme }) => {
      return theme.font.headlineSizes.h3;
    }};
    margin: ${({ theme }) => {
      return `${theme.spacing.spacingSizes.md} 0`;
    }};
  }
  color: ${({ theme }) => theme.palette.black};
`;

export default H3;
