import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import React, { useMemo } from 'react';
import {
  Button,
  DeviceSizes,
  useModal,
  ResponsiveDeviceMaxWidth,
} from '@televet/televet-ui';

import Text from '../theme/Text';
import Avatar from '../Common/Avatar';
import PetsList from './PetsList';
import MyPetsIcon from '../../assets/svg/my-pets.svg';
import TelevetLogo from '../../assets/svg/televet-logo.svg';
import { hslToHsla } from '../theme/helpers';
import AddNewPetIcon from '../../assets/svg/pets-paw-hospital.svg';
import NotificationsIcon from './NotificationIcon';

import logout from '../../redux/auth/actions/logout';
import { Mixpanel as mixpanel } from '../../redux/mixpanel';

const MainNavigation = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { openModal } = useModal();
  const history = useHistory();
  const { user, pets } = useSelector(state => state);

  const notificationsList = useMemo(() => {
    return Object.values(user.info.private.clientNotifs || []);
  }, [user.info.private.clientNotifs]);

  const handlePushPetPage = petId => {
    history.push(`/pets/${petId}`);
  };

  if (pathname.includes('video')) {
    return null;
  }

  const handleLogout = () => {
    mixpanel.track('Logout button clicked', { source: 'Navigation bar' });
    dispatch(logout());
  };

  return (
    <StyledContainer>
      <StyledLogoImg
        src={TelevetLogo}
        alt="TeleVet logo"
        onClick={() => {
          history.push('/');
          mixpanel.track('TeleVet logo clicked', { source: 'Navigation bar' });
        }}
      />
      <PetsLabel>My Pets</PetsLabel>
      <PetsDiv>
        <PetsList pets={pets} pushPetPage={handlePushPetPage} />
      </PetsDiv>
      <LinkContainer>
        <AddPetLink>
          <NavButton
            id="pet-navbar-link"
            onClick={() => {
              mixpanel.track('Add new pet button clicked', {
                source: 'Navigation bar',
                isOnboarding: false,
              });
              mixpanel.track('Add new pet', {
                isOnboarding: false,
              });
              openModal('add-pet');
            }}
          >
            <LinkIcon src={AddNewPetIcon} alt="Add New Pet Button" />
            <LinkText>Add a New Pet</LinkText>
          </NavButton>
        </AddPetLink>
        <PetsLink
          id="pet-navbar-link"
          to="/pets"
          onClick={() => {
            mixpanel.track('Pets link clicked', { source: 'Navigation bar' });
            history.push('/pets');
          }}
          component={NavButton}
        >
          <LinkIcon src={MyPetsIcon} alt="My Pets Button" />
          <LinkText>Pets</LinkText>
        </PetsLink>
        <Divider />
        <NavLink
          id="notification-button"
          aria-label="notifications"
          aria-haspopup="true"
          to="/notifications"
          onClick={() => {
            mixpanel.track('Notifications link clicked', {
              source: 'Navigation bar',
            });
            history.push('/notifications');
          }}
          component={NavButton}
        >
          <NotificationsIcon
            hasNotifications={notificationsList.some(element => !element.read)}
          />
          <NotificationLinkText>Notifications</NotificationLinkText>
        </NavLink>

        <NavLink
          id="account-navbar-link"
          to="/account"
          onClick={() => {
            mixpanel.track('Account link clicked', {
              source: 'Navigation bar',
            });
            history.push('/account');
          }}
          component={NavButton}
        >
          <UserAvatar
            height="24px"
            size="small"
            url={user.info.public?.profilePic}
          />
          <LinkText>Account</LinkText>
        </NavLink>
      </LinkContainer>
      <LogoutButton fullWidth id="logout-navbar-link" onClick={handleLogout}>
        Log out
      </LogoutButton>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  background-color: hsl(230, 38%, 36%);
  max-width: 265px;
  z-index: 99;
  width: 50%;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;

  @media (max-width: 500px) {
    height: 56px;
    overflow-y: hidden;
  }

  @media ${ResponsiveDeviceMaxWidth.tablet} {
    position: fixed;
    bottom: 0;
    height: auto;
    width: 100%;
    float: left;
    display: inline-block;
    max-width: 100%;
    overflow-y: hidden;
  }
`;

const StyledLogoImg = styled.img`
  flex: 0 0 auto;
  align-self: flex-start;
  height: 27.5px;
  object-fit: contain;
  margin: ${({ theme }) => theme.spacing.spacingSizes.xxxl} 0;
  padding-left: ${({ theme }) => theme.spacing.spacingSizes.xxxl};
  cursor: pointer;

  @media ${ResponsiveDeviceMaxWidth.tablet} {
    display: none;
  }
`;

const PetsDiv = styled.div`
  flex: 0 1 auto;
  min-height: 0;
  margin-bottom: 1em;

  @media ${ResponsiveDeviceMaxWidth.tablet} {
    display: none;
  }
`;

const AddPetLink = styled.span`
  @media ${ResponsiveDeviceMaxWidth.tablet} {
    display: none;
  }
`;

const PetsLink = styled(NavLink)`
  @media (min-width: ${Number(DeviceSizes.tablet.slice(0, 3)) + 1}px) {
    display: none !important;
  }
`;

const LinkContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  @media ${ResponsiveDeviceMaxWidth.tablet} {
    flex-direction: row;
    text-align: center;
    justify-content: space-evenly;
    padding: 0;
  }
`;

const Divider = styled.div`
  height: 0;
  width: 100%;
  margin: 24px 0;
  border-bottom: 1px solid
    ${({ theme: { palette } }) => hslToHsla(palette.primaryDark, 0.3)};

  @media ${ResponsiveDeviceMaxWidth.tablet} {
    display: none;
  }
`;

const LinkText = styled(Text)`
  @media ${ResponsiveDeviceMaxWidth.tablet} {
    margin-top: ${({ theme }) => theme.spacing.spacingSizes.xxs};
    margin-left: 0;
  }
  color: ${({ theme: { palette } }) => palette.white};
`;

const NotificationLinkText = styled(LinkText)`
  margin-top: 2px;
  @media ${ResponsiveDeviceMaxWidth.tablet} {
    margin-top: 6px;
  }
`;

const NavButton = styled.div`
  display: flex;
  color: white;
  text-decoration: none;
  align-items: center;
  background: ${({ isActive }) =>
    isActive ? 'rgba(0, 0, 0, 0.1)' : 'transparent'};
  border-radius: ${({ theme }) => theme.spacing.spacingSizes.sm};
  margin: 0 10%;
  text-align: left;
  padding: ${({ theme }) =>
    `${theme.spacing.spacingSizes.sm} ${theme.spacing.spacingSizes.lg}`};
  padding-left: ${({ theme }) => theme.spacing.spacingSizes.lg};

  & + & {
    margin-top: 4%;
  }

  > *:first-child {
    width: 24px;
    height: 24px;
    margin-right: ${({ theme }) => theme.spacing.spacingSizes.sm};
  }

  @media ${ResponsiveDeviceMaxWidth.tablet} {
    flex-direction: column;
    margin: 0 0;
    width: calc(100% / 3);
    border-radius: 0;

    & + & {
      margin-top: 0;
    }

    > *:first-child {
      margin-right: 0;
    }
  }

  &.active {
    background: rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background: rgba(255, 255, 255, 0.04);
  }

  :hover {
    cursor: pointer;
  }
`;

const UserAvatar = styled(Avatar)`
  opacity: 0.6;
`;

const LinkIcon = styled.img`
  width: 24px;
`;

const PetsLabel = styled(Text)`
  margin-left: ${({ theme }) => theme.spacing.spacingSizes.xxxl};
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.xs};
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.font.fontFamilies.body};

  @media ${ResponsiveDeviceMaxWidth.tablet} {
    display: none;
  }
`;

const LogoutButton = styled(Button)`
  flex: 0 0 auto;
  background: transparent;
  border-radius: 0;
  margin: 1rem 0;

  &:hover {
    background: rgba(255, 255, 255, 0.04) !important;
  }

  @media ${ResponsiveDeviceMaxWidth.tablet} {
    display: none;
  }
`;

export default MainNavigation;
