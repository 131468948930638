import produce from 'immer';
import clinicsActionTypes from './clinicsActionTypes';

const initialState = {};

let clinicsReducer = produce((draft, action) => {
  switch (action.type) {
    case clinicsActionTypes.addClinic:
      draft[action.payload.clinicId] = action.payload.clinicObject;
      break;

    default:
      break;
  }
}, initialState);

export default clinicsReducer;
