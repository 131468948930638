import axios from 'axios';
import firebase from '../../../firebase/firebase';
import appActionTypes from '../appActionTypes';
import { store } from '../../store';

/**
 * @async
 * @function getIntercomUserHash Fetch Intercom user hash from TeleVet api /users/intercom_verify
 * @return {Promise<String>} The user hash to be passed to Intercom in order to authenticate
 */

const getIntercomUserHash = async () => {
  const state = store.getState();
  const restURL = state.variable.restURL;

  const token = await firebase.auth().currentUser.getIdToken();

  return dispatch => {
    axios
      .get(`${restURL}/users/intercom_verify?plt=web&token=${token}`)
      .then(response => {
        const userHash = response.data.userHash;
        dispatch({
          type: appActionTypes.getIntercomUserHash,
          payload: userHash,
        });
      })
      .catch(error => {
        console.error('Error obtaining user_hash', error);
      });
  };
};

export default getIntercomUserHash;
