import React from 'react';
import styled from 'styled-components/macro';
import { Modal, ResponsiveDeviceMinWidth } from '@televet/televet-ui';

import NewConsultation from './NewConsultation';
const NewConsultationModal = ({ pet, petId }) => {
  return (
    <Modal size="lg" name="create-consultation">
      <ModalContent>
        <NewConsultation pet={pet} petId={petId} />
      </ModalContent>
    </Modal>
  );
};

export default NewConsultationModal;

const ModalContent = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  padding: 0 1em 1em 1em;

  @media ${ResponsiveDeviceMinWidth.tablet} {
    min-height: 650px;
    padding: 0 4em 4em 4em;
  }
`;
