import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { TextInput } from '@televet/televet-ui';
import { email } from '../../../utilities/validation';
import ResponsiveButton from '../../Common/ResponsiveButton';

const ResetPasswordForm = ({ onSubmit, loading }) => {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        name="email"
        label="Email"
        register={register}
        validation={{ required: true, pattern: email }}
        error={errors.email && 'Please enter a valid email'}
      />
      <ResponsiveButton disabled={loading}>Reset My Password</ResponsiveButton>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default ResetPasswordForm;
