import React from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import logout from '../redux/auth/actions/logout';

const ImpersonationBanner = () => {
  const dispatch = useDispatch();
  const isImpersonating = useSelector(state => state.app.isImpersonating);
  const uid = useSelector(state => state.auth.user.uid);
  const userInfo = useSelector(state => state.user.info);

  const handleLogOut = () => {
    dispatch(logout());
  };

  if (isImpersonating) {
    const name = `${userInfo.public.fname} ${userInfo.public.lname}`;
    const email = userInfo.private.email;
    return (
      <StyledBanner>
        IMPERSONATING: {name} ({email}) FIREBASE ID: {uid}
        <Spacer />
        <button onClick={handleLogOut}>Log Out</button>
      </StyledBanner>
    );
  }

  return null;
};

export default ImpersonationBanner;

const StyledBanner = styled.div`
  height: 40px;
  display: flex;
  background-color: red;
  color: white;
  justify-content: center;
  align-items: center;
`;

const Spacer = styled.div`
  width: 40px;
`;
