import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import resetPasswordRequestAction from '../../redux/auth/actions/sendPasswordResetEmail';
import CenteredBanner from '../Common/Layouts/CenteredBanner';
import ResetPasswordForm from './components/ResetPasswordForm';
import SubmissionMessage from '../Common/Message';
import { H1 } from '../Common/Headers';

const ResetPasswordRequest = ({ resetPasswordRequest }) => {
  const [state, setState] = useState({ success: null, loading: false });
  const { loading, success } = state;
  const history = useHistory();

  const onSubmit = async values => {
    const { email } = values;
    setState({ success: null, loading: true });
    await resetPasswordRequest(email);
    setState({
      success: `We've sent an email to ${email} with a link to create a new password. Please check your email.`,
      loading: false,
    });
  };

  return (
    <CenteredBanner onBack={() => history.push('/login')}>
      <H1>Reset Password</H1>
      {!success ? (
        <ResetPasswordForm onSubmit={onSubmit} loading={loading} />
      ) : (
        <SubmissionMessage>{success}</SubmissionMessage>
      )}
    </CenteredBanner>
  );
};

ResetPasswordRequest.propTypes = {
  resetPasswordRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  resetPasswordRequest: resetPasswordRequestAction,
};

export default connect(null, mapDispatchToProps)(ResetPasswordRequest);
