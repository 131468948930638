import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { Button, DeviceSizes } from '@televet/televet-ui';

import Text from '../theme/Text';
import { H3 } from '../Common/Headers';
import CreditCard from '../../assets/svg/credit-card.svg';
import ActionButton from '../Common/ActionButton';
import { hslToHsla } from '../theme/helpers';
import { Add, Pencil } from '../Common/Icons';
import AddPaymentMethod from '../Common/AddPaymentMethod';

import getClientToken from '../../redux/payment/actions/getClientToken';
import { pushSnackbar } from '../../redux/snackbar/snackbarActions.js';
import getCustomerToken from '../../redux/payment/actions/getCustomerToken';
import deletePaymentMethod from '../../redux/payment/actions/deletePaymentMethod';
import setDefaultPaymentMethod from '../../redux/payment/actions/setDefaultPaymentMethod';
import { Mixpanel as mixpanel } from '../../redux/mixpanel';

class Payment extends Component {
  instance;
  state = {
    customerTokenHasLoaded: false,
    clientToken: null,
    paymentMethods: [],
    isEditingPaymentMethod: false,
    isAddingPaymentMethod: false,
    paymentMethodToEdit: null,
  };

  setPaymentMethods(methods) {
    this.setState({ paymentMethods: methods });
  }

  setPayments = () => {
    if (!this.state.customerTokenHasLoaded) {
      Promise.all([
        this.props.getClientToken(),
        this.props.getCustomerToken(this.props.customerId),
      ])
        .then(([clientTokenResult, customerTokenResult]) => {
          this.setState({
            customerTokenHasLoaded: true,
            clientToken: clientTokenResult.payload,
            paymentMethods: customerTokenResult,
          });
          return;
        })
        .catch(error => {
          if (error.type !== 'CustomerIDException') {
            console.error(error);
            this.props.pushSnackbar({
              type: 'error',
              message: 'Info: ' + error.message,
            });
          }
          return;
        });
    }
  };

  componentDidMount() {
    this.setPayments();
  }

  updateDefaultPaymentMethod = ({ token }) => {
    this.props.setDefaultPaymentMethod(token).then(res => {
      this.setState({
        isEditingPaymentMethod: false,
        paymentMethodToEdit: null,
        customerTokenHasLoaded: false,
      });

      this.setPayments();
      // eslint-disable-next-line no-restricted-globals
      this.forceUpdate();
      this.props.pushSnackbar({
        type: 'success',
        message: 'Payment method successfully updated',
      });
    });
  };

  removePaymentMethod = async ({ token }) => {
    this.props.deletePaymentMethod(token).then(res => {
      this.setState({
        isEditingPaymentMethod: false,
        paymentMethodToEdit: null,
        customerTokenHasLoaded: false,
      });

      this.setPayments();
      // eslint-disable-next-line no-restricted-globals
      this.forceUpdate();
      this.props.pushSnackbar({
        type: 'success',
        message: 'Payment method successfully deleted',
      });
    });
  };

  render() {
    return (
      <Container>
        <HeaderSection>
          <SectionTitle>Payment Methods</SectionTitle>
          {!this.state.isAddingPaymentMethod && !this.props.isNewConsultation && (
            <AddPaymentButton
              buttonType="ghost"
              onClick={() => {
                this.setState({ isAddingPaymentMethod: true });
                if (this.state.paymentMethods.length > 0)
                  mixpanel.track('Add additional method clicked', {
                    isNewConsultation: this.props.isNewConsultation
                      ? true
                      : false,
                  });
              }}
            >
              <span>
                <Add title="Add Payment Method" />
              </span>
              <span style={{ padding: '0 4px' }}>Add</span>
            </AddPaymentButton>
          )}
        </HeaderSection>

        <div>
          {!this.state.isAddingPaymentMethod &&
          this.state.paymentMethods.length > 0 ? (
            this.state.paymentMethods.map(method => {
              return (
                <PaymentMethod key={method.last4}>
                  <CreditCardDiv>
                    <CardInfoDiv>
                      <span>
                        <CreditCardImage
                          src={method.imageUrl}
                          alt={`${method.cardType} Credit card logo`}
                        />
                      </span>
                      {!method.default && (
                        <CardNumber
                          style={{ padding: 8, width: '100%' }}
                        >{`${method.cardType} ending in ${method.last4}`}</CardNumber>
                      )}
                      {method.default && (
                        <>
                          <CardNumber
                            style={{ padding: '8px 0' }}
                          >{`${method.cardType} ending in ${method.last4}`}</CardNumber>
                          <DefaultMarker>Default</DefaultMarker>
                        </>
                      )}
                    </CardInfoDiv>
                    {this.state.paymentMethodToEdit !== method && (
                      <EditButton
                        onClick={() => {
                          this.setState({
                            isEditingPaymentMethod: true,
                            paymentMethodToEdit: method,
                          });
                          mixpanel.track('Edit payment method', {
                            isNewConsultation: this.props.isNewConsultation
                              ? true
                              : false,
                          });
                        }}
                        buttonType="text"
                      >
                        <span style={{ paddingTop: 6 }}>
                          <Pencil title="Edit payment method" />
                        </span>
                        <span style={{ padding: '2px 4px 0 0' }}>Edit</span>
                      </EditButton>
                    )}
                  </CreditCardDiv>
                  {this.state.isEditingPaymentMethod &&
                    this.state.paymentMethodToEdit === method && (
                      <EditPaymentDiv>
                        <CardNumber style={{ padding: '8px 0 24px' }}>
                          {`Modifying ${method.cardType} ending in ${method.last4}`}
                        </CardNumber>
                        {!this.props.isNewConsultation && (
                          <RemoveCardButton
                            fullWidth={false}
                            buttonType="primary"
                            onClick={() => this.removePaymentMethod(method)}
                          >
                            Remove Payment Method
                          </RemoveCardButton>
                        )}
                        <SetDefaultButton
                          fullWidth={false}
                          buttonType="primary"
                          onClick={() =>
                            this.updateDefaultPaymentMethod(method)
                          }
                        >
                          Set as Default
                        </SetDefaultButton>
                        <CancelButton
                          fullWidth={false}
                          buttonType="ghost"
                          onClick={() => {
                            this.setState({
                              isEditingPaymentMethod: false,
                              paymentMethodToEdit: null,
                            });
                            mixpanel.track('Cancel editing payment methods', {
                              isNewConsultation: this.props.isNewConsultation
                                ? true
                                : false,
                            });
                          }}
                        >
                          Cancel
                        </CancelButton>
                      </EditPaymentDiv>
                    )}
                </PaymentMethod>
              );
            })
          ) : (
            <TopSpacer style={{ display: 'flex', justifyContent: 'center' }}>
              {this.state.customerTokenHasLoaded &&
                !this.state.isAddingPaymentMethod && (
                  <EmptyStateDiv>
                    <img
                      style={{ width: 200, margin: '12px 0 24px' }}
                      src={CreditCard}
                      alt="Add a credit card to continue"
                    />
                    <Text style={{ margin: '0 0 12px' }}>
                      {' '}
                      Add a payment method to create <br /> consultations for
                      your pets.{' '}
                    </Text>
                  </EmptyStateDiv>
                )}
            </TopSpacer>
          )}
        </div>
        {this.state.isAddingPaymentMethod && (
          <AddPaymentMethod
            setMethods={methods => this.setPaymentMethods(methods)}
            onCancel={() => this.setState({ isAddingPaymentMethod: false })}
            onFinish={() => this.setState({ isAddingPaymentMethod: false })}
          />
        )}
      </Container>
    );
  }
}

const EmptyStateDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaymentMethod = styled.div`
  border-bottom: 2px solid
    ${({ theme }) => hslToHsla(theme.palette.primary, 0.3)};
`;

const CardInfoDiv = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
`;

const SectionTitle = styled(H3)`
  width: 80%;
`;

const HeaderSection = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
`;

const AddPaymentButton = styled(Button)`
  width: 50%;
  max-width: 140px;
  margin: 8px 0;
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};

  ${Add} {
    height: 13px;
    position: relative;
    width: 13px;
    stroke: ${({ theme }) => theme.palette.actionButton};
  }

  &:hover,
  &:active,
  &:focus {
    ${Add} {
      stroke: ${({ theme }) => theme.palette.white};
    }
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const CancelButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const RemoveCardButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.error};

  &:hover {
    background-color: transparent !important;
    background: transparent;
    color: ${({ theme }) => theme.palette.error};
    border: 2px solid ${({ theme }) => theme.palette.error};
  }
`;

const SetDefaultButton = styled(ActionButton)`
  margin-bottom: 16px;
`;

const EditPaymentDiv = styled.div`
  background: ${({ theme }) => theme.palette.white};
  display: flex;
  flex-direction: column;
  padding: 12px 36px;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  border: 2px solid ${({ theme }) => theme.palette.gray700};
  margin: 24px 0;
`;

const CreditCardImage = styled.img`
  @media (max-width: ${DeviceSizes.tablet}) {
    height: 24px;
  }
  height: 30px;
  padding-right: 8px;
`;

const DefaultMarker = styled.span`
  font-size: ${({ theme }) => theme.font.fontSizes.sm};
  font-weight: 500;
  color: ${({ theme }) => theme.palette.success};
  padding: 8px;
`;

const CardNumber = styled.span`
  font-size: ${({ theme }) => theme.font.fontSizes.md};
  color: ${({ theme }) => theme.palette.gray300};
`;

const EditButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 4px 10px;
  max-width: 50px;
  width: 50px;
  color: ${({ theme }) => theme.palette.actionButton};

  ${Pencil} {
    height: 13px;
    position: relative;
    width: 13px;
    padding-right: 4px;
    stroke: ${({ theme }) => theme.palette.actionButton};
  }

  &:hover,
  &:active,
  &:focus {
    ${Pencil} {
      stroke: ${({ theme }) => theme.palette.white};
    }
    background-color: ${({ theme }) => theme.palette.actionButton} !important;
    color: ${({ theme }) => theme.palette.white} !important;
  }
`;

const CreditCardDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px 0 4px;
`;

const Container = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.spacingSizes.lg};

  .braintree-heading {
    color: ${({ theme }) => theme.palette.black};
    font-family: Roboto;
    font-size: ${({ theme }) => theme.font.fontSizes.h4};
    font-weight: 500;
    margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.xxxl};
  }
`;

const TopSpacer = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: row;
`;

const mapStateToProps = state => ({
  customerId: state.user.info.private.customerId,
});

const mapDispatchToProps = {
  getClientToken,
  getCustomerToken,
  pushSnackbar,
  deletePaymentMethod,
  setDefaultPaymentMethod,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Payment));
