import firebase from '../../../firebase/firebase';
import authActionTypes from '../authActionTypes';
import { pushSnackbar } from '../../snackbar/snackbarActions.js';
import { Mixpanel } from '../../mixpanel';

const logout = () => {
  return dispatch => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.clear();
        Mixpanel.reset();
        dispatch({
          type: authActionTypes.logout,
        });
        dispatch({ type: 'RESET_STORE' });
      })
      .catch(error => {
        pushSnackbar({ type: 'error', message: error.message });
      });
  };
};

export default logout;
