import React, { Component } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

import PetAvatar from '../Common/PetAvatar';
import { getAge } from '../../utilities';

class Pet extends Component {
  render() {
    const { pet } = this.props;

    return (
      <Card raised style={{ marginBottom: 25 }}>
        <CardContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {pet && (
              <>
                <PetAvatar pet={pet.public} />
                <div style={{ marginLeft: 15 }}>
                  <Typography variant="h6">{pet.public.name}</Typography>
                  <Typography>{pet.public.breed}</Typography>
                  <Typography>
                    {pet.public.DOB
                      ? getAge(pet.public.DOB)
                      : 'No date of birth recorded'}
                  </Typography>
                </div>
              </>
            )}
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default Pet;
