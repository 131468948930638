import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useApolloClient, useQuery } from '@apollo/client';
import moment from 'moment';

import { BounceLoader } from '@televet/televet-ui';
import Context from '../../context/curbsideContext';
import { GET_CLINIC_WIDGET_REQUEST } from '../../queries';
import { VERIFY_CLINIC_WIDGET_REQUEST } from '../mutations';

const FormQuestionType = {
  textInput: 'textInput',
  multipleSelection: 'multipleSelection',
  dropDown: 'dropDown',
  checkbox: 'checkbox',
  dateInput: 'dateInput',
  timeInput: 'timeInput',
  ratingInput: 'ratingInput',
  textBlock: 'textBlock',
};

const WidgetRequestType = {
  RequestAppointment: 'RequestAppointment',
  RequestRxRefill: 'RequestRxRefill',
  RequestGroomingAppoinment: 'RequestGroomingAppoinment',
  RequestBoarding: 'RequestBoarding',
  TalkToStaff: 'TalkToStaff',
  RequestMedicalRecords: 'RequestMedicalRecords',
  RequestVirtualConsult: 'RequestVirtualConsult',
};

export const WidgetRequestTypeDisplayNames = {
  [WidgetRequestType.RequestAppointment]: 'Request an Appointment',
  [WidgetRequestType.RequestBoarding]: 'Request Boarding',
  [WidgetRequestType.RequestGroomingAppoinment]:
    'Request a Grooming Appointment',
  [WidgetRequestType.RequestRxRefill]: 'Request an RX Refill',
  [WidgetRequestType.RequestVirtualConsult]: 'Request a Virtual Visit',
  [WidgetRequestType.RequestMedicalRecords]: 'Request Records',
  [WidgetRequestType.TalkToStaff]: 'Talk to Staff',
};

const WidgetRequestAttachment = ({ attachment }) => {
  const apolloClient = useApolloClient();
  const curbsideContext = useContext(Context);
  const clinicWidgetRequestId = attachment.entityId;

  const { data: clinicWidgetRequestData } = useQuery(
    GET_CLINIC_WIDGET_REQUEST,
    {
      variables: { where: { id: clinicWidgetRequestId } },
      context: {
        headers: curbsideContext.headers,
      },
    }
  );

  const verifyWidgetRequest = async clinicWidgetRequestId => {
    try {
      apolloClient.mutate({
        mutation: VERIFY_CLINIC_WIDGET_REQUEST,
        variables: {
          where: { clinicWidgetRequestId },
          data: { isVerified: true },
        },
        context: {
          headers: curbsideContext.headers,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      !clinicWidgetRequestData?.clinicWidgetRequest ||
      clinicWidgetRequestData.clinicWidgetRequest.isVerified
    ) {
      return;
    }
    verifyWidgetRequest(clinicWidgetRequestData?.clinicWidgetRequest?.id);
  }, [clinicWidgetRequestData]); // eslint-disable-line

  const renderFormAnswer = ({ type, answer }) => {
    switch (type) {
      case FormQuestionType.checkbox:
        return Boolean(answer) ? 'Yes' : 'No';
      case FormQuestionType.multipleSelection:
        return (
          <ul>
            {answer.split(',').map(value => (
              <li key={value}>{value}</li>
            ))}
          </ul>
        );
      case FormQuestionType.dateInput:
        return moment(new Date(answer)).format('dddd, ll');
      case FormQuestionType.timeInput:
        const [hours, minutes] = answer.split(':');
        const date = new Date();
        date.setHours(Number(hours), Number(minutes));
        return <Time>{moment(date).format('h:mma')}</Time>;
      default:
        return answer;
    }
  };

  const formSubmission =
    clinicWidgetRequestData?.clinicWidgetRequest?.requestFormSubmission;
  const formQuestionAnswers = formSubmission?.questions;

  if (!clinicWidgetRequestData?.clinicWidgetRequest?.isVerified) {
    return (
      <BounceLoaderContainer>
        <BounceLoader loading={true} />
      </BounceLoaderContainer>
    );
  }

  const widgetRequestType =
    clinicWidgetRequestData?.clinicWidgetRequest?.clinicWidgetRequestType;

  return (
    <WidgetRequestAttachmentContainer>
      <Message>
        <span>
          Your request is confirmed! A member of our team will respond shortly
          via the chat.
        </span>
        <br />
        <br />
        <span>
          In the meantime, you can review the details of your request below.
        </span>
      </Message>
      <FormAnswers>
        <Row>
          <Prompt>Request Type</Prompt>
          <Answer>
            {widgetRequestType?.displayName ||
              WidgetRequestTypeDisplayNames[widgetRequestType.requestType]}
          </Answer>
        </Row>
        {formQuestionAnswers
          ?.filter(
            question =>
              question.type !== FormQuestionType.ratingInput &&
              question.type !== FormQuestionType.textBlock
          )
          .map(question => (
            <Row>
              <Prompt>{question.prompt}</Prompt>
              <Answer>{renderFormAnswer(question)}</Answer>
            </Row>
          ))}
      </FormAnswers>
    </WidgetRequestAttachmentContainer>
  );
};

const BounceLoaderContainer = styled.div`
  & > * {
    padding: 0;
  }

  .spinner {
    background: #fff;
  }
`;

const WidgetRequestAttachmentContainer = styled.div`
  font-family: 'LinearSans', Arial, Helvetica, sans-serif;
`;

const Message = styled.div`
  line-height: 1.4;
  white-space: pre-wrap;
`;

const FormAnswers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin: 24px 4px 12px;
  padding: 0 16px;
  border-left: 2px solid #39adc3;
  color: #fff;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 24px;
  }
`;

const Prompt = styled.div`
  font-weight: 600;
  color: #ddd;
  margin-bottom: 4px;
  line-height: 1.2;
`;

const Answer = styled.div`
  white-space: pre-wrap;
  line-height: 1.4;
  color: #fff;

  ul {
    padding-inline-start: 20px;
    list-style: disc;
  }
`;

const Time = styled.div`
  text-transform: lowercase;
`;

export default WidgetRequestAttachment;
