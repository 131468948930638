import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Button as UIButton } from '@televet/televet-ui';
import { useHistory } from 'react-router-dom';

import Text from '../theme/Text';
import { H3 } from '../Common/Headers';
import PersonAvatar from '../../assets/svg/client_avatar.svg';
import ActionButton from '../Common/ActionButton';
import { hslToHsla } from '../theme/helpers';
import ConsultationStatus from '../theme/ConsultationStatus';
import { requiresPayment } from '../../utilities/consultations';
import {
  capitalizeString,
  getTimeAgo,
  getRelativeTime,
  getTimeDifference,
} from '../../utilities';
import { Mixpanel } from '../../redux/mixpanel';

const ConsultationPreview = ({
  consultation,
  clinic,
  setSelectedConsultation,
  vets,
}) => {
  const history = useHistory();

  const {
    consultationId,
    transactionId,
    state,
    callType,
    summary,
    vet,
    nurse,
    scheduledAt,
  } = consultation;

  const checkConsultationType =
    callType === 'video' || callType === 'phone' || callType === 'text';
  const [relativeTime, setRelativeTime] = useState(getTimeAgo(scheduledAt));
  const [timeUntilAppointment, setTimeUntilAppointment] = useState(
    getTimeDifference(Date.now(), 'minutes', scheduledAt)
  );
  const vetInfo = vets ? vets[vet] : null;
  const nurseInfo = vets ? vets[nurse] : null;

  useEffect(() => {
    //create timer on component mount

    const timer = setInterval(() => {
      setRelativeTime(getRelativeTime(scheduledAt));
      setTimeUntilAppointment(
        getTimeDifference(Date.now(), 'minutes', scheduledAt)
      );
    }, 1000 * 60);

    // returned function will be called on component unmount
    return () => {
      clearInterval(timer);
    };
  }, [scheduledAt, timeUntilAppointment]);

  if (!consultation || !clinic) return null;

  return (
    <Card>
      <div style={{ display: 'flex', padding: '16px 20px', marginLeft: 8 }}>
        <ConsultationStatus status={state} />
      </div>
      <Content>
        <Preview>
          <div>
            <Summary>{summary || 'No summary added'}</Summary>
          </div>
        </Preview>
        <div>
          {checkConsultationType && state === 'SCHEDULED' && !transactionId && (
            <Text style={{ padding: '12px 0' }}>
              {clinic.name} has scheduled a follow-up appointment with you.
            </Text>
          )}
        </div>

        {state === 'NEW' && (
          <Text style={{ opacity: 0.7 }}>Awaiting care team assignment</Text>
        )}
        {(state === 'SCHEDULED' || state === 'PAID') && (
          <Text style={{ opacity: 0.7 }}>
            {capitalizeString(callType || '')} call scheduled: {''}
            <span>
              {relativeTime}
              {timeUntilAppointment <= 5 && timeUntilAppointment >= -20 && (
                <span style={{ color: '#30B80E' }}> Now </span>
              )}
            </span>
          </Text>
        )}
        <Divider />
        <Row>
          {vet || nurse ? (
            <Avatars>
              {vetInfo && (
                <Avatar src={vetInfo.profilePic || PersonAvatar} alt="" />
              )}
              {nurseInfo && (
                <Avatar src={nurseInfo.profilePic || PersonAvatar} alt="" />
              )}
            </Avatars>
          ) : (
            <ClinicInfo>
              <ClinicImage src={clinic.logo} alt={`${clinic.name} logo`} />
              <Text style={{ padding: '0 12px' }}>{clinic.name}</Text>
            </ClinicInfo>
          )}

          <Actions>
            <ViewButton
              buttonType={
                state === 'PAID' || (state === 'SCHEDULED' && transactionId)
                  ? 'ghost'
                  : 'primary'
              }
              onClick={() => {
                history.push(`/consultation/${consultationId}`);
                Mixpanel.track('View consultation button clicked');
              }}
              alt={`View consultation button`}
            >
              View Consultation
            </ViewButton>
            {(state === 'PAID' || (state === 'SCHEDULED' && transactionId)) &&
              callType === 'video' && (
                <ActionButton
                  onClick={() => {
                    history.push(`/video/${consultationId}`);
                    Mixpanel.track('Join call button clicked');
                  }}
                >
                  Join Call
                </ActionButton>
              )}
            {requiresPayment(consultation) && (
              <ActionButton
                onClick={() => setSelectedConsultation(consultation)}
              >
                Confirm Consultation
              </ActionButton>
            )}
          </Actions>
        </Row>
      </Content>
    </Card>
  );
};

const ViewButton = styled(UIButton)`
  margin-right: 12px;
  margin-bottom: 0;
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};
  background: transparent;
  min-width: 150px;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const Row = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const Content = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.spacingSizes.xl};
  ${({ theme }) => theme.spacing.spacingSizes.xl};
  width: 100%;
`;

const Card = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${({ theme }) => hslToHsla(theme.palette.primaryLightest, 0.4)};
  border-radius: 12px;
  margin: ${({ theme }) => theme.spacing.spacingSizes.lg} 0;
  width: 100%;
  overflow: hidden;
`;

const Preview = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.sm};
`;

const Summary = styled(H3)``;

const ClinicImage = styled.img`
  width: 48px;
  height: 48px;
  min-width: 48px;
`;

const ClinicInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1099px) {
    margin-top: 10px;
  }
`;

const Avatars = styled.div`
  display: flex;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  min-width: 48px;
  border-radius: 24px;
  margin: 0 10px 0 10px;
`;

const Divider = styled.hr`
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => hslToHsla(theme.palette.primary, 0.2)};
`;

export default ConsultationPreview;
