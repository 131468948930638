import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';

import Text from '../theme/Text';
import { getTimeAgo } from '../../utilities';

const Opened = ({ consultation, vets, clinic }) => {
  const [openedTimeAgo, setOpenedTimeAgo] = useState(
    getTimeAgo(consultation?.openedAt)
  );

  useEffect(() => {
    //create timer on component mount

    const timer = setInterval(() => {
      setOpenedTimeAgo(getTimeAgo(consultation?.openedAt));
    }, 1000 * 60);

    // returned function will be called on component unmount
    return () => {
      clearInterval(timer);
    };
  }, [consultation]);

  return (
    <Container>
      <div>
        <div>
          <InfoRow>
            <Text style={{ width: '80%', fontSize: 18 }}>
              Awaiting review and care team assignment
            </Text>
            <Text style={{ margin: '12px 0', fontSize: 12 }}>
              {openedTimeAgo}
            </Text>
          </InfoRow>
        </div>
      </div>
    </Container>
  );
};

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
`;

const InfoRow = styled(Row)`
  p {
    color: ${({ state, theme }) =>
      state === 'OPENED' ? theme.palette.black : 'rgba(16, 22, 38, 0.7)'};
  }
`;

const Container = styled.div`
  width: 100%;
`;

export default withRouter(Opened);
