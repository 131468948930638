import firebase from '../../../firebase/firebase';
import clinicSearchActionTypes from '../clinicSearchActionTypes';
const getClinics = () => {
  return async dispatch => {
    const clinicList = await getClinicList();
    let clinicArray = Object.keys(clinicList);
    clinicArray.forEach(async clinicId => {
      dispatch({
        type: clinicSearchActionTypes.getClinicData,
        payload: { key: clinicId, value: await getClinicData(clinicId) },
      });
    });
    dispatch({
      type: clinicSearchActionTypes.getClinicList,
      payload: clinicList,
    });
  };
};

export default getClinics;

const getClinicData = async clinicId => {
  let data = await firebase
    .database()
    .ref(`clinics/${clinicId}`)
    .once('value');

  return data.val();
};

const getClinicList = async () => {
  return await firebase
    .database()
    .ref('clinics-list')
    .once('value')
    .then(async snapshot => {
      return await snapshot.val();
    });
};
