import { store } from '../redux/store';
import axios from 'axios';

const getIsEmailRegistered = async email => {
  const state = store.getState();
  const restURL = state.variable.restURL;

  const { data: isEmailRegistered } = await axios.get(
    `${restURL}/users/isExistingEmail`,
    {
      params: { email },
    }
  );
  return isEmailRegistered;
};

export default getIsEmailRegistered;
