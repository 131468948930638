import React from 'react';
import styled from 'styled-components/macro';
import { Button } from '@televet/televet-ui';

import Text from '../theme/Text';
import Flex from '../theme/Flex';
import ClinicLogo from '../Common/ClinicLogo';
import { getFinalCost } from '../../utilities';

const ClinicInfo = ({ petId, clinic, onCreateConsultation, reducedCost }) => {
  const cost = getFinalCost(clinic.private.consultCost);

  return (
    <Container>
      <Flex>
        <ClinicLogo clinic={clinic} />
        <Description>
          <Text style={{ fontWeight: 500 }}>{clinic.name}</Text>
          <ResponsiveHours>
            <small>Responsive Hours</small>
          </ResponsiveHours>
          <Hours>{clinic.hours}</Hours>
        </Description>
      </Flex>
      {reducedCost && <CostText>{reducedCost}</CostText>}
      {onCreateConsultation && (
        <ConsultationButton
          buttonType="primary"
          name="create-consultation-button"
          onClick={onCreateConsultation}
          title={`Create consultation with ${clinic.name} for $${cost}`}
        >
          Create Consultation
        </ConsultationButton>
      )}
    </Container>
  );
};

const ResponsiveHours = styled(Text)`
  color: ${({ theme }) => theme.palette.gray300};
  padding-top: 4px;
`;

const CostText = styled(Text)`
  color: ${({ theme }) => theme.palette.success};
`;

const Container = styled.div`
  margin-bottom: ${({ onSelectConsultation, theme }) =>
    onSelectConsultation ? theme.spacing.spacingSizes.xxxl : 'initial'};
  justify-content: space-between;
  display: flex;
`;

const Description = styled.span`
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.md};
`;

const Hours = styled(Text)`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.gray300};
  max-width: 80%;
`;

const ConsultationButton = styled(Button)`
  flex: 1;
`;

export default ClinicInfo;
