import firebase from '../../../firebase/firebase';

import getUser from '../../user/actions/getUser';

/**
 * @function updatePetImage Saves an image to firebase storage and updates the pet picture with the new image's URL
 * @param {string} petId User ID to update
 * @param {blob} userImage Blob of the image to upload
 */
const updatePetImage = async (petId, userImage) => {
  let blob = await fetch(userImage).then(r => r.blob());
  return dispatch => {
    // Save image in user (Storage)
    firebase
      .storage()
      .ref()
      .child(`pets/${petId}`)
      .put(blob)
      .then(snapshot => {
        // Get Image URL
        firebase
          .storage()
          .ref(`pets/${petId}`)
          .getDownloadURL()
          .then(response => {
            // Response is the image URL
            // Save url in pets (Database)
            firebase
              .database()
              .ref(`pets/${petId}/public`)
              .update({ profilePic: response })
              .then(() => {
                dispatch(getUser());
              })
              .catch(error => {
                console.error('Error saving image url to database', error);
              });
          })
          .catch(error => {
            console.error('Error getting image url', error);
          });
      })
      .catch(error => {
        console.error('Error saving image in storage', error);
      });
  };
};

export default updatePetImage;
