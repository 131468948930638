import React from 'react';
import styled from 'styled-components/macro';
import { Modal, ResponsiveDeviceMinWidth } from '@televet/televet-ui';

import ConfirmConsultation from './ConfirmConsultation';

const ConfirmConsultationModal = ({ consultation, onCancel, onFinish }) => {
  return (
    <Modal size="lg" name="confirm-consultation">
      <ModalContent>
        <ConfirmConsultation
          consultation={consultation}
          onCancel={onCancel}
          onFinish={onFinish}
        />
      </ModalContent>
    </Modal>
  );
};

export default ConfirmConsultationModal;

const ModalContent = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  padding: 0 1em 1em 1em;

  @media ${ResponsiveDeviceMinWidth.tablet} {
    min-height: 650px;
    padding: 0 4em 4em 4em;
  }
`;
