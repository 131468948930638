import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Prompt from './Shared/Prompt';
import QuestionWrapper from './Shared/QuestionWrapper';
import RequiredQuestionMessage from './Shared/RequiredQuestionMessage';

interface IMultiLineTextInput {
  index: number;
  prompt: string;
  answer: string;
  setIsDraftSaved: Function;
  setAnswerAtIndex: Function;
  isRequired: boolean;
  isInvalid: boolean;
}

const MultiLineTextInput = ({
  index,
  prompt,
  answer,
  setIsDraftSaved,
  setAnswerAtIndex,
  isRequired,
  isInvalid,
}: IMultiLineTextInput): ReactElement => {
  return (
    <QuestionWrapper index={index}>
      <Prompt prompt={prompt} isRequired={isRequired} />
      <br />
      <StyledInput
        rows={5}
        placeholder=""
        value={answer}
        data-mixpanel-name="Multiline text input form field"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          const answer = e.currentTarget.value;
          setIsDraftSaved(false);
          setAnswerAtIndex(answer, index);
        }}
      />
      {isInvalid ? <RequiredQuestionMessage /> : null}
    </QuestionWrapper>
  );
};

export default MultiLineTextInput;

const StyledInput = styled.textarea`
  font-family: LinearSans;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: 1em;
  border-radius: 7px;
  border: solid 1px #d2d2d2;
`;
