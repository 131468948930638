import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './rootReducer';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];
let enhancers =
  process.env.REACT_APP_PRODUCTION !== 'false'
    ? applyMiddleware(...middleware)
    : composeWithDevTools(applyMiddleware(...middleware));

let store = createStore(persistedReducer, enhancers);
let persistor = persistStore(store);

const addPromiseSupportToDispatch = store => {
  const rawDispatch = store.dispatch;

  return action => {
    if (typeof action.then === 'function') {
      return action.then(rawDispatch);
    }
    return rawDispatch(action);
  };
};

store.dispatch = addPromiseSupportToDispatch(store);

export { store, persistor };
