const options = {
  environment: {
    Horse: [
      { label: 'Stable', value: 'Stable' },
      { label: 'Pasture', value: 'Pasture' },
      { label: 'Both', value: 'Stable/Pasture' },
    ],
    default: [
      { label: 'Inside', value: 'Inside' },
      { label: 'Outside', value: 'Outside' },
      { label: 'Both', value: 'Inside/Outside' },
    ],
  },
  gender: {
    Horse: [
      { label: 'Filly', value: 'Filly' },
      { label: 'Colt', value: 'Colt' },
      { label: 'Mare', value: 'Mare' },
      { label: 'Stallion', value: 'Stallion' },
      { label: 'Gelding', value: 'Gelding' },
    ],
    default: [
      { label: 'Male', value: 'Male' },
      { label: 'Female', value: 'Female' },
      { label: 'Unsure', value: 'Unsure' },
    ],
  },
};
export default options;
