import { store } from '../../store';
import axios from 'axios';
import paymentActionTypes from '../paymentActionTypes';

const getClientToken = customerId => {
  return new Promise((resolve, reject) => {
    // TODO: Access getState from a thunk instead of globally invoking it
    if (!customerId) {
      customerId = store.getState().user.info.private.customerId;
    }
    let restURL = store.getState().variable.restURL;
    let url =
      restURL +
      '/payment/client_token' +
      (customerId ? '?customerId=' + customerId : '');
    axios
      .get(url)
      .then(response => {
        resolve({
          type: paymentActionTypes.getClientToken,
          payload: response.data.clientToken,
        });
      })
      .catch(error => {
        console.error('Error obtaining client token', error);
        reject(error);
      });
  });
};

export default getClientToken;
