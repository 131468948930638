import React from 'react';
import styled from 'styled-components/macro';

import { CloseIcon } from '../Curbside/icons';
import VideoThumbnail from '../Common/VideoThumbnail';
import FileAttachment from '../Curbside/attachments/FileAttachment';
import { ChannelMessageAttachmentType } from '../Curbside/enums';

const ChatMediaPreview = ({
  stagedFile,
  removeFile,
  onThumbnail,
  handlePreviewClick,
}) => {
  const { file, type } = stagedFile;
  const fileURL = URL.createObjectURL(file);

  return (
    <StyledFilePreview>
      {removeFile && <StyledCloseIcon onClick={removeFile} />}
      <PreviewSpan>
        {(type === 'pic' || type.includes('image')) && (
          <PreviewImage
            onClick={() => {
              if (handlePreviewClick)
                handlePreviewClick(
                  fileURL,
                  ChannelMessageAttachmentType.Image,
                  file.name
                );
            }}
            src={fileURL}
            alt={file.name}
          />
        )}
        {type.includes('video') && (
          <div>
            <VideoThumbnail
              display={true}
              videoUrl={fileURL}
              thumbnailHandler={thumbnail => {
                if (onThumbnail) onThumbnail(thumbnail);
              }}
              onSelect={() => {
                if (handlePreviewClick)
                  handlePreviewClick(
                    fileURL,
                    ChannelMessageAttachmentType.Video,
                    file.name
                  );
              }}
            />
          </div>
        )}
        {!type.includes('video') &&
          !type.includes('image') &&
          type !== 'pic' &&
          type !== 'video' && (
            <PreviewDefaultFile
              onClick={() =>
                Object.assign(document.createElement('a'), {
                  href: fileURL,
                  target: '_blank',
                }).click()
              }
            >
              <FileAttachment onSelect={null} filename={file.name} />
            </PreviewDefaultFile>
          )}
      </PreviewSpan>
    </StyledFilePreview>
  );
};

const StyledFilePreview = styled.div`
  margin: 4px 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  width: auto;
  position: relative;
`;

const PreviewSpan = styled.span`
  margin: 0 12px 0 0;
`;

const PreviewImage = styled.img`
  max-height: 52px;
  border-radius: 4px;
`;

const PreviewDefaultFile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: hsl(230deg 18% 41%);

  &:hover {
    text-decoration: underline;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 3px;
  right: 8px;
  width: 12px;
  height: 12px;
  padding: 2px;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgb(5, 15, 95);
  z-index: 1;

  & * {
    fill: #fff;
  }

  &:hover,
  &:hover svg path {
    transform: scale(1.1);
  }
`;
export default ChatMediaPreview;
