import styled from 'styled-components/macro';

const IconButton = styled.div`
  align-items: center;
  background-color: #e5f3ff;
  border-radius: 6px;
  display: flex;
  height: 30px;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 30px;
`;

export default IconButton;
