import firebase from '../../../firebase/firebase';
import getUser from '../../user/actions/getUser';
import { pushSnackbar } from '../../snackbar/snackbarActions';

const db = firebase.database();

const linkConsultationToPet = ({
  consultationId,
  petId,
  userId,
}) => async dispatch => {
  try {
    await Promise.all([
      db
        .ref(`consults/${consultationId}`)
        .update({ pet: petId, owner: userId }),
      db.ref(`pets/${petId}/consults/${consultationId}`).set(true),
      db.ref(`users/${userId}/info/consults/${consultationId}`).set(true),
    ]);
    await dispatch(getUser());
  } catch (error) {
    console.error(error);
    dispatch(
      pushSnackbar({
        type: 'error',
        message:
          'Whoops! We had trouble finding your consultation, but your account was created successfully.',
      })
    );
  }
};

export default linkConsultationToPet;
