export const requiresPayment = consultation => {
  const { callType, state, transactionId } = consultation;
  const hasCallType =
    callType === 'video' || callType === 'phone' || callType === 'text';

  return (
    hasCallType && (state === 'NEW' || state === 'SCHEDULED') && !transactionId
  );
};

export const isClosed = consultation => {
  const { state } = consultation;
  return state === 'RESOLVED' || state === 'CANCELLED';
};
