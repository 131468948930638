import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { BounceLoader } from '@televet/televet-ui';
import { useLazyQuery } from '@apollo/client';

import MessageBody from './MessageBody';
import { ChannelMessageAttachmentType } from '../../enums';
import { GET_FORM_SUBMISSION_NAMES } from '../queries';
import CurbsideContext from '../../context/curbsideContext';
import { DEFAULT_PAGE_SIZE } from '../constants/defaultPageSize';
import SuggestedMessages from './SuggestedMessages';

const MessageTimeline = ({
  channelId,
  messages,
  pageInfo,
  televetChannel,
  onFirstMessageMount,
  onLastMessageMount,
  messageTimelineScrollContainerRef,
  previousMessagesRef,
  setViewAttachment,
  suggestedMessageOptions,
  suggestedMessageEntityId,
  workflowEventTriggerActionNumber,
  workflowActionEventTrigger,
  workflowSettingId,
}) => {
  const { user, clinic, token } = useContext(CurbsideContext);

  const history = useHistory();

  const headers = {
    'auth-type': 'clinic_pet_parent_token',
    authorization: `Bearer ${token}`,
    clinic: clinic?.id,
  };

  const [
    fetchFormSubmissionNames,
    { data: formSubmissionData, loading: isFormSubmissionDataLoading },
  ] = useLazyQuery(GET_FORM_SUBMISSION_NAMES, {
    context: { headers },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (messages.length > 0) {
      const submissionIds = [];
      messages.forEach(message => {
        if (message?.attachments) {
          message.attachments.forEach(attachment => {
            if (
              attachment.attachmentType ===
              ChannelMessageAttachmentType.FormRequest
            ) {
              submissionIds.push(attachment.entityId);
            }
          });
        }
      });
      fetchFormSubmissionNames({
        variables: { where: { id_in: submissionIds } },
      });
    }
  }, [messages, fetchFormSubmissionNames]);

  const formNames = useMemo(() => {
    if (!formSubmissionData && !formSubmissionData?.formSubmissions) return {};
    let formNames = {};
    formSubmissionData.formSubmissions.forEach(submission => {
      formNames[submission.id] = submission.formTemplate.title;
    });
    return formNames;
  }, [formSubmissionData]);

  const submittedAt = useMemo(() => {
    if (!formSubmissionData && !formSubmissionData?.formSubmissions) return {};
    let submittedAt = {};
    formSubmissionData.formSubmissions.forEach(submission => {
      submittedAt[submission.id] = submission.submittedAt;
    });
    return submittedAt;
  }, [formSubmissionData]);

  const joinCall = () => {
    history.push(`/conversation/video-call/${channelId}?token=${token}`);
  };

  return (
    <MessageTimelineContainer>
      <MessageListScrollContainer ref={messageTimelineScrollContainerRef}>
        {pageInfo && pageInfo.hasPreviousPage && (
          <PreviousMessagesLoadingContainer ref={previousMessagesRef}>
            <BounceLoader loading={true} />
          </PreviousMessagesLoadingContainer>
        )}
        {messages.length > DEFAULT_PAGE_SIZE &&
          pageInfo &&
          !pageInfo.hasPreviousPage && (
            <LastPageMessage>You've seen it all!</LastPageMessage>
          )}
        {messages.map((message, messageIndex) => {
          const isFirstMessage = messageIndex === 0;
          const isLastMessage = messageIndex === messages.length - 1;
          return (
            <MessageBody
              key={message.id}
              userId={user?.uid}
              message={message}
              televetChannel={televetChannel}
              clinic={clinic}
              formNames={formNames}
              submittedAt={submittedAt}
              loading={isFormSubmissionDataLoading}
              joinCall={joinCall}
              onFirstMessageMount={onFirstMessageMount}
              onLastMessageMount={onLastMessageMount}
              isFirstMessage={isFirstMessage}
              isLastMessage={isLastMessage}
              setViewAttachment={setViewAttachment}
            />
          );
        })}

        <SuggestedMessages
          options={suggestedMessageOptions}
          entityId={suggestedMessageEntityId}
          workflowEventTriggerActionNumber={workflowEventTriggerActionNumber}
          workflowSettingId={workflowSettingId}
        />
      </MessageListScrollContainer>
    </MessageTimelineContainer>
  );
};

export default MessageTimeline;

const MessageTimelineContainer = styled.div`
  flex: 1 1 auto;
  background-color: #f8f8f8;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
`;

const MessageListScrollContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 10px;
`;

const PreviousMessagesLoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  & > div {
    padding: 0;
  }
`;

const LastPageMessage = styled.div`
  color: rgb(87, 93, 124);
  font-size: 16px;
  padding: 1em;
  text-align: center;
`;
