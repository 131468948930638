import React, {
  useState,
  useEffect,
  useCallback,
  createRef,
  useMemo,
} from 'react';
import styled from 'styled-components/macro';
import { DeviceSizes, BounceLoader } from '@televet/televet-ui';
import { Modal, useModal } from '@televet/televet-ui';
import { useSelector } from 'react-redux';

import Text from '../theme/Text';
import Message from './Message';
import { hslToHsla } from '../theme/helpers';
import ChatMediaPreview from './ChatMediaPreview';

import EmptyChat from './EmptyChat';

import Eye from '../../assets/svg/eye.svg';

const Messages = props => {
  const [imageState, setImageState] = useState({
    src: null,
    alt: null,
    type: null,
  });
  const { openModal } = useModal();
  const { isLoading } = props;

  const scrollRef = createRef();
  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView(false, { behavior: 'smooth' });
    }
  }, [scrollRef]);

  const onImageMessageClick = (src, alt, type) => {
    setImageState({ src, alt, type });
    openModal('chat-media-preview');
  };
  const { consultationId } = props;

  const uid = useSelector(state => state.auth.user.uid);
  const vets = useSelector(state => state.vets);
  const chats = useSelector(state => state.chats);
  const consultation = useSelector(
    state => state.consultations[consultationId]
  );

  const userDisplayName = useSelector(state => state.auth.user.displayName);
  const userPictureSrc = useSelector(
    state => state.user.info.public.profilePic
  );
  const chat = chats[consultationId] || {};

  let chatArray = Object.keys(chat);

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToBottom();
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, [chatArray.length, scrollToBottom]);

  let userMessages = {};
  chatArray.forEach(key => {
    if (chat[key].user === uid) userMessages[key] = chat[key];
  });

  const userMessageKeys = Object.keys(userMessages);
  const lastUserMessageId = useMemo(() => {
    return userMessageKeys[userMessageKeys.length - 1];
  }, [userMessageKeys]);

  if (chatArray.length > 0) {
    return (
      <Wrapper>
        <ConversationStart>
          <Text>Beginning of conversation</Text>
        </ConversationStart>
        {chatArray.map((messageId, index) => {
          const id = chat[messageId].user;
          let displayName, pictureSrc, alignment, isVet;

          if (id !== uid) {
            displayName = `${vets[id]?.fname} ${vets[id]?.lname}`;
            pictureSrc = vets[id]?.profilePic;
            alignment = 'left';
            isVet = true;
          } else {
            displayName = userDisplayName;
            pictureSrc = userPictureSrc;
            alignment = 'right';
            isVet = false;
          }

          return (
            <Message
              key={`chat-message-${index}`}
              messageId={messageId}
              displayName={displayName}
              pictureSrc={pictureSrc}
              chat={chat}
              userId={id}
              lastUserMessageId={lastUserMessageId}
              alignment={alignment}
              onImageMessageClick={onImageMessageClick}
              previous={chat[chatArray[index - 1]]}
              next={chat[chatArray[index + 1]]}
              isVet={isVet}
              consultationId={consultationId}
            />
          );
        })}

        {Object.keys(consultation?.last_message?.readBy || {}).map(
          (readByUserId, index) => {
            if (readByUserId !== uid) {
              return (
                <SeenBy key={`seenBy_$index`}>
                  <StyledEye src={Eye} />
                  {vets[readByUserId]?.fname}
                </SeenBy>
              );
            }
            return null;
          }
        )}
        <BounceLoader loading={isLoading} />
        {props.stagedFile && !isLoading && (
          <StyledFileStage style={{ flex: 5, overflow: 'hidden' }}>
            <ChatMediaPreview
              removeFile={props.removeFile}
              stagedFile={props.stagedFile}
              onThumbnail={props.handleThumbnail}
            />
          </StyledFileStage>
        )}
        <PaddedDiv />
        <div style={{ height: '10px' }} ref={scrollRef} />
        <Modal name="chat-media-preview" size="lg">
          <ModalContent>
            {imageState.type === 'pic' && (
              <PreviewImage src={imageState.src} alt={imageState.alt} />
            )}
            {imageState.type === 'video' && (
              <PreviewVideo src={imageState.src} controls>
                Your browser does not support the video tag.
              </PreviewVideo>
            )}
          </ModalContent>
        </Modal>
      </Wrapper>
    );
  } else {
    return <EmptyChat consultation={consultation} />;
  }
};

export default Messages;

const ModalContent = styled.div`
  padding: 2em 1em;
  text-align: center;
`;

const PreviewImage = styled.img`
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  max-width: 37.5rem;
  width: 100%;
  height: auto;
`;

const PreviewVideo = styled.video`
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  max-width: 37.5rem;
  outline: none;
  width: 100%;
  height: auto;
`;

const PaddedDiv = styled.div`
  height: 0;
  @media (max-width: ${DeviceSizes.tablet}) {
    height: 130px;
  }
`;

const ConversationStart = styled.div`
  text-align: center;
  padding: 24px 12px;
  color: ${({ theme }) => theme.palette.gray500};
`;

const Wrapper = styled.div`
  height: 100%;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const StyledFileStage = styled.div`
  height: auto;
  padding: 8px 0 0 20px;
  border-top: 1px solid ${({ theme }) => hslToHsla(theme.palette.primary, 0.5)};
  display: flex;
  justify-content: center;
`;

const SeenBy = styled.div`
  color: gray;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -10px;
  margin-right: 60px;
`;

const StyledEye = styled.img`
  color: gray;
  height: 14px;
  width: 14px;
  margin-right: 5px;
`;
