import axios from 'axios';

import getUser from '../../user/actions/getUser';
import firebase from '../../../firebase/firebase';
import appActionTypes from '../appActionTypes';
import { pushSnackbar } from '../../snackbar/snackbarActions';
import { capitalizeString } from '../../../utilities';
import consultationsActionTypes from '../../consultations/consultationsActionTypes';

/**
 * @function
 * @param {object} consultation The function takes a single object parameter, which is destructured here
 * as the below parameters
 *
 * @param {string} nonce One-time identifier to create the transaction on Braintree
 * @param {string} clinicId ID of the clinic
 * @param {string} cost Amount of money being charged for the consultation
 * @param {string} details Detailed account of pet's condition
 * @param {boolean} media True if media files are being uploaded, false otherwise
 * @param {array} pics Array of JS File objects (images) uploaded by the user
 * @param {array} vids Array of JS File objects (videos) uploaded by the user
 * @param {string} petId ID of the pet for this consultation
 * @param {boolean} phonePref User preference of phone consultation
 * @param {string} summary Summary of pet's condition
 * @param {boolean} videoPref User preference of video consultation
 */
const createConsultation = ({
  nonce,
  clinicId,
  cost,
  details,
  media,
  pics,
  vids,
  petId,
  phonePref,
  summary,
  videoPref,
  selectedPromo = null,
}) => {
  return async (dispatch, getState) => {
    try {
      const {
        pets,
        user: { info },
        auth: {
          user: { uid },
        },
        variable: { restURL },
        clinics,
      } = getState();
      const clinic = clinics[clinicId];
      const owner = uid;
      const userInfo = info.public;

      if (!clinic.active) {
        dispatch(
          pushSnackbar({
            type: 'info',
            message: `We're sorry, ${clinic?.info?.name ||
              'your selected clinic'} no longer uses TeleVet. Please reach out to them for further assistance.`,
            timeoutMs: 7000,
          })
        );

        return;
      }
      let data = {
        clinic: clinicId,
        promo: selectedPromo,
        cost: (
          cost +
          5 -
          (cost + 5) * ((selectedPromo ? selectedPromo.discount : 0) / 100)
        ).toFixed(2),
        type: 'CONSULT',
        client: owner,
        nonce,
      };

      const response = await axios.post(
        `${restURL}/payment/create_transaction`,
        data
      );

      if (response.data?.transaction?.status === 'authorized') {
        const transactionId = response.data.transaction.id;

        // Create consultation
        const snapshot = await firebase
          .database()
          .ref(`consults`)
          .push({
            clinic: clinicId,
            cost: (
              cost -
              (cost + 5) * ((selectedPromo ? selectedPromo.discount : 0) / 100)
            ).toFixed(2),
            createdAt: Date.now(),
            details,
            media,
            owner,
            read: false,
            received: false,
            assigned: false,
            pet: petId,
            phonePref,
            state: 'NEW',
            summary,
            transactionId,
            type: 'CONSULT',
            videoPref,
          });

        const consultationId = snapshot.key;
        const consultStorageRef = firebase.storage();
        const consultDatabaseRef = firebase
          .database()
          .ref(`consults/${consultationId}`);

        const uploadImage = async (image, index) => {
          const route = `consults/${consultationId}/${image.name}`;
          await consultStorageRef.ref(route).put(image);

          const imageUrlResponse = await consultStorageRef
            .ref(route)
            .getDownloadURL();

          // Response is the image storage URL
          const update = { [index]: imageUrlResponse };
          await consultDatabaseRef.child(`pics/`).update(update);
          return;
        };

        const uploadVideo = async (video, index) => {
          const route = `consults/${consultationId}/${video.name}`;
          await consultStorageRef.ref(route).put(video);

          const videoUrlResponse = await consultStorageRef
            .ref(route)
            .getDownloadURL();

          // Response is the video storage URL
          const data = await getVideoMetadata(
            videoUrlResponse,
            video.size,
            index
          );

          await consultDatabaseRef.child(`vids/`).update(data);
          return;
        };

        const uploadMedia = async () => {
          await Promise.all(
            pics.map(async (image, index) => await uploadImage(image, index))
          );
          return Promise.all(
            vids.map(async (video, index) => await uploadVideo(video, index))
          );
        };

        // Upload images to database and storage
        await uploadMedia();

        const updateObject = { [consultationId]: true };

        await firebase
          .database()
          .ref(`pets/${petId}/consults`)
          .update(updateObject);

        // Updating owner
        await firebase
          .database()
          .ref(`users/${owner}/info/consults`)
          .update(updateObject);

        // Updating clinic
        await firebase
          .database()
          .ref(`clinics/${clinicId}/info/private/consults/active`)
          .update(updateObject);

        dispatch({
          type: appActionTypes.createConsultation,
          payload: consultationId,
        });

        const consultationSnapshot = await consultDatabaseRef.once('value');

        dispatch({
          type: consultationsActionTypes.addConsultation,
          payload: {
            consultId: consultationId,
            consultObj: consultationSnapshot.val(),
          },
        });

        await dispatch(getUser());

        let notification = {
          data: {
            consult: consultationId,
            type: 'NEW',
          },
          petId,
          clinic: clinicId,
          ownerId: owner,
          ownerName: `${capitalizeString(userInfo.fname)} ${capitalizeString(
            userInfo.lname
          )}`,
          petName: capitalizeString(pets[petId].public.name),
          summary,
          details,
        };

        await axios.post(`${restURL}/notif/create`, notification);

        return consultationId;
      } else if (response.data?.transaction?.status !== 'authorized') {
        console.error(response?.data?.message);
        dispatch(
          pushSnackbar({
            type: 'error',
            message:
              'The consultation was unable to be created: ' +
              (response?.data?.message || 'an unknown error occurred'),
          })
        );
      }
    } catch (error) {
      console.error(
        'The consultation was unable to be created: ',
        error.message || 'an unknown error occurred'
      );
      dispatch(
        pushSnackbar({
          type: 'error',
          message:
            'The consultation was unable to be created: ' +
            (error.message || 'an unknown error occurred'),
        })
      );
    }
  };
};

export default createConsultation;

/**
 * @function
 * @param {string} url URL of the video in Firebase Storage
 * @param {number} size Size in bytes of the video
 * @param {number} videoIndex Position of the video in the array of user-uploaded videos
 */
function getVideoMetadata(url, size, videoIndex) {
  return new Promise(function(resolve) {
    const thumbnail =
      'https://firebasestorage.googleapis.com/v0/b/televet-v2.appspot.com/o/black-square.png?alt=media&token=9f34f856-ee91-46a1-aa26-4b68c3556438';
    let video = document.createElement('video');

    video.addEventListener(
      'loadedmetadata',
      function() {
        let height = this.videoHeight;
        let width = this.videoWidth;
        let duration = this.duration;

        resolve({
          [videoIndex]: {
            '0': thumbnail, // TODO: Update this with video thumbnail
            '1': url, // Full Video
            '2': {
              // Video metadata
              height,
              width,
              duration,
              size,
              bitrate: size / duration,
              orientation: height > width ? 'portrait' : 'landscape',
            },
          },
        });
      },
      false
    );

    // start downloading meta-data
    video.src = url;
  });
}
