import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import {
  TextInput,
  Select,
  Button,
  ResponsiveDeviceMinWidth,
  Modal,
  useModal,
} from '@televet/televet-ui';
import options from '../../constants/petSelectOptions';
import { number } from '../../utilities/validation';
import { getDayOptions, getYearOptions } from '../../utilities';
import { monthOptions } from '../../constants/monthOptions';
import { fertilityOptions } from '../../constants/fertilityOptions';
import { Dog, Cat, Horse, AnimalPrint } from '../Common/Icons';
import ImageCropper from '../Common/ImageCropper';
import { H4 } from '../Common/Headers';
import PetPracticeForm from '../AddPet/components/PetPracticeForm';
import ClinicPlaceHolderLogo from '../../assets/svg/clinic-logo-placeholder.svg';
import { formatPossessiveName } from '../../utilities';

const iconTypes = {
  Dog: Dog,
  Cat: Cat,
  Horse: Horse,
};

const PetEdit = ({
  petId,
  name,
  type,
  gender,
  reproductiveStatus,
  environment,
  breed,
  weight,
  month,
  day,
  year,
  profilePic,
  clinic,
  onSave,
  onCancel,
  onRemove,
  onUpdateImage,
}) => {
  const [editClinic, setEditClinic] = useState(false);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const { openModal, closeModal } = useModal();
  const selectedImageRef = createRef();
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      name,
      type,
      gender,
      reproductiveStatus,
      environment,
      breed,
      weight,
      month,
      day,
      year,
    },
    mode: 'onBlur',
  });

  const currentClinic = selectedClinic || clinic;

  const genderWatch = watch('gender');

  const genderFertilityOptions =
    genderWatch === 'Male' || genderWatch === 'Female'
      ? fertilityOptions[genderWatch]
      : fertilityOptions['Default'];
  const environmentOptions =
    type === 'Horse' ? options.environment.Horse : options.environment.default;

  const EmptyImageIcon = iconTypes[type] || AnimalPrint;

  const onSubmit = values => {
    let petClinic = {};

    if (selectedClinic) {
      petClinic = {
        clinicDisplay: currentClinic.info.name,
        clinicId: currentClinic.id,
      };
    }
    onSave({
      ...petClinic,
      ...values,
      petId,
      profilePic,
      type,
    });
  };

  const onSelectClinic = ({ clinic }) => {
    setSelectedClinic(clinic);
    setEditClinic(false);
  };

  const onCropImageImage = croppedImage => {
    onUpdateImage(croppedImage);
    setSelectedImage(null);
  };

  const onRemoveConfirm = () => {
    onRemove(petId);
    closeModal();
  };

  return (
    <>
      <ImageContainer>
        {!selectedImage && (
          <ImageArea onClick={() => selectedImageRef.current.click()}>
            <ImageAreaOverlay>
              {profilePic ? (
                <PreviewImage src={profilePic} alt={name} />
              ) : (
                <EmptyImageIcon />
              )}
              <SelectImageOverlay>Select Image</SelectImageOverlay>
            </ImageAreaOverlay>

            <FileInput
              type="file"
              accept=".png, .jpg, .jpeg"
              name="newPetImage"
              onChange={e => setSelectedImage(e.target.files[0])}
              ref={selectedImageRef}
            />
          </ImageArea>
        )}
      </ImageContainer>

      {selectedImage && (
        <ImageCropper
          src={selectedImage ? URL.createObjectURL(selectedImage) : null}
          circularCrop={true}
          crop={{
            aspect: 1,
            unit: '50%',
            x: 25,
            y: 25,
            width: 50,
            maxWidth: 60,
            maxHeight: 40,
          }}
          setCrop={croppedImage => onCropImageImage(croppedImage)}
        />
      )}
      {!editClinic && (
        <ClinicDisplayContainer>
          <ClinicImage
            src={currentClinic?.info?.logo || ClinicPlaceHolderLogo}
            alt={`${formatPossessiveName(currentClinic?.info?.name) ||
              "your clinic's"} logo`}
          />
          <Title>
            <ClinicName>{`${currentClinic?.info?.name}`}</ClinicName>
            <SelectClinicButton
              buttonType="text"
              onClick={() => setEditClinic(true)}
            >
              Change Clinic
            </SelectClinicButton>
          </Title>
        </ClinicDisplayContainer>
      )}
      {editClinic && (
        <PetPracticeForm
          name="petPractice"
          practiceName=""
          onSubmit={onSelectClinic}
          withoutHeader
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          name="name"
          label="Name"
          register={register}
          validation={{ required: true }}
          error={errors.name && 'Please enter a name'}
        />
        <Select
          name="gender"
          label="Select Gender"
          options={options.gender[type] || options.gender.default}
          register={register}
        />
        <Select
          name="reproductiveStatus"
          label="Reproductive Status"
          options={genderFertilityOptions}
          register={register}
        />
        <Select
          name="environment"
          label="Environment"
          options={environmentOptions}
          register={register}
        />
        <TextInput name="breed" label="Breed" register={register} />
        <TextInput
          name="weight"
          label="Weight (Pounds)"
          register={register}
          validation={{ pattern: number }}
          error={
            errors.weight &&
            `Please enter ${formatPossessiveName(name) ||
              "your pet's"} weight in pounds`
          }
        />
        <Select
          name="year"
          label="Pet's birth year"
          options={getYearOptions()}
          register={register}
        />
        <Select
          name="month"
          label="Pet's birth month"
          options={monthOptions}
          register={register}
        />
        <Select
          name="day"
          label="Pet's birth day"
          options={getDayOptions(
            monthOptions.findIndex(i => i.value === watch('month')),
            watch('year')
          )}
          register={register}
        />

        <ActionButton type="submit" fullWidth>
          Save
        </ActionButton>
        <CancelButton
          type="button"
          fullWidth
          onClick={onCancel}
          buttonType="ghost"
        >
          Cancel
        </CancelButton>
        <DeleteButton
          type="button"
          fullWidth
          onClick={() => openModal('delete-pet-confirmation')}
        >
          Delete
        </DeleteButton>
      </form>
      <Modal name="delete-pet-confirmation">
        <ModalContent>
          <ModalH4>Are you sure you want to delete {name}</ModalH4>
          <Button
            type="button"
            onClick={() => closeModal()}
            buttonType="secondary"
          >
            No, go back
          </Button>
          <DeleteButton onClick={onRemoveConfirm}>
            Yes, I want to delete my pet
          </DeleteButton>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PetEdit;

PetEdit.propTypes = {
  petId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  gender: PropTypes.string,
  reproductiveStatus: PropTypes.string,
  environment: PropTypes.string,
  breed: PropTypes.string,
  weight: PropTypes.string,
  month: PropTypes.string,
  day: PropTypes.string,
  year: PropTypes.string,
  profilePic: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const ActionButton = styled(Button)`
  background: ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.white};
  border: 1px solid transparent;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background: ${({ theme }) => theme.palette.actionButtonHover} !important;
    border-color: ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const CancelButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};
  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const DeleteButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.error};

  &:hover:not([disabled]) {
    background-color: ${({ theme }) => theme.palette.error};
  }

  &:focus:not([disabled]) {
    background-color: ${({ theme }) => theme.palette.error};
  }
`;

const ImageContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
`;

const ImageArea = styled.div`
  height: 250px;
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.md};
  position: relative;
  width: 250px;
`;

const ImageAreaOverlay = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 0;

  svg {
    height: auto;
    width: auto;
  }
`;

const PreviewImage = styled.img`
  border-radius: 50%;
  height: 250px;
  width: 250px;
`;

const FileInput = styled.input`
  display: none;
`;

const SelectImageOverlay = styled.span`
  bottom: 40px;
  color: ${({ theme }) => theme.palette.primary};
  background-color: ${({ theme }) => theme.palette.white};
  padding: 10px;
  border-radius: 4px;
  position: absolute;
`;

const ClinicImage = styled.img`
  border-radius: 4px;
  height: 36px;
  margin-right: ${({ theme }) => theme.spacing.spacingSizes.sm};
  width: 36px;
`;

const ClinicDisplayContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: ${({ theme }) => theme.spacing.spacingSizes.sm} 0;
`;

const Title = styled.span`
  width: 85%;
`;

const ClinicName = styled(H4)`
  width: 100%;
  display: inline-block;
  margin: 0;
  @media ${ResponsiveDeviceMinWidth.tablet} {
    margin: 0;
  }
`;

const SelectClinicButton = styled(Button)`
  margin: 0;
  padding: 0;
  text-align: left;
`;

const ModalContent = styled.div`
  padding: ${({ theme }) => theme.spacing.spacingSizes.md};
  text-align: center;

  button {
    margin: ${({ theme }) => theme.spacing.spacingSizes.xs};
    min-width: 15em;
  }
`;

const ModalH4 = styled(H4)`
  @media ${ResponsiveDeviceMinWidth.tablet} {
    margin: ${({ theme }) => theme.spacing.spacingSizes.lg};
  }
`;
