import { useEffect, useState } from 'react';

const useIntercom = (appId, userData = {}) => {
  const [loaded, setLoaded] = useState(false);
  const { email, user_id, user_hash } = userData;

  useEffect(() => {
    if (!window.Intercom && appId) {
      (function(w, d, id, s, x) {
        function i() {
          i.c(arguments);
        }
        i.q = [];
        i.c = function(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        s = d.createElement('script');
        s.async = 1;
        s.src = 'https://widget.intercom.io/widget/' + id;
        d.head.appendChild(s);
      })(window, document, appId);
    }
  }, [appId, email, user_id]);

  useEffect(() => {
    if (window.Intercom && appId && user_hash) {
      if (loaded) {
        window.Intercom('update', { app_id: appId, ...userData });
      } else {
        window.Intercom('boot', { app_id: appId, ...userData });
        setLoaded(true);
      }
    }
  }, [appId, userData, loaded, user_hash]);

  return [loaded];
};

export default useIntercom;
