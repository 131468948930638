import React, { useContext, useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import { BounceLoader } from '@televet/televet-ui';
import Context from '../context/curbsideContext';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';
import { ReactComponent as LockIcon } from '../../../assets/svg/lock.svg';
import { ReactComponent as PoweredByStripeIcon } from '../../../assets/svg/stripe-powered-by.svg';

export enum StripePaymentIntentStatus {
  Created = 'Created',
  Succeeded = 'Succeeded',
  Canceled = 'Canceled',
  RequiresPaymentMethod = 'Requires_Payment_Method',
  RequiresConfirmation = 'Requires_Confirmation',
  RequiresAction = 'Requires_Action',
  Processing = 'Processing',
  RequiresCapture = 'Requires_Capture',
}
interface StripePaymentOutput {
  paymentIntentClientSecret: string;
  requiresAction: boolean;
}

interface StripePaymentIntent {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  stripeId: string;
  status: StripePaymentIntentStatus;
  amount: number;
  amountCaptured: number;
  intent: StripePaymentOutput;
}
interface IPaymentModalProps {
  isOpen: boolean;
  paymentIntent: StripePaymentIntent;
  amount: string;
  onClose: (isSuccess: boolean) => void;
}

const PaymentModal = ({
  isOpen,
  paymentIntent,
  amount,
  onClose,
}: IPaymentModalProps): JSX.Element => {
  const curbsideContext = useContext<{ [key: string]: any }>(Context);
  const stripe = useStripe();
  const elements = useElements();

  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const handleModalClose = useCallback(
    (isSuccess: boolean) => {
      setIsValid(false);
      setIsSubmitting(false);
      setValidationMessage('');
      onClose(isSuccess);
    },
    [onClose]
  );

  const handleCardElementChange = useCallback(
    (event: StripeCardElementChangeEvent) => {
      const { complete, error } = event;
      if (complete && !error) setIsValid(true);
    },
    []
  );

  const handleSubmitButtonClick = useCallback(async () => {
    if (!elements || !stripe) return;
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return;

    setIsSubmitting(true);

    try {
      const {
        paymentIntent: updatedIntent,
        error,
      } = await stripe.confirmCardPayment(
        paymentIntent.intent.paymentIntentClientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );
      if (updatedIntent?.status === 'succeeded') {
        handleModalClose(true);
      } else {
        console.error(error);
        setValidationMessage(
          error?.message ||
            'Whoops! Something went wrong while processing this payment.'
        );
      }
    } catch (error) {
      console.error(error);
      setValidationMessage(
        'Whoops! Something went wrong while processing this payment.'
      );
    } finally {
      setIsSubmitting(false);
    }
  }, [paymentIntent, stripe, elements, handleModalClose]);

  return (
    <PaymentModalContainer className={isOpen ? 'isOpen' : ''}>
      <StyledCloseIcon onClick={() => handleModalClose(false)} />
      <Clinic>
        <StyledClinicLogo
          src={curbsideContext.clinic.logo}
          alt={`${curbsideContext.clinic.name} logo`}
        />
        <div>{curbsideContext.clinic.name}</div>
      </Clinic>
      <Amount>
        <div>Amount Due:</div>
        <div>$ {amount}</div>
      </Amount>
      {isOpen && (
        <StyledCardElement
          onReady={element => element.focus()}
          onChange={handleCardElementChange}
          options={{
            style: {
              base: {
                color: '#fff',
                '::placeholder': {
                  color: '#eee',
                },
                iconColor: '#f8f8f8',
                fontSize: '16px',
              },
            },
          }}
        />
      )}
      {!!validationMessage && (
        <ValidationMessage>{validationMessage}</ValidationMessage>
      )}
      <SubmitButton
        disabled={!isValid || !stripe || isSubmitting}
        onClick={handleSubmitButtonClick}
      >
        {isSubmitting ? (
          <BounceLoaderContainer>
            <BounceLoader loading={true} />
          </BounceLoaderContainer>
        ) : (
          'Pay Now'
        )}
      </SubmitButton>
      <PoweredByStripeContainer>
        <LockIcon />
        <span>
          Guaranteed <b>Safe &amp; Secure</b>
        </span>
        <StyledPoweredByStripeIcon
          onClick={(): void =>
            Object.assign(document.createElement('a'), {
              target: '_blank',
              href: 'https://stripe.com',
            }).click()
          }
          height="20"
          width="auto"
        />
      </PoweredByStripeContainer>
    </PaymentModalContainer>
  );
};

const PaymentModalContainer = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 24px;
  background: #575d7c;
  color: #fff;
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.2s ease-in-out;

  &.isOpen {
    transform: translateY(0);
    opacity: 1;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  width: 30px;
  height: 30px;
  padding: 4px;
  top: 20px;
  right: 20px;
`;

const Clinic = styled.div`
  font-size: 24px;
  margin-bottom: 50px;
  text-align: center;
`;

const StyledClinicLogo = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 6px;
  margin-bottom: 20px;
`;

const Amount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
`;

const StyledCardElement = styled(CardElement)`
  margin: 30px 0;
  padding: 15px;
  border: 1px solid #f8f8f8;
  border-radius: 10px;

  &.StripeElement--focus {
    border-color: hsl(190, 55%, 49%);
  }
`;

const ValidationMessage = styled.div`
  margin: 0 0 20px;
  color: #eb1c26;
`;

const SubmitButton = styled.button`
  border: none;
  border-radius: 15px;
  background: hsl(190, 55%, 49%);
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 10px;
  height: 42px;
  color: #fff;
  outline: none;
  cursor: pointer;

  &:active {
    background: hsla(190, 55%, 49%, 0.85);
  }

  &[disabled] {
    background: #c1c9ca;
  }
`;

const BounceLoaderContainer = styled.div`
  > * {
    padding: 0;
  }

  & .spinner {
    background: #fff;
  }
`;

const PoweredByStripeContainer = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;

  & svg:first-of-type {
    margin-right: 10px;
  }

  & svg:last-of-type {
    margin-left: 10px;
  }

  & > span {
    flex: 1 1 auto;
  }

  & b {
    font-weight: 600;
  }
`;

const StyledPoweredByStripeIcon = styled(PoweredByStripeIcon)`
  width: auto;
  height; 20px;
  cursor: pointer;
`;

export default PaymentModal;
