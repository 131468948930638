import produce from 'immer';
import userActionTypes from './userActionTypes';

const initialState = {
  info: { private: { customerId: '' }, public: {} },
};

let userReducer = produce((draft, action) => {
  switch (action.type) {
    case userActionTypes.getUser:
      Object.keys(action.payload).forEach(key => {
        draft[key] = action.payload[key];
      });
      break;
    case userActionTypes.getPromoCodeExpiration:
      draft.info.promos[action.payload.id].exp = action.payload.exp;
      break;
    default:
      break;
  }
}, initialState);

export default userReducer;
