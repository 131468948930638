import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { connectToChild } from 'penpal';
import {
  withRouter,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Mixpanel } from '../../../redux/mixpanel';

import { useLazyQuery } from '@apollo/client';
import { GET_CLINIC_PET_PARENT } from '../queries';
import jwt from 'jsonwebtoken';

const VideoCall = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const { channelId } = useParams();
  const history = useHistory();
  const { clinicPetParentId } = jwt.decode(token);
  const [clinicPetParent, setClinicPetParent] = useState();

  const headers = {
    'auth-type': 'clinic_pet_parent_token',
    authorization: `Bearer ${token}`,
  };

  const [getClinicPetParent, { data }] = useLazyQuery(GET_CLINIC_PET_PARENT, {
    variables: { where: { id: clinicPetParentId } },
    context: { headers },
  });

  useEffect(() => {
    getClinicPetParent();
  }, [getClinicPetParent]);

  const setUpVideo = useCallback(() => {
    const iframe = iframeRef.current;
    const userId = clinicPetParentId;
    const clinicName = clinicPetParent.clinic.name;
    const clientName =
      clinicPetParent?.firstName + ' ' + clinicPetParent?.lastName;

    if (channelId) {
      connectToChild({
        iframe,
        methods: {
          getRoomData: () => {
            return {
              userId,
              channelId,
              clientName: clinicName || clientName,
              userInfo: {
                firstName: clinicPetParent.firstName,
                lastName: clinicPetParent.lastName,
              },
              appName: 'pet-web',
            };
          },
          onCallEnd: () => {
            if (channelId)
              history.push(`/conversations/${channelId}?token=${token}`);
            navigator.mediaDevices
              .getUserMedia({ audio: true, video: true })
              .then(mediaStream =>
                mediaStream.getTracks().forEach(track => track.stop())
              );

            Mixpanel.track('End call clicked');
          },
        },
      });
    }
  }, [history, channelId, clinicPetParentId, token, clinicPetParent]);

  useEffect(() => {
    if (data) setClinicPetParent(data.clinicPetParent);
  }, [data, setClinicPetParent]);

  useEffect(() => {
    if (clinicPetParent) setUpVideo();
  }, [clinicPetParent, setUpVideo]);

  const URL = process.env.REACT_APP_VIDEO_CALL_PLUGIN_URL;

  const iframeRef = useRef(null);

  return (
    <VideoPlugInWrapper>
      <StyledIframe
        title="Televet-Video-Call"
        ref={iframeRef}
        src={URL}
        allow="camera;microphone;fullscreen"
        frameBorder="0"
        name="video-call-iframe"
      />
    </VideoPlugInWrapper>
  );
};

export default connect(null)(withRouter(VideoCall));

const VideoPlugInWrapper = styled.div`
  background-color: hsl(0, 0, 18);
  width: 100%;
  height: 100%;
`;

const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
`;
