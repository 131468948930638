import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { connectToChild } from 'penpal';
import { useModal, Button } from '@televet/televet-ui';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import React, { useRef, useEffect, useState, useCallback } from 'react';

import { Mixpanel } from '../../redux/mixpanel';
import ConfirmPermissionModal from '../Common/ConfirmPermissionModal';

const VideoCall = ({ match, uid, consultations, user, clinics }) => {
  const [hasRequiredPermissions, setHasRequiredPermissions] = useState(false);
  const [permissionsRetrieved, setPermissionsRetrieved] = useState(false);
  const { channelId } = useParams();
  const { openModal, closeModal } = useModal();
  const history = useHistory();

  const token = localStorage.getItem('token');

  const setUpVideo = useCallback(() => {
    const iframe = iframeRef.current;
    const consultId = match.params.consultationId;
    const userId = uid;
    const clinicId = consultations[consultId]?.clinic;
    const clinicName = clinics[clinicId]?.info?.name;
    const clientName = [user?.info?.public?.fname, user?.info?.public?.lname]
      .join(' ')
      .trim();

    if (consultId || channelId) {
      connectToChild({
        iframe,
        methods: {
          getRoomData: () => {
            return {
              userId,
              consultId,
              channelId,
              clientName: clinicName || clientName,
              userInfo: user?.info?.public,
              appName: 'pet-web',
            };
          },
          onCallEnd: () => {
            if (consultId) history.push(`/consultation/${consultId}`);
            if (channelId)
              history.push(`/conversations/${channelId}?token=${token}`);
            navigator.mediaDevices
              .getUserMedia({ audio: true, video: true })
              .then(mediaStream =>
                mediaStream.getTracks().forEach(track => track.stop())
              );
            Mixpanel.track('End call clicked');
          },
        },
      });
    }
  }, [
    clinics,
    consultations,
    history,
    match.params.consultationId,
    uid,
    channelId,
    user,
    token,
  ]);

  useEffect(() => {
    if (permissionsRetrieved && !hasRequiredPermissions) {
      openModal('confirm-permission');
    } else closeModal();
  }, [hasRequiredPermissions, openModal, closeModal, permissionsRetrieved]);

  const checkBrowserPermissions = useCallback(() => {
    if (permissionsRetrieved) return;
    if (navigator.mediaDevices !== undefined) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(stream => {
          // Microphone and/or camera permissions granted
          setHasRequiredPermissions(true);
          setPermissionsRetrieved(true);
          setUpVideo();
        })
        .catch(error => {
          // Microphone and/or camera permissions denied
          setHasRequiredPermissions(false);
          setPermissionsRetrieved(true);
        });
    } else {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  }, [setUpVideo, permissionsRetrieved]);

  useEffect(() => {
    if (!permissionsRetrieved) checkBrowserPermissions();
  }, [permissionsRetrieved, hasRequiredPermissions, checkBrowserPermissions]);

  const URL = process.env.REACT_APP_VIDEO_CALL_PLUGIN_URL;

  const iframeRef = useRef(null);

  return (
    <VideoPlugInWrapper>
      {hasRequiredPermissions ? (
        <StyledIframe
          title="Televet-Video-Call"
          ref={iframeRef}
          src={URL}
          allow="camera;microphone;fullscreen"
          frameBorder="0"
          name="video-call-iframe"
        />
      ) : (
        <div style={{ textAlign: 'center', padding: 96 }}>
          {permissionsRetrieved && (
            <Button
              style={{ marginTop: 25 }}
              onClick={() => history.goBack()}
              buttonType="secondary"
              id="return-to-consultation-button"
              name="return-to-consultation-button"
            >
              Back to consultation
            </Button>
          )}
        </div>
      )}
      <ConfirmPermissionModal onAccept={closeModal} />
    </VideoPlugInWrapper>
  );
};

const mapStateToProps = state => ({
  uid: state.auth.user.uid,
  user: state.user,
  consultations: state.consultations,
  clinics: state.clinics,
});

export default connect(mapStateToProps, null)(withRouter(VideoCall));

const VideoPlugInWrapper = styled.div`
  background-color: hsl(0, 0, 18);
  width: 100%;
  height: 100%;
`;

const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
`;
