import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { AttachIcon, PdfOutlineIcon, DocumentsOutlineIcon } from '../icons';
import { truncateFilename } from '../utilities';

interface IFileAttachmentProps {
  onSelect: () => void;
  onAttachmentRemove?: () => void;
  filename?: string;
}
const FileAttachment = ({
  onSelect,
  filename,
  onAttachmentRemove,
}: IFileAttachmentProps): JSX.Element | null => {
  const renderTypeIcon = useCallback(() => {
    if (!filename) return null;
    if (filename?.includes('pdf')) {
      return (
        <IconContainer>
          <PdfOutlineIcon />
        </IconContainer>
      );
    } else if (filename?.includes('doc')) {
      return (
        <IconContainer>
          <DocumentsOutlineIcon />
        </IconContainer>
      );
    } else {
      return (
        <IconContainer>
          <AttachIcon />
        </IconContainer>
      );
    }
  }, [filename]);

  return (
    <FileDisplay onClick={onSelect}>
      <MessageBubbleAttachmentContainer>
        {renderTypeIcon()}
        <AttachmentNameContainer>
          <p>{truncateFilename(filename) || 'Unknown'}</p>
        </AttachmentNameContainer>
      </MessageBubbleAttachmentContainer>
    </FileDisplay>
  );
};

export default FileAttachment;

const MessageBubbleAttachmentContainer = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.sm};
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #bbb;
  border-radius: 10px;
  height: 52px;
  position: relative;
  margin: 3px 0;
  color: #575d7c;
  width: 210px;
  background: hsl(230, 0%, 98%);
`;

const AttachmentNameContainer = styled.div`
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }

  & svg {
    width: auto;
    height: 20px;
  }

  & svg * {
    fill: #575d7c;
  }
`;

const FileDisplay = styled.div`
  position: relative;
`;
