import React, { ReactElement, useState, useEffect } from 'react';
import styled from 'styled-components';
import Prompt from './Shared/Prompt';
import QuestionWrapper from './Shared/QuestionWrapper';
import RequiredQuestionMessage from './Shared/RequiredQuestionMessage';
import CheckedCheckbox from '../../../../assets/svg/checked-checkbox.svg';

interface Props {
  index: number;
  prompt: string;
  answer: string | string[];
  options: string[];
  setIsDraftSaved: Function;
  setAnswerAtIndex: Function;
  isRequired: boolean;
  isInvalid: boolean;
}

const MultipleSelection = ({
  index,
  prompt,
  answer,
  options,
  setIsDraftSaved,
  setAnswerAtIndex,
  isRequired,
  isInvalid,
}: Props): ReactElement => {
  const [selected, setSelected] = useState<any>([]);

  const uniqueElements = (array: Array<any>) => {
    const newSet: Set<String> = new Set(array);
    const newArray: String[] = [];
    newSet.forEach(element => {
      if (element) {
        newArray.push(element);
      }
    });
    return newArray;
  };

  useEffect(() => {
    setSelected((state: any) => {
      if (typeof answer === 'string') {
        setAnswerAtIndex([answer], index);
        return [...state, answer];
      } else {
        setSelected(answer);
      }
    });
  }, [answer, index, setAnswerAtIndex]);

  return (
    <QuestionWrapper index={index}>
      <Prompt prompt={prompt} isRequired={isRequired} />
      <br />
      {options.map((option, optionIndex) => {
        return (
          <Option
            key={`question_${index}_option_${optionIndex}`}
            onClick={() => {
              setIsDraftSaved(false);
              const indexOfOption = selected?.indexOf(option);
              if (indexOfOption < 0) {
                const newAnswer = [...selected, option];
                setAnswerAtIndex(uniqueElements(newAnswer), index);
              } else {
                const newAnswer = [...selected];
                newAnswer.splice(indexOfOption, 1);
                setAnswerAtIndex(uniqueElements(newAnswer), index);
              }
            }}
            data-mixpanel-name="Multiple selection form select"
          >
            {selected?.indexOf(option) >= 0 ? (
              <CustomCheckedCheckbox src={CheckedCheckbox} />
            ) : (
              <CustomEmptyCheckbox />
            )}
            <OptionText>{option}</OptionText>
          </Option>
        );
      })}
      {isInvalid ? <RequiredQuestionMessage /> : null}
    </QuestionWrapper>
  );
};

export default MultipleSelection;

const Option = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;
`;

const OptionText = styled.p`
  font-family: LinearSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #73747d;
  margin-left: 10px;
`;

const CustomCheckedCheckbox = styled.img`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
`;

const CustomEmptyCheckbox = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 2px;
  border: solid 1.5px #575d7c;
  background-color: #ffffff;
`;
