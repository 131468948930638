import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ModalHeader from './ModalHeader';
import { Avatar, AvatarSize } from '../../Avatar';

const ParentMembers = ({ parentMembers, onDone, onBack }) => {
  return (
    <ParentMembersContainer>
      <ModalHeader onBack={onBack} title="Parent Members" onDone={onDone} />
      <ParentMembersList>
        {parentMembers.map(petParent => {
          return (
            <ParentMembersListItem key={petParent.id}>
              <AvatarContainer>
                <Avatar
                  firstName={petParent.firstName}
                  lastName={petParent.lastName}
                  size={AvatarSize.XLarge}
                />
              </AvatarContainer>
              <Title>{`${petParent.firstName} ${petParent.lastName}`}</Title>
            </ParentMembersListItem>
          );
        })}
      </ParentMembersList>
    </ParentMembersContainer>
  );
};

ParentMembers.propTypes = {
  onDone: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  parentMembers: PropTypes.array.isRequired,
};

export default ParentMembers;

const ParentMembersContainer = styled.div``;

const ParentMembersList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ParentMembersListItem = styled.li`
  align-items: center;
  border-bottom: solid 1px #e8e9e9;
  cursor: pointer;
  display: flex;
  padding: 20px 0 20px 0;
`;

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h2`
  color: #575d7c;
  font-size: 18px;
  margin-left: 10px;
`;
