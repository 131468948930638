import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PublicRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const authenticated = useSelector(
    state => !isEmpty(state.auth.user && state.user.info.private)
  );

  if (authenticated) {
    const params = new URLSearchParams(location.search);
    const redirect = params.has('redirect') ? params.get('redirect') : '/';
    return <Redirect to={`${redirect}`} />;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.elementType,
  ]).isRequired,
};

export default PublicRoute;
