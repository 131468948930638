import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import BlueBubble from '../../assets/svg/BlueBubble.svg';
import DogCatIcon from '../../assets/svg/DogCatIcon.svg';
import BlackPaw from '../../assets/svg/BlackPaw.svg';
import TeleVetBlueLogo from '../../assets/svg/TeleVetBlueLogo.svg';

const TermsModal = (): ReactElement => {
  const [isTermsAccepted, setIsTermsAccepted] = useState(true);

  useEffect(() => {
    setIsTermsAccepted(!!localStorage.getItem('isTermsAccepted'));
  }, []);

  const handleStartChat = (): void => {
    localStorage.setItem('isTermsAccepted', 'true');
    setIsTermsAccepted(true);
  };

  if (!isTermsAccepted) {
    return (
      <ModalWrapper>
        <ModalContent>
          <Top>
            <TopLeft>
              <Welcome>Welcome!</Welcome>
              <Looking>
                We're looking forward to taking care of your pet.
              </Looking>
            </TopLeft>
            <TopRight>
              <Circle>
                <StyledImage src={DogCatIcon} alt="" />
              </Circle>
            </TopRight>
          </Top>
          <Center>
            <Continuing>By continuing you can:</Continuing>
            <ListItem>
              <ListIcon src={BlackPaw} /> Confirm your appointment
            </ListItem>
            <ListItem>
              <ListIcon src={BlackPaw} /> Let us know when you are on your way
            </ListItem>
            <ListItem>
              <ListIcon src={BlackPaw} /> Ask any questions you have
            </ListItem>
            <Separator />
          </Center>
          <Bottom>
            <PreStatement>
              By clicking "Continue" you agree to the
              <StyledAnchor
                href="https://pet-terms-of-service.s3.us-east-2.amazonaws.com/terms.html"
                target="_blank"
              >
                Televet Terms of Service
              </StyledAnchor>
              which includes the
              <StyledAnchor
                href="https://www.gettelevet.com/privacy-policy"
                target="_blank"
              >
                Televet Privacy Policy.
              </StyledAnchor>
            </PreStatement>

            <StyledButton onClick={handleStartChat}>Continue</StyledButton>
            <PoweredBy>
              <TeleVetLogo src={TeleVetBlueLogo} /> Powered by Televet
            </PoweredBy>
          </Bottom>
        </ModalContent>
      </ModalWrapper>
    );
  }
  return <></>;
};

export default TermsModal;

const Bottom = styled.div`
  flex: 1;
  padding: 0px 24px 24px 24px;
`;

const Center = styled.div`
  flex: 1;
  padding: 24px;
`;
const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  box-shadow: 0 13px 12px 0 rgba(57, 68, 70, 0.05);
  background-color: #ffffff;
`;

const Continuing = styled.p`
  font-family: LinearSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #575d7c;
`;

const ListIcon = styled.img`
  margin-right: 10px;
`;

const ListItem = styled.p`
  display: flex;
  align-items: center;
  margin: 15px 0;

  font-family: LinearSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #575d7c;
`;

const Looking = styled.p`
  height: 48px;
  margin-top: 7px;
  font-family: LinearSans;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #575d7c;
  margin-bottom: 10px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 357px;
  border-radius: 28px;
  box-shadow: 1px 2px 21px 0 rgba(36, 46, 42, 0.09);
  background-color: #ffffff;
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 31, 37, 0.2);
  height: 100vh;
  width: 100vw;
  z-index: 9999;
`;

const PoweredBy = styled.p`
  margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: LinearSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.13px;
  color: #39adc3;
`;

const PreStatement = styled.div`
  font-family: LinearSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #575d7c;
  user-select: none;
`;

const Separator = styled.div`
  width: 293px;
  height: 1px;
  border: solid 1px #e8e9e9;
  margin: auto;
  margin-top: 27px;
`;

const StyledAnchor = styled.a`
  margin: 0 6px;
  font-family: LinearSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #39adc3;
`;

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 291px;
  height: 35px;
  border-radius: 9px;
  background-color: #52b0c3;
  font-family: LinearSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.13px;
  text-align: center;
  color: #ffffff;
  user-select: none;
  cursor: pointer;
  margin-top: 30px;
`;

const StyledImage = styled.img`
  width: 48px;
  height: 48px;
`;

const TeleVetLogo = styled.img`
  margin-right: 5px;
  width: 11px;
  height: 12px;
`;

const Top = styled.div`
  display: flex;
  height: 136px;
  background-image: url(${BlueBubble});
  background-repeat: no-repeat;
  background-size: contain;
  padding: 24px 16px 0 24px;
  flex: 1;
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TopRight = styled.div``;

const Welcome = styled.p`
  font-family: LinearSans;
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #575d7c;
`;
