import styled from 'styled-components/macro';
import { connect, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useModal, Button } from '@televet/televet-ui';

import getClientToken from '../../redux/payment/actions/getClientToken';
import getCustomerToken from '../../redux/payment/actions/getCustomerToken';
import { pushSnackbar } from '../../redux/snackbar/snackbarActions.js';
import editPet from '../../redux/pets/actions/editPet';
import removePet from '../../redux/pets/actions/removePet';
import updatePetImage from '../../redux/pets/actions/updatePetImage';
import cancelConsultation from '../../redux/app/actions/cancelConsultation';

import { H4, H1 } from '../Common/Headers';
import PetInfo from './PetInfo';
import PetEdit from '../Common/PetEdit';
import Separator from '../theme/Separator';
import ClinicInfo from '../Common/ClinicInfo';
import PetSelection from './PetSelection';
import ActionButton from '../Common/ActionButton';
import { hslToHsla } from '../theme/helpers';
import ConsultationList from './ConsultationList';
import NewConsultationModal from './NewConsultationModal';
import AddPaymentModal from './../Common/AddPaymentModal';
import ConfirmConsultationModal from '../Consultation/ConfirmConsultationModal';
import { Mixpanel as mixpanel } from '../../redux/mixpanel';
import { PersistedKeys } from '../../PersistKeys';

const PetsView = props => {
  const {
    consultationsArray,
    consultations,
    pets,
    petsArray,
    clinics,
    customerId,
    editPet,
    removePet,
    updatePetImage,
  } = props;
  const { openModal, closeModal, modal } = useModal();
  const [selectedConsultation, setSelectedConsultation] = useState(null);
  const history = useHistory();
  const match = useRouteMatch('/pets/:petId');
  const [editingPet, setEditingPet] = useState(false);
  const petId = match?.params?.petId;
  const { user, payment } = useSelector(state => state);
  const userHasActivePet = user.info.pets
    ? Object.values(user.info.pets).some(petIsActive => petIsActive)
    : null;

  let clinicId = '';
  let clinic = {};
  let pet = {};

  useEffect(() => {
    setEditingPet(false);
  }, [petId]);

  useEffect(() => {
    setPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const firstActivePet = Object.keys(pets)[0];

    if (!petId && firstActivePet) history.push(`/pets/${firstActivePet}`);
  }, [history, pets, petId, user, userHasActivePet]);

  const setPayments = () => {
    if (customerId) {
      props.getCustomerToken(customerId).catch(error => {
        console.error(error);
        props.pushSnackbar({ type: 'error', message: error.message });
      });
    }
  };

  const consultationList = useMemo(() => {
    return consultationsArray.map(consultationId => {
      return {
        ...consultations[consultationId],
        consultationId,
      };
    });
  }, [consultationsArray, consultations]);

  const activeConsultationList = useMemo(() => {
    return consultationList.filter(({ state, pet }) => {
      return pet === petId && state !== 'RESOLVED' && state !== 'CANCELLED';
    });
  }, [consultationList, petId]);

  const previousConsultationList = useMemo(() => {
    return consultationList.filter(({ state, pet }) => {
      return pet === petId && (state === 'RESOLVED' || state === 'CANCELLED');
    });
  }, [consultationList, petId]);

  if (pets[petId] !== undefined) {
    pet = pets[petId];
    localStorage.setItem(PersistedKeys.ClinicName, pet.clinicDisplay);
    clinicId = pet.clinicId;
    clinic = clinics[clinicId];
  }

  const handleSelectPet = petId => {
    history.push(`/pets/${petId}`);
  };

  const handleEditPet = petId => {
    setEditingPet(true);
    mixpanel.track('Edit pet button clicked');
  };

  const handleRemovePet = removalPetId => {
    removePet(removalPetId);

    if (removalPetId === petId && Object.keys(pets).length === 1) {
      history.push('/onboarding');
      return;
    }

    history.push('/pets');

    setEditingPet(false);
  };

  const handleCancelEdit = () => {
    setEditingPet(false);
  };

  const handleSavePet = values => {
    setEditingPet(false);
    handleSelectPet(values.petId);
    editPet(values);
  };

  const handleUpdatePetImage = image => {
    const petId = match?.params?.petId;
    updatePetImage(petId, image);
  };

  const petCollection = petsArray.map(petId => ({
    petId: petId,
    ...pets[petId]?.public,
    clinicDisplay: pets[petId].clinicDisplay || '',
    clinicId: pets[petId].clinicId || null,
  }));

  const selectedPet = useMemo(() => {
    return petCollection.find(pet => pet.petId === petId) || petCollection[0];
  }, [petCollection, petId]);

  const currentClinic = clinics[selectedPet?.clinicId];

  const handleCreateConsultation = () => {
    mixpanel.track('New consultation button clicked');
    if (payment.methods.length > 0) {
      openModal('create-consultation');
    } else {
      openModal('add-payment');
    }
  };

  const onFinishAddingPaymentMethod = () => {
    closeModal();
    if (selectedConsultation) {
      openModal('confirm-consultation');
    } else {
      openModal('create-consultation');
    }
  };

  const onFinishConfirmingConsultation = () => {
    closeModal();
  };

  const confirmConsultation = consultation => {
    setSelectedConsultation(consultation);
    if (payment.methods.length > 0) {
      if (!modal.isOpen) openModal('confirm-consultation');
    } else {
      if (!modal.isOpen) openModal('add-payment');
    }
  };

  return (
    <Container>
      <LeftDiv>
        {petsArray.length > 0 &&
          clinic &&
          pet.public &&
          (editingPet ? (
            <PetEdit
              petId={petId}
              name={selectedPet.name}
              type={selectedPet.type}
              gender={selectedPet.sex}
              reproductiveStatus={selectedPet.repoStatus}
              environment={selectedPet.env}
              breed={selectedPet.breed}
              weight={selectedPet.weight || ''}
              month={selectedPet.DOB ? selectedPet.DOB.month : ''}
              day={selectedPet.DOB ? selectedPet.DOB.day : ''}
              year={selectedPet.DOB ? selectedPet.DOB.year : ''}
              profilePic={selectedPet.profilePic}
              clinic={currentClinic}
              onRemove={handleRemovePet}
              onCancel={handleCancelEdit}
              onSave={handleSavePet}
              onUpdateImage={handleUpdatePetImage}
            />
          ) : (
            <>
              <PetSelection
                onAddPet={() => openModal('add-pet')}
                onSelectPet={handleSelectPet}
                selectedPet={selectedPet}
                pets={petCollection}
              />
              <ClinicInfo
                petId={petId}
                clinic={{ clinicId: pet.clinicId, ...clinic.info }}
              />
              <PetInfo pet={pet.public} id={petId} onEditPet={handleEditPet} />
            </>
          ))}
        {petsArray.length <= 0 && (
          <EmptyPetsContainer>
            <EmptyMessage>No Pets Found</EmptyMessage>
            <Button onClick={() => openModal('add-pet')}>Add a Pet</Button>
          </EmptyPetsContainer>
        )}
      </LeftDiv>
      <RightDiv>
        <Row style={{ paddingBottom: 24 }}>
          <div style={{ width: '50%' }}>
            <H4>Active Consultations</H4>
          </div>

          <ConsultationButtonDiv>
            <ConsultationButton
              buttonType="primary"
              name="create-consultation-button"
              onClick={handleCreateConsultation}
              title={`Create consultation`}
            >
              New Consultation
            </ConsultationButton>
          </ConsultationButtonDiv>
          <Separator style={{ margin: 0 }} />
        </Row>
        <ListContent>
          <ConsultationList
            petId={petId}
            active={activeConsultationList}
            clinics={clinics}
            past={previousConsultationList}
            onCreateConsultation={handleCreateConsultation}
            setSelectedConsultation={confirmConsultation}
          />
          <div style={{ height: 200 }} />
        </ListContent>
      </RightDiv>
      <NewConsultationModal pet={pet} petId={petId} />
      <AddPaymentModal
        onCancel={() => closeModal()}
        onFinish={onFinishAddingPaymentMethod}
      />
      <ConfirmConsultationModal
        consultation={selectedConsultation}
        setSelectedConsultation={confirmConsultation}
        onCancel={() => closeModal()}
        onFinish={onFinishConfirmingConsultation}
      />
    </Container>
  );
};

const EmptyPetsContainer = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
`;

const EmptyMessage = styled(H1)`
  color: ${({ theme }) => theme.palette.gray700};
`;

const LeftDiv = styled.div`
  padding: 32px ${({ theme }) => theme.spacing.spacingSizes.lg};
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (min-width: 961px) {
    overflow: auto;
    border-right: 2px solid
      ${({ theme }) => hslToHsla(theme.palette.primary, 0.1)};
  }
`;

const RightDiv = styled.div`
  padding: 32px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 2;
  @media (max-width: 960px) {
    display: block;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  @media (max-width: 960px) {
    display: block;
    overflow-y: scroll;
    height: 100%;
    min-height: 100%;
  }
`;

const ListContent = styled.div`
  @media (max-width: 960px) {
    height: 100%;
  }
  @media (min-width: 961px) {
    overflow: scroll;
    height: inherit;
  }
`;

const ConsultationButtonDiv = styled.div`
  align-self: center;
  margin-right: 12px;
  padding-right: 12px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 12px;
  justify-content: space-between;
  align-items: center;
`;

const ConsultationButton = styled(ActionButton)`
  padding: 12px 16px;
  margin-bottom: 0.75rem !important;
`;

const mapStateToProps = state => ({
  pets: state.pets,
  petsArray: Object.keys(state.pets),
  clinics: state.clinics,
  consultations: state.consultations,
  consultationsArray: Object.keys(state.consultations),
  customerId: state.user.info.private.customerId,
});

const mapDispatchToProps = {
  cancelConsultation,
  getClientToken,
  getCustomerToken,
  pushSnackbar,
  editPet,
  removePet,
  updatePetImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PetsView);
