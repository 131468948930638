import firebase from '../../../firebase/firebase';
import vetsActionTypes from '../vetsActionTypes';
const getVet = id => {
  return async dispatch => {
    const snapshot = await firebase
      .database()
      .ref(`users/${id}/info/public`)
      .once('value');
    const vetObj = snapshot.val();
    dispatch({ type: vetsActionTypes.getVet, payload: { vetId: id, vetObj } });
  };
};

export default getVet;
