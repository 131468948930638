import React, { HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import Tooltip from './Tooltip';
import { TippyPlacement } from './enums';

export const getColorCode = (charCode: number): string => {
  if (charCode >= 65 && charCode <= 71) {
    return '#7584e6';
  } else if (charCode >= 72 && charCode <= 77) {
    return '#e47792';
  } else if (charCode >= 78 && charCode <= 84) {
    return '#5cc098';
  } else {
    return '#f08f02';
  }
};

export enum AvatarSize {
  Small = 'Small',
  Large = 'Large',
  XLarge = 'XLarge',
  XXLarge = 'XXLarge',
}

interface IAvatarProps extends HTMLAttributes<HTMLElement> {
  firstName: string;
  lastName?: string;
  nameDisplay?: string;
  photoUrl?: string;
  size?: AvatarSize;
  showTooltip?: boolean;
  clinic: any;
  showBorder?: boolean;
  showRemoveButton?: boolean;
  onRemove?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  squared?: boolean;
  onClick?: () => void;
}

export const Avatar = ({
  firstName,
  lastName,
  nameDisplay,
  photoUrl,
  clinic,
  size = AvatarSize.Small,
  showTooltip = true,
  showBorder = true,
  squared = false,
  onClick,
}: IAvatarProps): JSX.Element => {
  const initials = firstName
    ? `${firstName[0]}${lastName ? lastName[0] : ''}`
    : null;
  const fullName = [firstName, lastName].filter(n => !!n).join(' ');

  const avatarIcon = (
    <AvatarIconContainer
      squared={squared}
      data-mixpanel-name="User profile icon"
      data-testid="user-profile-icon"
      size={size}
      charCode={firstName.charCodeAt(0)}
      hasPhoto={!!photoUrl}
      showBorder={!photoUrl && !!initials}
      onClick={onClick}
    >
      {photoUrl && <img src={photoUrl} alt={fullName} />}
      {!photoUrl && initials ? initials : null}
      {!initials && !photoUrl ? (
        <img src={clinic.logo} alt={clinic.name} />
      ) : null}
    </AvatarIconContainer>
  );

  return (
    <div>
      {showTooltip === false ? (
        avatarIcon
      ) : (
        <Tooltip
          content={nameDisplay || fullName || clinic?.name || ''}
          placement={TippyPlacement.Left}
        >
          {avatarIcon}
        </Tooltip>
      )}
    </div>
  );
};

const getSize = (size: AvatarSize): string => {
  switch (size) {
    case AvatarSize.Small:
      return '30px';
    case AvatarSize.Large:
      return '36px';
    case AvatarSize.XLarge:
      return '45px';
    case AvatarSize.XXLarge:
      return '50px';
    default:
      return '36px';
  }
};

interface IAvatarIconContainerProps {
  charCode: number;
  showBorder: boolean;
  hasPhoto: boolean;
  size: AvatarSize;
  squared?: boolean;
}

export const AvatarIconContainer = styled.div<IAvatarIconContainerProps>`
  height: ${({ size }: IAvatarIconContainerProps) => getSize(size)};
  width: ${({ size }: IAvatarIconContainerProps) => getSize(size)};
  flex: 0 0 auto;
  position: relative;
  border-radius: ${({ squared }: IAvatarIconContainerProps) => {
    return squared ? '6px;' : '50%;';
  }};
  background-color: #fff;
  border: ${({
    charCode,
    showBorder,
    hasPhoto,
  }: IAvatarIconContainerProps): string =>
    showBorder
      ? `2px solid ${hasPhoto ? '#7f879a' : getColorCode(charCode)}`
      : 'none'};
  color: ${({ charCode }: IAvatarIconContainerProps): string =>
    getColorCode(charCode)};
  font-size: 15px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  align-self: flex-end;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    z-index: 2;
  }
`;
