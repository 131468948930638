import React from 'react';
import styled from 'styled-components/macro';

const getBackgroundColor = ({ status, theme }) => {
  return theme.palette.actionButton;
};

const getTextColor = ({ status, theme }) => {
  return getBackgroundColor({ status, theme });
};

const PillLabel = styled.span`
  color: ${getTextColor};
  font-size: ${({ theme }) => theme.font.fontSizes.lg};
`;

const getStatusText = status => {
  switch (status) {
    case 'NEW':
      return 'New';
    case 'OPENED':
      return 'Assigned';
    case 'CANCELLED':
      return 'Cancelled';
    case 'SCHEDULED':
      return 'Scheduled';
    case 'PAID':
      return 'Paid';
    default:
      return 'Resolved';
  }
};

const ConsultationStatus = ({ status }) => {
  const statusText = getStatusText(status);
  return (
    <>
      <Circle status={status} />
      <PillLabel> {statusText} </PillLabel>
    </>
  );
};

const Circle = styled.div`
  display: inline-block;
  height: 6px;
  width: 6.3px;
  border-radius: 20px;
  margin: 0px 8px 0 0;
  background-color: ${getBackgroundColor};
  display: ${({ read }) => (read ? 'none' : 'inherit')};
`;

export default ConsultationStatus;
