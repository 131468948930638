import moment from 'moment';
import firebase from '../../../firebase/firebase';
import getUser from './getUser';

export const savePromoCode = (
  userId,
  codeObj,
  codeId,
  clinicName,
  dispatch
) => {
  const newCode = {
    [codeId]: {
      added: moment().valueOf(),
      clinicId: codeObj.clinic,
      clinicDisplay: clinicName,
      code: codeObj.code,
      discount: codeObj.discount,
      available: codeObj.available,
      used: 0,
    },
  };
  // save promo code in user (Database)
  firebase
    .database()
    .ref(`users/${userId}/info/promos`)
    .update(newCode)
    .then(snapshot => {
      dispatch(getUser());
    });
};
