import { gql } from '@apollo/client';

export const SUBSCRIBE_TO_CHANNEL_MESSAGES = gql`
  subscription subscribeToChannelMessages(
    $where: ChannelMessageSubscriptionWhereInput!
  ) {
    channelMessage(where: $where) {
      node {
        id
        createdAt
        updatedAt
        body
        index
        attachments {
          id
          attachmentType
          entityType
          entityId
          filename
          url
        }
        author {
          id
          twilioIdentity
          user {
            id
            firstName
            lastName
            profilePic
          }
          clinicPetParent {
            id
            firstName
            lastName
          }
        }
        channel {
          id
        }
      }
    }
  }
`;

export const CREATE_CHANNEL_MESSAGE = gql`
  mutation createChannelMessage($data: ChannelMessageCreateInput!) {
    createChannelMessage(data: $data) {
      id
      createdAt
      updatedAt
      body
      index
      attachments {
        id
        attachmentType
        entityType
        entityId
        filename
        url
      }
      author {
        id
        user {
          id
          firstName
          lastName
          profilePic
        }
        clinicPetParent {
          id
          firstName
          lastName
        }
      }
      channel {
        id
      }
    }
  }
`;

export const UPDATE_CHANNEL_MEMBER = gql`
  mutation updateChannelMember(
    $where: ChannelMemberWhereUniqueInput!
    $data: ChannelMemberUpdateInput!
  ) {
    updateChannelMember(where: $where, data: $data) {
      id
      lastConsumedMessageIndex
    }
  }
`;

export const TRIGGER_WORKFLOW_EVENT = gql`
  mutation triggerWorkflowEvent($data: TriggerWorkflowEventInput!) {
    triggerWorkflowEvent(data: $data) {
      success
    }
  }
`;

export const VERIFY_CLINIC_WIDGET_REQUEST = gql`
  mutation verifyClinicWidgetRequest($where: VerifyClinicWidgetRequestInput!) {
    verifyClinicWidgetRequest(where: $where) {
      id
      isVerified
    }
  }
`;
