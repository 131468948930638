import React from 'react';
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { initialize } from './utilities/tracking';
import { StylesProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import { store, persistor } from './redux/store';
import * as FullStory from '@fullstory/browser';
import ErrorBoundary from './components/Common/ErrorBoundary';
import { Mixpanel as mixpanel } from './redux/mixpanel';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

if (process.env.REACT_APP_PRODUCTION === 'true') {
  setupLogRocketReact(LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID));
  FullStory.init({ orgId: 'TGQZZ' });
}

initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);

Sentry.init({
  dsn:
    process.env.REACT_APP_PRODUCTION !== 'false'
      ? 'https://a900c54588e94b19a258fbf34532e725@sentry.io/5178000'
      : '',
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <StylesProvider injectFirst>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </StylesProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
