import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';

const RequiredQuestionMessage = (): ReactElement => {
  return (
    <StyledMessage>
      <Icon>i</Icon> <p>An Answer is Required</p>
    </StyledMessage>
  );
};

export default RequiredQuestionMessage;

const StyledMessage = styled.div`
  display: flex;
  align-items: center;

  font-family: LinearSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.13px;
  color: #da4167;
`;

const Icon = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  border: 2px solid #da4167;
  padding: 2px;
  font-size: 10px;
  margin: 0 8px 0 0;
`;
