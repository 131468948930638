const notificationSet = {
  opened: {
    type: 'opened',
    message: 'Consultation In Review',
  },
  booked: {
    type: 'booked',
    message: 'Call Scheduled',
  },
  follow: {
    type: 'follow',
    message: 'Follow Up Booked',
  },
  followup: {
    type: 'followup',
    message: 'Follow Up Booked',
  },
  reminder: {
    type: 'reminder',
    message: 'Video Call Today',
  },
  reminder10: {
    type: 'reminder10',
    message: 'Call in 10 Minutes',
  },
  call: {
    type: 'call',
    message: 'Time For Your Call',
  },
  resolved: {
    type: 'resolved',
    message: 'Consultation Resolved',
  },
  declined: {
    type: 'declined',
    message: 'Unresolvable Consultation',
  },
  updated_consult: {
    type: 'updated_consult',
    message: 'Your veterinarian has updated your TeleVet consultation',
  },
  messaged: {
    type: 'messaged',
    message: 'New Message',
  },
  canceled: {
    type: 'canceled',
    message: 'Consultation Canceled',
  },
  cancelled: {
    type: 'cancelled',
    message: 'Consultation Cancelled',
  },
  videocall: {
    type: 'videocall',
    message: 'Time for your video chat!',
  },
  scheduled: {
    type: 'scheduled',
    message: 'Consultation Scheduled',
  },
  new: {
    type: 'new',
    message: 'New',
  },
  paid: {
    type: 'paid',
    message: 'Paid',
  },
};

export default notificationSet;
