import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ClinicListItem from './ClinicListItem';

const ClinicsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  text-align: center;
`;

const ClinicList = ({ clinics, onSelect }) => {
  return (
    <ClinicsListContainer>
      {clinics.map(clinic => {
        return (
          <div key={clinic.id}>
            {clinic && clinic.info && (
              <ClinicListItem clinic={clinic} onSelect={onSelect} />
            )}
          </div>
        );
      })}
    </ClinicsListContainer>
  );
};

ClinicList.propTypes = {
  clinics: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ClinicList;
