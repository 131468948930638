import React from 'react';
import styled from 'styled-components/macro';
import Text from '../../components/theme/Text';
import { hslToHsla } from '../../components/theme/helpers';
import personAvatarSrc from '../../assets/svg/client_avatar.svg';
import { getTimeAgo } from '../../utilities';
import { useDispatch, useSelector } from 'react-redux';
import setMessageRead from '../../redux/chats/actions/setMessageRead';
import Linkify from 'linkifyjs/react';

const Message = ({
  messageId,
  chat,
  pictureSrc,
  displayName,
  alignment,
  onImageMessageClick,
  previous,
  userId,
  isVet,
  next,
  lastUserMessageId,
  consultationId,
}) => {
  const currentMessage = chat[messageId];
  const showReceipt = lastUserMessageId === messageId;
  const options = { target: '_blank' };

  let messageType = 'text';
  if (currentMessage.pic) {
    messageType = 'pic';
  } else if (currentMessage.video) {
    messageType = 'video';
  } else if (currentMessage.link) {
    messageType = 'link';
  }

  const localUserId = useSelector(state => state.auth.user.uid);
  const dispatch = useDispatch();

  if (currentMessage.user !== localUserId && !currentMessage.read) {
    // once the message is received, give one second and set it as read
    setTimeout(() => {
      dispatch(setMessageRead(consultationId, messageId));
    }, 1000 * 60);
  }

  return (
    <Container alignment={alignment}>
      <MessageWrapper
        alignment={alignment}
        isPreviousMessageSameAuthor={previous?.user === userId}
        isNextMessageSameAuthor={next?.user === userId}
      >
        <MessageContainer>
          <BubbleColumn isVet={isVet}>
            <MessageBubble alignment={alignment} isVet={isVet}>
              {messageType === 'text' && (
                <Linkify tagName="span" options={options}>
                  {currentMessage.text}
                </Linkify>
              )}

              {messageType === 'pic' && (
                <MessageImage
                  src={currentMessage.pic}
                  alt={`${displayName} attached media`}
                  onClick={() =>
                    onImageMessageClick(
                      currentMessage.pic,
                      displayName,
                      messageType
                    )
                  }
                />
              )}

              {messageType === 'video' && (
                <MessageVideo
                  src={currentMessage.video}
                  controls
                  onClick={() =>
                    onImageMessageClick(
                      currentMessage.link || currentMessage.video,
                      displayName,
                      messageType
                    )
                  }
                >
                  Your browser does not support the video tag.
                </MessageVideo>
              )}

              {messageType === 'link' && (
                <>
                  <span>Attachment: </span>
                  <MessageLink href={currentMessage.link} target="_blank">
                    {currentMessage.filename}
                  </MessageLink>
                </>
              )}
            </MessageBubble>
            {showReceipt && (
              <ReceiptText>
                {currentMessage.read
                  ? `Read ${getTimeAgo(currentMessage.read)}`
                  : `Delivered ${getTimeAgo(currentMessage.timestamp)}`}
              </ReceiptText>
            )}
          </BubbleColumn>
        </MessageContainer>
        <AvatarContainer>
          {(!next || next.user !== userId) && (
            <Avatar src={pictureSrc || personAvatarSrc} alt="User picture" />
          )}
        </AvatarContainer>
      </MessageWrapper>
    </Container>
  );
};

const Container = styled.div`
  text-align: ${props => props.alignment};
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 60px;
`;

// flex-direction reverses the order of the avatar and message
// bubble if alignment is "right"
const MessageWrapper = styled.div`
  display: flex;
  flex-direction: ${props =>
    props.alignment === 'right' ? 'row' : 'row-reverse'};
  justify-content: flex-end;
  padding-top: ${({ isPreviousMessageSameAuthor }) =>
    isPreviousMessageSameAuthor ? '2px' : '12px'};
  padding-bottom: ${({ isNextMessageSameAuthor }) =>
    isNextMessageSameAuthor ? '2px' : '12px'};
`;

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
  max-width: 65%;
  white-space: pre-wrap;
`;

const BubbleColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 100%;
`;

const MessageBubble = styled(Text)`
  background-color: ${({ theme, isVet }) =>
    isVet ? hslToHsla(theme.palette.gray700, 0.3) : 'hsl(213deg 86% 36%)'};
  color: ${({ theme, isVet }) =>
    isVet ? theme.palette.black : theme.palette.white};
  padding: 10px;
  overflow: hidden;
  word-break: break-word;
  border-bottom-left-radius: ${props =>
    props.alignment === 'right' ? '10px' : '2px'};
  border-bottom-right-radius: ${props =>
    props.alignment === 'right' ? '2px' : '10px'};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 16px;

  img {
    width: 100%;
  }

  video {
    width: 100%;
  }
  a {
    color: ${({ isVet }) =>
      isVet ? 'hsl(213deg 86% 40%)' : 'hsl(341 96% 87%)'};
  }
`;

const ReceiptText = styled(Text)`
  color: ${({ theme }) => hslToHsla(theme.palette.black, 0.7)};
  margin-top: 4px;
`;

const MessageImage = styled.img`
  cursor: pointer;
`;

const MessageVideo = styled.video`
  cursor: pointer;
`;

const MessageLink = styled.a`
  color: ${({ theme }) => theme.palette.blue};
`;

export default Message;
