const paymentActionTypes = {
  skipPayment: 'payment/skipPayment',
  getClientToken: 'payment/getClientToken',
  setPaymentAdded: 'payment/setPaymentAdded',
  getCustomerToken: 'payment/getCustomerToken',
  deletePaymentMethod: 'payment/deletePaymentMethod',
  setDefaultPaymentMethod: 'payment/setDefaultPaymentMethod',
};

export default paymentActionTypes;
