import React from 'react';
import styled from 'styled-components/macro';
import { DeviceSizes } from '@televet/televet-ui';

import PersonAvatar from '../../assets/svg/client_avatar.svg';

const Avatar = ({ url, height, borderRadius, alt, opacity }) => (
  <StyledAvatar
    height={height}
    borderRadius={borderRadius}
    src={url || PersonAvatar}
    alt={alt}
  />
);

Avatar.defaultProps = {
  alt: 'Picture of person',
  size: 'small',
};

const StyledAvatar = styled.img`
  @media (min-width: 200px) {
    height: ${props => (props.height ? props.height : '54px')};
  }
  @media (min-width: ${DeviceSizes.mobileS}) {
    height: ${props => (props.height ? props.height : '84px')};
  }
  @media (min-width: ${DeviceSizes.mobileM}) {
    height: ${props => (props.height ? props.height : '140px')};
  }
  @media (min-width: ${DeviceSizes.tablet}) {
    height: ${props => (props.height ? props.height : '200px')};
  }

  border-radius: ${props => (props.borderRadius ? props.borderRadius : '50%')};
`;

export default Avatar;
