import produce from 'immer';
import chatsActionTypes from './chatsActionTypes';

const initialState = {};

let chatsReducer = produce((draft, action) => {
  switch (action.type) {
    case chatsActionTypes.addChat:
      draft[action.payload.consultId] = action.payload.chatObj;
      break;

    default:
      break;
  }
}, initialState);

export default chatsReducer;
