import produce from 'immer';
import paymentActions from './paymentActionTypes';

const initialState = {
  clientToken: '',
  paymentAdded: false,
  methods: [],
};

let paymentReducer = produce((draft, action) => {
  switch (action.type) {
    case paymentActions.getClientToken:
      draft.clientToken = action.payload;
      break;
    case paymentActions.setPaymentAdded:
      draft.paymentAdded = action.payload;
      break;
    case paymentActions.getCustomerToken:
      draft.methods = action.payload;
      break;
    case paymentActions.deletePaymentMethod:
      break;
    case paymentActions.setDefaultPaymentMethod:
      break;
    default:
      break;
  }
}, initialState);

export default paymentReducer;
