import styled from 'styled-components/macro';
import { ResponsiveDeviceMinWidth } from '@televet/televet-ui';

const CallToAction = styled.p`
  font-size: ${({ theme }) => {
    return theme.font.fontSizes.sm;
  }};

  margin: ${({ theme }) => {
    return `${theme.spacing.spacingSizes.md} 0`;
  }};

  @media ${ResponsiveDeviceMinWidth.tablet} {
    font-size: ${({ theme }) => {
      return theme.font.fontSizes.md;
    }};
    margin: ${({ theme }) => {
      return `${theme.spacing.spacingSizes.lg} 0`;
    }};
  }
`;

export default CallToAction;
