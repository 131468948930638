import React, { Component } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

export default class Details extends Component {
  render() {
    const { clinic, consultation } = this.props;
    return (
      <Card raised style={{ marginBottom: 25 }}>
        <CardContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignContent: 'center',
            }}
          >
            <Typography
              style={{
                color: 'white',
                backgroundColor: '#1DE9B6',
                paddingTop: 5,
                paddingRight: 10,
                paddingBottom: 5,
                paddingLeft: 15,
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
                position: 'relative',
                right: -20,
              }}
            >
              {consultation?.state}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{ height: 50, borderRadius: 10 }}
              src={clinic?.info.logo}
              alt=""
            />
            <div style={{ marginLeft: 15 }}>
              <Typography variant="h6">{clinic?.info.name}</Typography>
            </div>
          </div>
          <hr />
          <Typography style={{ fontSize: 18 }}>
            {consultation?.summary}
          </Typography>
          <Typography style={{ fontSize: 15, margin: 5 }}>
            {consultation?.details}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}
