import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { ResponsiveDeviceMinWidth } from '@televet/televet-ui';

const deriveColor = (type, theme) => {
  if (type === 'success') {
    return theme.palette.success;
  }
  if (type === 'error') {
    return theme.palette.error;
  }

  return theme.palette.black;
};

const StyledMessage = styled.p`
  color: ${({ type, theme }) => {
    return deriveColor(type, theme);
  }};
  font-size: ${({ theme }) => {
    return theme.font.fontSizes.sm;
  }};
  line-height: 1.2;
  margin: ${({ theme }) => {
    return `${theme.spacing.spacingSizes.md} 0`;
  }};

  @media ${ResponsiveDeviceMinWidth.tablet} {
    font-size: ${({ theme }) => {
      return theme.font.fontSizes.md;
    }};
    line-height: 1.5;
    margin: ${({ theme }) => {
      return `${theme.spacing.spacingSizes.xl} 0`;
    }};
  }
`;

const Message = ({ children, type }) => {
  return <StyledMessage type={type}>{children}</StyledMessage>;
};

Message.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['info', 'success', 'error']),
};

Message.defaultValues = {
  type: 'info',
};

export default Message;
