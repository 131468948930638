import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import branch from 'branch-sdk';

import getIsEmailRegistered from '../../api/getIsEmailRegistered';

export const DeeplinkTypes = {
  CLINIC_ONLY_INVITE_LINK: 'CLINIC_ONLY_INVITE_LINK',
  FOLLOWUP: 'FOLLOWUP',
  FULL_REGISTRATION_WITH_FOLLOW_UP_LINK:
    'FULL_REGISTRATION_WITH_FOLLOW_UP_LINK',
};

const Deeplink = () => {
  const history = useHistory();
  const isAuthenticated = useSelector(
    state => !isEmpty(state.auth.user && state.user.info.private)
  );

  useEffect(() => {
    branch.init(process.env.REACT_APP_BRANCH_IO, async (err, data) => {
      const deeplinkData = data.data_parsed;
      if (isAuthenticated) {
        deeplinkData?.consultId
          ? history.push(`/consultation/${deeplinkData?.consultId}`)
          : history.push('/');
      } else {
        const isEmailRegistered = await getIsEmailRegistered(
          deeplinkData?.clientEmail
        );
        if (isEmailRegistered) {
          history.push('/login', { deeplink: deeplinkData });
        } else {
          history.push('/register', { deeplink: deeplinkData });
        }
      }
    });
  }, [history, isAuthenticated]);

  return null;
};

export default Deeplink;
