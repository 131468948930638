import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { TextInput } from '@televet/televet-ui';
import resetPasswordAction from '../../redux/auth/actions/resetPassword';
import CenteredBanner from '../Common/Layouts/CenteredBanner';
import { H1 } from '../Common/Headers';
import SubmissionMessage from '../Common/Message';
import ResponsiveButton from '../Common/ResponsiveButton';

const ResetPassword = ({ resetPassword }) => {
  const [state, setState] = useState({ error: null, loading: false });
  const { loading, error } = state;
  const history = useHistory();
  const { search } = useLocation();

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = async values => {
    const { password } = values;
    const urlParams = new URLSearchParams(search);

    try {
      setState({ error: null, loading: true });
      await resetPassword(urlParams.get('oobCode'), password);
      history.push('/login');
    } catch (e) {
      setState({ error: e.message.replace('Error:', ''), loading: false });
    }
  };

  return (
    <CenteredBanner>
      <H1>Enter a new password</H1>
      {error && <SubmissionMessage type="error">{error}</SubmissionMessage>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          name="password"
          label="New Password"
          type="password"
          register={register}
          validation={{ required: true }}
          error={errors.password && 'Please enter a password'}
        />
        <ResponsiveButton disabled={loading}>
          Reset My Password
        </ResponsiveButton>
      </form>
    </CenteredBanner>
  );
};

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  resetPassword: resetPasswordAction,
};

export default connect(null, mapDispatchToProps)(ResetPassword);
