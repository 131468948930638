import styled from 'styled-components/macro';
import React, { useState, useEffect } from 'react';

import Text from '../theme/Text';
import { getTimeAgo } from '../../utilities';

const Cancelled = props => {
  const { consultation } = props;
  const [timeAgo, setTimeAgo] = useState(getTimeAgo(consultation?.cancelledAt));

  useEffect(() => {
    //create timer on component mount

    const timer = setInterval(() => {
      setTimeAgo(getTimeAgo(consultation?.cancelledAt));
    }, 1000 * 60);

    // returned function will be called on component unmount
    return () => {
      clearInterval(timer);
    };
  }, [consultation]);
  return (
    <Container>
      <Row>
        <Text style={{ width: '80%', fontSize: 18 }}>
          This consultation was cancelled
        </Text>
        <Text style={{ margin: '12px 0', fontSize: 12 }}>{timeAgo}</Text>
      </Row>
    </Container>
  );
};

export default Cancelled;

const Container = styled.div`
  padding-bottom: 8px;
  width: 100%;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  margin: 12px 0;
  justify-content: space-between;
  color: rgba(16, 22, 38, 0.7);
`;
