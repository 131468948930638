import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import {
  filterClinicsByName,
  filterClinicsByLocation,
  formatPossessiveName,
} from '../../../utilities';
import getClinics from '../../../redux/clinicSearch/actions/getClinics';
import { H1 } from '../../Common/Headers';
import { Compass } from '../../Common/Icons';
import { TextInput, Button, Select, BounceLoader } from '@televet/televet-ui';
import ClinicList from './ClinicList';
import { Mixpanel as mixpanel } from '../../../redux/mixpanel';

const getGeolocationError = error => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      return 'User or browser denied the request for Geolocation';
    case error.POSITION_UNAVAILABLE:
      return 'Location information is unavailable';
    case error.TIMEOUT:
      return 'The request to get user location timed out';
    case error.UNKNOWN_ERROR:
      return 'An unknown error occurred';
    default:
      return 'Location information is unavailable';
  }
};

const mileOptions = [5, 10, 15, 20, 25, 30, 50, 100];

const PetPracticeForm = ({
  name,
  practiceName,
  withoutHeader,
  onSubmit,
  isOnboarding,
}) => {
  const { register, watch, setValue, errors } = useForm({
    defaultValues: {
      practiceName,
      miles: 5,
    },
    mode: 'onBlur',
  });
  const practiceNameWatch = watch('practiceName');
  const milesWatch = watch('miles');
  const [coords, setCoords] = useState({});
  const [isFindByLocation, setIsFindByLocation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [geolocationError, setGeoLocationError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClinics());
  }, [dispatch]);

  useEffect(() => {
    if (milesWatch !== 5) {
      mixpanel.track('Mileage clicked', {
        isOnboarding,
        miles: milesWatch,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milesWatch]);

  const onFindByLocation = () => {
    setGeoLocationError(null);
    setValue('practiceName', '');
    setIsFindByLocation(currentState => !currentState);
    setIsLoading(true);
    mixpanel.track('Search nearby practices clicked', { isOnboarding });
    navigator.geolocation.getCurrentPosition(
      location => {
        setCoords({
          lat: location.coords.latitude,
          long: location.coords.longitude,
        });
        setIsLoading(false);
      },
      error => {
        setIsLoading(false);
        setGeoLocationError(getGeolocationError(error));
      }
    );
  };

  const onSelectPractice = clinic => {
    const {
      id,
      info: { name },
    } = clinic;
    mixpanel.track('Practice entered', { name, isOnboarding });
    mixpanel.track('Clicked select clinic', { name, isOnboarding });
    onSubmit({ practiceId: id, practiceName: name, clinic });
  };

  const onFindByClinicName = () => {
    setIsFindByLocation(currentState => !currentState);
    setGeoLocationError(null);
    mixpanel.track('Search by practice name clicked', { isOnboarding });
  };

  const clinicData = useSelector(
    state => state.clinicSearch.clinicData,
    shallowEqual
  );

  const clinics = isFindByLocation
    ? filterClinicsByLocation(coords, clinicData, milesWatch)
    : filterClinicsByName(practiceNameWatch, clinicData);

  return (
    <FormContainer withoutHeader={withoutHeader}>
      {!withoutHeader && (
        <H1>
          What is the name of {formatPossessiveName(name) || "your clinic's"}{' '}
          veterinary practice?
        </H1>
      )}

      <TextInput
        name="practiceName"
        label="Enter Veterinary Practice Name"
        register={register}
        validation={{ required: true }}
        error={errors.name && 'Please enter the type of pet.'}
        onClick={() =>
          mixpanel.track('User searches by practice name', { isOnboarding })
        }
      />
      {isFindByLocation && (
        <FindPracticeButton
          buttonType="ghost"
          fullWidth
          onClick={onFindByClinicName}
        >
          Search by practice name
        </FindPracticeButton>
      )}
      {!isFindByLocation && (
        <FindPracticeButton
          buttonType="ghost"
          fullWidth
          onClick={onFindByLocation}
          type="button"
        >
          <Compass title="Find Practice" />
          <span>Or find nearby practices</span>
        </FindPracticeButton>
      )}

      <Divider />
      {isFindByLocation && (
        <DistanceSelectWrapper>
          <DistanceSelectLabel>
            Nearby practices results within
          </DistanceSelectLabel>
          <Select
            name="miles"
            label="Select a distance"
            options={mileOptions.map(mile => {
              return {
                label: `${mile} miles`,
                value: mile,
              };
            })}
            register={register}
          />
        </DistanceSelectWrapper>
      )}
      <ClinicList clinics={clinics} onSelect={onSelectPractice} />
      {(isFindByLocation || practiceNameWatch) &&
        clinics.length === 0 &&
        !isLoading &&
        !geolocationError && (
          <EmptyStateContainer>
            <p>No Clinics Found</p>
          </EmptyStateContainer>
        )}
      {isLoading && (
        <EmptyStateContainer>
          <p>Loading ...</p>
          <BounceLoader loading={isLoading} />
        </EmptyStateContainer>
      )}
      {geolocationError && isFindByLocation && (
        <EmptyStateContainer>
          <p>{geolocationError}</p>
        </EmptyStateContainer>
      )}
    </FormContainer>
  );
};

PetPracticeForm.propTypes = {
  name: PropTypes.string.isRequired,
  practiceName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  withoutHeader: PropTypes.bool,
};

export default PetPracticeForm;

const FormContainer = styled.form`
  margin-top: ${({ withoutHeader, theme }) => {
    return withoutHeader ? theme.spacing.spacingSizes.xl : 0;
  }};
`;

const FindPracticeButton = styled(Button)`
  svg {
    position: relative;
    margin-right: 8px;
    top: 2px;
  }

  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};

  ${Compass} {
    height: 13px;
    position: relative;
    width: 13px;
    stroke: ${({ theme }) => theme.palette.actionButton};
  }

  &:hover,
  &:active,
  &:focus {
    ${Compass} {
      stroke: ${({ theme }) => theme.palette.white};
    }
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const Divider = styled.hr`
  border-top: ${({ theme }) => {
    return `1px solid ${theme.palette.grayLightest}`;
  }};
  margin-top: ${({ theme }) => {
    return theme.spacing.spacingSizes.md;
  }};
  margin-bottom: ${({ theme }) => {
    return theme.spacing.spacingSizes.md;
  }};
`;

const DistanceSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.spacingSizes.xl};
`;

const DistanceSelectLabel = styled.label`
  color: ${({ theme }) => theme.palette.black};
`;

const EmptyStateContainer = styled.div`
  color: ${({ theme }) => theme.palette.grayLight};
  font-size: ${({ theme }) => theme.font.fontSizes.xxl};
  padding: ${({ theme }) => theme.spacing.spacingSizes.xxl};
  text-align: center;
`;
