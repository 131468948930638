import firebase from '../../../firebase/firebase';
import getUser from './getUser';

export const saveUserPhoneNumber = (userId, phoneNumber) => {
  return dispatch => {
    // save phone number in user (Database)
    firebase
      .database()
      .ref(`users/${userId}/info/private`)
      .update({ phone: phoneNumber })
      .then(() => {
        // update user (Redux Store)
        dispatch(getUser());
      });
  };
};
