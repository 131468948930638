import styled from 'styled-components/macro';
import { Button } from '@televet/televet-ui';
import React, { useState, useRef, useEffect } from 'react';

import Text from '../theme/Text';
import Flex from '../theme/Flex';
import Check from '../../assets/svg/check.svg';
import { H3 } from '../Common/Headers';
import { Add } from '../Common/Icons';
import PetAvatar from '../Common/PetAvatar';
import ChevronDown from '../../assets/svg/chevron-down.svg';
import { capitalizeString } from '../../utilities';

const PetSelection = ({ onAddPet, selectedPet, pets, onSelectPet }) => {
  const node = useRef();
  const [opened, setOpened] = useState(false);

  const componentDidMount = () => {
    document.addEventListener('mousedown', handleToggleDropDown);
    return () =>
      document.removeEventListener('mousedown', handleToggleDropDown);
  };

  useEffect(componentDidMount, []);

  const handleSelectPet = petId => () => {
    onSelectPet(petId);
    setOpened(false);
  };

  const handleToggleDropDown = e => {
    if (node.current.contains(e.target)) {
      setOpened(true);
      return;
    }

    setOpened(false);
  };

  return (
    <Container>
      <Select ref={node}>
        <ActionsHeader>
          {pets.length > 1 ? (
            <Trigger name="select-pet-button" onClick={handleToggleDropDown}>
              <SelectedPet>{capitalizeString(selectedPet.name)}</SelectedPet>
              <ChevronButton alt="Select a pet" />
            </Trigger>
          ) : (
            <SelectedPet>{capitalizeString(selectedPet.name)}</SelectedPet>
          )}
          <AddPetButton
            buttonType="ghost"
            name="add-pet-button"
            onClick={onAddPet}
          >
            <span>
              <Add title="Add Pet" />
            </span>
            <span style={{ padding: '0 0 0 8px' }}>Add pet</span>
          </AddPetButton>
        </ActionsHeader>
        {opened && pets.length > 1 && (
          <DropDown>
            {pets.map(pet => (
              <Pet
                key={pet.petId}
                name={`select-pet-${pet.name}`}
                onClick={handleSelectPet(pet.petId)}
              >
                <Text as="h2">{capitalizeString(pet.name)}</Text>
                {selectedPet.petId === pet.petId && (
                  <img src={Check} alt="Selected pet checkmark" />
                )}
              </Pet>
            ))}
          </DropDown>
        )}
        {pets.length === 1 && (
          <Pet
            key={selectedPet.petId}
            name={`select-pet-${selectedPet.name}`}
            onClick={handleSelectPet(selectedPet.petId)}
          />
        )}
      </Select>
      <AvatarDiv>
        <PetAvatar pet={selectedPet} size="large" />
      </AvatarDiv>
    </Container>
  );
};

const AvatarDiv = styled.div`
  text-align: left;
  margin: 24px 0;
`;

const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.lg};
  text-align: center;
`;

const ActionsHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Select = styled.div`
  position: relative;
`;

const Trigger = styled.div`
  cursor: pointer;
`;

const AddPetButton = styled(Button)`
  position: absolute;
  right: 0;

  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};

  ${Add} {
    height: 13px;
    position: relative;
    width: 13px;
    stroke: ${({ theme }) => theme.palette.actionButton};
  }

  &:hover,
  &:active,
  &:focus {
    ${Add} {
      stroke: ${({ theme }) => theme.palette.white};
    }
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const SelectedPet = styled(H3)`
  color: ${({ theme }) => theme.palette.black};
  display: inline-flex;
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.sm};
`;

const ChevronButton = styled.img.attrs(() => ({
  src: ChevronDown,
}))`
  @media (min-width: 769px) {
    display: none;
  }
  display: inline-flex;
  margin-left: ${({ theme }) => theme.spacing.spacingSizes.sm};
`;

const DropDown = styled.div`
  background: ${({ theme }) => theme.palette.white};
  box-shadow: 0px
    ${({ theme: { spacing } }) =>
      `${spacing.spacingSizes.sm} ${spacing.spacingSizes.lg}`}
    rgba(0, 0, 0, 0.2);
  border-radius: ${({ theme }) => theme.spacing.spacingSizes.sm};
  padding: 0 ${({ theme }) => theme.spacing.spacingSizes.lg};
  position: absolute;
  top: 100%;
  z-index: 800;
`;

const Pet = styled(Flex)`
  cursor: pointer;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing.spacingSizes.lg} 0;
`;

export default PetSelection;
