import axios from 'axios';
import { pushSnackbar } from '../../snackbar/snackbarActions';
import paymentActionTypes from '../paymentActionTypes';
import getCustomerToken from './getCustomerToken';
import { Mixpanel as mixpanel } from '../../mixpanel';

const deletePaymentMethod = token => {
  return (dispatch, getState) => {
    const { restURL } = getState().variable;
    const url = `${restURL}/payment/delete_payment_method?token=${token}`;

    return axios
      .delete(url)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: paymentActionTypes.deletePaymentMethod,
          });
          dispatch(getCustomerToken());
        }
        mixpanel.track('Delete payment method');
      })
      .catch(error => {
        console.error(error);
        dispatch(pushSnackbar({ type: 'error', message: error.message }));
      });
  };
};

export default deletePaymentMethod;
