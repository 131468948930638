import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import TwilioChatClient from 'twilio-chat';
import { useSelector } from 'react-redux';
import jwt from 'jsonwebtoken';

import firebase from '../../../firebase/firebase';
import CurbsideContext from './curbsideContext';
import { CREATE_TWILIO_TOKEN, GET_CHANNELS } from '../queries';
import { SUBSCRIBE_TO_CHANNEL } from '../subscriptions';

const Store = props => {
  const { channelId, formSubmissionId } = useParams();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    if (!user) {
      firebase
        .auth()
        .signInAnonymously()
        .catch(function(error) {
          console.error(error);
        });
    }
  });

  localStorage.setItem('token', token);
  const { clinicPetParentId } = jwt.decode(token);

  const headers = {
    'auth-type': 'clinic_pet_parent_token',
    authorization: `Bearer ${token}`,
  };

  const { data, loading } = useQuery(GET_CHANNELS, {
    variables: { clinicPetParentId },
    context: { headers },
  });

  const [createTwilioToken] = useMutation(CREATE_TWILIO_TOKEN, {
    context: { headers },
  });

  const history = useHistory();
  useEffect(() => {
    if (data?.channels && !channelId && !formSubmissionId) {
      history.push(
        `conversations/${data.channels[0]?.id}${history.location.search}`
      );
    }
  }, [channelId, data, history, formSubmissionId]);
  if (!channelId) {
  }

  const initialState = {
    user: {},
    clinic: {},
    chatClient: {},
    currentChannelSid: {},
    chatChannel: {},
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (!loading && data && channelId) {
      const channel = data.channels.find(c => c.id === channelId);
      const clinicPetParent = channel?.channelMembers.find(
        m => !!m.clinicPetParent && m.clinicPetParent.id === clinicPetParentId
      ).clinicPetParent;

      if (clinicPetParent) {
        const { id, firstName, lastName } = clinicPetParent;
        const { clinic } = channel;

        setState(prevState => ({
          ...prevState,
          user: {
            firstName,
            lastName,
            uid: id,
          },
          clinic: {
            name: clinic.name,
            address: clinic.fullAddress,
            logo: clinic.logo,
            id: clinic.id,
          },
          channel,
        }));
      }
    }
  }, [data, loading, channelId, clinicPetParentId]);

  useSubscription(SUBSCRIBE_TO_CHANNEL, {
    skip: !state.channel,
    variables: { where: { node: { id: channelId } } },
  });

  const getTwilioToken = useCallback(
    async identity => {
      const {
        data: {
          createTwilioToken: { token },
        },
      } = await createTwilioToken({ variables: { identity } });
      return token;
    },
    [createTwilioToken]
  );

  const initializeChat = useCallback(async () => {
    if (state.user.uid) {
      const token = await getTwilioToken(state.user.uid);
      const client = await TwilioChatClient.create(token);

      client.on('channelJoined', channel => {
        if (channel.sid === state.channel.twilioChannelSid) {
          setState(prevState => ({
            ...prevState,
            chatChannel: channel,
          }));
        }
      });

      client.on('tokenAboutToExpire', async () => {
        const token = await getTwilioToken(state.user.uid);
        client.updateToken(token);
      });
    }
  }, [getTwilioToken, state.user, state.channel]);

  useEffect(() => {
    if (state.user.uid) {
      initializeChat();
    }
  }, [initializeChat, state.user]);

  // useEffect(() => {
  //   if (state.chatClient) {
  //     state.chatClient.on('channelJoined', function(channel) {
  //       setState(prevState => ({
  //         ...prevState,
  //         chatChannel: channel,
  //       }));
  //     });
  //   }
  // }, [state.chatClient]);

  return (
    <CurbsideContext.Provider
      value={{
        user: state.user,
        clinic: state.clinic,
        chatChannel: state.chatChannel,
        token,
        headers,
        channel: state.channel,
      }}
    >
      {props.children}
    </CurbsideContext.Provider>
  );
};

export default Store;
