import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

import Text from '../theme/Text';
import HistoryIcon from '../../assets/svg/history.svg';
import { hslToHsla } from '../theme/helpers';
import ConsultationPreview from './ConsultationPreview';

const ConsultationList = ({
  active,
  past,
  clinics,
  petId,
  setSelectedConsultation,
}) => {
  const [viewPast, setViewPast] = useState(false);
  const vets = useSelector(state => state.vets);

  const resetViewPast = () => {
    setViewPast(false);
  };

  useEffect(resetViewPast, [petId]);

  const handlePastConsultations = () => {
    setViewPast(state => !state);
  };

  return (
    <Container>
      <List>
        <ReverseOrderList>
          {active.length > 0 &&
            active.map(consultation => {
              const clinic = clinics[consultation.clinic];
              return consultation.pet === petId && clinic ? (
                <ConsultationPreview
                  key={consultation.consultationId}
                  consultation={consultation}
                  clinic={clinic.info}
                  setSelectedConsultation={setSelectedConsultation}
                  vets={vets}
                />
              ) : null;
            })}
        </ReverseOrderList>
        {active.length === 0 && (
          <EmptyStateMessage>
            You don't have anything scheduled.
          </EmptyStateMessage>
        )}

        {past.length > 0 && (
          <ViewPast as="h4" onClick={handlePastConsultations}>
            <ViewPastIcon />
            {viewPast ? 'Hide' : 'View'} past consultations
          </ViewPast>
        )}

        <ReverseOrderList>
          {viewPast &&
            past.length > 0 &&
            past.map(consultation => {
              const clinic = clinics[consultation.clinic];

              return consultation.pet === petId && clinic ? (
                <ConsultationPreview
                  key={consultation.consultationId}
                  consultation={consultation}
                  clinic={clinic.info}
                />
              ) : null;
            })}
        </ReverseOrderList>
      </List>
    </Container>
  );
};

const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.spacingSizes.md};
`;

const EmptyStateMessage = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.lg};
  color: ${({ theme }) => hslToHsla(theme.palette.black, 0.8)};
`;

const ReverseOrderList = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const ViewPast = styled(Text)`
  color: ${({ theme }) => hslToHsla(theme.palette.black, 0.8)};
  cursor: pointer;
`;

const ViewPastIcon = styled.img.attrs(() => ({
  src: HistoryIcon,
}))`
  display: inline-flex;
  margin-right: ${({ theme }) => theme.spacing.spacingSizes.xs};
`;

export default ConsultationList;
