import authActionTypes from '../authActionTypes';
import firebase from '../../../firebase/firebase';
import { pushSnackbar } from '../../snackbar/snackbarActions';

const resetPassword = (code, password) => {
  return dispatch => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        dispatch({
          type: authActionTypes.resetPassword,
          payload: { success: true },
        });
        dispatch(
          pushSnackbar({
            type: 'success',
            message: 'Your password has been reset successfully!',
            timeoutMs: 3000,
          })
        );
      })
      .catch(error => {
        dispatch({
          type: authActionTypes.resetPassword,
          payload: { error: error, status: 'error' },
        });
        throw new Error(error);
      });
  };
};

export default resetPassword;
