import styled from 'styled-components/macro';
import { Button, useModal, BounceLoader } from '@televet/televet-ui';
import { useHistory } from 'react-router-dom';
import { TextField, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useCallback } from 'react';

import Flex from '../theme/Flex';
import Text from '../theme/Text';
import { H1, H3 } from '../Common/Headers';
import ActionButton from '../Common/ActionButton';
import ClinicInfo from '../Common/ClinicInfo';
import AttachFiles from '../Common/AttachFiles';
import Payment from '../AccountView/Payment';
import createConsultation from '../../redux/app/actions/createConsultation';
import initialConsultationState from '../Common/consultationInitialState';
import BraintreeDropIn from '../Common/BraintreeDropIn';
import {
  capitalizeString,
  getPromoCodesByClinic,
  isCodeUsable,
  checkNewPromoCode,
  formatPossessiveName,
  isCodeExpired,
} from '../../utilities';
import { saveUserPhoneNumber } from '../../redux/user/actions/saveUserPhoneNumber';
import { savePromoCode } from '../../redux/user/actions/savePromoCode';
import { pushSnackbar } from '../../redux/snackbar/snackbarActions.js';
import AddPromoCode from '../AccountView/PromoCodesView/AddPromoCode';
import PromoCodeList from '../AccountView/PromoCodesView/PromoCodeList';
import TELEVET_FEE from '../../../src/constants/app';
import getClientToken from '../../redux/payment/actions/getClientToken';
import getCustomerToken from '../../redux/payment/actions/getCustomerToken';
import updatePromoCodeInUser from '../../redux/user/actions/updatePromoCodeInUser';
import { Mixpanel as mixpanel, Mixpanel } from '../../redux/mixpanel';

const NewConsultation = ({ pet, petId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const app = useSelector(state => state.app);
  const pets = useSelector(state => state.pets);
  const user = useSelector(state => state.user);
  const clinics = useSelector(state => state.clinics);
  const auth = useSelector(state => state.auth);
  const payment = useSelector(state => state.payment);

  const [isLoading, setIsLoading] = useState(false);

  const [consultationState, setConsultationState] = useState(
    initialConsultationState
  );
  const [braintreeInstance, setBraintreeInstance] = useState(null);
  const errorState = {};
  const errorTypes = ['summary', 'details'];
  errorTypes.forEach(error => (errorState[error] = false));

  const [formErrors, setFormErrors] = useState(errorState);
  const [consultationNonce, setConsultationNonce] = useState(null);
  const [showEnterPhoneField, setShowEnterPhoneField] = useState(false);
  const [showAddPromoCodeField, setShowAddPromoCodeField] = useState(false);
  const [promoCodeErrorMessage, setPromoCodeErrorMessage] = useState('');
  const [selectedPromoCode, setSelectedPromoCode] = useState();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [promoCodes, setPromoCodes] = useState([]);
  const [customerTokenHasLoaded, setCustomerTokenHasLoaded] = useState(false);
  const [clientToken, setClientToken] = useState(null);

  const clinicId = pets[petId]?.clinicId;
  const clinic = clinics[clinicId];
  const fileUploadStatus = app.fileUpload;
  const userId = auth.user.uid;
  const hasPhoneNumber = !!user.info.private.phone;

  const { closeModal } = useModal();

  const setPayments = useCallback(() => {
    if (!customerTokenHasLoaded) {
      getClientToken()
        .then(clientTokenResult => {
          setClientToken(clientTokenResult.payload);
          setCustomerTokenHasLoaded(true);
        })
        .catch(error => {
          console.error(error);
          dispatch(pushSnackbar({ type: 'error', message: error.message }));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerTokenHasLoaded]);

  const setupBraintree = async () => {
    if (braintreeInstance) {
      try {
        if (braintreeInstance.isPaymentMethodRequestable()) {
          const { nonce } = await braintreeInstance.requestPaymentMethod();
          setConsultationNonce(nonce);
        }
      } catch (error) {
        dispatch(
          pushSnackbar({
            type: 'error',
            message: 'Error setting payment method' + error.message,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (braintreeInstance && !consultationNonce) {
      setupBraintree();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [braintreeInstance, consultationNonce]);

  useEffect(() => {
    setPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerTokenHasLoaded]);

  const validateField = (fieldName, value) => {
    switch (value.length) {
      case 0:
        setFormErrors({
          ...formErrors,
          [fieldName]: true,
        });
        return false;

      default:
        setFormErrors({
          ...formErrors,
          [fieldName]: false,
        });
        return true;
    }
  };

  const calculateConsultCost = () => {
    const baseConsultCost =
      parseInt(clinic?.info.private.consultCost) + TELEVET_FEE;
    if (selectedPromoCode && selectedPromoCode.discount) {
      const percentDiscount = parseInt(selectedPromoCode.discount);
      return baseConsultCost - (baseConsultCost * percentDiscount) / 100;
    }
    return baseConsultCost;
  };

  const handleClickShowAddPromoCodeField = () => {
    setShowAddPromoCodeField(true);
  };

  const handleSavePromoCode = async enteredPromoCode => {
    // reset in case there was an error message
    setPromoCodeErrorMessage('');
    const codeStatus = await checkNewPromoCode(
      enteredPromoCode,
      clinicId,
      user.info.promos
    );

    if (codeStatus.isValid) {
      const clinicName = clinics[clinicId].info.name;
      savePromoCode(
        userId,
        codeStatus.codeObj,
        codeStatus.codeId,
        clinicName,
        dispatch
      );
      setShowAddPromoCodeField(false);
    } else {
      setPromoCodeErrorMessage(codeStatus.message.text);
    }
  };

  const handleCodeSelect = code => () => {
    setSelectedPromoCode(code);
    mixpanel.track('Promo code added to consultation');
  };

  const handleSummaryChange = event => {
    const value = event.currentTarget.value;
    setConsultationState({
      ...consultationState,
      summary: capitalizeString(value.slice(0, 50)),
    });
    validateField('summary', value);
  };

  const handleDetailsChange = event => {
    const value = event.currentTarget.value;
    validateField('details', value);

    setConsultationState({
      ...consultationState,
      details: capitalizeString(value),
    });
  };

  const handlePhoneToggle = () => {
    if (consultationState.phonePref && !hasPhoneNumber) {
      setShowEnterPhoneField(true);
    } else {
      setShowEnterPhoneField(false);
      setConsultationState({
        ...consultationState,
        phonePref: !consultationState.phonePref,
      });
    }
  };

  const handlePhoneNumberChange = e => {
    setPhoneNumber(e.target.value);
  };

  const handleSavePhoneNumber = () => {
    setShowEnterPhoneField(false);
    dispatch(saveUserPhoneNumber(userId, phoneNumber));
  };

  const createNewConsultation = (clinic, nonce) => {
    const { summary, videoPref, phonePref, details } = consultationState;

    const consultationObject = {
      nonce,
      petId,
      details,
      summary,
      clinicId,
      phonePref,
      videoPref,
      cost: clinic?.info.private.consultCost,
      pics: consultationState.uploadedImages,
      vids: consultationState.uploadedVideos,
      media: consultationState.consultationContainsMedia,
      selectedPromo: selectedPromoCode
        ? { ...selectedPromoCode, key: selectedPromoCode.id }
        : null,
    };

    return new Promise((resolve, reject) => {
      dispatch(createConsultation(consultationObject))
        .then(consultationId => {
          updatePromoCodeInUser(userId, selectedPromoCode, dispatch);
          closeModal();
          if (phonePref) Mixpanel.track('Phone call selected');
          if (videoPref) Mixpanel.track('Video call selected');
          return resolve(consultationId);
        })
        .catch(error => {
          console.error(error);
          dispatch(pushSnackbar({ type: 'error', message: error.message }));
          reject(false);
        });
    });
  };

  const handleClickCreateConsultation = async () => {
    Mixpanel.track('Consultation request submitted');
    if (
      Object.keys(formErrors).some(
        key => !validateField(key, consultationState[key])
      )
    ) {
      return;
    }
    setIsLoading(true);
    await dispatch(getCustomerToken(user.info.private.customerId));
    const { nonce } = await braintreeInstance.requestPaymentMethod();

    setConsultationState({ ...consultationState, nonce });

    const consultationId = await createNewConsultation(clinic, nonce);
    setIsLoading(false);
    closeModal();

    if (consultationId) {
      setConsultationState(initialConsultationState);
      history.push(`/consultation/${consultationId}`);
    }
  };

  const setUploadedFiles = (imageFiles, videoFiles, allFiles) => {
    setConsultationState({
      ...consultationState,
      uploadedImages: imageFiles,
      uploadedVideos: videoFiles,
      uploadedFiles: allFiles,
      consultationContainsMedia: allFiles.length > 0,
    });
  };

  const handleCancelConsultation = () => {
    setConsultationState(initialConsultationState);
    Mixpanel.track('Consultation request cancelled');
    closeModal();
  };

  // Get user's promo codes for this clinic. If any found,
  // select the newest code
  useEffect(() => {
    const usersPromoCodes = getPromoCodesByClinic(
      clinicId,
      user.info.promos
    ).filter(code => isCodeUsable(code) && !isCodeExpired(code));

    if (usersPromoCodes.length > 0) {
      setPromoCodes(usersPromoCodes);
      // the codes are already sorted by time added, so
      // the first code will be the newest
      setSelectedPromoCode(usersPromoCodes[0]);
    }
  }, [user.info.promos, clinicId]);

  return (
    <Container>
      {braintreeInstance ? (
        <Form>
          <H1>New Consultation for {capitalizeString(pet?.public?.name)}</H1>
          <Disclaimer>
            In case of emergency, contact the hospital directly or go to your
            nearest emergency care provider.
          </Disclaimer>
          {clinic && (
            <ClinicInfo
              petId={petId}
              clinic={{ clinicId: clinicId, ...clinic.info }}
              reducedCost={`$${calculateConsultCost().toFixed(2)}`}
            />
          )}
          <Fieldset>
            <StyledTextField
              placeholder="Summary of issue"
              label="Summary of issue"
              name="summary"
              variant="outlined"
              error={formErrors.summary}
              helperText={
                formErrors.summary
                  ? '*This field cannot be left empty'
                  : `*This field is required, ${50 -
                      consultationState.summary.length} characters left.`
              }
              required
              value={consultationState.summary}
              onChange={handleSummaryChange}
            />
            <StyledTextField
              placeholder={`Describe ${formatPossessiveName(
                pet?.public?.name
              ) || "your pet's"} situation`}
              label={`Describe ${formatPossessiveName(pet?.public?.name) ||
                "your pet's"} situation`}
              variant="outlined"
              name="details"
              multiline
              error={formErrors.details}
              helperText={
                formErrors.details
                  ? '*This field cannot be left empty'
                  : '*This field is required'
              }
              required
              rows="3"
              value={consultationState.details}
              onChange={handleDetailsChange}
            />
          </Fieldset>
          <AttachFiles onUploadedFiles={setUploadedFiles} />
          <H3>Consultation preferences</H3>
          <StyledSpaceCenter>
            <Text>Video Call</Text>
            <Switch
              checked={consultationState.videoPref}
              onChange={() => {
                setConsultationState({
                  ...consultationState,
                  videoPref: !consultationState.videoPref,
                });
              }}
              color="primary"
              name="checkedVideoCall"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Text>Phone Call</Text>
            <Switch
              checked={hasPhoneNumber && consultationState.phonePref}
              onChange={handlePhoneToggle}
              color="primary"
              name="checkedPhoneCall"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </StyledSpaceCenter>
          {showEnterPhoneField && (
            <TextFieldContainer>
              <StyledTextField
                label="Enter phone number"
                variant="outlined"
                onChange={handlePhoneNumberChange}
                fullWidth
              />
              <ButtonRow>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={!phoneNumber}
                  onClick={handleSavePhoneNumber}
                >
                  Save
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowEnterPhoneField(false)}
                >
                  Cancel
                </Button>
              </ButtonRow>
            </TextFieldContainer>
          )}

          <Payment
            isNewConsultation={true}
            onPaymentMethodAdded={() => setupBraintree()}
          />

          <Fieldset>
            <PromoDiv>
              <H3>Promo Codes</H3>
              <AddPromoButton
                buttonType="ghost"
                fullwidth
                onClick={handleClickShowAddPromoCodeField}
              >
                Add promo code
              </AddPromoButton>
            </PromoDiv>

            {promoCodes.length > 0 && (
              <PromoCodeList
                width="100%"
                promoCodes={promoCodes}
                clinic={clinic}
                handleCodeSelect={handleCodeSelect}
                selected={selectedPromoCode}
              />
            )}
            {showAddPromoCodeField && (
              <AddPromoCode
                handleCancel={() => {
                  setShowAddPromoCodeField(false);
                  mixpanel.track('Add promo code canceled', {
                    isNewConsultation: true,
                  });
                }}
                handleSavePromoCode={handleSavePromoCode}
                error={promoCodeErrorMessage.length > 0}
                helperText={promoCodeErrorMessage}
              />
            )}
          </Fieldset>
          {isLoading ? (
            <div style={{ textAlign: 'center' }}>
              <Text>Creating your consultation...</Text>
              <BounceLoader loading={isLoading} />
            </div>
          ) : (
            <>
              <CreateButton
                disabled={
                  fileUploadStatus.isUploading ||
                  !payment.methods.length ||
                  consultationState.details.length < 2 ||
                  consultationState.summary.length < 2 ||
                  !braintreeInstance ||
                  isLoading
                }
                onClick={handleClickCreateConsultation}
                variant="contained"
                size="large"
                buttonType="primary"
              >
                <CreateButtonText>Create Consultation</CreateButtonText>
                <CreateButtonCost>
                  ${calculateConsultCost().toFixed(2)}
                </CreateButtonCost>
              </CreateButton>
              <div>
                <CancelButton
                  buttonType="ghost"
                  fullWidth={true}
                  onClick={handleCancelConsultation}
                >
                  Cancel
                </CancelButton>
              </div>
            </>
          )}
        </Form>
      ) : (
        <LoadingDiv>
          <Text>Loading data from our payment processor...</Text>
          <BounceLoader loading={true} />
        </LoadingDiv>
      )}

      {customerTokenHasLoaded && (
        <BraintreeContainer>
          <BraintreeDropIn
            options={{ authorization: clientToken }}
            onInstance={instance => {
              setBraintreeInstance(instance);
              setupBraintree();
            }}
          />
        </BraintreeContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  min-height: 100%;

  .braintree-heading {
    color: ${({ theme }) => theme.palette.black};
    font-family: Roboto;
    font-size: ${({ theme }) => theme.font.fontSizes.h4};
    font-weight: 500;
  }
`;

const Form = styled.div`
  margin: 0 auto;
  max-width: 656px;
  padding-top: ${({ theme }) => theme.spacing.spacingSizes.xl};
  position: relative;

  .MuiInputBase-root {
    background-color: ${({ theme }) => theme.palette.white};
  }
`;

const Disclaimer = styled(Text)`
  color: ${({ theme }) => theme.palette.gray300};
  font-size: 18px;
  line-height: 1.5;
  font-weight: 200;
  margin-top: ${({ theme }) => theme.spacing.spacingSizes.xs};
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.lg};
`;

const Fieldset = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.xxxl};
  width: 100%;
`;

const BraintreeContainer = styled.div`
  width: 0px;
  height: 0px;
  opacity: 0;
  display: none;
`;

const TextFieldContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 15px;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.spacingSizes.sm};
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.sm};
`;

const StyledTextField = styled(TextField)`
  margin: ${({ theme }) => theme.spacing.spacingSizes.xs} 0;
  width: 100%;
`;

const StyledSpaceCenter = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.spacingSizes.xs};
  margin-right: 0;
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.xs};
`;

const CreateButton = styled(ActionButton)`
  margin-top: ${({ theme }) => theme.spacing.spacingSizes.xxxl};
  width: 100% !important;
  padding 6px 0;
`;

const AddPromoButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};
  margin-bottom: 0;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const CreateButtonText = styled(Text)`
  position: relative;
  top: -8px;
  color: ${({ theme }) => theme.palette.white};
`;

const CreateButtonCost = styled(Text)`
  font-size: 12px;
  line-height: 0;
  color: ${({ theme }) => theme.palette.white};
`;

const CancelButton = styled(Button)`
  display: block;
  margin: ${({ theme }) => theme.spacing.spacingSizes.lg} auto;
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const PromoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
`;

const LoadingDiv = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default NewConsultation;
