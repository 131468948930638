import mixpanel from 'mixpanel-browser';
import { PersistedKeys } from '../../PersistKeys';

const envCheck = process.env.REACT_APP_PRODUCTION === 'true';
const clinicName =
  localStorage.getItem(PersistedKeys.ClinicName) || 'Clinic not set';
const appName = localStorage.getItem(PersistedKeys.AppName) || 'Pet Web';

const actions = {
  init: token => {
    try {
      if (envCheck && token) mixpanel.init(token);
    } catch (e) {
      console.log(e);
    }
  },
  identify: id => {
    try {
      if (envCheck) mixpanel.identify(id);
    } catch (e) {
      console.log(e);
    }
  },
  alias: id => {
    try {
      if (envCheck) mixpanel.alias(id);
    } catch (e) {
      console.log(e);
    }
  },
  track: (name, props) => {
    try {
      if (envCheck) {
        mixpanel.track(name, {
          ...props,
          clinicName,
          appName,
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
  people: {
    set: props => {
      try {
        if (envCheck) mixpanel.people.set(props);
      } catch (e) {
        console.log(e);
      }
    },
  },
  reset: () => {
    try {
      if (envCheck) {
        mixpanel.reset();
      }
    } catch (e) {
      console.log(e);
    }
  },
};

export const Mixpanel = actions;
