import styled from 'styled-components/macro';
import { ResponsiveDeviceMinWidth } from '@televet/televet-ui';

const H4 = styled.h4`
  font-size: ${({ theme }) => {
    return theme.font.headlineSizes.h5;
  }};
  margin: ${({ theme }) => {
    return `${theme.spacing.spacingSizes.sm} 0`;
  }};

  @media ${ResponsiveDeviceMinWidth.tablet} {
    font-size: ${({ theme }) => {
      return theme.font.headlineSizes.h4;
    }};
    margin: ${({ theme }) => {
      return `${theme.spacing.spacingSizes.sm} 0`;
    }};
  }
  color: ${({ theme }) => theme.palette.black};
`;

export default H4;
