import ReactGA from 'react-ga';

export const initialize = trackingID => {
  if (process.env.REACT_APP_PRODUCTION === 'true') {
    ReactGA.initialize(trackingID);
  }
};

export const setUser = userId => {
  if (process.env.REACT_APP_PRODUCTION === 'true') {
    ReactGA.set({ userId });
  }
};

export const pageView = location => {
  if (process.env.REACT_APP_PRODUCTION === 'true') {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }
};

export const trackEvent = (category, action, label) => {
  if (process.env.REACT_APP_PRODUCTION === 'true') {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }
};
