import React, { Component } from 'react';
import styled from 'styled-components/macro';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <ErrorMessageContainer>
          <ErrorHeadline>An unexpected error has ocurred.</ErrorHeadline>
        </ErrorMessageContainer>
      );
    }

    return this.props.children;
  }
}

const ErrorMessageContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

const ErrorHeadline = styled.h1`
  color: hsl(0, 0%, 90%);
  font-size: 3rem;
`;
