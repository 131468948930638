import styled from 'styled-components/macro';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-top: ${props => props.top || '15px'};
  flex-wrap: wrap;
`;

export default Row;
