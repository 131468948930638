import firebase from '../../../firebase/firebase';

import getUser from './getUser';
import { pushSnackbar } from '../../snackbar/snackbarActions';
import { capitalizeString } from '../../../utilities';

const updateUser = userObject => {
  return async (dispatch, getState) => {
    const state = getState();
    const uid = state.auth.user.uid;
    const previousFName = state.user.info.public.fname;
    const previousLName = state.user.info.public.lname;
    let {
      fname,
      lname,
      email,
      phone,
      pushNotification,
      tz,
      newUser,
    } = userObject;
    fname = capitalizeString(fname);
    lname = capitalizeString(lname);

    if (newUser) {
      await firebase
        .database()
        .ref(`users/${uid}/info/public/`)
        .update({ signUpSource: 'pet-web' });
    }

    if (email) {
      await firebase
        .auth()
        .currentUser.updateEmail(email)
        .then(async response => {
          await firebase
            .database()
            .ref(`users/${uid}/info/private/`)
            .update({ email });
        })
        .catch(error => {
          dispatch(
            pushSnackbar({
              type: 'error',
              message: 'An error occurred: ' + error.message,
            })
          );
        });
    }
    if (phone || phone === null) {
      phone = phone.replace(/\D+/g, ''); // strip formatting characters

      await firebase
        .database()
        .ref(`users/${uid}/info/private/`)
        .update({ phone });
    }
    if (fname) {
      await firebase.auth().currentUser.updateProfile({
        displayName: `${fname} ${previousLName}`,
      });
      await firebase
        .database()
        .ref(`users/${uid}/info/public/`)
        .update({ fname });
    }
    if (lname) {
      await firebase.auth().currentUser.updateProfile({
        displayName: `${previousFName} ${lname}`,
      });
      await firebase
        .database()
        .ref(`users/${uid}/info/public/`)
        .update({ lname });
    }
    if (pushNotification === false || pushNotification === true) {
      await firebase
        .database()
        .ref(`users/${uid}/info/public/`)
        .update({ pushNotification });
    }
    if (tz) {
      if (tz === 'auto') {
        tz = null;
      }
      await firebase
        .database()
        .ref(`users/${uid}/info/public/`)
        .update({ tz });
    }
    dispatch(getUser());
  };
};

export default updateUser;
