import firebase from '../firebase/firebase';

export const getPromoCodes = async promoCode => {
  return await firebase
    .database()
    .ref('promo')
    .orderByChild('code')
    .equalTo(promoCode)
    .once('value')
    .then(async snapshot => {
      return await snapshot.val();
    });
};
