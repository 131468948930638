import produce from 'immer';
import consultationsActionTypes from './consultationsActionTypes';

const initialState = {};

let consultationsReducer = produce((draft, action) => {
  switch (action.type) {
    case consultationsActionTypes.addConsultation:
      draft[action.payload.consultId] = action.payload.consultObj;
      break;

    default:
      break;
  }
}, initialState);

export default consultationsReducer;
