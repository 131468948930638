import React from 'react';
import styled from 'styled-components/macro';
import { Button } from '@televet/televet-ui';

import Text from '../theme/Text';
import Flex from '../theme/Flex';
import { H5 } from '../Common/Headers';
import Separator from '../theme/Separator';
import { Pencil } from '../Common/Icons';
import { formatStringDate } from '../../utilities';

const PetInfo = ({
  id,
  pet: { breed, DOB, sex, env, repoStatus, weight },
  onEditPet,
}) => {
  const handleEditPet = id => () => onEditPet(id);
  const emptyMessage = 'Not recorded';

  return (
    <Container>
      <Row>
        <PetBio>Pet Bio</PetBio>
      </Row>
      <Separator style={{ margin: '0 0 24px' }} />
      <Row>
        <Label>Breed</Label>
        <Detail>{breed || emptyMessage}</Detail>
      </Row>
      <Row>
        <Label>Date of Birth</Label>
        {DOB && (
          <Detail>{`${formatStringDate(DOB.month, DOB.day, DOB.year)}`}</Detail>
        )}
        {!DOB && <Detail>{emptyMessage}</Detail>}
      </Row>
      <Row>
        <Label>Sex</Label>
        <Detail>{sex || emptyMessage}</Detail>
      </Row>
      <Row>
        <Label>Status</Label>
        <Detail>{repoStatus || emptyMessage}</Detail>
      </Row>
      <Row>
        <Label>Environment</Label>
        <Detail>{env || emptyMessage}</Detail>
      </Row>
      <Row>
        <Label>Weight</Label>
        <Detail>{weight || emptyMessage}</Detail>
      </Row>

      <EditButton
        buttonType="ghost"
        name="edit-pet-button"
        onClick={handleEditPet(id)}
      >
        <span>
          <Pencil title="Edit Pet" />
        </span>
        <span style={{ padding: '0 0 0 8px' }}>Edit Pet</span>
      </EditButton>
    </Container>
  );
};

const PetBio = styled(H5)`
  margin: 0;
`;

const Container = styled(Flex)`
  margin: ${({ theme }) => theme.spacing.spacingSizes.lg} 0;
`;

const Row = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.md};
  width: 100%;
`;

const Detail = styled(Text)`
  display: flex;
  flex: 50%;
  text-align: left;
  color: ${({ theme }) => theme.palette.black};
`;

const Label = styled(Detail)`
  color: ${({ theme }) => theme.palette.black};
  text-align: left;
`;

const EditButton = styled(Button)`
  margin-top: 24px;

  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};

  ${Pencil} {
    height: 13px;
    position: relative;
    width: 13px;
    stroke: ${({ theme }) => theme.palette.actionButton};
  }

  &:hover,
  &:active,
  &:focus {
    ${Pencil} {
      stroke: ${({ theme }) => theme.palette.white};
    }
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

export default PetInfo;
