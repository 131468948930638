import React, { useCallback, useContext, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import {
  ResponsiveDeviceMaxWidth,
  ResponsiveDeviceMinWidth,
} from '@televet/televet-ui';
import { AttachIcon, SentIcon as SendIcon } from '../../icons';
import LoadingSpinner from '../../../Common/LoadingSpinner';
import useSendMessage from '../hooks/sendMessage';
import { ChannelMessageAttachmentType, ChannelStatusAction } from '../../enums';
import StagedAttachments from './StagedAttachments';
import TextareaAutosize from 'react-textarea-autosize';
import curbsideContext from '../../context/curbsideContext';
import { useMutation } from '@apollo/client';
import { TRIGGER_WORKFLOW_EVENT } from '../mutations';

import * as FullStory from '@fullstory/browser';

const acceptedFileTypes =
  'image/*, video/*, *.mp4, video/mp4, video/x-m4v, .pdf, .doc, .docx, .gif, .txt, audio/*, .zip, .7z';
const maxImageCount = 5;
const maxCombinedVideoSize = 100000000; // 100 MB

const MessageComposer = ({
  channel,
  userId,
  handlePreviewClick,
  suggestedMessageOptions,
  workflowActionEventTrigger,
  suggestedMessageEntityId,
  workflowEventTriggerActionNumber,
}) => {
  const [messageText, setMessageText] = useState('');
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [stagedFiles, setStagedFiles] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const inputFileRef = useRef(null);

  const { headers, clinic } = useContext(curbsideContext);
  const [triggerWorkFlowEvent] = useMutation(TRIGGER_WORKFLOW_EVENT, {
    context: { headers },
  });

  const handleFileUploadClick = useCallback(() => {
    if (!isSendingMessage) inputFileRef.current.click();
  }, [isSendingMessage, inputFileRef]);

  const onError = error => {
    console.info(error.message);
  };

  const { sendMessage } = useSendMessage({ onError });

  const handleSendMessage = useCallback(async () => {
    if (isSendingMessage) return;
    try {
      setIsSendingMessage(true);
      const trimmedMessage = messageText.trim();
      if (trimmedMessage || attachments.length) {
        await sendMessage({
          text: trimmedMessage,
          attachments: attachments,
        });

        if (
          suggestedMessageEntityId &&
          workflowEventTriggerActionNumber &&
          workflowActionEventTrigger &&
          !suggestedMessageOptions.length
        ) {
          triggerWorkFlowEvent({
            variables: {
              data: {
                entityId: suggestedMessageEntityId,
                event: workflowActionEventTrigger,
                delay: 1000,
                actionNumber: workflowEventTriggerActionNumber,
              },
            },
          });
          if (
            workflowEventTriggerActionNumber === 1 &&
            process.env.REACT_APP_PRODUCTION === 'true'
          ) {
            FullStory.event('Workflow Started', {
              clinic: clinic?.name,
              appointmentId: suggestedMessageEntityId,
            });
          }
        }

        setMessageText('');
        setStagedFiles([]);
        setAttachments([]);
        setErrorMessages([]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSendingMessage(false);
    }
  }, [
    attachments,
    clinic,
    isSendingMessage,
    messageText,
    sendMessage,
    suggestedMessageEntityId,
    suggestedMessageOptions.length,
    triggerWorkFlowEvent,
    workflowActionEventTrigger,
    workflowEventTriggerActionNumber,
  ]);

  const onAttachmentRemove = useCallback(
    index => {
      setAttachments(prevAttachments => {
        const attachments = [...prevAttachments];
        const removal = attachments.splice(index, 1).pop();
        if (removal?.value?.filename) {
          const newFiles = stagedFiles.filter(
            file => file?.name !== removal.value.filename
          );
          setStagedFiles(newFiles);
        }
        return attachments;
      });
    },
    [setAttachments, stagedFiles]
  );

  const convertFileToAttachment = file => {
    let attachmentType = ChannelMessageAttachmentType.File;

    if (file?.type.includes('image')) {
      attachmentType = ChannelMessageAttachmentType.Image;
    } else if (file?.type.includes('video')) {
      attachmentType = ChannelMessageAttachmentType.Video;
    }

    return {
      value: {
        attachmentType,
        filename: file.name,
      },
      file,
    };
  };

  const validateTotalVideoSize = videoSize => {
    return videoSize <= maxCombinedVideoSize;
  };

  const validateTotalImageCount = imageList => {
    return imageList.length <= maxImageCount;
  };

  const updateSelectedFiles = async files => {
    if (!files) return;

    const fileList = Object.entries(files)
      .map(([, file]) => {
        return file;
      })
      .concat(stagedFiles);

    const imageList = fileList?.filter(file => file?.type?.includes('image'));
    const videoList = fileList?.filter(file => file?.type?.includes('video'));

    if (videoList.length > 0) {
      const totalVideoSize = videoList
        .map(video => {
          return video.size;
        })
        .reduce((prev, curr) => {
          return prev + curr;
        });

      if (!validateTotalVideoSize(totalVideoSize)) {
        setErrorMessages(['Videos must not exceed 100 MB combined']);
        return;
      }
    }

    if (!validateTotalImageCount(imageList)) {
      setErrorMessages([
        `Maximum ${maxImageCount} images allowed per consultation`,
      ]);
      return;
    }

    setAttachments(
      fileList.map(file => convertFileToAttachment(file)).concat(attachments)
    );
    setStagedFiles(fileList);
  };

  return (
    <>
      {!!errorMessages.length && (
        <ErrorMessages>
          {errorMessages.map(message => (
            <p key={message}>{message}</p>
          ))}
        </ErrorMessages>
      )}
      {channel?.channelStatus?.channelStatusAction ===
      ChannelStatusAction.InactivePermanently ? (
        <ClosedStatusBanner>
          {`${channel?.clinic?.name ||
            'Your care provider'} closed this conversation.`}
        </ClosedStatusBanner>
      ) : (
        <FooterContainer hasStagedFiles={!!stagedFiles?.length}>
          <HiddenInput
            multiple
            type="file"
            name="file"
            id="file-input"
            accept={acceptedFileTypes}
            ref={inputFileRef}
            onChange={event => {
              updateSelectedFiles(event.target.files);
            }}
          />
          <AttachIconContainer onClick={handleFileUploadClick}>
            <AttachIcon title="Upload a file" />
          </AttachIconContainer>
          <SectionDiv hasStagedFiles={!!stagedFiles?.length}>
            {!!stagedFiles.length && (
              <TempStage hasStagedFiles={!!stagedFiles?.length}>
                <StagedAttachments
                  files={stagedFiles}
                  onAttachmentRemove={onAttachmentRemove}
                  handlePreviewClick={handlePreviewClick}
                />
              </TempStage>
            )}
            <StyledInputArea>
              <MessageInput
                maxRows={3}
                name="Send message"
                label="Send message"
                variant="outlined"
                autoComplete="off"
                type="text"
                placeholder="Write a message..."
                value={messageText}
                onKeyPress={event => {
                  if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault();
                    handleSendMessage();
                  }
                }}
                onChange={({ currentTarget: { value } }) => {
                  setMessageText(value);
                }}
              />
              <SendButton onClick={handleSendMessage}>
                {isSendingMessage ? (
                  <LoadingSpinner />
                ) : (
                  <SendIcon title="Send message" />
                )}
              </SendButton>
            </StyledInputArea>
          </SectionDiv>
        </FooterContainer>
      )}
    </>
  );
};
export default MessageComposer;

const TempStage = styled.div`
  border-bottom: ${({ hasStagedFiles }) =>
    hasStagedFiles ? '1px solid #989aa1' : 'none'};
`;

const ClosedStatusBanner = styled.div`
  padding: 20px 10px;
  text-align: center;
  color: #5d6dd8;
  font-weight: 600;
  background: #f2f4fe;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
  line-height: 1.4;
`;

const FooterContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: hsl(228, 29%, 97%);
  height: ${({ hasStagedFiles }) => (hasStagedFiles ? 'auto' : '60px')};
  min-height: 60px;
  padding: 6px;
  @media ${ResponsiveDeviceMinWidth.laptop} {
    max-width: 750px;
  }
  @media ${ResponsiveDeviceMaxWidth.laptop} {
    width: 100%;
  }
`;

const AttachIconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: ${({ hasStagedFiles }) =>
    hasStagedFiles ? 'flex-end' : 'center'};
  justify-content: center;
  padding: ${({ hasStagedFiles }) => (hasStagedFiles ? '4px 16px' : '0 16px')};
  margin-right: 6px;
  border-radius: 6px;
  cursor: pointer;

  & svg {
    height: 30px;
    width: auto;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const StyledInputArea = styled.div`
  flex: 1 1 auto;
  display: flex;
  height: 100%;
  position: relative;
`;

const MessageInput = styled(TextareaAutosize)`
  color: #333;
  font-family: LinearSans;
  font-size: 18px;
  height: 100%;
  outline: none;
  background-color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 12px 48px 8px 8px;
  position: relative;
  resize: none;
  width: 100%;
  height: 44px;

  &:focus {
    border-color: #989aa1;
  }
`;

const SendButton = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  height: calc(100% - 2px);
  width: 48px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:active,
  &:hover {
    background: hsl(0, 0%, 95%);
    cursor: pointer;
  }
`;

const ErrorMessages = styled.div`
  color: ${({ theme }) => theme.palette.error};
  & > * + * {
    margin: 20px 0;
  }
`;

const SectionDiv = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid
    ${({ hasStagedFiles }) => (hasStagedFiles ? '#989aa1' : '#dedede')};
  margin-right: 12px;
`;
