import styled from 'styled-components/macro';
import { Button } from '@televet/televet-ui';
import React, { useState } from 'react';

import Text from '../theme/Text';
import { H3 } from '../Common/Headers';
import FilePreviewList from './FilePreviewList';

const acceptedFileTypes = [
  'image/*',
  'video/*',
  '*.mp4',
  'video/mp4',
  'video/x-m4v',
];
const maxImageCount = 5;
const maxCombinedVideoSize = 15000000; // 15 MB

export const FileUploadContext = React.createContext();

const AttachFiles = ({ onUploadedFiles }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  const validateTotalVideoSize = videoSize => {
    return videoSize <= maxCombinedVideoSize;
  };

  const validateTotalImageCount = imageList => {
    return imageList.length <= maxImageCount;
  };

  const updateSelectedFiles = async event => {
    const fileList = Object.entries(event.target.files)
      .map(([_, file]) => {
        return file;
      })
      .concat(uploadedFiles);

    const imageList = fileList.filter(file => file.type.includes('image'));
    const videoList = fileList.filter(file => file.type.includes('video'));

    const errorMessages = [];
    setErrorMessages(errorMessages);

    if (videoList.length > 0) {
      const totalVideoSize = videoList
        .map(video => {
          return video.size;
        })
        .reduce((prev, curr) => {
          return prev + curr;
        });

      if (!validateTotalVideoSize(totalVideoSize)) {
        errorMessages.push('Videos must not exceed 15 MB combined');
      }
    }

    if (!validateTotalImageCount(imageList)) {
      errorMessages.push(
        `Maximum ${maxImageCount} images allowed per consultation`
      );
    }

    if (errorMessages.length) {
      setErrorMessages(errorMessages);
      return;
    }

    onUploadedFiles(imageList, videoList, fileList);
    setUploadedFiles(fileList);
  };

  const handleEnter = () => {
    document.getElementById('file').click();
  };

  const removeFile = fileName => {
    const newFiles = uploadedFiles.filter(file => file.name !== fileName);
    const imageList = newFiles.filter(file => file.type.includes('image'));
    const videoList = newFiles.filter(file => file.type.includes('video'));

    onUploadedFiles(imageList, videoList, newFiles);
    setUploadedFiles(newFiles);
  };

  return (
    <Container>
      <Row>
        <H3>Attachments</H3>
        <ButtonWrapper>
          <ChooseFilesButton buttonType="ghost" onClick={handleEnter}>
            Choose files
            <Input
              multiple
              id="file"
              type="file"
              name="file"
              accept={acceptedFileTypes.join(', ')}
              onClick={e => e.stopPropagation()}
              onChange={event => updateSelectedFiles(event)}
            />
          </ChooseFilesButton>
        </ButtonWrapper>
      </Row>
      <Row>
        <Directions>
          Include any photos and videos you feel your care team should take a
          look at.
        </Directions>
      </Row>

      <ErrorMessages>
        {errorMessages.map(message => (
          <div key={message}>{message}</div>
        ))}
      </ErrorMessages>

      <StyledFilePreview>
        <FileUploadContext.Provider value={{ removeFile }}>
          <FilePreviewList uploadedFiles={uploadedFiles} isUploading={true} />
        </FileUploadContext.Provider>
      </StyledFilePreview>
    </Container>
  );
};

const ChooseFilesButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Directions = styled(Text)`
  color: ${({ theme }) => theme.palette.gray300};
  margin-top: ${({ theme }) => theme.spacing.spacingSizes.xs};
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.lg};
`;

const ButtonWrapper = styled.div`
  cursor: pointer;
`;

const Input = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
`;

const StyledFilePreview = styled.div`
  margin: 20px 0;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const ErrorMessages = styled.div`
  color: ${({ theme }) => theme.palette.error};

  & > * + * {
    margin-top: 8px;
  }
`;

export default AttachFiles;
