const appActionTypes = {
  setIsLoadingTo: 'app/setIsLoadingTo',
  setAddPetTo: 'app/setAddPetTo',
  sendReceipt: 'app/sendReceipt',
  resetIsReceiptSent: 'app/resetIsReceiptSent',
  setViewConsultationTo: 'app/setViewConsultationTo',
  createConsultation: 'app/createConsultation',
  updateConsultation: 'app/updateConsultation',
  getIntercomUserHash: 'app/getIntercomUserHash',
  setIsImpersonatingTo: 'app/setIsImpersonatingTo',
};

export default appActionTypes;
