import React, { Fragment } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { pageView } from './utilities/tracking';

import FAQ from './components/FAQ';
import Chat from './components/Consultation/Chat';
import Home from './components/Home/Home';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Deeplink from './components/Deeplink/Deeplink';
import PetsView from './components/PetsView/PetsView';
import VideoCall from './components/Consultation/VideoCall';
import Onboarding from './components/Onboarding/Onboarding';
import PublicRoute from './components/Auth/PublicRoute';
import AccountView from './components/AccountView/AccountView';
import Consultation from './components/Consultation/Consultation';
import ResetPassword from './components/Auth/ResetPassword';
import PromoCodesView from './components/AccountView/PromoCodesView';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import OnboardingRoute from './components/Auth/OnboardingRoute';
import AddPaymentMethod from './components/Common/AddPaymentMethod';
import NotificationsView from './components/Common/Notifications';
import ResetPasswordRequest from './components/Auth/ResetPasswordRequest';
import Curbside from './components/Curbside/CurbsideLayout';
import logout from './redux/auth/actions/logout';
import { useDispatch } from 'react-redux';
import CurbsideState from './components/Curbside/context/curbsideState';
import Forms from './components/Curbside/Forms/Forms';
import ConversationVideoCall from './components/Curbside/utilities/VideoCall';

export const history = createBrowserHistory();

history.listen(location => {
  pageView(location);
});

const Routes = () => {
  return (
    <Fragment>
      <Router history={history}>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/deeplink" component={Deeplink}></Route>
          <Route path="/logout" component={LogoutComponent} />
          <Route path="/conversations/:channelId?" component={Curbside} />
          <Route path="/forms/:formSubmissionId" component={FormsWithState} />
          <PublicRoute
            exact
            path="/reset-password"
            component={ResetPasswordRequest}
          />
          <PublicRoute
            exact
            path="/reset-password/code"
            component={ResetPassword}
          />
          <ProtectedRoute path="/faq" component={FAQ} />

          <OnboardingRoute path="/onboarding" component={Onboarding} />
          <OnboardingRoute path="/onboarding/:from" component={Onboarding} />
          <ProtectedRoute
            path="/payment/add/:from/:petId"
            component={AddPaymentMethod}
          />
          <ProtectedRoute
            path="/payment/add/:from"
            component={AddPaymentMethod}
          />
          <ProtectedRoute
            path="/chat/:consultationId/:clinicId"
            component={Chat}
          />
          <ProtectedRoute path="/video/:consultationId" component={VideoCall} />
          <Route
            path="/conversation/video-call/:channelId?"
            component={ConversationVideoCall}
          />
          <ProtectedRoute
            path="/consultation/:consultationId"
            component={Consultation}
          />
          <ProtectedRoute path="/pets/:petId" component={PetsView} />
          <ProtectedRoute path="/pets" component={PetsView} />
          <ProtectedRoute
            path="/account/promo-codes"
            component={PromoCodesView}
          />
          <ProtectedRoute path="/account" component={AccountView} />
          <ProtectedRoute path="/notifications" component={NotificationsView} />
          <ProtectedRoute path="/" component={Home} />
        </Switch>
      </Router>
    </Fragment>
  );
};

export default Routes;

const LogoutComponent = () => {
  const dispatch = useDispatch();
  dispatch(logout());
  return <Redirect to="/login" />;
};

const FormsWithState = () => {
  return (
    <CurbsideState>
      <Forms />
    </CurbsideState>
  );
};
