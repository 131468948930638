import { gql } from '@apollo/client';

export const GET_CHANNEL_MEMBERS = gql`
  query channel($whereInput: ChannelWhereUniqueInput!) {
    channel(where: $whereInput) {
      assignees {
        id
        firstName
        lastName
        nameDisplay
        type
      }
      channelMembers(where: { user: null }) {
        clinicPetParent {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_PET_PARENT_SETTINGS = gql`
  query user($whereInput: ClinicPetParentWhereUniqueInput!) {
    clinicPetParent(where: $whereInput) {
      petParentSetting {
        id
        emailNotifications
        smsNotifications
      }
    }
  }
`;
