import styled from 'styled-components/macro';
import { Button } from '@televet/televet-ui';
import React, { useEffect } from 'react';

const ConfirmPermission = ({ onAccept }) => {
  const checkPermissions = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(stream => {
        onAccept();
      })
      .catch(error => {
        console.error(error);
      });
  };

  useEffect(checkPermissions, []);

  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        margin: '24px 0',
      }}
    >
      <div style={{ width: 500 }}>
        <div style={{ textAlign: 'left', lineHeight: '1.35rem' }}>
          <p style={{ margin: '12px 24px' }}>
            Your browser's <Bold>camera</Bold> and/or <Bold>microphone</Bold>{' '}
            permissions have denied access for the TeleVet app.
          </p>
          <p style={{ margin: '0 24px 48px' }}>
            Please visit your browser's settings and enable the{' '}
            <Bold>camera</Bold> and <Bold>microphone</Bold> permissions in order
            for TeleVet to continue with your video consultation.
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              style={{ marginTop: 25 }}
              onClick={onAccept}
              buttonType="secondary"
              id="acknowledge-enable-permissions-button"
            >
              Okay
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Bold = styled.strong`
  font-weight: bold;
`;

export default ConfirmPermission;
