import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { TextInput, Select, BounceLoader } from '@televet/televet-ui';

import { H1 } from '../../Common/Headers';
import Text from '../../theme/Text';
import options from '../../../constants/petSelectOptions';
import Message from '../../Common/Message';
import { number } from '../../../utilities/validation';
import { monthOptions } from '../../../constants/monthOptions';
import ResponsiveButton from '../../Common/ResponsiveButton';
import { fertilityOptions } from '../../../constants/fertilityOptions';
import { Mixpanel as mixpanel } from '../../../redux/mixpanel';
import { getDayOptions, getYearOptions } from '../../../utilities';

const PetInfoForm = ({
  name,
  type,
  gender,
  reproductiveStatus,
  environment,
  breed,
  weight,
  month,
  day,
  year,
  onSubmit,
  isLoading,
  isOnboarding,
}) => {
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      name,
      type,
      gender,
      reproductiveStatus,
      environment,
      breed,
      weight,
      month,
      day,
      year,
    },
    mode: 'onBlur',
  });

  const genderWatch = watch('gender');
  const reproductiveStatusWatch = watch('reproductiveStatus');
  const environmentWatch = watch('environment');
  const yearWatch = watch('year');
  const monthWatch = watch('month');
  const dayWatch = watch('day');

  useEffect(() => {
    if (reproductiveStatusWatch) {
      mixpanel.track('Reproductive status selected', {
        reproductiveStatus: reproductiveStatusWatch,
        isOnboarding,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reproductiveStatusWatch]);

  useEffect(() => {
    if (dayWatch) {
      mixpanel.track('Pet birth day entered', {
        birthDay: dayWatch,
        isOnboarding,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayWatch]);

  useEffect(() => {
    if (monthWatch) {
      mixpanel.track('Pet birth month entered', {
        birthMonth: monthWatch,
        isOnboarding,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthWatch]);

  useEffect(() => {
    if (yearWatch) {
      mixpanel.track('Pet birth year entered', {
        birthYear: yearWatch,
        isOnboarding,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearWatch]);

  useEffect(() => {
    if (environmentWatch) {
      mixpanel.track('Environment selected', {
        environment: environmentWatch,
        isOnboarding,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentWatch]);

  useEffect(() => {
    if (genderWatch) {
      mixpanel.track('Gender selected', { gender: genderWatch, isOnboarding });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genderWatch]);

  const genderFertilityOptions =
    genderWatch === 'Male' || genderWatch === 'Female'
      ? fertilityOptions[genderWatch]
      : fertilityOptions['Default'];

  const environmentOptions =
    type === 'Horse' ? options.environment.Horse : options.environment.default;

  return (
    <>
      <H1>Now let's add some basic information about {name}</H1>
      <Message>Just fill out what you know and skip the rest.</Message>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Select
          name="gender"
          label="Select Gender"
          options={options.gender[type] || options.gender.default}
          register={register}
        />
        <Select
          name="reproductiveStatus"
          label="Reproductive Status"
          options={genderFertilityOptions}
          register={register}
        />
        <Select
          name="environment"
          label="Environment"
          options={environmentOptions}
          register={register}
        />
        <TextInput
          name="breed"
          label={`What breed is ${name}?`}
          register={register}
        />
        <TextInput
          name="weight"
          label={`How much does ${name} weigh? (Pounds)`}
          register={register}
          validation={{ pattern: number }}
          error={errors.weight && 'Please enter a weight in pounds'}
        />
        <Select
          name="year"
          label="Pet's birth year"
          options={getYearOptions()}
          register={register}
        />
        <Select
          name="month"
          label="Pet's birth month"
          options={monthOptions}
          register={register}
        />
        <Select
          name="day"
          label="Pet's birth day"
          options={getDayOptions(
            monthOptions.findIndex(i => i.value === watch('month')),
            watch('year')
          )}
          register={register}
        />
        {isLoading ? (
          <CenterDiv>
            <Text>Adding {name} to your account...</Text>
            <BounceLoader loading={isLoading} />
          </CenterDiv>
        ) : (
          <StyledResponsiveButton fullwidth>Finish</StyledResponsiveButton>
        )}
      </form>
    </>
  );
};

const CenterDiv = styled.div`
  margin: 20px 0;
  text-align: center;
`;

const StyledResponsiveButton = styled(ResponsiveButton)`
  background: ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.white};
  width: 100% !important;
  border: 1px solid transparent;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background: ${({ theme }) => theme.palette.actionButtonHover} !important;
    border-color: ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

PetInfoForm.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  reproductiveStatus: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  breed: PropTypes.string.isRequired,
  weight: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PetInfoForm;
