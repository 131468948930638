import React from 'react';
import styled from 'styled-components/macro';
import Tippy from '@tippyjs/react';

const Tooltip = ({ content, children, ...rest }) => {
  if (!!content) {
    return (
      <StyledTippy
        animation={false}
        content={content}
        hideOnClick={true}
        touch={['hold', 1000]}
        {...rest}
      >
        {children}
      </StyledTippy>
    );
  }
  return null;
};

const DARK_BLUE = '#050f5f';

const StyledTippy = styled(Tippy)`
  background: ${DARK_BLUE};
  padding: 8px;
  border-radius: 6px;
  font-size: 14px;

  &.tippy-box[data-placement='top'] > .tippy-arrow::before {
    border-top-color: ${DARK_BLUE};
  }
  &.tippy-box[data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: ${DARK_BLUE};
  }
  &.tippy-box[data-placement^='left'] > .tippy-arrow::before {
    border-left-color: ${DARK_BLUE};
  }
  &.tippy-box[data-placement^='right'] > .tippy-arrow::before {
    border-right-color: ${DARK_BLUE};
  }

  & div,
  & div:visited {
    color: #fff;
    font-weight: 400;
    transition: color 0.1s ease-in-out;

    &:hover {
      color: #39adc3;
    }
  }
`;

export default Tooltip;
