import styled from 'styled-components/macro';

const SnackbarCloseButton = styled.button`
  margin: 0;
  padding: 0;
  outline: none;
  border: none;

  background-color: inherit;
  color: inherit;
  font-family: inherit;
  font-size: 2em;
  margin-left: auto;
  font-weight: bold;
  cursor: pointer;
`;

export default SnackbarCloseButton;
