import moment from 'moment';
import firebase from '../../../firebase/firebase';
import { Mixpanel as mixpanel } from '../../mixpanel';

const db = firebase.database();

const login = (email, password) => {
  return async () => {
    try {
      const auth = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

      const userSnapshot = await db.ref(`users/${auth.user.uid}`).once('value');
      const user = userSnapshot.val();

      mixpanel.people.set({
        $email: email,
        USER_ID: auth.user.uid,
        IS_DEVELOPMENT:
          process.env.REACT_APP_PRODUCTION === 'true' ? false : true,
        'Sign up date': moment(user.signUpDate).format('LLLL'),
        application: 'Pet Web',
      });

      mixpanel.identify(auth.user.uid);
      mixpanel.track('Clicked login button', { success: true });

      return user;
    } catch (e) {
      mixpanel.track('Clicked login button', { success: false });
      throw new Error(e.message);
    }
  };
};

export default login;
