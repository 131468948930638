import getVet from '../../vets/actions/getVet';
import firebase from '../../../firebase/firebase';
import setIsLoadingTo from '../../app/actions/setIsLoadingTo';
import userActionTypes from '../userActionTypes';
import petsActionTypes from '../../pets/petsActionTypes';
import chatsActionTypes from '../../chats/chatsActionTypes';
import clinicsActionTypes from '../../clinics/clinicsActionTypes';
import consultationsActionTypes from '../../consultations/consultationsActionTypes';
import { pushSnackbar } from '../../snackbar/snackbarActions';

let db = firebase.database();
let firebaseUsers = db.ref('users');

const getUser = id => {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoadingTo(true));
      const uid = id || getState().auth.user.uid;
      const userSnapshot = await firebaseUsers.child(uid).once('value');

      // Subscribing to user model
      firebaseUsers.child(uid).on('value', userSnapshot => {
        dispatch({
          type: userActionTypes.getUser,
          payload: userSnapshot.val(),
        });
      });

      const user = userSnapshot.val();
      dispatch({
        type: userActionTypes.getUser,
        payload: user,
      });

      if (user.info.clinics) {
        const clinicIds = Object.keys(user.info.clinics);
        for (const clinicId of clinicIds) {
          if (user.info.clinics[clinicId]) {
            const clinicSnapshot = await firebase
              .database()
              .ref(`clinics/${clinicId}`)
              .once('value');
            dispatch({
              type: clinicsActionTypes.addClinic,
              payload: { clinicId, clinicObject: clinicSnapshot.val() },
            });
          }
        }
      }

      const promos = user.info.promos;

      if (promos) {
        const promosArray = Object.keys(promos);
        for (const promo of promosArray) {
          const promoSnapshot = await firebase
            .database()
            .ref(`promo/${promo}`)
            .once('value');
          dispatch({
            type: userActionTypes.getPromoCodeExpiration,
            payload: { id: promo, exp: promoSnapshot.val().exp },
          });
        }
      }

      const pets = user.info.pets;
      if (pets) {
        const petIds = Object.keys(pets);
        for (const petId of petIds) {
          if (user.info.pets[petId]) {
            const petSnapshot = await firebase
              .database()
              .ref(`pets/${petId}`)
              .once('value');

            const pet = petSnapshot.val();
            dispatch({
              type: petsActionTypes.addPet,
              payload: { petId, petObject: pet },
            });

            if (pet.consults) {
              const consultIds = Object.keys(pet.consults);
              for (const consultId of consultIds) {
                firebase
                  .database()
                  .ref(`consults/${consultId}`)
                  .on('value', consultSnapshot => {
                    const consult = consultSnapshot.val();
                    dispatch({
                      type: consultationsActionTypes.addConsultation,
                      payload: { consultId, consultObj: consult },
                    });

                    const { vet, nurse } = consult;
                    if (vet) {
                      dispatch(getVet(vet));
                    }
                    if (nurse) {
                      dispatch(getVet(nurse));
                    }
                  });

                firebase
                  .database()
                  .ref(`chats/${consultId}`)
                  .on('value', chatSnapshot => {
                    dispatch({
                      type: chatsActionTypes.addChat,
                      payload: { consultId, chatObj: chatSnapshot.val() },
                    });
                  });
              }
            }
          }
        }
      }
      return user;
    } catch (e) {
      dispatch(
        pushSnackbar({
          type: 'error',
          message: e.message,
        })
      );
    } finally {
      dispatch(setIsLoadingTo(false));
    }
  };
};

export default getUser;
