import debounceAction from 'debounce-action';

export const PUSH_SNACKBAR = 'PUSH_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const RESET_SNACKBAR_STATE = 'RESET_SNACKBAR_STATE';

/**
 * Add a message to the snackbar
 * @param {object} payload - Payload to add a message to the snackbar
 * @param {string} payload.type - Type of snackbar (info, success, error)
 * @param {message} payload.message - The message for the snackbar
 * @param {number} payload.timeoutMs - Time in milliseconds to automatically clear the message
 */
export const pushSnackbar = debounceAction(payload => {
  return {
    type: PUSH_SNACKBAR,
    payload,
  };
}, 350);

/**
 * Remove a message from the snackbar by id
 * @param {number} id
 */
export const removeSnackbar = id => {
  return {
    type: REMOVE_SNACKBAR,
    id,
  };
};

/**
 * Reset the snackbar state. Clear all snackbars
 */
export const resetSnackbarState = () => {
  return {
    type: RESET_SNACKBAR_STATE,
  };
};
