import React from 'react';
import styled from 'styled-components/macro';
import { keyframes } from 'styled-components';

const LoadingSpinner = () => {
  return (
    <LoadingSpinnerContainer>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 1.44678C13.1421 1.44678 16.5 4.80464 16.5 8.94678C16.5 13.0889 13.1421 16.4468 9 16.4468C4.85786 16.4468 1.5 13.0889 1.5 8.94678"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </LoadingSpinnerContainer>
  );
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & svg {
    animation: ${rotate} 1s linear infinite;
    width: 26px;
    height: 26px;
  }

  & svg * {
    stroke: #39adc3;
  }
`;

export default LoadingSpinner;
