import React, { ReactElement } from 'react';

import styled from 'styled-components/macro';

interface Props {
  prompt: string;
  inline?: boolean;
  isRequired: boolean;
}

function Prompt({ prompt, inline, isRequired }: Props): ReactElement {
  return (
    <StyledPrompt inline={inline}>
      {prompt}
      {isRequired ? <RequiredMarker>*</RequiredMarker> : null}
    </StyledPrompt>
  );
}

export default Prompt;

const StyledPrompt = styled.p<any>`
  font-family: LinearSans;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #73747d;

  display: ${props => (props.inline ? 'inline' : 'block')};
`;

const RequiredMarker = styled.span`
  width: 7px;
  height: 7px;
  color: #da4167;
`;
