import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import PetAvatar from '../Common/PetAvatar';
import { ResponsiveDeviceMaxWidth } from '@televet/televet-ui';

const PetsList = ({ pets, pushPetPage }) => {
  const { petId: currentPetId, consultationId } = useParams();
  const currentConsultation = useSelector(
    state => state.consultations[consultationId]
  );
  return (
    <PetsContainer>
      {Object.entries(pets).map(([petId, pet]) => {
        const isActivePet =
          petId === currentPetId || currentConsultation?.pet === petId;
        return (
          <PetContainer onClick={() => pushPetPage(petId)} key={petId}>
            {pet && (
              <PetButton isActive={isActivePet}>
                <PetAvatar pet={pet.public} />
                <StyledText>{pet.public.name}</StyledText>
              </PetButton>
            )}
          </PetContainer>
        );
      })}
    </PetsContainer>
  );
};

const PetsContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  max-height: 260px;
  &::-webkit-scrollbar {
    height: 0;
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    margin: 5px 0;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.35);
  }
`;

const PetContainer = styled.div`
  text-align: center;
`;

const StyledText = styled.p`
  padding: ${({ theme }) => theme.spacing.spacingSizes.xs} 0;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.font.fontFamilies.body};
`;

const PetButton = styled.div`
  display: flex;
  align-items: center;
  background: ${({ isActive }) =>
    isActive ? 'rgba(0, 0, 0, 0.15)' : 'transparent'};
  border-radius: ${({ theme }) => theme.spacing.spacingSizes.sm};
  margin: 0 10% 4%;
  text-align: left;
  padding: ${({ theme }) =>
    `${theme.spacing.spacingSizes.sm} ${theme.spacing.spacingSizes.lg}`};
  padding-left: ${({ theme }) => theme.spacing.spacingSizes.lg};

  &:hover {
    background: rgba(255, 255, 255, 0.04);
  }

  @media ${ResponsiveDeviceMaxWidth.tablet} {
    display: none;
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: ${({ theme }) => theme.spacing.spacingSizes.sm};
  }

  :hover {
    cursor: pointer;
  }
`;

export default PetsList;
