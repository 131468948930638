import firebase from '../../../firebase/firebase';
import { numberFormat, getMonth } from '../../../utilities';
import getUser from '../../user/actions/getUser';
import { saveClinicInUser } from '../../pets/actions/savePetToDatabase';

const editPet = ({
  petId,
  type,
  name,
  gender,
  reproductiveStatus,
  environment,
  breed,
  weight,
  month,
  day,
  year,
  profilePic,
  clinicId,
  clinicDisplay,
}) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.user.uid;

    let clinicData = {};
    if (clinicId) {
      clinicData = {
        clinicDisplay,
        clinicId,
        clinics: {
          [clinicId]: true,
        },
      };
    }

    const data = {
      ...clinicData,
      public: {
        DOB: {
          day: day || null,
          month: getMonth(month) || null,
          year: year || null,
        },
        breed: breed || null,
        env: environment || null,
        name,
        profilePic: profilePic || '',
        owner: userId,
        repoStatus: reproductiveStatus || null,
        sex: gender || null,
        type: type || null,
        weight: weight ? numberFormat(weight) : null,
      },
    };

    await firebase
      .database()
      .ref(`pets/${petId}`)
      .update(data);

    if (clinicId) {
      saveClinicInUser(userId, clinicId, dispatch);
    }

    dispatch(getUser());
  };
};

export default editPet;
