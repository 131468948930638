import axios from 'axios';
import getUser from '../../user/actions/getUser';
import firebase from '../../../firebase/firebase';
import getClientToken from './getClientToken';
import getCustomerToken from './getCustomerToken';
import { pushSnackbar } from '../../snackbar/snackbarActions';
import paymentActionTypes from '../paymentActionTypes';
import { Mixpanel as mixpanel } from '../../mixpanel';

const addPaymentMethod = nonce => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const userUid = state.auth.user.uid;
      const { user } = state;
      const customerId = user.info.private.customerId;
      const restURL = state.variable.restURL;

      let data = customerId
        ? {
            nonce,
            customerId,
          }
        : {
            firstName: user.info.public.fname,
            lastName: user.info.public.lname,
            email: user.info.private.email,
            nonce,
          };
      const response = await axios.post(
        `${restURL}/payment/add_payment_method`,
        data
      );

      if (response.data.customerId) {
        await firebase
          .database()
          .ref(`users/${userUid}/info/private`)
          .update({ customerId: response.data.customerId });

        dispatch({
          type: paymentActionTypes.setPaymentAdded,
          payload: true,
        });

        const [user] = await Promise.all([
          dispatch(getUser()),
          getCustomerToken(response.data.customerId),
          getClientToken(response.data.customerId),
        ]);
        mixpanel.track('Add payment method');
        return user;
      }
    } catch (e) {
      sendError(dispatch, e, 'An error occurred: ');
    }
  };
};

const sendError = (dispatch, error, title) => {
  console.error(title, error);
  dispatch(pushSnackbar({ type: 'error', message: title + error.message }));
};

export default addPaymentMethod;
