import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components/macro';

const Link = styled(RouterLink)`
  color: ${({ theme }) => {
    return theme.palette.primary;
  }};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.error {
    color: ${({ theme }) => {
      return theme.palette.error;
    }};
  }
`;

export default Link;
