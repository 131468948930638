import axios from 'axios';
import { store } from '../../store';
import appActionTypes from '../appActionTypes';

import setIsLoadingTo from './setIsLoadingTo';

const sendReceipt = consultationId => {
  return async dispatch => {
    const state = store.getState();
    const graphQLurl = state.variable.graphQLurl;
    const token = await state.auth.user.getIdToken(/* forceRefresh */ true);

    dispatch(setIsLoadingTo(true));

    axios
      .post(
        graphQLurl,
        {
          query: `query ($data: SendPetParentReceiptInput!) {sendPetParentReceipt(data: $data)}`,
          variables: {
            data: {
              consultationId: '',
              firebaseId: consultationId,
            },
          },
        },

        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(response => {
        // Expected response
        // {
        //     "data": {
        //         "sendPetParentReceipt": {
        //             "sentReceipt": true
        //         }
        //     }
        // }
        const data = response.data.data.sendPetParentReceipt;

        dispatch({ type: appActionTypes.sendReceipt, payload: data });
        dispatch(setIsLoadingTo(false));
      })
      .catch(error => {
        // Expected response
        // {
        //     "data": {
        //         "sendPetParentReceipt": {
        //             "sentReceipt": false,
        //             "error": "blah blah string"
        //         }
        //     }
        // }
        console.error('Error sending receipt', error);
        dispatch(setIsLoadingTo(false));
      });
  };
};

export default sendReceipt;
