import firebase from '../../../firebase/firebase';
import { store } from '../../store';
import petsActionTypes from '../petsActionTypes';

const removePet = petId => {
  return async dispatch => {
    const uid = store.getState().auth.user.uid;
    let petUpdate = {};
    petUpdate[petId] = false;
    await firebase
      .database()
      .ref(`users/${uid}/info/pets`)
      .update(petUpdate);
    dispatch({ type: petsActionTypes.removePet, payload: petId });
  };
};

export default removePet;
