import React from 'react';
import styled from 'styled-components/macro';
import { DeviceSizes, Button } from '@televet/televet-ui';
import { useSelector } from 'react-redux';

import Flex from '../theme/Flex';
import Avatar from '../Common/Avatar';

import { formatPhoneNumber, capitalizeString } from '../../utilities';

const User = ({ onEditUser }) => {
  const { user } = useSelector(state => state);
  const { email, phone } = user.info.private;
  const { fname, lname, profilePic, tz } = user.info.public;
  const userName = `${capitalizeString(fname)} ${capitalizeString(lname)}`;

  return (
    <>
      <Flex style={{ justifyContent: 'center' }}>
        <UserInfo>
          <Row>
            <UserName style={{ fontWeight: 500 }}>{userName}</UserName>
          </Row>
          <UserAvatar>
            <Avatar url={profilePic} borderRadius="5%" />
          </UserAvatar>
          <Row>
            <UserInfoText>{email}</UserInfoText>
          </Row>
          <Row>
            <UserInfoText>{formatPhoneNumber(phone)}</UserInfoText>
          </Row>
          <Row>
            <UserInfoText>{`${tz ? tz : 'Auto'} Timezone`}</UserInfoText>
          </Row>
          <Row>
            <EditButton name="edit-user-button" onClick={onEditUser}>
              Edit Profile
            </EditButton>
          </Row>
        </UserInfo>
        <Row></Row>
      </Flex>
      <DividerSection>
        <Divider />
      </DividerSection>
    </>
  );
};

const Divider = styled.hr`
  border-top: 1px solid rgba(26, 26, 26, 0.1);
`;

const DividerSection = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.spacingSizes.sm};
  @media (min-width: ${DeviceSizes.laptop}) {
    display: none;
  }
`;

const UserInfo = styled.div`
  padding: 24px 12px 12px;
  @media (max-width: 960px) {
    padding: 24px 24px 12px;
  }
  @media (max-width: ${DeviceSizes.tablet}) {
    width: 100%;
    p {
      font-size: 12px;
    }
  }
`;

const UserName = styled.span`
  font-size: 16px;
`;

const UserInfoText = styled.p`
  font-size: 14px;
  padding-bottom: 12px;
`;

const Row = styled(Flex)`
  width: 100%;
  color: ${({ theme }) => theme.palette.black};
`;

const UserAvatar = styled(Flex)`
  margin: ${({ theme }) => theme.spacing.spacingSizes.lg} 0;
  @media (max-width: ${DeviceSizes.mobileS}) {
    max-height: 74px;
    max-width: 74px;
  }
  @media (max-width: ${DeviceSizes.tablet}) {
  }
`;

const EditButton = styled(Button)`
  margin-top: 6px;
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};
  background: ${({ theme }) => theme.palette.white};

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

export default User;
