import { gql } from '@apollo/client';

export const CREATE_TWILIO_TOKEN = gql`
  mutation($identity: String!) {
    createTwilioToken(data: { identity: $identity }) {
      token
    }
  }
`;

export const CONFIRM_APPOINTMENT = gql`
  mutation($where: IdInput) {
    confirmAppointment(where: $where) {
      id
      status
    }
  }
`;

export const RESCHEDULE_APPOINTMENT = gql`
  mutation(
    $where: AppointmentWhereUniqueInput!
    $data: AppointmentUpdateInput!
  ) {
    updateAppointment(data: $data, where: $where) {
      id
      status
    }
  }
`;

export const GET_CHANNELS = gql`
  query getChannelsAndAppointments($clinicPetParentId: ID!) {
    channels(
      orderBy: createdAt_DESC
      where: {
        channelMembers_some: { clinicPetParent: { id: $clinicPetParentId } }
        isActive: true
      }
    ) {
      id
      channelMembers {
        id
        clinicPetParent {
          firstName
          lastName
          id
        }
        user {
          id
          firstName
          lastName
          profilePic
          vetInfo {
            roles {
              role
            }
          }
          type
        }
      }
      channelStatus {
        id
        channelStatusAction
      }
      twilioChannelSid
      clinic {
        name
        fullAddress
        logo
        timezone
        timezoneName
        id
      }
    }
  }
`;

export const GET_FORM_SUBMISSION_NAMES = gql`
  query getFormSubmissions($where: FormSubmissionWhereInput) {
    formSubmissions(where: $where) {
      id
      submittedAt
      formTemplate {
        title
      }
    }
  }
`;

export const GET_CLINIC_PET_PARENT = gql`
  query getClinicPetParent($where: ClinicPetParentWhereUniqueInput!) {
    clinicPetParent(where: $where) {
      id
      lastName
      firstName
      email
      pets {
        id
        species
        name
        gender
      }
      clinic {
        id
        name
        timezone
        logo
      }
    }
  }
`;

export const GET_FORM_SUBMISSION = gql`
  query getFormSubmission($where: FormSubmissionWhereUniqueInput!) {
    formSubmission(where: $where) {
      id
      createdAt
      updatedAt
      submittedAt
      formTemplate {
        id
        createdAt
        updatedAt
        title

        content
        draftContent
        isDeleted
        isActive
      }
      clinic {
        id
        name
      }
      clinicPet {
        name
      }
      clinicPetParent {
        firstName
        lastName
        id
      }

      completedContent
      draftContent
      appointment {
        id
      }
    }
  }
`;

export const UPDATE_FORM_SUBMISSION = gql`
  mutation(
    $data: FormSubmissionUpdateInput!
    $where: FormSubmissionWhereUniqueInput!
  ) {
    updateFormSubmission(data: $data, where: $where) {
      draftContent
      id
    }
  }
`;

export const GET_APPOINTMENT_INFO = gql`
  query getAppointmentInfo($appointmentId: ID!) {
    appointment(where: { id: $appointmentId }) {
      status
      title
      startAt
      id
      appointmentType {
        name
      }
      clinicPet {
        name
      }
      description
      clinic {
        timezone
        timezoneName
      }
    }
  }
`;

export const GET_STRIPE_PAYMENT_INTENT = gql`
  query getStripePaymentIntent($where: StripePaymentIntentWhereUniqueInput!) {
    stripePaymentIntent(where: $where) {
      id
      createdAt
      updatedAt
      stripeId
      status
      amount
      amountCaptured
      intent {
        paymentIntentClientSecret
        requiresAction
      }
    }
  }
`;

export const GET_CLINIC_WIDGET_REQUEST = gql`
  query getClinicWidgetRequest($where: ClinicWidgetRequestWhereUniqueInput!) {
    clinicWidgetRequest(where: $where) {
      id
      isVerified
      clinicWidgetRequestType {
        displayName
        requestType
      }
      requestFormSubmission
    }
  }
`;
