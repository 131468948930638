import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ModalHeader from './ModalHeader';
import { Avatar, AvatarSize } from '../../Avatar';

const ClinicAssignees = ({ clinicAssignees, onDone, onBack }) => {
  return (
    <ClinicAssigneesContainer>
      <ModalHeader onBack={onBack} title="Clinic Members" onDone={onDone} />
      <ClinicAssigneesList>
        {clinicAssignees.map(assignee => {
          return (
            <ClinicAssigneesListItem key={assignee.id}>
              <AvatarContainer>
                <Avatar
                  firstName={assignee.firstName}
                  lastName={assignee.lastName}
                  nameDisplay={assignee.nameDisplay}
                  size={AvatarSize.XLarge}
                />
              </AvatarContainer>
              <Title>
                {assignee.nameDisplay ||
                  `${assignee.firstName} ${assignee.lastName}`}
              </Title>
            </ClinicAssigneesListItem>
          );
        })}
      </ClinicAssigneesList>
    </ClinicAssigneesContainer>
  );
};

export default ClinicAssignees;

ClinicAssignees.propTypes = {
  onDone: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  clinicAssignees: PropTypes.array.isRequired,
};

const ClinicAssigneesContainer = styled.div``;

const ClinicAssigneesList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ClinicAssigneesListItem = styled.li`
  align-items: center;
  border-bottom: solid 1px #e8e9e9;
  cursor: pointer;
  display: flex;
  padding: 20px 0 20px 0;
`;

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h2`
  color: #575d7c;
  font-size: 18px;
  margin-left: 10px;
`;
