import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ModalHeader from './ModalHeader';

const NotificationSettings = ({
  petParentSetting,
  updateSettings,
  onBack,
  onDone,
}) => {
  const [settings, setSettings] = useState({
    smsNotifications: false,
    emailNotifications: false,
  });

  useEffect(() => {
    if (petParentSetting) {
      setSettings({
        smsNotifications: petParentSetting.smsNotifications,
        emailNotifications: petParentSetting.emailNotifications,
      });
    }
  }, [petParentSetting, setSettings]);

  const onChange = e => {
    updateSettings({
      variables: {
        data: {
          [e.target.name]: !settings[e.target.name],
        },
        where: {
          id: petParentSetting.id,
        },
      },
    });
    setSettings({
      ...settings,
      [e.target.name]: !settings[e.target.name],
    });
  };

  return (
    <NotificationSettingsContainer>
      <ModalHeader onBack={onBack} title="Your Notifications" onDone={onDone} />
      <NotificationSettingsList>
        <NotificationSettingsListItem>
          <TitleContainer>
            <Title>Email</Title>
          </TitleContainer>
          <CheckboxContainer>
            <CheckboxContent>
              <Checkbox
                name="emailNotifications"
                type="checkbox"
                onChange={onChange}
                checked={settings['emailNotifications']}
              />
            </CheckboxContent>
          </CheckboxContainer>
        </NotificationSettingsListItem>

        <NotificationSettingsListItem>
          <TitleContainer>
            <Title>SMS</Title>
          </TitleContainer>
          <CheckboxContainer>
            <CheckboxContent>
              <Checkbox
                name="smsNotifications"
                type="checkbox"
                onChange={onChange}
                checked={settings['smsNotifications']}
              />
            </CheckboxContent>
          </CheckboxContainer>
        </NotificationSettingsListItem>
      </NotificationSettingsList>
    </NotificationSettingsContainer>
  );
};

NotificationSettings.propTypes = {
  onDone: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
  petParentSetting: PropTypes.object,
};

export default NotificationSettings;

const NotificationSettingsContainer = styled.div``;

const NotificationSettingsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NotificationSettingsListItem = styled.li`
  align-items: center;
  border-bottom: solid 1px #e8e9e9;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px 0;
`;

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  width: 75%;
`;

const Title = styled.span`
  color: #73747d;
  font-size: 18px;
  margin-left: 10px;
`;

const CheckboxContainer = styled.div`
  text-align: right;
  width: 25%;
`;

const CheckboxContent = styled.div`
  display: inline-block;
  margin-right: 10px;
`;

const Checkbox = styled.input`
  position: relative;
  top: 2px;
`;
