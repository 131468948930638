import firebase from '../../../firebase/firebase';
import getUser from './getUser';

export const updatePromoCodeInUser = (userId, selectedPromoCode, dispatch) => {
  if (!selectedPromoCode) return;
  const codeId = selectedPromoCode.id;
  const newCode = {
    added: selectedPromoCode.added,
    clinicId: selectedPromoCode.clinicId,
    clinicDisplay: selectedPromoCode.clinicDisplay,
    code: selectedPromoCode.code,
    discount: selectedPromoCode.discount,
    available: selectedPromoCode.available,
    used: selectedPromoCode.used + 1,
  };

  // update promo code in user (Database)
  return firebase
    .database()
    .ref(`users/${userId}/info/promos/${codeId}`)
    .update(newCode)
    .then(snapshot => {
      // update user (Redux Store)
      dispatch(getUser());
    });
};

export default updatePromoCodeInUser;
