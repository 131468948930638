import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Text from '../theme/Text';
import ActionButton from '../Common/ActionButton';
import getClientToken from '../../redux/payment/actions/getClientToken';
import getCustomerToken from '../../redux/payment/actions/getCustomerToken';
import { getTimeAgo } from '../../utilities';

const New = props => {
  const { consultation, setSelectedConsultation } = props;
  const { state, transactionId } = consultation;
  const [timeAgo, setTimeAgo] = useState(getTimeAgo(consultation?.createdAt));

  useEffect(() => {
    //create timer on component mount

    const timer = setInterval(() => {
      setTimeAgo(getTimeAgo(consultation?.createdAt));
    }, 1000 * 60);

    // returned function will be called on component unmount
    return () => {
      clearInterval(timer);
    };
  }, [consultation]);

  return (
    <div style={{ width: '100%' }}>
      <div>
        <div>
          {state === 'NEW' && !transactionId && (
            <div>
              <PaymentText>
                Please add a payment method to continue with this consultation.
              </PaymentText>
              <ActionButton
                onClick={() => setSelectedConsultation(consultation)}
              >
                Confirm Consultation
              </ActionButton>
            </div>
          )}
          <InfoRow>
            <Text style={{ width: '80%', fontSize: 18, margin: '6px 0' }}>
              You created a new consultation
            </Text>
            <Text style={{ margin: '6px 0 12px', fontSize: 12 }}>
              {timeAgo}
            </Text>
          </InfoRow>
        </div>
      </div>
    </div>
  );
};

const InfoRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  p {
    color: rgba(16, 22, 38, 0.7);
  }
`;

const mapStateToProps = state => ({
  clinics: state.clinics,
  state: state,
  consultations: state,
});

const PaymentText = styled.p`
  padding: 30px 0 20px;
  color: maroon;
`;

const mapDispatchToProps = {
  getCustomerToken,
  getClientToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(New));
