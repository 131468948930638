import produce from 'immer';
import notificationActionTypes from './notificationActionTypes';

const initialState = {
  notificationList: {},
};

let userReducer = produce((draft, action) => {
  switch (action.type) {
    case notificationActionTypes.markAsRead:
    case notificationActionTypes.markAllAsReceived:
    case notificationActionTypes.markAllAsRead:
    case notificationActionTypes.setNotificationList:
      draft.notificationList = action.payload;
      break;

    default:
      break;
  }
}, initialState);

export default userReducer;
