import React, { useState, useReducer, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { capitalizeString, getDOBFromDate } from '../../utilities';
import reducer, { initialState } from '../AddPet/reducers';
import {
  onPetNameSubmit,
  onPetPracticeSubmit,
  onPetPhotoSubmit,
  onPetSetState,
} from '../AddPet/actions';
import savePetToDatabase from '../../redux/pets/actions/savePetToDatabase';
import linkConsultationToPet from '../../redux/consultations/actions/linkConsultationToPet';
import { DeeplinkTypes } from '../Deeplink/Deeplink';
import CenteredBanner from '../Common/Layouts/CenteredBanner';
import PetNameForm from '../AddPet/components/PetNameForm';
import PetPracticeForm from '../AddPet/components/PetPracticeForm';
import PetPhotoForm from '../AddPet/components/PetPhotoForm';
import PetInfoForm from '../AddPet/components/PetInfoForm';
import { Mixpanel as mixpanel } from '../../redux/mixpanel';

export const AVAILABLE_STEPS = {
  NAME: 'name',
  PRACTICE: 'practice',
  PHOTO: 'photo',
  INFO: 'info',
};

const steps = [
  AVAILABLE_STEPS.NAME,
  AVAILABLE_STEPS.PRACTICE,
  AVAILABLE_STEPS.PHOTO,
  AVAILABLE_STEPS.INFO,
];

const Onboarding = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  const [stepIndex, setStepIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [deeplinkData, setDeeplinkData] = useState();
  const [isClinicPreselected, setIsClinicPreselected] = useState(false);
  const userId = useSelector(state => state.auth.user.uid);
  const history = useHistory();

  const onBack =
    stepIndex !== 0 ? () => setStepIndex(state => state - 1) : undefined;
  const onNext = () => setStepIndex(state => state + 1);

  const handlePetNameSubmit = values => {
    dispatch(onPetNameSubmit(values));
    isClinicPreselected ? setStepIndex(2) : onNext();
  };

  const handlePetPracticeSubmit = values => {
    dispatch(onPetPracticeSubmit(values));
    onNext();
  };

  const handlePetPhotoSubmit = () => {
    onNext();
  };

  const handlePetPhotoSave = values => {
    dispatch(onPetPhotoSubmit(values));
  };

  const handlePetInfoSubmit = useCallback(
    async (values = {}) => {
      setIsLoading(true);

      mixpanel.track('User clicked finish adding pet', { isOnboarding: true });
      const petId = await reduxDispatch(
        savePetToDatabase({
          ...state,
          ...values,
        })
      );
      const { breed, weight } = values;
      if (breed)
        mixpanel.track('Breed entered', {
          breed,
          isOnboarding: true,
        });
      if (weight)
        mixpanel.track('Weight entered', {
          weight,
          isOnboarding: true,
        });

      if (deeplinkData?.consultId) {
        await reduxDispatch(
          linkConsultationToPet({
            consultationId: deeplinkData.consultId,
            petId,
            userId,
          })
        );
        setIsLoading(false);
        history.push(`/consultation/${deeplinkData.consultId}`);
      } else {
        setIsLoading(false);
        history.push(`/pets/${petId}`);
      }
    },
    [deeplinkData, history, reduxDispatch, state, userId]
  );

  useEffect(() => {
    const deeplink = history.location.state?.deeplink;
    setDeeplinkData(deeplink);

    if (deeplink?.type === DeeplinkTypes.FOLLOWUP) {
      const { patientName, patientSpecies, clinicId, clinicName } = deeplink;
      dispatch(
        onPetSetState({
          name: patientName,
          type: patientSpecies,
          practiceId: clinicId,
          practiceName: clinicName,
        })
      );
      setStepIndex(2);
    } else if (deeplink?.type === DeeplinkTypes.CLINIC_ONLY_INVITE_LINK) {
      dispatch(
        onPetSetState({
          practiceId: deeplink.clinicId,
          practiceName: deeplink.clinicName || null,
        })
      );
      setIsClinicPreselected(true);
    } else if (
      deeplink?.type === DeeplinkTypes.FULL_REGISTRATION_WITH_FOLLOW_UP_LINK
    ) {
      const { clinicId, clinicName, patients } = deeplink;
      const {
        name,
        type,
        breed,
        sex,
        bornOn,
        weight,
        repoStatus,
        env,
        pimsId,
      } = patients[0]; // Registration deeplinks only support a single patient for now

      let animalType = '';
      if (type === 'Dog' || type.toLowerCase().includes('canine'))
        animalType = 'Dog';
      else if (type === 'Cat' || type.toLowerCase().includes('feline'))
        animalType = 'Cat';
      else animalType = 'Other';

      const { month, day, year } = getDOBFromDate(new Date(bornOn));

      dispatch(
        onPetSetState({
          name,
          practiceId: clinicId,
          practiceName: clinicName,
          type: animalType,
          breed,
          gender: sex,
          reproductiveStatus: repoStatus,
          environment: env,
          weight,
          month,
          day,
          year,
          pimsId,
        })
      );
    }
  }, [history.location.state]);

  useEffect(() => {
    mixpanel.track('Started onboarding');
  }, []);

  useEffect(() => {
    if (
      deeplinkData?.type ===
        DeeplinkTypes.FULL_REGISTRATION_WITH_FOLLOW_UP_LINK &&
      state !== initialState
    ) {
      handlePetInfoSubmit();
    }
  }, [state, deeplinkData, handlePetInfoSubmit]);

  const renderContent = () => {
    const {
      name,
      type,
      otherType,
      practiceName,
      image,
      gender,
      reproductiveStatus,
      environment,
      breed,
      weight,
      month,
      day,
      year,
    } = state;
    switch (steps[stepIndex]) {
      case AVAILABLE_STEPS.NAME:
        return (
          <PetNameForm
            name={capitalizeString(name)}
            type={type}
            otherType={otherType}
            isClinicPreselected={isClinicPreselected}
            onSubmit={handlePetNameSubmit}
            isOnboarding={true}
          />
        );
      case AVAILABLE_STEPS.PRACTICE:
        return (
          <PetPracticeForm
            name={name}
            practiceName={practiceName}
            onSubmit={handlePetPracticeSubmit}
            isOnboarding={true}
          />
        );
      case AVAILABLE_STEPS.PHOTO:
        return (
          <PetPhotoForm
            name={name}
            type={type}
            image={image}
            onSave={handlePetPhotoSave}
            onSubmit={handlePetPhotoSubmit}
            isOnboarding={true}
          />
        );
      case AVAILABLE_STEPS.INFO:
        return (
          <PetInfoForm
            name={name}
            type={type}
            gender={gender}
            reproductiveStatus={reproductiveStatus}
            environment={environment}
            breed={breed}
            weight={weight}
            month={month}
            day={day}
            year={year}
            onSubmit={handlePetInfoSubmit}
            isLoading={isLoading}
            isOnboarding={true}
          />
        );
      default:
        return null;
    }
  };

  return <CenteredBanner onBack={onBack}>{renderContent()}</CenteredBanner>;
};

export default Onboarding;
