import produce from 'immer';
import appActionTypes from './appActionTypes';

const initialState = {
  isLoading: false,

  viewConsultation: {
    status: false,
    consultationId: '',
  },
  receipt: {
    isReceiptSent: false,
    errors: '',
  },
  fileUpload: {
    isUploading: false,
    percentage: 0,
  },
  createdConsultationID: '',
  updateConsultationID: '',
  intercom: {
    appId:
      process.env.REACT_APP_PRODUCTION === 'true' ? 'd35uv7ub' : 't1f8j8dy',
    userHash: '',
  },
  isImpersonating: false,
};

let appReducer = produce((draft, action) => {
  switch (action.type) {
    case appActionTypes.setIsLoadingTo:
      draft.isLoading = action.payload;
      break;
    case appActionTypes.setAddPetTo:
      draft.addPet = action.payload;
      break;

    case appActionTypes.sendReceipt:
      draft.receipt.isReceiptSent = action.payload.sentReceipt;
      draft.receipt.errors = action.payload.error;
      break;
    case appActionTypes.resetIsReceiptSent:
      draft.receipt.isReceiptSent = initialState.sentReceipt;
      draft.receipt.errors = initialState.error;
      break;
    case appActionTypes.createConsultation:
      draft.createdConsultationID = action.payload;
      break;
    case appActionTypes.updateConsultation:
      draft.updateConsultationID = action.payload;
      break;
    case appActionTypes.getIntercomUserHash:
      draft.intercom.userHash = action.payload;
      break;
    case appActionTypes.setIsImpersonatingTo:
      draft.isImpersonating = action.payload;
      break;
    default:
      break;
  }
}, initialState);

export default appReducer;
