import React from 'react';
import styled from 'styled-components/macro';
import { loadStripe } from '@stripe/stripe-js';
import { Elements as StripeElementsProvider } from '@stripe/react-stripe-js';
import { ResponsiveDeviceMaxWidth, useModal } from '@televet/televet-ui';
import Navigation from './Navigation';
import CurbsideState from './context/curbsideState';
import SettingsModal from './SettingsModal';
import { modalNames } from '../../constants/modalNames';
import { Mixpanel } from '../../redux/mixpanel';
import Conversations from './Conversations';
import TermsModal from './TermsModal';

const stripe = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

Mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

const CurbsideLayout = ({ match }) => {
  const { modal, openModal, closeModal } = useModal();
  const { channelId } = match.params;

  return (
    <StripeElementsProvider stripe={stripe}>
      <CurbsideState>
        <MainLayout>
          <Navigation
            onSettingsClick={() => openModal(modalNames.settingsModal)}
          />
          <Conversations />
        </MainLayout>
        <TermsModal />
        {modal?.name === modalNames.settingsModal && (
          <SettingsModal channelId={channelId} onDone={() => closeModal()} />
        )}
      </CurbsideState>
    </StripeElementsProvider>
  );
};

export default CurbsideLayout;

const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  margin: auto;
  overflow: hidden;

  @media ${ResponsiveDeviceMaxWidth.laptop} {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }
`;
