import React from 'react';
import styled from 'styled-components/macro';
import AddPetModal from '../../AddPet/AddPetModal';
import MainNavigation from '../../MainNavigation';

const MainLayout = ({ children }) => (
  <Container>
    <MainNavigation />
    <Content>{children}</Content>
    <AddPetModal />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  margin: 0;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior-y: contain;
`;

export default MainLayout;
