import styled from 'styled-components/macro';
import { Button, DeviceSizes } from '@televet/televet-ui';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useCallback } from 'react';

import User from './User';
import Link from '../Common/Link';
import Column from '../theme/Column';
import { H4 } from '../Common/Headers';
import Payment from './Payment';
import EditUser from './EditUser';
import Separator from '../theme/Separator';
import useIntercom from '../Common/Hooks/useIntercom';
import ChevronRight from '../../assets/svg/chevron-right.svg';
import { hslToHsla } from '../theme/helpers';
import PromoCodesView from './PromoCodesView';

import logout from '../../redux/auth/actions/logout';
import getIntercomUserHash from '../../redux/app/actions/getIntercomUserHash';
import { Mixpanel } from '../../redux/mixpanel';

const AccountView = () => {
  const dispatch = useDispatch();
  const [isEditingPayment, setIsEditingUser] = useState();

  const appId = useSelector(state => state.app.intercom.appId);
  const uid = useSelector(state => state.auth.user.uid);
  const userInfo = useSelector(state => state.user.info);
  const userHash = useSelector(state => state.app.intercom.userHash);
  const email = userInfo.private.email;
  const name = `${userInfo.public.fname} ${userInfo.public.lname}`;

  const handleLogout = () => dispatch(logout());

  const getIntercomSettings = useCallback(() => {
    dispatch(getIntercomUserHash());
  }, [dispatch]);

  useEffect(() => {
    getIntercomSettings();
  }, [getIntercomSettings]);

  const [intercomLoaded] = useIntercom(appId, {
    user_id: uid,
    email,
    name,
    user_hash: userHash,
    hide_default_launcher: true,
  });
  // <UserEdit onFinishEditing={() => setIsEditingUser(false)} />
  return (
    <Container>
      <LeftDiv>
        {isEditingPayment ? (
          <EditUser onFinishEditing={() => setIsEditingUser(false)} />
        ) : (
          <User onEditUser={() => setIsEditingUser(true)} />
        )}
      </LeftDiv>
      <RightDiv>
        <Content>
          <Payment />
          <Separator />
          <Section>
            <PromoCodesView />
          </Section>
          <Separator />
          <Support>
            <Header>
              <SectionTitle>Support</SectionTitle>
            </Header>
            <SupportItem>
              <Link
                onClick={() => Mixpanel.track('Clicked FAQs link')}
                style={{ color: 'hsl(224,76%,57%)' }}
                to="/faq"
              >
                FAQ
                <LinkIcon src={ChevronRight} alt="Go to FAQ" />
              </Link>
            </SupportItem>
            <SupportItem>
              <OutboundLink
                onClick={() => Mixpanel.track('Clicked Privacy Policy link')}
                href="https://www.gettelevet.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
                <LinkIcon src={ChevronRight} alt="Go to privacy policy" />
              </OutboundLink>
            </SupportItem>
            {intercomLoaded && (
              <SupportItem>
                <OutboundLinkButton
                  onClick={() => {
                    window.Intercom('show');
                    Mixpanel.track('Clicked Contact Support');
                  }}
                >
                  Contact Support
                  <LinkIcon src={ChevronRight} alt="Go to privacy policy" />
                </OutboundLinkButton>
              </SupportItem>
            )}
            <SupportItem>
              <OutboundLink
                onClick={() => Mixpanel.track('Clicked Terms of Service')}
                href="https://www.gettelevet.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
                <LinkIcon src={ChevronRight} alt="Go to terms of service" />
              </OutboundLink>
            </SupportItem>
          </Support>
          <LogoutButtonBottom id="logout-navbar-link" onClick={handleLogout}>
            Log out
          </LogoutButtonBottom>
        </Content>
      </RightDiv>
    </Container>
  );
};

const LeftDiv = styled.div`
  padding: 32px ${({ theme }) => theme.spacing.spacingSizes.lg};
  display: flex;
  flex: 2;
  flex-direction: column;
  @media (min-width: 961px) {
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 2px solid
      ${({ theme }) => hslToHsla(theme.palette.primary, 0.1)};
  }
`;

const RightDiv = styled.div`
  padding: 32px ${({ theme }) => theme.spacing.spacingSizes.lg};
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 5;
  button {
    min-width: 6rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  @media (max-width: 960px) {
    display: block;
    overflow-y: scroll;
    height: 100%;
    min-height: 100%;
  }
`;

const SectionTitle = styled(H4)`
  width: 80%;
`;

const OutboundLink = styled.a`
  color: ${({ theme }) => theme.palette.actionButton};
  text-decoration: none;
`;

const OutboundLinkButton = styled.span`
  color: ${({ theme }) => theme.palette.actionButton};
  cursor: pointer;
  text-decoration: none;
`;

const LinkIcon = styled.img`
  margin-left: 6px;
  height: 10px;
`;

const LogoutButton = styled(Button)`
  color: ${({ theme }) => theme.palette.error};
  border: 1px solid ${({ theme }) => theme.palette.error};
  background: transparent;
  border-radius: 4px;
  padding: 8px 12px;
  max-width: 50px;
  margin: 80px 0;
  @media (max-width: ${DeviceSizes.tablet}) {
    margin: 80px 0 140px;
  }
  @media (max-width: 960px) {
    display: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.error} !important;
    background: ${({ theme }) => theme.palette.error};
    color: ${({ theme }) => theme.palette.white};
  }
`;

const LogoutButtonBottom = styled(LogoutButton)`
  display: none;
  @media (max-width: 960px) {
    display: block;
    margin: 40px 0 100px;
  }
`;

const Content = styled.div`
  @media (min-width: 961px) {
    overflow: auto;
    height: inherit;
  }
  @media (min-width: 890px) {
    margin-top: 40px;
  }
`;

const Section = styled(Column)`
  padding: ${({ theme }) => theme.spacing.spacingSizes.xl} 0;
`;

const Header = styled.header`
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.lg};
  width: 100%;
  position: relative;
`;

const Support = styled(Section)`
  box-sizing: border-box;
  display: block;
  width: 100%;
`;

const SupportItem = styled.span`
  display: inline-flex;
  color: ${({ theme }) => theme.palette.actionButton};
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.lg};
  width: 50%;
`;

export default AccountView;
