import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Typography,
  Select,
} from '@material-ui/core';

import { Button } from '@televet/televet-ui';
import { hslToHsla } from '../../theme/helpers';
import { useSelector, useDispatch, connect } from 'react-redux';
import styled from 'styled-components/macro';
import Alert from '../../Common/Alert';
import { H4 } from '../../Common/Headers';
import { Add } from '../../Common/Icons';
import ClinicPlaceHolderLogo from '../../../assets/svg/clinic-logo-placeholder.svg';
import PromoCodeList from './PromoCodeList';
import PromoCodeInput from './PromoCodeInput';
import {
  getPromoCodesByClinic,
  checkNewPromoCode,
  formatPossessiveName,
  isCodeExpired,
} from '../../../utilities';
import { savePromoCode } from '../../../redux/user/actions/savePromoCode';

import { pushSnackbar } from '../../../redux/snackbar/snackbarActions';

const PromoCodesView = props => {
  const dispatch = useDispatch();
  const [clinicIds, setClinicIds] = useState([]);
  const [selectedClinicId, setSelectedClinicId] = useState('');
  const [message, setMessage] = useState({ text: '', severity: undefined });
  const [isAddingPromoCode, setIsAddingPromoCode] = useState(false);

  const userId = useSelector(state => state.auth.user.uid);
  const { clinics, user } = useSelector(state => state) || {};
  const clinic = clinics[selectedClinicId];

  const promoCodes = getPromoCodesByClinic(selectedClinicId, user.info.promos);

  useEffect(() => {
    // if only one clinic, initialize state with first clinic
    const ids = Object.keys(clinics);
    setClinicIds(ids);
    setSelectedClinicId(ids && ids.length ? ids[0] : null);
  }, [clinics]);

  const handleClinicChange = e => {
    setSelectedClinicId(e.target.value);
  };

  const handleAddClick = async enteredPromoCode => {
    // reset message
    setMessage({ text: '', severity: undefined });

    const codeStatus = await checkNewPromoCode(
      enteredPromoCode,
      selectedClinicId,
      user.info.promos
    );
    setMessage(codeStatus.message);

    if (codeStatus.isValid) {
      const clinicName = clinic.info.name;
      savePromoCode(
        userId,
        codeStatus.codeObj,
        codeStatus.codeId,
        clinicName,
        dispatch
      );
      props.pushSnackbar({
        type: 'success',
        message: 'Success: Your promo code was added',
      });
    }
  };

  const handleAlertClose = () => {
    setMessage({ text: '', severity: null });
  };

  let allPromoCodes = {};
  Object.keys(clinics).forEach(practice => {
    allPromoCodes[practice] = getPromoCodesByClinic(practice, user.info.promos);
  });

  const startAddPromoCode = () => {
    setIsAddingPromoCode(true);
  };

  return (
    <div>
      <HeaderSection>
        <SectionTitle>Promo Codes</SectionTitle>
        {!isAddingPromoCode && clinicIds.length > 0 && (
          <PromoCodesButton buttonType="ghost" onClick={startAddPromoCode}>
            <HeaderSection style={{ justifyContent: 'center' }}>
              <span>
                <Add title="Add Promo Code" />
              </span>
              <span style={{ padding: '0 4px' }}>Add</span>
            </HeaderSection>
          </PromoCodesButton>
        )}
      </HeaderSection>
      <div>
        {message.severity && (
          <Snackbar
            open={!!message}
            autoHideDuration={3000}
            onClose={handleAlertClose}
          >
            <Alert onClose={handleAlertClose} severity={message.severity}>
              {message.text}
            </Alert>
          </Snackbar>
        )}
        <div>
          <div>
            {clinicIds.length === 0 && (
              <Typography>
                Please add a pet to add promo codes for your clinic.
              </Typography>
            )}
            {clinicIds.length === 1 && isAddingPromoCode && (
              <>
                <Typography>{clinics[clinicIds[0]].info.name}</Typography>
                <PromoCodeInput
                  finishAddPromoCode={setIsAddingPromoCode}
                  onClick={handleAddClick}
                />
              </>
            )}
            {clinicIds.length > 1 && isAddingPromoCode && (
              <>
                <StyledFormControl>
                  <InputLabel id="clinic-select-label">
                    Select clinic
                  </InputLabel>
                  <Select
                    labelId="clinic-select-label"
                    value={selectedClinicId || ''}
                    onChange={handleClinicChange}
                  >
                    {clinicIds.map(id => (
                      <MenuItem key={id} value={id}>
                        {clinics[id].info.name}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
                <PromoCodeInput
                  finishAddPromoCode={setIsAddingPromoCode}
                  onClick={handleAddClick}
                />
                {promoCodes.length > 0 && (
                  <div style={{ marginBottom: 100 }}>
                    <div>
                      <StyledTypography>Active Promo Codes</StyledTypography>
                      <PromoCodeList promoCodes={promoCodes} clinic={clinic} />
                      <StyledTypography>
                        *Promo codes can be applied at payment
                      </StyledTypography>
                    </div>
                  </div>
                )}
              </>
            )}
            {clinicIds.length >= 1 &&
              !isAddingPromoCode &&
              Object.keys(clinics).map(practiceId => {
                if (clinics[practiceId]) {
                  const { info } = clinics[practiceId];
                  const codes = allPromoCodes[practiceId];
                  if (codes.length === 0) {
                    return (
                      <PracticePromoSection key={practiceId}>
                        <p style={{ marginBottom: 4, fontWeight: 500 }}>
                          {info.name}
                        </p>
                        <p style={{ margin: '12px 12px 24px' }}>
                          No promo codes added for this clinic.
                        </p>
                      </PracticePromoSection>
                    );
                  }
                  return (
                    <PracticePromoSection key={practiceId}>
                      <p style={{ marginBottom: 4, fontWeight: 500 }}>
                        {info.name}
                      </p>
                      {codes.map(singleCode => {
                        if (singleCode.available) {
                          return (
                            <StyledClinic key={singleCode.id}>
                              <ClinicImage
                                src={info.logo || ClinicPlaceHolderLogo}
                                alt={`${formatPossessiveName(info.name) ||
                                  "your clinic's"} logo`}
                              />
                              <div style={{ textAlign: 'left', width: '100%' }}>
                                <p style={{ marginBottom: 4, fontWeight: 500 }}>
                                  {`${singleCode.discount}% Off Your Next Consultation`}
                                  {isCodeExpired(singleCode) ? (
                                    <Expired>Expired</Expired>
                                  ) : null}
                                </p>
                                <p style={{ color: 'rgba(16, 22, 38, 0.8)' }}>
                                  {singleCode.code}
                                </p>
                              </div>
                            </StyledClinic>
                          );
                        } else return null;
                      })}
                    </PracticePromoSection>
                  );
                } else return null;
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const SectionTitle = styled(H4)`
  width: 80%;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const PracticePromoSection = styled.div`
  border-bottom: 1px solid
    ${({ theme }) => hslToHsla(theme.palette.primary, 0.5)};
  margin: 24px 0;
`;

const ClinicImage = styled.img`
  width: 36px;
  height: 36px;
  margin-right: ${({ theme }) => theme.spacing.spacingSizes.sm};
  border-radius: 4px;
`;

const StyledClinic = styled.div`
  height: auto;
  padding: ${({ theme }) => theme.spacing.spacingSizes.lg};
  display: flex;
  margin: ${({ theme }) => theme.spacing.spacingSizes.lg} 0;
  cursor: pointer;
  border-radius: 12px;
  flex-direction: row;
  background-color: rgba(60, 105, 229, 0.05);
`;

const PromoCodesButton = styled(Button)`
  width: 50%;
  max-width: 140px;
  margin: 8px 0;
  font-size: 16px;

  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};

  ${Add} {
    height: 13px;
    position: relative;
    width: 13px;
    stroke: ${({ theme }) => theme.palette.actionButton};
  }

  &:hover,
  &:active,
  &:focus {
    ${Add} {
      stroke: ${({ theme }) => theme.palette.white};
    }
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  margin-top: 20px;
`;

const Expired = styled.span`
  color: red;
  margin-left: 10px;
`;

const mapDispatchToProps = {
  pushSnackbar,
};

export default connect(null, mapDispatchToProps)(PromoCodesView);
