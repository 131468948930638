import React from 'react';
import styled from 'styled-components/macro';
import { Button } from '@televet/televet-ui';
import Text from '../../theme/Text';

import { isCodeUsable } from '../../../utilities';
import PlaceHolderClinicLogo from '../../../assets/png/clinic-logo-placeholder.png';
import CheckImage from '../../../assets/svg/tick-white.svg';

const PromoCodeList = ({
  promoCodes,
  clinic,
  handleCodeSelect,
  selected,
  width,
}) => {
  return promoCodes.map(code => (
    <PromoCodeCard
      key={code.code}
      code={code}
      clinic={clinic}
      handleCodeSelect={handleCodeSelect}
      selected={selected && selected.id === code.id}
      width={width}
    />
  ));
};

const PromoCodeCard = ({ code, clinic, handleCodeSelect, selected, width }) => {
  return (
    <CardContainer width={width}>
      <LogoTextWrapper>
        <Logo
          src={clinic?.info?.logo || PlaceHolderClinicLogo}
          alt={clinic?.info.name}
        />
        <PromoCodeDescription as="p">
          {code.discount}% Off Your Next Consultation
        </PromoCodeDescription>
        <PromoCode>{code.code}</PromoCode>
      </LogoTextWrapper>
      {handleCodeSelect && isCodeUsable(code) && !selected && (
        <SelectButton fullwidth onClick={handleCodeSelect(code)}>
          Select
        </SelectButton>
      )}
      {selected && <GreenCheck onClick={handleCodeSelect(null)} />}
    </CardContainer>
  );
};

const SelectButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};
  background: ${({ theme }) => theme.palette.white};

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const LogoTextWrapper = styled.div`
  flex: 1;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top: 15px;
  background-color: rgba(60, 105, 229, 0.05);
  border-radius: 12px;
  ${props => (props.width ? `width: ${props.width}` : '')}
`;

const PromoCodeDescription = styled(Text)`
  color: rgba(16, 22, 38, 0.8);
`;

const PromoCode = styled(PromoCodeDescription)`
  font-size: 12px;
`;

const Logo = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  float: left;
  margin-right: ${({ theme }) => theme.spacing.spacingSizes.sm};
`;

const GreenCheck = styled.div`
  width: 36px;
  height: 36px;
  background-color: #277b54;
  border-radius: 18px;
  background-position: 8px 8px;
  background-image: url(${CheckImage});
  background-size: 20px 20px;
  background-repeat: no-repeat;
`;

export default PromoCodeList;
