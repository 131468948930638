import { useMutation } from '@apollo/client';
import React, { ReactElement, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import curbsideContext from '../../context/curbsideContext';

import useSendMessage from '../hooks/sendMessage';
import { TRIGGER_WORKFLOW_EVENT } from '../mutations';

import * as FullStory from '@fullstory/browser';

interface Props {
  options: any;
  entityId: string;
  workflowEventTriggerActionNumber: number | null;
  workflowSettingId: string;
}

const SuggestedMessages = ({
  options,
  entityId,
  workflowEventTriggerActionNumber,
  workflowSettingId,
}: Props): ReactElement => {
  const onError = (error: any) => {
    console.info(error);
  };

  const { sendMessage } = useSendMessage({ onError });

  const { headers, clinic } = useContext(curbsideContext);
  const [triggerWorkFlowEvent] = useMutation(TRIGGER_WORKFLOW_EVENT, {
    context: { headers },
  });

  const onSuggestionClick = async (option: any): Promise<void> => {
    const remainingOptions = options.filter((element: any) => {
      return !(element === option);
    });

    let attributes;
    if (option.workflowActionEventTrigger) {
      attributes = {};
    } else {
      attributes = {
        options: remainingOptions,
        workflowSettingId,
        entityId,
        workflowEventTriggerActionNumber,
      };
    }
    if (option.body) {
      await sendMessage({
        text: option.body,
        attachments: [],
        attributes,
      });
    }
    if (option.workflowActionEventTrigger) {
      triggerWorkFlowEvent({
        variables: {
          data: {
            entityId: entityId,
            event: option.workflowActionEventTrigger,
            delay: option.delay || 1000,
            actionNumber: workflowEventTriggerActionNumber,
          },
        },
      });
      if (
        workflowEventTriggerActionNumber === 1 &&
        process.env.REACT_APP_PRODUCTION === 'true'
      ) {
        FullStory.event('Workflow Started', {
          clinic: clinic?.name,
          appointmentId: entityId,
        });
      }
    }
  };

  const ref = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [options]);
  return options.length ? (
    <OptionsWrapper>
      <Title>Response Suggestions</Title>
      <Options>
        {options.map((option: any, index: number) => {
          return (
            <Option
              key={`suggested-message-option-${index}`}
              onClick={() => {
                onSuggestionClick(options[index]);
              }}
            >
              {option.body}
            </Option>
          );
        })}
      </Options>
      <ScrollReference ref={ref} />
    </OptionsWrapper>
  ) : (
    <></>
  );
};

export default SuggestedMessages;

const OptionsWrapper = styled.div`
  margin-top: 20px;
`;

const ScrollReference = styled.div`
  margin-top: 20px;
`;

const Option = styled.div`
  border-radius: 26px;
  border: solid 1px #050f5f;
  background-color: #ffffff;
  padding: 10px 15px;
  margin: 0 15px;
  cursor: pointer;
`;

const Title = styled.p`
  color: rgb(87 93 124);
  padding: 10px;
`;

const Options = styled.div`
  display: flex;
  justify-content: flex-end;
`;
