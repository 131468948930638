import React from 'react';
import { isEmpty } from 'lodash';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const OnboardingRoute = ({ component: Component, ...rest }) => {
  const { pathname, search } = useLocation();
  const authenticated = useSelector(state => !isEmpty(state.auth.user));

  if (authenticated) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  } else {
    return <Redirect to={`/login?redirect=${pathname}${search}`} />;
  }
};

export default OnboardingRoute;
