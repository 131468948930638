import React from 'react';
import styled from 'styled-components/macro';
import { Modal, ResponsiveDeviceMinWidth } from '@televet/televet-ui';

import AddPaymentMethod from './AddPaymentMethod';

const AddPaymentModal = ({ onCancel, onFinish }) => {
  return (
    <Modal size="lg" name="add-payment">
      <ModalContent>
        <AddPaymentMethod onCancel={onCancel} onFinish={onFinish} />
      </ModalContent>
    </Modal>
  );
};

export default AddPaymentModal;

const ModalContent = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  padding: 0 1em 1em 1em;

  @media ${ResponsiveDeviceMinWidth.tablet} {
    min-height: 650px;
    padding: 0 4em 4em 4em;
  }
`;
