import firebase from '../../../firebase/firebase';

import getUser from '../../user/actions/getUser';
import setIsLoadingTo from '../../app/actions/setIsLoadingTo.js';
import { pushSnackbar } from '../../snackbar/snackbarActions';
import notificationActionTypes from '../notificationActionTypes';

const db = firebase.database();

const markNotifications = (field, notificationId = null) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoadingTo(true));
      const uid = getState().auth.user.uid;
      const currentNotifications = getState().user.info.private.clientNotifs;
      const keys = Object.keys(currentNotifications);

      if (notificationId) {
        // mark single notification as read
        if (field === 'read') {
          const updateObject = {
            ...currentNotifications[notificationId],
            [field]: Date.now(),
          };

          const newNotificationSet = {
            ...currentNotifications,
            [notificationId]: updateObject,
          };

          await db
            .ref(`users/${uid}/info/private/clientNotifs/`)
            .update(newNotificationSet);

          dispatch({
            type: notificationActionTypes.markAsRead,
            payload: newNotificationSet,
          });

          await dispatch(getUser());

          return newNotificationSet;
        }
      }

      // mark all notifications as either read or delivered, depending on `field`
      const newNotificationSet = {};
      keys.forEach(notificationKey => {
        newNotificationSet[notificationKey] = {
          ...currentNotifications[notificationKey],
          [field]: currentNotifications[notificationKey][field]
            ? currentNotifications[notificationKey][field]
            : Date.now(),
        };
      });

      await db
        .ref(`users/${uid}/info/private/clientNotifs/`)
        .update(newNotificationSet);

      dispatch({
        type:
          field === 'read'
            ? notificationActionTypes.markAllAsRead
            : notificationActionTypes.markAllAsReceived,
        payload: newNotificationSet,
      });

      await dispatch(getUser());

      return newNotificationSet;
    } catch (e) {
      dispatch(
        pushSnackbar({
          type: 'error',
          message: e.message,
        })
      );
    } finally {
      dispatch(setIsLoadingTo(false));
    }
  };
};

const setNotificationList = notificationList => {
  return dispatch => {
    dispatch({
      type: notificationActionTypes.setNotificationList,
      payload: notificationList,
    });
  };
};

export { markNotifications, setNotificationList };
