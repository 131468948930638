import { combineReducers } from 'redux';

import appReducer from './app/appReducer';
import authReducer from './auth/authReducer';
import userReducer from './user/userReducer';
import petsReducer from './pets/petsReducer';
import vetsReducer from './vets/vetsReducer';
import chatsReducer from './chats/chatsReducer';
import paymentReducer from './payment/paymentReducer';
import clinicsReducer from './clinics/clinicsReducer';
import variableReducer from './variable/variableReducer';
import snackbarReducer from './snackbar/snackbarReducer';
import clinicSearchReducer from './clinicSearch/clinicSearchReducer';
import notificationsReducer from './notifications/notificationsReducer';
import consultationsReducer from './consultations/consultationsReducer';

const combinedReducers = combineReducers({
  variable: variableReducer,
  auth: authReducer,
  user: userReducer,
  clinicSearch: clinicSearchReducer,
  payment: paymentReducer,
  pets: petsReducer,
  clinics: clinicsReducer,
  consultations: consultationsReducer,
  chats: chatsReducer,
  vets: vetsReducer,
  app: appReducer,
  snackbar: snackbarReducer,
  notifications: notificationsReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'RESET_STORE') {
    state = undefined;
  }

  return combinedReducers(state, action);
};

export default rootReducer;
