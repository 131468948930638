import styled from 'styled-components/macro';
import { Button, BounceLoader } from '@televet/televet-ui';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import Text from '../theme/Text';
import { H4 } from '../Common/Headers';
import ActionButton from '../Common/ActionButton';
import PromoCodeList from '../AccountView/PromoCodesView/PromoCodeList';
import getClientToken from '../../redux/payment/actions/getClientToken';
import BraintreeDropIn from '../Common/BraintreeDropIn';
import { pushSnackbar } from '../../redux/snackbar/snackbarActions.js';
import getCustomerToken from '../../redux/payment/actions/getCustomerToken';
import updateConsultation from '../../redux/app/actions/updateConsultation';
import {
  getPromoCodesByClinic,
  isCodeUsable,
  isCodeExpired,
} from '../../utilities';

const ConfirmConsultation = ({ consultation, onCancel, onFinish }) => {
  const [braintreeInstance, setBraintreeInstance] = useState(null);
  const [clientToken, setClientToken] = useState(null);
  const [tokensLoaded, setTokensLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.user);
  const clinics = useSelector(state => state.clinics);
  const dispatch = useDispatch();

  let { cost, clinic } = consultation;
  const fullClinic = clinics[clinic];
  const [selectedPromoCode, setSelectedPromoCode] = useState();
  const [promoCodes, setPromoCodes] = useState([]);

  const handleCodeSelect = code => () => {
    setSelectedPromoCode(code);
    consultation.promo = code;
  };

  useEffect(() => {
    const usersPromoCodes = getPromoCodesByClinic(
      clinic,
      user.info.promos
    ).filter(code => isCodeUsable(code) && !isCodeExpired(code));

    if (usersPromoCodes.length > 0) {
      setPromoCodes(usersPromoCodes);
      // the codes are already sorted by time added, so
      // the first code will be the newest
      setSelectedPromoCode(usersPromoCodes[0]);
      consultation.promo = usersPromoCodes[0];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.info.promos, clinic]);

  cost = (
    cost +
    5 -
    (cost + 5) * ((selectedPromoCode ? selectedPromoCode.discount : 0) / 100)
  ).toFixed(2);

  const setPayments = () => {
    return Promise.all([
      getClientToken(),
      getCustomerToken(user?.info?.private?.customerId),
    ])
      .then(([clientTokenResult, customerTokenResult]) => {
        setClientToken(clientTokenResult.payload);
        setTokensLoaded(true);
      })
      .catch(error => {
        console.error(error);
        dispatch(pushSnackbar({ type: 'error', message: error.message }));
      });
  };

  const componentDidMount = () => {
    setPayments();
  };

  const confirmConsultationPayment = async () => {
    if (braintreeInstance) {
      setIsLoading(true);
      const { nonce } = await braintreeInstance.requestPaymentMethod();

      try {
        await dispatch(updateConsultation(consultation, nonce));
        onFinish();
        dispatch(
          pushSnackbar({
            type: 'success',
            message: 'Success! Your consultation has been confirmed.',
          })
        );
        setIsLoading(false);
      } catch (error) {
        console.error('Error adding payment method', error);
        dispatch(
          pushSnackbar({
            type: 'error',
            message: 'Error adding payment method: ' + error.message,
          })
        );
        setIsLoading(false);
      }
    } else {
      dispatch(
        pushSnackbar({
          type: 'error',
          message:
            'Error confirming payment method. Please refresh the page and try again',
          timeoutMs: 5000,
        })
      );
    }
  };

  useEffect(componentDidMount, []);

  if (!tokensLoaded || !clientToken) return null;

  return (
    <ConfirmationContainer>
      <div style={{ width: 500 }}>
        <div>
          <div>
            <p style={{ margin: 25 }}>
              Choose a payment method to confirm this consultation.
            </p>
            <div style={{ paddingBottom: 40, color: '#222', fontSize: 18 }}>
              The cost of this consultation is <CostText>${cost}</CostText>
            </div>
            <BraintreeDropIn
              options={{
                authorization: clientToken,
                vaultManager: false,
              }}
              onInstance={instance => {
                setBraintreeInstance(instance);
              }}
            />
            {promoCodes.length > 0 && (
              <PromoContainer>
                <H4>Promo Codes</H4>
                <PromoCodeList
                  width="100%"
                  promoCodes={promoCodes}
                  clinic={fullClinic}
                  handleCodeSelect={handleCodeSelect}
                  selected={selectedPromoCode}
                />
              </PromoContainer>
            )}
            {isLoading ? (
              <div style={{ textAlign: 'center' }}>
                <Text>Confirming your consultation...</Text>
                <BounceLoader loading={isLoading} />
              </div>
            ) : (
              <ButtonContainer>
                <CancelButton
                  style={{ marginTop: 25 }}
                  onClick={onCancel}
                  buttonType="secondary"
                  id="cancel-confirm-consultation-button"
                >
                  Cancel
                </CancelButton>
                <ConfirmButton
                  disabled={!tokensLoaded || isLoading}
                  buttonType="primary"
                  id="continue-confirm-consultation-button"
                  onClick={confirmConsultationPayment}
                >
                  Confirm Payment
                </ConfirmButton>
              </ButtonContainer>
            )}
          </div>
        </div>
      </div>
    </ConfirmationContainer>
  );
};

const PromoContainer = styled.div`
  margin: 48px 0 32px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ConfirmationContainer = styled.div`
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 50px
  margin-bottom: 100px;
  [data-braintree-id="toggle"] {
    display: none;
  }
`;

const ConfirmButton = styled(ActionButton)`
  width: auto !important;
  max-width: 200px;
`;

const CancelButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};
  background: transparent;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white} !important;
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const CostText = styled.span`
  color: ${({ theme }) => theme.palette.success};
`;

export default ConfirmConsultation;
