import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';

import QuestionWrapper from './Shared/QuestionWrapper';

interface ITextBlock {
  index: number;
  prompt: string;
}

const TextInput = ({ index, prompt }: ITextBlock): ReactElement => {
  return (
    <QuestionWrapper index={index}>
      {prompt.split('\n').map((line, lineIndex) => {
        return (
          <StyledParagraph key={`question-${index}-line-${lineIndex}`}>
            {line}
          </StyledParagraph>
        );
      })}
    </QuestionWrapper>
  );
};

export default TextInput;

const StyledParagraph = styled.p`
  font-size: 20px;
  min-height: 20px;
  line-height: 25px;
  font-family: LinearSans;
  color: #575d7c;
`;
