import {
  PUSH_SNACKBAR,
  REMOVE_SNACKBAR,
  RESET_SNACKBAR_STATE,
} from './snackbarActions';

export const initialState = [];

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUSH_SNACKBAR:
      if (action.payload.message.length > 0) {
        const id = Date.now();
        const newSnackbars = [...state];
        newSnackbars.unshift({ id, ...action.payload });
        return newSnackbars;
      }
      return state;
    case REMOVE_SNACKBAR:
      return state.filter(snackbar => snackbar.id !== action.id);
    case RESET_SNACKBAR_STATE:
      return initialState;
    default:
      return state;
  }
};
export default snackbarReducer;
