import styled from 'styled-components/macro';
import {
  ResponsiveDeviceMinWidth,
  Button as UIButton,
} from '@televet/televet-ui';

const ActionButton = styled(UIButton)`
  height: 42px;
  min-width: auto;
  width: 100%;
  background: ${({ theme }) => theme.palette.actionButton};
  margin-bottom: 0;
  border: 1px solid transparent;

  &:hover {
    background: ${({ theme }) => theme.palette.actionButtonHover} !important;
    border-color: ${({ theme }) => theme.palette.actionButtonHover} !important;
  }

  @media ${ResponsiveDeviceMinWidth.tablet} {
    min-width: 8rem;
    width: auto;
  }
`;

export default ActionButton;
