import React, { Component } from 'react';
import styled from 'styled-components/macro';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  ResponsiveDeviceMinWidth,
  Button as DefaultButton,
} from '@televet/televet-ui';

export default class ImageCropper extends Component {
  state = { crop: null };
  componentDidMount() {
    this.setState({ crop: this.props.crop });
  }
  componentDidUpdate() {}

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  render() {
    return (
      <Wrapper>
        <ImageArea>
          <ReactCrop
            imageStyle={this.props.imageStyle || null}
            src={this.props.src}
            crop={this.state.crop}
            ruleOfThirds
            circularCrop={this.props.circularCrop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        </ImageArea>

        <br />
        <ActionContainer>
          <Button
            fullWidth
            type="button"
            onClick={() => {
              this.props.setCrop(this.state.croppedImageUrl);
            }}
          >
            Save Photo
          </Button>
        </ActionContainer>
      </Wrapper>
    );
  }
}

const ImageArea = styled.div`
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.md};
  position: relative;
  width: 250px;
`;

const Wrapper = styled.div`
  text-align: center;
`;

const ActionContainer = styled.div`
  width: 100%;

  @media ${ResponsiveDeviceMinWidth.mobileL} {
    margin: 0 auto;
    width: 50%;
  }

  @media ${ResponsiveDeviceMinWidth.laptopL} {
    margin: 0 auto;
    width: 40%;
  }
`;

const Button = styled(DefaultButton)`
  margin-top: ${({ theme }) => theme.spacing.spacingSizes.md};
`;
