import React, {
  useEffect,
  useRef,
  useContext,
  useCallback,
  useState,
} from 'react';
import {
  useLazyQuery,
  useApolloClient,
  useMutation,
  useSubscription,
} from '@apollo/client';
import styled from 'styled-components/macro';
import { useInView } from 'react-intersection-observer';
import { DEFAULT_PAGE_SIZE } from './constants/defaultPageSize';
import { useModal } from '@televet/televet-ui';

import {
  GET_CHANNEL_MESSAGES,
  GET_CHANNEL_VIEW_CHANNEL,
  GET_CHANNEL_MEMBERS_CONSUMPTION_HORIZON,
} from './queries';
import { UPDATE_CHANNEL_MEMBER } from './mutations';
import {
  SUBSCRIBE_TO_CHANNEL_MESSAGES,
  SUBSCRIBE_TO_CHANNEL_MEMBERS,
} from '../subscriptions';
import CurbsideContext from '../context/curbsideContext';
import MessageTimeline from './components/MessageTimeline';
import MessageComposer from './components/MessageComposer';
import { modalNames } from './constants/modalNames';
import ChatMediaPreviewModal from './components/ChatMediaPreviewModal';
import { ChannelMessageAttachmentType } from '../enums';

import * as FullStory from '@fullstory/browser';

const Conversations = () => {
  const apolloClient = useApolloClient();
  const { clinic, channel, token, user } = useContext(CurbsideContext);
  const channelId = channel?.id;
  const { openModal } = useModal();

  const messageTimelineScrollContainerRef = useRef(null);

  const [previewData, setPreviewData] = useState({
    previewFileUrl: '',
    previewAttachmentType: ChannelMessageAttachmentType.File,
    previewFileName: '',
  });

  const headers = {
    'auth-type': 'clinic_pet_parent_token',
    authorization: `Bearer ${token}`,
    clinic: clinic?.id,
  };

  const [getChannel, { data: channelData }] = useLazyQuery(
    GET_CHANNEL_VIEW_CHANNEL,
    {
      variables: {
        where: {
          id: channelId,
        },
      },
      context: { headers },
      fetchPolicy: 'network-only',
    }
  );

  const [
    getChannelMessages,
    {
      data: channelMessagesData,
      loading: isChannelMessagesLoading,
      fetchMore: fetchMoreChannelMessages,
    },
  ] = useLazyQuery(GET_CHANNEL_MESSAGES, {
    variables: {
      where: {
        channel: {
          id: channelId,
        },
        messageType: 'Message',
      },
      orderBy: 'createdAt_ASC',
      last: DEFAULT_PAGE_SIZE,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
    returnPartialData: true,
    context: { headers },
    // fetchPolicy: 'network-only',
  });
  const pageInfo = channelMessagesData?.channelMessagesConnection?.pageInfo;

  useSubscription(SUBSCRIBE_TO_CHANNEL_MEMBERS, {
    variables: { where: { node: { channel: { id: channelId } } } },
  });

  const { data: channelMessageSubscriptionData } = useSubscription(
    SUBSCRIBE_TO_CHANNEL_MESSAGES,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: { node: { channel: { id: channelId }, messageType: 'Message' } },
      },
    }
  );

  useEffect(() => {
    const message = channelMessageSubscriptionData?.channelMessage?.node;
    if (!message || !channelMessagesData?.channelMessages) return;

    const result = apolloClient.readQuery({
      query: GET_CHANNEL_MESSAGES,
      variables: {
        where: {
          channel: {
            id: channelId,
          },
          messageType: 'Message',
        },
        orderBy: 'createdAt_ASC',
        last: DEFAULT_PAGE_SIZE,
      },
    });

    if (!result) return;
    const { channelMessages, channelMessagesConnection } = result;
    apolloClient.writeQuery({
      query: GET_CHANNEL_MESSAGES,
      variables: {
        where: {
          channel: {
            id: channelId,
          },
          messageType: 'Message',
        },
        orderBy: 'createdAt_ASC',
        last: DEFAULT_PAGE_SIZE,
      },
      broadcast: true,
      data: {
        channelMessages: [...channelMessages, message],
        channelMessagesConnection,
      },
    });
  }, [channelMessageSubscriptionData]); // eslint-disable-line

  useEffect(() => {
    if (channelId) {
      getChannel();
      getChannelMessages();
    }
  }, [channelId, getChannel, getChannelMessages]);

  const lastMessage = useRef({
    messageId: '',
    messageIndex: -1,
    channelId: '',
  });
  const scrollHeight = useRef(0);

  const loadPreviousMessages = useCallback(() => {
    if (fetchMoreChannelMessages && !isChannelMessagesLoading) {
      fetchMoreChannelMessages({
        variables: {
          before: pageInfo?.startCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (
            !fetchMoreResult?.channelMessages ||
            !fetchMoreResult.channelMessagesConnection
          )
            return previousResult;

          const scrollContainer = messageTimelineScrollContainerRef.current;
          if (!scrollContainer) return previousResult;
          scrollHeight.current = scrollContainer.scrollHeight;

          return {
            channelMessages: [
              ...(fetchMoreResult?.channelMessages || []),
              ...previousResult.channelMessages,
            ],
            channelMessagesConnection:
              fetchMoreResult?.channelMessagesConnection,
          };
        },
      });
    }
  }, [fetchMoreChannelMessages, isChannelMessagesLoading, pageInfo]);

  const [updateChannelMember] = useMutation(UPDATE_CHANNEL_MEMBER, {
    context: { headers },
  });
  const advanceConsumedMessageIndex = async (
    messageIndex,
    channelId,
    clinicPetParentId
  ) => {
    if (document.visibilityState !== 'visible') return;

    // TODO: Prevent unnecessary network requests; seems cache is sometimes missed
    // even when channel member should exist from at least the channel list query
    const response = await apolloClient.query({
      query: GET_CHANNEL_MEMBERS_CONSUMPTION_HORIZON,
      variables: {
        where: {
          channel: {
            id: channelId,
          },
          clinicPetParent: {
            id: clinicPetParentId,
          },
        },
      },
      fetchPolicy: 'cache-first',
      context: { headers },
    });
    if (!response.data?.channelMembers.length)
      throw new Error('No channel members found for channel and user');
    const [currentUserMember] = response.data.channelMembers;
    const currentUserMessageIndex =
      currentUserMember?.lastConsumedMessageIndex || 0;
    if (messageIndex > currentUserMessageIndex) {
      updateChannelMember({
        variables: {
          where: {
            id: currentUserMember?.id,
          },
          data: {
            lastConsumedMessageIndex: messageIndex,
            sendMessageNotification: true,
          },
        },
      });
    }
  };

  const { inView: isScrolledToTop, ref: previousMessagesRef } = useInView({
    root: messageTimelineScrollContainerRef.current,
    rootMargin: '100px 0px 0px 0px',
  });
  useEffect(() => {
    if (isScrolledToTop && !isChannelMessagesLoading) {
      loadPreviousMessages();
    }
  }, [isScrolledToTop]); // eslint-disable-line

  const handleFirstMessageMount = async () => {
    const scrollContainer = messageTimelineScrollContainerRef?.current;
    if (!scrollContainer) return;
    scrollContainer.scrollTop =
      scrollContainer.scrollHeight - scrollHeight.current;
  };

  const handleLastMessageMount = async (message, element) => {
    const { messageId, channelId } = lastMessage.current;
    if (messageId !== message.id) {
      lastMessage.current = {
        messageId: message.id,
        messageIndex: message.index,
        channelId: message.channel.id,
      };

      if (!user || !message.channel?.id) return;

      element.scrollIntoView({
        behavior: message.channel?.id === channelId ? 'smooth' : 'auto',
      });

      advanceConsumedMessageIndex(message.index, message.channel.id, user.uid);
    }
  };

  useEffect(() => {
    const onPageVisibilityChange = () => {
      // TODO: Add "isScrolledToBottom" check here
      if (document.visibilityState === 'visible' && user?.uid) {
        const { messageIndex, channelId } = lastMessage.current;
        advanceConsumedMessageIndex(messageIndex, channelId, user.uid);
      }
    };
    document.addEventListener('visibilitychange', onPageVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onPageVisibilityChange);
    };
  }, [user]); // eslint-disable-line

  const setFilePreview = (previewFileUrl, previewAttachmentType, fileName) => {
    if (!previewFileUrl) throw new Error("Could not find the file's location");

    setPreviewData({
      previewFileUrl: previewFileUrl || '',
      previewAttachmentType,
      previewFileName: fileName,
    });

    if (fileName) {
      const extension = fileName
        ?.slice()
        .substring(fileName.lastIndexOf('.') + 1);

      if (['doc', 'zip', '7z'].some(el => extension?.includes(el))) {
        Object.assign(document.createElement('a'), {
          href: previewFileUrl,
        }).click();
      } else {
        openModal(modalNames.chatMediaPreview);
      }
    } else {
      openModal(modalNames.chatMediaPreview);
    }
  };

  const [workflowSettingId, setWorkflowSettingId] = useState('');
  const [suggestedMessageOptions, setSuggestedMessageOptions] = useState([]);
  const [suggestedMessageEntityId, setSuggestedMessageEntityId] = useState('');
  const [workflowActionEventTrigger, setWorkflowActionEventTrigger] = useState(
    null
  );
  const [
    workflowEventTriggerActionNumber,
    setWorkflowEventTriggerActionNumber,
  ] = useState(null);

  useEffect(() => {
    const messages = channelMessagesData?.channelMessages || [];
    const lastMessageIndex = messages.length - 1;
    const lastMessage = messages[lastMessageIndex];
    if (lastMessage?.attributes?.workflowSettingId) {
      setWorkflowSettingId(lastMessage?.attributes?.workflowSettingId);
      setSuggestedMessageOptions(lastMessage?.attributes?.options || []);
      setSuggestedMessageEntityId(lastMessage?.attributes?.entityId);
      setWorkflowEventTriggerActionNumber(
        lastMessage?.attributes?.workflowEventTriggerActionNumber
      );
      setWorkflowActionEventTrigger(
        lastMessage?.attributes?.workflowEventTrigger
      );

      if (
        lastMessage?.attributes?.workflowEventTriggerActionNumber === 1 &&
        process.env.REACT_APP_PRODUCTION === 'true'
      ) {
        FullStory.event('Workflow Initiated', {
          clinic: clinic?.name,
          appointmentId: lastMessage?.attributes?.entityId,
        });
      }
    } else {
      setSuggestedMessageOptions([]);
    }
  }, [clinic, channelMessagesData]);

  return (
    <CurbsideContainer>
      <ChatMediaPreviewModal
        fileURL={previewData.previewFileUrl}
        attachmentType={previewData.previewAttachmentType}
        fileName={previewData.previewFileName}
      />
      <MessageTimeline
        channelId={channelId}
        messages={channelMessagesData?.channelMessages || []}
        televetChannel={channelData?.channel}
        pageInfo={pageInfo}
        onFirstMessageMount={handleFirstMessageMount}
        onLastMessageMount={handleLastMessageMount}
        messageTimelineScrollContainerRef={messageTimelineScrollContainerRef}
        previousMessagesRef={previousMessagesRef}
        setViewAttachment={setFilePreview}
        suggestedMessageOptions={suggestedMessageOptions}
        suggestedMessageEntityId={suggestedMessageEntityId}
        workflowEventTriggerActionNumber={workflowEventTriggerActionNumber}
        workflowActionEventTrigger={workflowActionEventTrigger}
        workflowSettingId={workflowSettingId}
      />
      <MessageComposer
        channel={channel}
        userId={user?.uid}
        setViewAttachment={setFilePreview}
        handlePreviewClick={setFilePreview}
        suggestedMessageOptions={suggestedMessageOptions}
        workflowActionEventTrigger={workflowActionEventTrigger}
        suggestedMessageEntityId={suggestedMessageEntityId}
        workflowEventTriggerActionNumber={workflowEventTriggerActionNumber}
      />
    </CurbsideContainer>
  );
};

export default Conversations;

const CurbsideContainer = styled.div`
  align-items: stretch;
  background: #eee;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
`;
