import { gql } from '@apollo/client';

export const GET_CHANNEL_VIEW_CHANNEL = gql`
  query($where: ChannelWhereUniqueInput!) {
    channel(where: $where) {
      id
      twilioChannelSid
      lastMessage {
        id
        createdAt
        index
        body
      }
      clinic {
        id
        name
        logo
      }
      channelStatus {
        id
        name
        color
        channelStatusAction
      }
      channelMembers {
        id
        lastConsumedMessageIndex
        twilioMemberSid
        twilioIdentity
        clinicPetParent {
          id
          firstName
          lastName
          phoneNumber
          pets {
            id
            name
            species
          }
        }
        user {
          id
          firstName
          lastName
          nameDisplay
          profilePic
          email
          vetInfo {
            roles {
              role
            }
          }
          type
        }
      }
      assignees {
        id
        firstName
        lastName
      }
      attributes
    }
  }
`;

export const GET_CHANNEL_MESSAGES = gql`
  query getChannelMessages(
    $where: ChannelMessageWhereInput!
    $orderBy: ChannelMessageOrderByInput!
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    channelMessages(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      createdAt
      updatedAt
      body
      index
      messageType
      attachments {
        id
        attachmentType
        entityType
        entityId
        filename
        url
      }
      author {
        id
        twilioIdentity
        user {
          id
          firstName
          lastName
          profilePic
          nameDisplay
        }
        clinicPetParent {
          id
          firstName
          lastName
        }
      }
      channel {
        id
      }
      attributes
    }
    channelMessagesConnection(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_FORM_SUBMISSION_NAMES = gql`
  query getFormSubmissions($where: FormSubmissionWhereInput) {
    formSubmissions(where: $where) {
      id
      submittedAt
      formTemplate {
        title
      }
    }
  }
`;

export const GET_CHANNEL_MEMBERS_CONSUMPTION_HORIZON = gql`
  query getChannelMembersConsumptionHorizon($where: ChannelMemberWhereInput!) {
    channelMembers(where: $where) {
      id
      lastConsumedMessageIndex
    }
  }
`;
