import React from 'react';
import styled from 'styled-components/macro';
import TrashIcon from '@material-ui/icons/DeleteOutline';

import { FileUploadContext } from './AttachFiles';
import IconButton from '../theme/IconButton';

const FilePreview = ({
  fileName,
  fileType,
  fileURL,
  isUploading,
  videoHeight,
  onMediaClick,
}) => {
  return (
    <Container>
      <PreviewContainer>
        {fileType === 'image' ? (
          <Image
            src={fileURL}
            alt={fileName}
            onClick={() => onMediaClick(fileURL, fileName, fileType)}
          />
        ) : (
          <Video height={videoHeight} src={fileURL}>
            Your browser does not support the video tag.
          </Video>
        )}
      </PreviewContainer>
      {isUploading && (
        <FileUploadContext.Consumer>
          {({ removeFile }) => (
            <RemoveFileButton onClick={() => removeFile(fileName)}>
              <StyledTrashIcon />
            </RemoveFileButton>
          )}
        </FileUploadContext.Consumer>
      )}
    </Container>
  );
};

const Container = styled.div`
  border-radius: 6px;
  display: inline-block;
  height: 90px;
  margin: ${({ theme }) =>
    `${theme.spacing.spacingSizes.md} 25px ${theme.spacing.spacingSizes.xxxl} 0`};
  position: relative;
  width: 90px;
`;

const RemoveFileButton = styled(IconButton)`
  position: absolute;
  right: -15px;
  top: -15px;
  border: 2px solid #fff;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledTrashIcon = styled(TrashIcon)`
  color: #3c69e5;
`;

const PreviewContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const Video = styled.video`
  height: 100%;
`;

export default FilePreview;
