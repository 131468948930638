import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { DeviceSizes } from '@televet/televet-ui';

import { requiresPayment, isClosed } from '../../utilities/consultations';
import Messages from './Messages';
import BackButton from '../Common/BackButton';
import { hslToHsla } from '../theme/helpers';
import { SendArrow, PaperClip } from '../Common/Icons';

import sendMessage from '../../redux/chats/actions/sendMessage';
import { pushSnackbar } from '../../redux/snackbar/snackbarActions';
import { Mixpanel } from '../../redux/mixpanel';

const maxVideoSize = 15000000; // 15 MB
const acceptedFileTypes = 'image/*, video/*, *.mp4, video/mp4, video/x-m4v';

const Chat = props => {
  const history = useHistory();
  const inputFileRef = useRef(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [stagedFile, setStagedFile] = useState(null);

  const { sendMessage, pushSnackbar } = props;
  let { consultationId } = props;

  consultationId = consultationId
    ? consultationId
    : props.match !== undefined
    ? props.match?.params?.consultationId
    : null;

  const routeClinic = useSelector(state =>
    props.match !== undefined
      ? state.clinics[props.match.params?.clinicId]
      : null
  );
  const consultation = useSelector(
    state => state.consultations[consultationId]
  );
  const user = useSelector(state => state.user);

  const handleFileUploadClick = useCallback(() => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  }, [inputFileRef]);

  const handleSendMessage = useCallback(
    async message => {
      setIsLoading(true);
      if (stagedFile && consultation) {
        Mixpanel.track('Attachment sent to clinic');
        await sendMessage({
          consultationId,
          data: stagedFile.file,
          thumbnail: stagedFile.thumbnail,
          messageType: stagedFile.type,
          consultation,
          sender: user,
        });
        setStagedFile(null);
      }

      const trimmedMessage = message.trim();
      if (trimmedMessage && consultation) {
        Mixpanel.track('Message sent to clinic');
        await sendMessage({
          consultationId: consultationId,
          data: trimmedMessage,
          messageType: 'text',
          consultation,
          sender: user,
        });
      }
      setMessage('');
      setIsLoading(false);
    },
    [consultation, consultationId, sendMessage, stagedFile, user]
  );

  const validateVideoSize = videoSize => {
    return videoSize <= maxVideoSize;
  };

  const updateSelectedFiles = useCallback(
    async file => {
      const type = file.type.includes('image') ? 'pic' : 'video';

      if (type === 'video' && !validateVideoSize(file.size)) {
        pushSnackbar({
          type: 'error',
          message: 'Video must not exceed 15 MB combined',
        });
        return;
      }
      setStagedFile({
        file,
        type,
        consultationId,
      });
      return;
    },
    [consultationId, pushSnackbar]
  );

  const removeFile = () => {
    setStagedFile(null);
  };

  const handleThumbnail = useCallback(
    dataFile => {
      const binary = atob(dataFile.split(',')[1]);
      let array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      const blob = new Blob([new Uint8Array(array)], { type: 'image/png' });
      const date = Date.now();

      const file = new File([blob], `thumbnail-${date}.png`);

      setStagedFile({ ...stagedFile, thumbnail: file });
    },
    [stagedFile]
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        position: 'relative',
      }}
    >
      <VetsArea>
        {routeClinic && (
          <StyledBackButton>
            <BackButton onClick={() => history.goBack()} />
          </StyledBackButton>
        )}
        <VetAreaText>
          Chatting with{' '}
          {props.clinicName || routeClinic.info.name || 'your vet'}
        </VetAreaText>
      </VetsArea>
      <MessagesContainer>
        <Messages
          consultationId={consultationId}
          removeFile={removeFile}
          stagedFile={stagedFile}
          handleThumbnail={handleThumbnail}
          isLoading={isLoading}
        />
      </MessagesContainer>
      {!requiresPayment(consultation) && !isClosed(consultation) && (
        <StyledInputArea>
          <TextInputContainer>
            <StyledTextField
              id="outlined-basic"
              name="Send message"
              label="Send message"
              variant="outlined"
              autoComplete="off"
              type="text"
              placeholder="Type a message"
              value={message}
              onKeyPress={event => {
                if (event.key === 'Enter' && !event.shiftKey) {
                  event.preventDefault();
                  handleSendMessage(message);
                }
              }}
              onChange={event => {
                const message = event.currentTarget.value;
                setMessage(message);
              }}
            />
          </TextInputContainer>
          <ActionButtonsContainer>
            <HiddenInput
              type="file"
              name="file"
              id="file-input"
              accept={acceptedFileTypes}
              ref={inputFileRef}
              onChange={event => updateSelectedFiles(event.target.files[0])}
            />
            <ActionButton onClick={() => handleFileUploadClick()}>
              <PaperClip title="Attach files" />
            </ActionButton>
            <ActionButton
              onClick={() => {
                handleSendMessage(message);
              }}
            >
              <SendArrow title="Send message" />
            </ActionButton>
          </ActionButtonsContainer>
        </StyledInputArea>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  sendMessage,
  pushSnackbar,
};

export default connect(null, mapDispatchToProps)(Chat);

const VetsArea = styled.div`
  flex: 0 0 auto;
  background: ${({ theme }) => theme.palette.white};
  border-bottom: 1px solid
    ${({ theme }) => hslToHsla(theme.palette.gray700, 0.5)};
  padding: 16px;
`;

const MessagesContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const StyledInputArea = styled.div`
  flex: 0 0 auto;
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  @media (max-width: ${DeviceSizes.tablet}) {
    position: fixed;
    bottom: 86px;
    z-index: 100;
    background: ${({ theme }) => theme.palette.white};
  }
`;
const StyledBackButton = styled.span``;

const VetAreaText = styled.span`
  padding: 0 12px;
`;

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 100%;

  &:hover {
    background: #eee;
  }
`;

const ActionButtonsContainer = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  width: 90px;
`;

const HiddenInput = styled.input`
  display: none;
`;

const TextInputContainer = styled.div`
  flex: 1 1 auto;
`;

const StyledTextField = styled.input`
  background-color: transparent;
  width: 100%;
  padding: 16px 90px 16px 16px;
  font-size: 14px;
  outline: none;
  border: 1px solid transparent;
  border-top: 1px solid ${({ theme }) => hslToHsla(theme.palette.gray700, 0.5)};

  &:focus {
    border-top-color: ${({ theme }) => theme.palette.primaryLight};
  }
`;
