import React from 'react';
import styled from 'styled-components/macro';
import Badge from '@material-ui/core/Badge';

import BellIcon from '../../assets/svg/notifications-bell.svg';

const NotificationsIcon = ({ hasNotifications }) => {
  return (
    <div>
      <StyledBadge
        color="primary"
        variant="dot"
        overlap="circle"
        invisible={!hasNotifications}
      >
        <Icon src={BellIcon} alt="Notifications" />
      </StyledBadge>
    </div>
  );
};

const StyledBadge = styled(Badge)`
  .MuiBadge-colorPrimary {
    background-color: #53c999;
  }
`;

const Icon = styled.img`
  width: 24px;
`;

export default NotificationsIcon;
