import axios from 'axios';
import moment from 'moment';

import firebase from '../../../firebase/firebase';
import updateUser from '../../user/actions/updateUser';
import { Mixpanel as mixpanel } from '../../mixpanel';

const db = firebase.database();

const createUserWithEmailAndPassword = ({
  firstName: fname,
  lastName: lname,
  email,
  phone,
  password,
  pimsId,
}) => {
  return async (dispatch, getState) => {
    try {
      const { restURL } = getState().variable;

      await axios.post(`${restURL}/users/newOwnerUser`, {
        private: {
          email: email,
        },
        public: {
          fname,
          lname,
          pushNotification: true,
          emailNotification: true,
          smsNotification: false,
        },
        password: password,
        pimsId: pimsId || null,
      });

      const auth = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      const userSnapshot = await db.ref(`users/${auth.user.uid}`).once('value');
      const user = userSnapshot.val();

      mixpanel.people.set({
        $email: email,
        USER_ID: auth.user.uid,
        IS_DEVELOPMENT:
          process.env.REACT_APP_PRODUCTION === 'true' ? false : true,
        'Sign up date': moment(user.signUpDate).format('LLLL'),
        application: 'Pet Web',
      });

      mixpanel.identify(auth.user.uid);

      // Store phone number after user creation and authentication
      dispatch(updateUser({ phone, newUser: true }));
      mixpanel.track('Clicked register button', {
        success: true,
        'Sign up date': moment(user.signUpDate).format('LLLL'),
      });
      mixpanel.track('Add new pet', {
        isOnboarding: true,
      });
      mixpanel.track('User ID Created', { USER_ID: auth.user.uid });
    } catch (e) {
      mixpanel.track('Clicked register button', { success: false });
      console.error('error', e);
      throw new Error(
        e?.response?.data?.message ||
          'There was a problem registering your account. Please try again later.'
      );
    }
  };
};

export default createUserWithEmailAndPassword;
