import React, { ReactNode } from 'react';
import {
  ApolloProvider as ParentProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { ConnectionParams } from 'subscriptions-transport-ws';

const apolloClient = (
  getAuthHeaders: () => Record<string, string>,
  uri: string,
  clinicId: string | undefined
): ApolloClient<unknown> => {
  const httpLink = createHttpLink({
    uri,
  });

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_SUBSCRIPTIONS_URI || '',
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: async (a: any): Promise<ConnectionParams> =>
        getAuthHeaders(),
    },
  });

  const authLink = setContext(async (_, { headers }: { headers: {} }) => {
    return {
      headers: {
        clinicId: clinicId || '',
        ...headers,
      },
    };
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  return new ApolloClient({
    link: authLink.concat(splitLink),
    cache: new InMemoryCache(),
  });
};

interface IProps {
  getAuthHeaders: () => Record<string, string>;
  uri: string;
  clinicId: string | undefined;
  children: ReactNode;
}

const ApolloProvider = ({
  uri,
  getAuthHeaders,
  clinicId,
  children,
}: IProps): JSX.Element => {
  return (
    <ParentProvider client={apolloClient(getAuthHeaders, uri, clinicId)}>
      {children}
    </ParentProvider>
  );
};

export default ApolloProvider;
