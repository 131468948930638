import styled from 'styled-components/macro';
import { Button } from '@televet/televet-ui';
import { TextField } from '@material-ui/core';
import React, { useState } from 'react';

import { Mixpanel as mixpanel } from '../../../redux/mixpanel';

const AddPromoCode = ({
  handleSavePromoCode,
  handleCancel,
  helperText,
  error,
}) => {
  const [promoCode, setPromoCode] = useState();

  const handlePromoCodeChange = e => {
    setPromoCode(e.target.value);
    mixpanel.track('Promo code entered', {
      promoCode: e.target.value,
      isNewConsultation: this.props.isNewConsultation ? true : false,
    });
  };

  const clickSavePromoCode = () => {
    handleSavePromoCode(promoCode);
    mixpanel.track('Promo code saved', {
      promoCode,
      isNewConsultation: this.props.isNewConsultation ? true : false,
    });
  };

  return (
    <TextFieldContainer>
      <StyledTextField
        label="Add promo code"
        name="promo-codes-input"
        variant="outlined"
        helperText={helperText}
        error={error}
        onChange={handlePromoCodeChange}
        fullWidth
      />
      <ButtonRow>
        <Button buttonType="ghost" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          buttonType="primary"
          name="new-promo-codes-submit-button"
          disabled={!promoCode}
          onClick={clickSavePromoCode}
        >
          Save
        </Button>
      </ButtonRow>
    </TextFieldContainer>
  );
};

const TextFieldContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 15px;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.spacingSizes.sm};
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.sm};
`;

const StyledTextField = styled(TextField)`
  margin: ${({ theme }) => theme.spacing.spacingSizes.xs} 0;
  width: 100%;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.spacingSizes.xs};
  margin-right: 0;
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.xs};
`;

export default AddPromoCode;
