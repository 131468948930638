import React, { useState, useCallback, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { ResponsiveDeviceMinWidth, Button } from '@televet/televet-ui';
import { H1 } from '../../Common/Headers';
import ImageCropper from '../../Common/ImageCropper';
import { Dog, Cat, Horse, AnimalPrint } from '../../Common/Icons';
import { Mixpanel as mixpanel } from '../../../redux/mixpanel';

const iconTypes = {
  Dog: Dog,
  Cat: Cat,
  Horse: Horse,
};

const PetPhotoForm = ({
  name,
  type,
  image,
  onSave,
  onSubmit,
  isOnboarding,
}) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const selectedImageRef = createRef();

  const getImageFromUrl = useCallback(async () => {
    if (image) {
      try {
        const result = await fetch(image);
        const file = await result.blob();
        setPreviewImage(URL.createObjectURL(file));
        mixpanel.track('Pet photo selected', { isOnboarding });
      } catch (e) {
        setPreviewImage(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  useEffect(() => {
    getImageFromUrl();
  }, [getImageFromUrl, image]);

  const onUpdatePetImage = croppedImage => {
    onSave(croppedImage);
    setSelectedImage(null);
  };

  const EmptyImageIcon = iconTypes[type] || AnimalPrint;

  return (
    <>
      <H1>Care to share a picture of {name}?</H1>
      <ImageContainer>
        {!selectedImage && (
          <ImageArea onClick={() => selectedImageRef.current.click()}>
            <ImageAreaOverlay>
              {previewImage ? (
                <PreviewImage src={previewImage} alt={name} />
              ) : (
                <EmptyImageIcon
                  title={type}
                  style={{ height: '100%', width: '100%', stroke: 'none' }}
                />
              )}
            </ImageAreaOverlay>
            <FileInput
              type="file"
              accept=".png, .jpg, .jpeg"
              name="newPetImage"
              onChange={e => setSelectedImage(e.target.files[0])}
              ref={selectedImageRef}
            />
          </ImageArea>
        )}
      </ImageContainer>

      {selectedImage && (
        <ImageCropper
          src={selectedImage ? URL.createObjectURL(selectedImage) : null}
          circularCrop={true}
          crop={{
            aspect: 1,
            unit: '50%',
            x: 25,
            y: 25,
            width: 50,
            maxWidth: 60,
            maxHeight: 40,
          }}
          setCrop={onUpdatePetImage}
        />
      )}
      <ActionContainer>
        {!selectedImage && (
          <ActionButton
            style={{ width: '100%' }}
            fullWidth
            onClick={() => selectedImageRef.current.click()}
          >
            Select a photo
          </ActionButton>
        )}
        <ActionButton
          style={{ width: '100%' }}
          ton
          fullWidth
          onClick={() => {
            onSubmit();
            mixpanel.track('Clicked next with pet photo', { isOnboarding });
          }}
          disabled={!image}
        >
          Next
        </ActionButton>
        <SkipButton
          fullWidth
          onClick={() => {
            onSubmit(null);
            mixpanel.track('Clicked skip pet photo', { isOnboarding });
          }}
          buttonType="secondary"
        >
          Skip for now
        </SkipButton>
      </ActionContainer>
    </>
  );
};

PetPhotoForm.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  image: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default PetPhotoForm;

const ActionButton = styled(Button)`
  background: ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.white};
  border: 1px solid transparent;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background: ${({ theme }) => theme.palette.actionButtonHover} !important;
    border-color: ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const SkipButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white} !important;
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const ImageContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
`;

const ImageArea = styled.div`
  height: 250px;
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.md};
  position: relative;
  width: 250px;
`;

const ImageAreaOverlay = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.grayLightest};
  border-radius: 50%;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 0;

  svg {
    stroke: ${({ theme }) => theme.palette.primary};
    height: 96px;
    width: 96px;
  }
`;

const PreviewImage = styled.img`
  border-radius: 50%;
  height: 250px;
  width: 250px;
`;

const FileInput = styled.input`
  display: none;
`;

const ActionContainer = styled.div`
  width: 100%;

  @media ${ResponsiveDeviceMinWidth.mobileL} {
    margin: 0 auto;
    width: 50%;
  }

  @media ${ResponsiveDeviceMinWidth.laptopL} {
    margin: 0 auto;
    width: 40%;
  }
`;
