import styled from 'styled-components/macro';
import {
  ResponsiveDeviceMinWidth,
  Button as UIButton,
} from '@televet/televet-ui';

const ResponsiveButton = styled(UIButton)`
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.sm};
  min-width: auto;
  width: 100%;

  @media ${ResponsiveDeviceMinWidth.tablet} {
    margin-bottom: 0;
    min-width: 8rem;
    width: auto;
  }
`;

export default ResponsiveButton;
