import React, { memo } from 'react';
import styled from 'styled-components/macro';

import ChatMediaPreview from '../../../Consultation/ChatMediaPreview';
import { AttachIcon, PdfOutlineIcon, DocumentsOutlineIcon } from '../../icons';

const StagedAttachments = memo(
  ({ files, onAttachmentRemove, handlePreviewClick }) => {
    if (!files.length) return null;

    const renderTypeIcon = file => {
      const fileType = file?.type;
      if (fileType?.includes('pdf')) {
        return (
          <IconContainer>
            <PdfOutlineIcon />
          </IconContainer>
        );
      } else if (fileType?.includes('msword')) {
        return (
          <IconContainer>
            <DocumentsOutlineIcon />
          </IconContainer>
        );
      } else {
        return (
          <IconContainer>
            <AttachIcon />
          </IconContainer>
        );
      }
    };

    return (
      <StagedAttachmentsContainer>
        {files.map((file, index) => {
          if (file) {
            return (
              <ChatMediaPreview
                key={file.name}
                removeFile={() => onAttachmentRemove(index)}
                stagedFile={{ file, type: file.type }}
                renderTypeIcon={() => renderTypeIcon(file)}
                handlePreviewClick={handlePreviewClick}
              />
            );
          } else return null;
        })}
      </StagedAttachmentsContainer>
    );
  }
);

export default StagedAttachments;

const StagedAttachmentsContainer = styled.div`
  padding: 0 12px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const IconContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }

  & svg {
    width: auto;
    height: 20px;
  }

  & svg * {
    fill: #575d7c;
  }
`;
