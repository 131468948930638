import produce from 'immer';
import vetsActionTypes from './vetsActionTypes';

const initialState = {};

let vetsReducer = produce((draft, action) => {
  switch (action.type) {
    case vetsActionTypes.getVet:
      draft[action.payload.vetId] = action.payload.vetObj;
      break;

    default:
      break;
  }
}, initialState);

export default vetsReducer;
