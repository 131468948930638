import React, { useState, useReducer, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, ResponsiveDeviceMinWidth, useModal } from '@televet/televet-ui';
import reducer, { initialState } from './reducers';
import {
  onPetNameSubmit,
  onPetPracticeSubmit,
  onPetPhotoSubmit,
  petResetState,
} from './actions';
import savePetToDatabase from '../../redux/pets/actions/savePetToDatabase';
import BackButton from '../Common/BackButton';
import PetNameForm from './components/PetNameForm';
import PetPracticeForm from './components/PetPracticeForm';
import PetPhotoForm from './components/PetPhotoForm';
import PetInfoForm from './components/PetInfoForm';
import { Mixpanel as mixpanel } from '../../redux/mixpanel';

export const AVAILABLE_STEPS = {
  NAME: 'name',
  PRACTICE: 'practice',
  PHOTO: 'photo',
  INFO: 'info',
};

const steps = [
  AVAILABLE_STEPS.NAME,
  AVAILABLE_STEPS.PRACTICE,
  AVAILABLE_STEPS.PHOTO,
  AVAILABLE_STEPS.INFO,
];

const AddPet = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const [stepIndex, setStepIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { closeModal, modal } = useModal();

  useEffect(() => {
    if (!modal?.isOpen) {
      setStepIndex(0);
      dispatch(petResetState());
    }
  }, [setStepIndex, modal]);

  const onBack =
    stepIndex !== 0 ? () => setStepIndex(state => state - 1) : undefined;
  const onNext = () => setStepIndex(state => state + 1);

  const handlePetNameSubmit = values => {
    dispatch(onPetNameSubmit(values));
    onNext();
  };

  const handlePetPracticeSubmit = values => {
    dispatch(onPetPracticeSubmit(values));
    onNext();
  };

  const handlePetPhotoSubmit = values => {
    dispatch(onPetPhotoSubmit(values));
    mixpanel.track('Pet photo selected', { isOnboarding: false });
    onNext();
  };

  const handlePetInfoSubmit = async values => {
    setIsLoading(true);
    mixpanel.track('User clicked finish adding pet', { isOnboarding: true });
    const petId = await reduxDispatch(
      savePetToDatabase({
        ...state,
        ...values,
      })
    );
    const { breed, weight } = values;
    if (breed)
      mixpanel.track('Breed entered', {
        breed,
        isOnboarding: true,
      });
    if (weight)
      mixpanel.track('Weight entered', {
        weight,
        isOnboarding: true,
      });
    setIsLoading(false);
    closeModal();
    history.push(`/pets/${petId}`);
  };

  const renderContent = () => {
    const {
      name,
      type,
      otherType,
      practiceName,
      image,
      gender,
      reproductiveStatus,
      environment,
      breed,
      weight,
      birthday,
    } = state;
    switch (steps[stepIndex]) {
      case AVAILABLE_STEPS.NAME:
        return (
          <PetNameForm
            name={name}
            type={type}
            otherType={otherType}
            onSubmit={handlePetNameSubmit}
            isOnboarding={false}
          />
        );
      case AVAILABLE_STEPS.PRACTICE:
        return (
          <PetPracticeForm
            name={name}
            practiceName={practiceName}
            onSubmit={handlePetPracticeSubmit}
            isOnboarding={false}
          />
        );
      case AVAILABLE_STEPS.PHOTO:
        return (
          <PetPhotoForm
            name={name}
            type={type}
            image={image}
            onSubmit={handlePetPhotoSubmit}
            onSave={handlePetPhotoSubmit}
            isOnboarding={false}
          />
        );
      case AVAILABLE_STEPS.INFO:
        return (
          <PetInfoForm
            name={name}
            type={type}
            gender={gender}
            reproductiveStatus={reproductiveStatus}
            environment={environment}
            breed={breed}
            weight={weight}
            birthday={birthday}
            onSubmit={handlePetInfoSubmit}
            isLoading={isLoading}
            isOnboarding={false}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal size="lg" name="add-pet">
      <ModalContent>
        <ContentHeader>
          {onBack && <BackButton onClick={onBack} />}
        </ContentHeader>
        {renderContent()}
      </ModalContent>
    </Modal>
  );
};

export default AddPet;

const ModalContent = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  padding: 0 1em 1em 1em;

  @media ${ResponsiveDeviceMinWidth.tablet} {
    min-height: 650px;
    padding: 0 4em 4em 4em;
  }
`;

const ContentHeader = styled.div`
  align-items: flex-end;
  display: flex;
  height: 2em;
  width: 100%;

  @media ${ResponsiveDeviceMinWidth.tablet} {
    height: 4em;
  }
`;
