import { connect } from 'react-redux';
import { removeSnackbar as removeSnackbarAction } from '../../redux/snackbar/snackbarActions';
import Snackbars from './components/Snackbars';

const mapStateToProps = state => {
  return {
    snackbars: state.snackbar,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeSnackbar: id => dispatch(removeSnackbarAction(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Snackbars);
