import React, { ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import Prompt from './Shared/Prompt';
import QuestionWrapper from './Shared/QuestionWrapper';
import CheckedCheckbox from '../../../../assets/svg/checked-checkbox.svg';
import RequiredQuestionMessage from './Shared/RequiredQuestionMessage';
interface Props {
  index: number;
  prompt: string;
  answer: string | undefined;
  defaultValue: boolean;
  setIsDraftSaved: Function;
  setAnswerAtIndex: Function;
  isRequired: boolean;
  isInvalid: boolean;
}

const Checkbox = ({
  index,
  prompt,
  answer,
  defaultValue,
  setIsDraftSaved,
  setAnswerAtIndex,
  isRequired,
  isInvalid,
}: Props): ReactElement => {
  const handleToggle = (): void => {
    let newAnswer = answer === 'false' ? 'true' : 'false';
    setIsDraftSaved(false);
    setAnswerAtIndex(newAnswer, index);
  };

  useEffect(() => {
    let newAnswer = '';
    if (answer === undefined) {
      newAnswer = `${defaultValue}`;
      setAnswerAtIndex(newAnswer, index);
    }
  });
  return (
    <QuestionWrapper index={index}>
      <Row>
        {answer === 'true' ? (
          <CustomCheckedCheckbox
            data-mixpanel-name="Checkbox form field"
            src={CheckedCheckbox}
            onClick={handleToggle}
          />
        ) : (
          <CustomEmptyCheckbox
            data-mixpanel-name="Checkbox form field"
            onClick={handleToggle}
          />
        )}
        <Prompt inline prompt={prompt} isRequired={isRequired} />
      </Row>
      {isInvalid ? <RequiredQuestionMessage /> : null}
    </QuestionWrapper>
  );
};

export default Checkbox;
const Row = styled.div`
  display: flex;
  align-items: center;
`;

const CustomCheckedCheckbox = styled.img`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  margin-right: 10px;
`;

const CustomEmptyCheckbox = styled.div`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 2px;
  border: solid 1.5px #575d7c;
  background-color: #ffffff;
`;
