import firebase from '../../../firebase/firebase';
import { store } from '../../store';

const setMessageRead = async (consultationId, messageId) => {
  await firebase
    .database()
    .ref(`chats/${consultationId}/${messageId}/`)
    .update({ read: Date.now() });

  const userId = store.getState().auth.user.uid;
  const updateObject = {};
  updateObject[userId] = true;
  await firebase
    .database()
    .ref(`consults/${consultationId}/last_message/readBy`)
    .update(updateObject);
  return { type: '' };
};

export default setMessageRead;
