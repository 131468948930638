import React, {
  useCallback,
  useContext,
  useState,
  HTMLAttributes,
} from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import styled from 'styled-components/macro';

import {
  ChannelMessageAttachmentType,
  ChannelMessageAttachmentEntityType,
} from '../enums';
import { GET_STRIPE_PAYMENT_INTENT } from '../queries';
import Context from '../context/curbsideContext';
import { ReactComponent as InvoiceOutlineIcon } from '../../../assets/svg/invoice-receipt-outline.svg';

import PaymentModal, { StripePaymentIntentStatus } from './PaymentModal';

type ChannelMessageAttachment = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  attachmentType: ChannelMessageAttachmentType;
  entityType?: ChannelMessageAttachmentEntityType;
  entityId?: string;
  filename?: string;
  url?: string;
};

interface IInvoiceAttachmentProps {
  attachment: ChannelMessageAttachment;
}

const InvoiceAttachment = React.memo(
  ({ attachment }: IInvoiceAttachmentProps): JSX.Element => {
    const { cache } = useApolloClient();
    const curbsideContext = useContext<{ [key: string]: any }>(Context);
    const {
      data: stripePaymentIntentData,
      loading: stripePaymentIntentDataLoading,
    } = useQuery(GET_STRIPE_PAYMENT_INTENT, {
      variables: {
        where: {
          id: attachment.entityId,
        },
      },
      context: {
        headers: curbsideContext.headers,
      },
    });

    const { url, filename } = attachment;
    const paymentIntent = stripePaymentIntentData?.stripePaymentIntent || {};
    const { amount, status } = paymentIntent;
    const amountDisplayed = amount ? (amount / 100).toFixed(2) : '—';

    const handlePreviewClick = useCallback(() => {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: url,
      }).click();
    }, [url]);

    const [isPaying, setIsPaying] = useState(false);

    const handlePaymentModalClose = (isSuccess: boolean): void => {
      if (isSuccess) {
        cache.modify({
          id: cache.identify(paymentIntent),
          fields: {
            status: cachedStatus => StripePaymentIntentStatus.Succeeded,
          },
        });
      }
      setIsPaying(false);
    };

    return (
      <InvoiceAttachmentContainer>
        <PaymentModal
          isOpen={isPaying}
          paymentIntent={paymentIntent}
          amount={amountDisplayed}
          onClose={handlePaymentModalClose}
        />
        <InvoiceContainer>
          <InvoiceHeader>
            <IconContainer>
              <StyledInvoiceOutlineIcon />
            </IconContainer>
            <h3>Invoice</h3>
          </InvoiceHeader>
          <Amount>
            <div>Total:</div>
            <div>$ {amountDisplayed}</div>
          </Amount>
          <StatusButton
            disabled={
              stripePaymentIntentDataLoading ||
              status === StripePaymentIntentStatus.Succeeded
            }
            onClick={(): void => setIsPaying(isPaying => !isPaying)}
          >
            {stripePaymentIntentDataLoading
              ? 'Loading...'
              : status === StripePaymentIntentStatus.Succeeded
              ? 'Paid'
              : 'Pay Now'}
          </StatusButton>
        </InvoiceContainer>
        {!!url && !!filename && (
          <>
            <HR />
            <Document onClick={handlePreviewClick}>
              <StyledInvoiceOutlineIcon />
              {/* <TruncatedText lines={2}>{filename}</TruncatedText> */}
              {filename}
            </Document>
          </>
        )}
      </InvoiceAttachmentContainer>
    );
  }
);

InvoiceAttachment.displayName = 'InvoiceAttachment';

const InvoiceAttachmentContainer = styled.div`
  background: #575d7c;
  color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const InvoiceContainer = styled.div``;
const InvoiceHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  & h3 {
    font-weight: bold;
    font-size: 16px;
    display: inline-block;
    margin: 5px 0 0 10px;
  }
`;

const IconContainer = styled.div`
  background: #fff;
  width: 38px;
  height: 38px;
  border-radius: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 22px;
    height: auto;
  }
`;

const StyledInvoiceOutlineIcon = styled(InvoiceOutlineIcon)``;

const Amount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
`;

const StatusButton = styled.button<HTMLAttributes<HTMLElement>>`
  width: 100%;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  background: hsl(190 60% 40%);
  color: #fff;
  font-weight: bold;
  margin-top: 15px;
  cursor: pointer;

  &[disabled] {
    background: #b6b8c5;
  }
`;

const HR = styled.hr`
  border-top: 1px solid #f8f8f8;
  border-right: none;
  border-bottom: none;
  border-left: none;
  margin: 15px -5px;
`;

const Document = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #f8f8f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  cursor: pointer;
  font-size: 14px;

  & ${StyledInvoiceOutlineIcon} {
    flex: 0 0 auto;
    margin-right: 10px;
    width: 22px;
    height: auto;

    & * {
      fill: #fff;
    }
  }
`;

export default InvoiceAttachment;
