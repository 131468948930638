import React, { ReactElement } from 'react';
import styled from 'styled-components';
import RatingComponent from 'react-rating';
import Prompt from './Shared/Prompt';
import QuestionWrapper from './Shared/QuestionWrapper';
import StarFull from '../../../../assets/svg/star-full.svg';
import StarEmpty from '../../../../assets/svg/star-empty.svg';
import RequiredQuestionMessage from './Shared/RequiredQuestionMessage';

interface IRating {
  index: number;
  prompt: string;
  answer: string;
  minValue: number;
  maxValue: number;
  setIsDraftSaved: Function;
  setAnswerAtIndex: Function;
  isRequired: boolean;
  isInvalid: boolean;
}

const Rating = ({
  index,
  prompt,
  answer,
  setIsDraftSaved,
  setAnswerAtIndex,
  minValue,
  maxValue,
  isRequired,
  isInvalid,
}: IRating): ReactElement => {
  return (
    <QuestionWrapper index={index}>
      <Prompt prompt={prompt} isRequired={isRequired} />
      <br />

      <RatingComponent
        start={minValue}
        stop={maxValue}
        initialRating={parseFloat(answer)}
        data-mixpanel-name="Rating input form field"
        onClick={(value: number) => {
          const answer = value;
          setIsDraftSaved(false);
          setAnswerAtIndex(answer.toString(), index);
        }}
        emptySymbol={
          <IconContainer>
            <Icon src={StarEmpty} alt="" />
          </IconContainer>
        }
        fullSymbol={
          <IconContainer>
            <Icon src={StarFull} alt="" />
          </IconContainer>
        }
      />
      {isInvalid ? <RequiredQuestionMessage /> : null}
    </QuestionWrapper>
  );
};

export default Rating;

const IconContainer = styled.div`
  display: flex;
  flex: 1;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  height: 28px;
  width: 28px;
  padding: 1px;
`;
