import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Checkbox,
  ResponsiveDeviceMinWidth,
  BounceLoader,
} from '@televet/televet-ui';
import createUserAction from '../../redux/auth/actions/createUserWithEmailAndPassword';
import { email, phone as phoneValidator } from '../../utilities/validation';
import { phone as phoneMask } from '../../utilities/masks';
import { DeeplinkTypes } from '../Deeplink/Deeplink';
import CenteredBanner from '../Common/Layouts/CenteredBanner';
import ResponsiveButton from '../Common/ResponsiveButton';
import { H1 } from '../Common/Headers';
import Link from '../Common/Link';
import CallToAction from './components/CallToAction';
import SubmissionMessage from '../Common/Message';
import Text from '../theme/Text';
import { Mixpanel as mixpanel } from '../../redux/mixpanel';

const Register = ({ createUser }) => {
  const [state, setState] = useState({ error: null, loading: false });
  const [hasEnteredEmail, setHasEnteredEmail] = useState(false);
  const [hasEnteredPassword, setHasEnteredPassword] = useState(false);
  const [hasEnteredFirstName, setHasEnteredFirstName] = useState(false);
  const [hasEnteredLastName, setHasEnteredLastName] = useState(false);
  const [hasEnteredPhoneNumber, setHasEnteredPhoneNumber] = useState(false);
  const [hasCheckedTermsOfService, setHasCheckedTermsOfService] = useState(
    false
  );
  const [deeplinkData, setDeeplinkData] = useState();
  const { loading, error } = state;
  const { register, handleSubmit, reset, control, watch, errors } = useForm({
    mode: 'onBlur',
  });
  const history = useHistory();

  useEffect(() => {
    const deeplink = history.location.state?.deeplink;
    if (deeplink) {
      setDeeplinkData(deeplink);

      let userUpdates = {};
      if (deeplink.type === DeeplinkTypes.FOLLOWUP) {
        const [firstName, lastName] = deeplink.clientName.split(' ');
        userUpdates = { firstName, lastName, email: deeplink.clientEmail };
      } else if (
        deeplink.type === DeeplinkTypes.FULL_REGISTRATION_WITH_FOLLOW_UP_LINK
      ) {
        const { name, email, phone } = deeplink.clients[0];
        const [firstName, lastName] = name.split(' ');
        userUpdates = {
          firstName,
          lastName,
          email,
          phone: phone ? phoneMask(phone.substr(-10)) : null,
        };
      }

      reset(userUpdates);
    }
  }, [reset, history]);

  const emailWatch = watch('email');
  const phoneWatch = watch('phone');
  const passwordWatch = watch('password');
  const lastNameWatch = watch('lastName');
  const firstNameWatch = watch('firstName');
  const termsWatch = watch('termsOfService');

  useEffect(() => {
    if (emailWatch && !hasEnteredEmail) {
      mixpanel.track('Email entered', { source: 'Register' });
      setHasEnteredEmail(true);
    }
  }, [emailWatch, hasEnteredEmail]);

  useEffect(() => {
    if (passwordWatch && !hasEnteredPassword) {
      mixpanel.track('Password entered', { source: 'Register' });
      setHasEnteredPassword(true);
    }
  }, [passwordWatch, hasEnteredPassword]);

  useEffect(() => {
    if (firstNameWatch && !hasEnteredFirstName) {
      mixpanel.track('First name entered', { source: 'Register' });
      setHasEnteredFirstName(true);
    }
  }, [firstNameWatch, hasEnteredFirstName]);

  useEffect(() => {
    if (lastNameWatch && !hasEnteredLastName) {
      mixpanel.track('Last name entered', { source: 'Register' });
      setHasEnteredLastName(true);
    }
  }, [lastNameWatch, hasEnteredLastName]);

  useEffect(() => {
    if (phoneWatch && !hasEnteredPhoneNumber) {
      mixpanel.track('Phone number entered', { source: 'Register' });
      setHasEnteredPhoneNumber(true);
    }
  }, [phoneWatch, hasEnteredPhoneNumber]);

  useEffect(() => {
    if (termsWatch && !hasCheckedTermsOfService) {
      mixpanel.track('Terms of service checked', { source: 'Register' });
      setHasCheckedTermsOfService(true);
    }
  }, [termsWatch, hasCheckedTermsOfService]);

  const onSubmit = async values => {
    const { firstName, lastName, email, phone, password } = values;
    try {
      setState({ error: null, loading: true });
      await createUser({
        firstName,
        lastName,
        email,
        phone,
        password,
        pimsId: deeplinkData?.clients ? deeplinkData?.clients[0]?.pimsId : null,
      });
      history.push('/onboarding', { deeplink: deeplinkData });
    } catch (e) {
      setState({ error: e.message, loading: false });
    }
  };

  const onPhoneChangeIntercept = ([e]) => {
    e.target.value = phoneMask(e.target.value);
    return e;
  };

  const renderPreregistrationMessage = () => {
    if (
      !(
        deeplinkData?.type === DeeplinkTypes.FOLLOWUP ||
        deeplinkData?.type ===
          DeeplinkTypes.FULL_REGISTRATION_WITH_FOLLOW_UP_LINK
      )
    ) {
      return null;
    }

    const { clinicName } = deeplinkData;

    return (
      <PreregistrationMessage>
        Good news! The team at {clinicName} has already gathered some
        information for your account.
      </PreregistrationMessage>
    );
  };

  return (
    <CenteredBanner>
      <H1>Register for an account</H1>
      <CallToAction>
        Have an account?{' '}
        <AuthLinks
          onClick={() =>
            mixpanel.track('Clicked login link', { source: 'Register' })
          }
          to={{ pathname: '/login', state: { deeplink: deeplinkData } }}
        >
          Login
        </AuthLinks>
      </CallToAction>
      {renderPreregistrationMessage()}
      {error && <SubmissionMessage type="error">{error}</SubmissionMessage>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Item>
            <TextInput
              name="firstName"
              label="First Name"
              register={register}
              validation={{ required: true }}
              error={errors.firstName && 'Please enter a first name'}
            />
          </Item>
          <Item>
            <TextInput
              name="lastName"
              label="Last Name"
              register={register}
              validation={{ required: true }}
              error={errors.lastName && 'Please enter a last name'}
            />
          </Item>
        </Row>

        <TextInput
          name="email"
          label="Email"
          register={register}
          validation={{ required: true, pattern: email }}
          error={errors.email && 'Please enter a valid email'}
        />

        <Controller
          as={TextInput}
          label="Phone Number"
          name="phone"
          control={control}
          rules={{ required: true, pattern: phoneValidator }}
          error={errors.phone && 'Please enter a valid phone number'}
          defaultValue=""
          onChange={onPhoneChangeIntercept}
        />
        <TextInput
          name="password"
          type="password"
          label="Password"
          register={register}
          validation={{ required: true, minLength: 8 }}
          error={
            errors.password && 'Password must be a minimum of 8 characters'
          }
        />
        <CheckboxContainer>
          <Checkbox
            name="termsOfService"
            label={
              <span>
                I agree to the{' '}
                <ExternalLink
                  onClick={() =>
                    mixpanel.track('Terms of service viewed', {
                      source: 'Register',
                    })
                  }
                  href="https://www.televet.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of service.
                </ExternalLink>
              </span>
            }
            register={register}
            validation={{ required: true }}
            error={errors.termsOfService && 'This field is required'}
          />
        </CheckboxContainer>
        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <Text>Setting up your account...</Text>
            <BounceLoader loading={loading} />
          </div>
        ) : (
          <ResponsiveButton>Register</ResponsiveButton>
        )}
      </form>
    </CenteredBanner>
  );
};

Register.propTypes = {
  createUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = { createUser: createUserAction };

export default connect(null, mapDispatchToProps)(Register);

const AuthLinks = styled(Link)`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

const Row = styled.div`
  display: block;
  width: 100%;

  @media ${ResponsiveDeviceMinWidth.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Item = styled.div`
  width: 100%;

  @media ${ResponsiveDeviceMinWidth.tablet} {
    width: 48%;
  }
`;

const ExternalLink = styled.a`
  color: ${({ theme }) => {
    return theme.palette.primary;
  }};

  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }

  &.error {
    color: ${({ theme }) => {
      return theme.palette.error;
    }};
  }
`;

const PreregistrationMessage = styled.div`
  border: 1px solid #ddd;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background: ${({ theme }) => theme.palette.grayLightest};
  padding: ${({ theme }) => theme.spacing.spacingSizes.md};
  margin-bottom: ${({ theme }) => theme.spacing.spacingSizes.lg};
  font-size: ${({ theme }) => theme.font.fontSizes.md};
  line-height: 1.5;
`;

const CheckboxContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.spacingSizes.lg} 0;
`;
