import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { useForm, Controller } from 'react-hook-form';
import { TextInput, Select, Button } from '@televet/televet-ui';
import {
  email as emailValidator,
  phone as phoneValidator,
} from '../../utilities/validation';
import { phone as phoneMask } from '../../utilities/masks';
import updateUserImage from '../../redux/user/actions/updateUserImage';
import updateUser from '../../redux/user/actions/updateUser';
import { pushSnackbar } from '../../redux/snackbar/snackbarActions';
import Avatar from '../Common/Avatar';
import ActionButton from '../Common/ActionButton';
import ImageCropper from '../Common/ImageCropper';

const timezoneOptions = [
  { value: 'auto', label: 'Auto' },
  { value: 'US/Eastern', label: 'ET – Eastern Time' },
  { value: 'US/Central', label: 'CT – Central Time' },
  { value: 'US/Mountain', label: 'MT – Mountain Time' },
  { value: 'US/Pacific', label: 'PT – Pacific Time' },
  { value: 'US/Alaska', label: 'AKT – Alaska Time' },
  { value: 'US/Hawaii', label: 'HT – Hawaii Time' },
];

const EditUser = ({
  user,
  userId,
  updateUserImage,
  updateUser,
  onFinishEditing,
  pushSnackbar,
}) => {
  const { fname, lname, tz, profilePic } = user?.public;
  const { email, phone } = user?.private;

  const selectedImageRef = createRef();
  const [userImage, setUserImage] = useState(null);
  const { register, handleSubmit, control, errors } = useForm({
    defaultValues: {
      fname,
      lname,
      email,
      phone: phoneMask(phone),
      tz: tz || 'auto',
    },
    mode: 'onBlur',
  });

  const onUpdateImage = croppedImage => {
    updateUserImage(userId, croppedImage);
    setUserImage(null);
  };

  const onSubmit = values => {
    updateUser(values);
    onFinishEditing();
    pushSnackbar({
      type: 'success',
      message: 'Success: Your information was updated',
    });
  };

  const onPhoneChangeIntercept = ([e]) => {
    e.target.value = phoneMask(e.target.value);
    return e;
  };

  if (!user.public) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        name="fname"
        label="First Name"
        register={register}
        validation={{ required: true }}
        error={errors.fname && 'Please enter a first name'}
      />
      <TextInput
        name="lname"
        label="Last Name"
        register={register}
        validation={{ required: true }}
        error={errors.fname && 'Please enter a last name'}
      />
      {userImage ? null : (
        <ImageContainer>
          <ImageArea>
            <Avatar
              url={profilePic}
              alt="User"
              height="248px"
              borderRadius="5%"
            />
            <input
              type="file"
              style={{ display: 'none' }}
              accept=".png, .jpg, .jpeg"
              name="newUserImage"
              onChange={event => setUserImage(event.target.files[0])}
              ref={selectedImageRef}
            />
          </ImageArea>
          <SelectPhotoButton
            buttonType="ghost"
            fullWidth
            type="button"
            onClick={() => {
              selectedImageRef.current.click();
            }}
          >
            Select a photo
          </SelectPhotoButton>
        </ImageContainer>
      )}
      {userImage ? (
        <ImageCropper
          src={userImage ? URL.createObjectURL(userImage) : null}
          circularCrop={false}
          crop={{
            aspect: 1,
            unit: '%',
            x: 25,
            y: 25,
            width: 50,
            maxWidth: 60,
            maxHeight: 40,
          }}
          setCrop={onUpdateImage}
        />
      ) : null}
      <TextInput
        name="email"
        label="Email"
        register={register}
        validation={{ required: true, pattern: emailValidator }}
        error={errors.email && 'Please enter a valid email'}
      />
      <Controller
        as={TextInput}
        label="Phone Number"
        name="phone"
        control={control}
        rules={{ required: true, pattern: phoneValidator }}
        error={errors.phone && 'Please enter a valid phone number'}
        onChange={onPhoneChangeIntercept}
      />
      <Select
        name="tz"
        label="Timezone"
        options={timezoneOptions}
        register={register}
      />
      <ActionContainer>
        <CancelButton
          onClick={onFinishEditing}
          buttonType="ghost"
          name="cancel-user-edit-button"
        >
          Cancel
        </CancelButton>
        <ActionButton>Save</ActionButton>
      </ActionContainer>
    </form>
  );
};

EditUser.propTypes = {
  user: PropTypes.object,
  userId: PropTypes.string,
  updateUserImage: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  onFinishEditing: PropTypes.func.isRequired,
  pushSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user.info,
  userId: state.auth?.user?.uid,
});

const mapDispatchToProps = { updateUser, updateUserImage, pushSnackbar };

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);

const CancelButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};
  margin-right: 8px !important;
  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const SelectPhotoButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.palette.actionButton};
  color: ${({ theme }) => theme.palette.actionButton};
  margin-top: 8px;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) =>
      theme.palette.actionButtonHover} !important;
    border: 1px solid ${({ theme }) => theme.palette.actionButtonHover} !important;
  }
`;

const ImageArea = styled.div`
  height: 248px;
  position: relative;
  width: 248px;
  max-height: 248px;
  text-align: center;
  display: inline-block;
`;

const ImageContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const ActionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button {
    min-width: 6rem;
  }
  button:first-child {
    margin-right: 3px;
  }
`;
