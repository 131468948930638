import React from 'react';
import styled from 'styled-components/macro';
import { Card, CardContent, Typography } from '@material-ui/core';

import MediaAttachmentList from './MediaAttachmentList';

const MediaSection = ({ images, videos }) => {
  return (
    <StyledCard raised>
      <CardContent>
        <Typography>Uploaded Attachments</Typography>
        <StyledSpacingDiv>
          {images && Array.isArray(images) && (
            <>
              <Typography>Images</Typography>
              <MediaAttachmentList mediaList={images} mediaType="image" />
            </>
          )}
        </StyledSpacingDiv>
        <StyledSpacingDiv>
          {videos && Array.isArray(videos) && (
            <>
              <Typography>Videos</Typography>
              <MediaAttachmentList mediaList={videos} mediaType="video" />
            </>
          )}
        </StyledSpacingDiv>
      </CardContent>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin: 10px 0 20px 0;
`;

const StyledSpacingDiv = styled.div`
  margin: 10px 0 20px 0;
`;

export default MediaSection;
