import firebase from '../../../firebase/firebase';
import getUser from '../../user/actions/getUser';
import {
  numberFormat,
  capitalizeString,
  getMonth,
  removeEmptyValues,
} from '../../../utilities';
import { pushSnackbar } from '../../snackbar/snackbarActions';

const savePetToDatabase = ({
  type,
  otherType,
  name,
  practiceName,
  practiceId,
  image,
  gender,
  reproductiveStatus,
  environment,
  breed,
  weight,
  month,
  day,
  year,
  pimsId,
}) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.user.uid;

    const data = {
      clinicDisplay: practiceName,
      clinicId: practiceId,
      clinics: {
        [practiceId]: true,
      },
      public: {
        DOB: {
          day: day,
          month: getMonth(month),
          year: year,
        },
        breed,
        env: environment,
        name: capitalizeString(name),
        owner: userId,
        profilePic: '',
        repoStatus: reproductiveStatus,
        sex: gender,
        type: type === 'Other' ? otherType : type,
        weight: numberFormat(weight),
      },
      pimsId: pimsId,
    };

    const petData = removeEmptyValues(data);

    try {
      const pet = await firebase
        .database()
        .ref(`pets/`)
        .push(petData);

      if (image) {
        const imgURL = await uploadImage(pet.key, image);
        await await firebase
          .database()
          .ref(`pets/${pet.key}/public`)
          .update({ profilePic: imgURL });
      }

      await saveClinicInUser(userId, practiceId, dispatch);
      await savePetInUser(userId, pet.key, dispatch);

      return pet.key;
    } catch (e) {
      console.error(e);
      dispatch(
        pushSnackbar({
          type: 'error',
          message: e.message,
        })
      );
    }
  };
};

export default savePetToDatabase;

const uploadImage = async (petId, petImage) => {
  const blob = await fetch(petImage).then(r => r.blob());
  await firebase
    .storage()
    .ref()
    .child(`pets/${petId}`)
    .put(blob);
  return await firebase
    .storage()
    .ref(`pets/${petId}`)
    .getDownloadURL();
};

const savePetInUser = async (userId, petId, dispatch) => {
  try {
    await firebase
      .database()
      .ref(`users/${userId}/info/pets`)
      .update({
        [petId]: true,
      });
    await dispatch(getUser());
  } catch (e) {
    dispatch(
      pushSnackbar({
        type: 'error',
        message: e.message,
      })
    );
  }
};

export const saveClinicInUser = async (userId, clinicId, dispatch) => {
  try {
    await firebase
      .database()
      .ref(`users/${userId}/info/clinics`)
      .update({
        [clinicId]: true,
      });
  } catch (e) {
    dispatch(
      pushSnackbar({
        type: 'error',
        message: e.message,
      })
    );
  }
};
