import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  NotificationIcon,
  ClinicMembersIcon,
  ParentMembersIcon,
  ChevronLeftIcon,
} from '../../../Common/Icons';
import { views } from '../constants/views';
import ModalHeader from './ModalHeader';
import Divider from './Divider';
import { Avatar, AvatarSize } from '../../Avatar';

const SettingsMenu = ({
  clinicAssignees,
  parentMembers,
  firstName,
  lastName,
  onDone,
  onSetView,
}) => {
  return (
    <SettingsContainer>
      <ModalHeader onDone={onDone} />
      <SettingsHeaderContainer>
        <AvatarContainer>
          <Avatar
            firstName={firstName}
            lastName={lastName}
            size={AvatarSize.XXLarge}
          />
        </AvatarContainer>
        <UserHeadline>{`${firstName} ${lastName}`}</UserHeadline>
      </SettingsHeaderContainer>
      <Divider />
      <SettingsList>
        <SettingsListItem onClick={() => onSetView(views.parentMembers)}>
          <TitleContainer>
            <ParentMembersIcon />
            <SettingsTitle>Parent Members</SettingsTitle>
          </TitleContainer>
          <IndicatorContainer>
            <IndicatorContent>
              <Indicator>
                <IndicatorText>{parentMembers.length}</IndicatorText>
              </Indicator>
            </IndicatorContent>
            <GoToButton />
          </IndicatorContainer>
        </SettingsListItem>
        <SettingsListItem onClick={() => onSetView(views.clinicAssignees)}>
          <TitleContainer>
            <ClinicMembersIcon />
            <SettingsTitle>Clinic Member in Chat</SettingsTitle>
          </TitleContainer>
          <IndicatorContainer>
            <IndicatorContent>
              <Indicator>
                <IndicatorText>{clinicAssignees.length}</IndicatorText>
              </Indicator>
            </IndicatorContent>
            <GoToButton />
          </IndicatorContainer>
        </SettingsListItem>
        <SettingsListItem onClick={() => onSetView(views.notificationSettings)}>
          <TitleContainer>
            <NotificationIcon />
            <SettingsTitle>Your Notifications</SettingsTitle>
          </TitleContainer>
          <IndicatorContainer>
            <GoToButton />
          </IndicatorContainer>
        </SettingsListItem>
      </SettingsList>
    </SettingsContainer>
  );
};

SettingsMenu.propTypes = {
  onDone: PropTypes.func.isRequired,
  clinicAssignees: PropTypes.array.isRequired,
  parentMembers: PropTypes.array.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

export default SettingsMenu;

const SettingsContainer = styled.div``;

const SettingsHeaderContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
`;

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const UserHeadline = styled.h2`
  color: #575d7c;
  display: inline-block;
  font-size: 22px;
`;

const SettingsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const SettingsListItem = styled.li`
  align-items: center;
  border-bottom: solid 1px #e8e9e9;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px 0;
`;

const SettingsTitle = styled.span`
  color: #575d7c;
  font-size: 18px;
  margin-left: 10px;
`;

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  width: 75%;
`;

const IndicatorContainer = styled.div`
  text-align: right;
  width: 25%;
`;

const IndicatorContent = styled.div`
  display: inline-block;
  margin-right: 10px;
`;

const Indicator = styled.div`
  align-items: center;
  background-color: #39adc3;
  border-radius: 50%;
  display: flex;
  height: 20px;
  justify-content: center;
  position: relative;
  width: 20px;
`;

const IndicatorText = styled.span`
  color: #ffffff;
  font-size: 12px;
  position: relative;
`;

const GoToButton = styled(ChevronLeftIcon)`
  height: 15px;
  position: relative;
  top: 3px;
  transform: rotate(180deg);
`;
