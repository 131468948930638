export const getPropertyValue = property => ({ as: element, theme }) => {
  if (theme[property]) {
    return (
      theme[property][element] ||
      theme[property][Object.keys(theme[property])[0]]
    );
  } else {
    throw new Error(`${element} does not have ${property} defined for it`);
  }
};

export const rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const hslToHsla = (hslColor, alpha) => {
  return `${hslColor.slice(0,-1) + ',' + alpha})`;
};
