import styled from 'styled-components/macro';
import { ReactComponent as Settings } from '../../../assets/svg/gear.svg';

const SettingsIcon = styled(Settings)`
  fill: #878890;
  height: 24px;
  width: 24px;
`;

export default SettingsIcon;
