import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { DeviceSizes, Modal, useModal, Button } from '@televet/televet-ui';

import { New, Chat, Opened, Resolved, Scheduled, Cancelled } from './';

import Flex from '../theme/Flex';
import Text from '../theme/Text';
import { H1 } from '../Common/Headers';
import PetAvatar from '../Common/PetAvatar';
import BackButton from '../Common/BackButton';
import ActionButton from '../Common/ActionButton';
import PersonAvatar from '../../assets/svg/client_avatar.svg';
import { hslToHsla } from '../theme/helpers';
import { CircleCheck } from '../Common/Icons';
import AddPaymentModal from './../Common/AddPaymentModal';
import { QuestionMark } from '../Common/Icons';
import ClinicPlaceHolderLogo from '../../assets/svg/clinic-logo-placeholder.svg';
import ConfirmConsultationModal from '../Consultation/ConfirmConsultationModal';
import VideoImagePreview from '../../assets/svg/video-play-thumbnail.jpg';
import cancelConsultation from '../../redux/app/actions/cancelConsultation';
import {
  showConsultationType,
  formatPossessiveName,
  getTimeDifference,
  getTimeAgo,
  capitalizeString,
  getRelativeTime,
} from '../../utilities';
import { requiresPayment } from '../../utilities/consultations';
import { Mixpanel } from '../../redux/mixpanel';

const Consultation = ({
  match,
  consultations,
  clinics,
  pets,
  vets,
  payment,
  cancelConsultation,
}) => {
  const [imageState, setImageState] = useState({
    src: null,
    alt: null,
    type: null,
  });
  const [selectedConsultation, setSelectedConsultation] = useState(null);
  const { openModal, closeModal, modal } = useModal();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const { uid } = useSelector(state => state.auth.user);
  const { consultationId } = match.params;
  const consultation = {
    consultationId,
    ...consultations[consultationId],
  };
  const clinicId = consultation.clinic;
  const clinic = clinics[clinicId];
  const petId = consultation.pet;
  const pet = pets[petId];
  const {
    pics,
    vids,
    summary,
    details,
    state,
    transactionId,
    last_message,
    openedAt,
    createdAt,
    paidAt,
  } = consultation;
  const clinicResponseTimeBuffer = clinic?.info.resTime || 0;
  const [relativeTime, setRelativeTime] = useState(getTimeAgo(createdAt));
  const hoursSinceConsultationCreated = getTimeDifference(
    consultation.createdAt,
    'hours'
  );
  const [messageTimeAgo, setMessageTimeAgo] = useState(
    getTimeAgo(last_message?.timestamp)
  );
  const [openedTimeAgo, setOpenedTimeAgo] = useState(getTimeAgo(openedAt));
  const [createdTimeAgo, setCreatedTimeAgo] = useState(getTimeAgo(createdAt));
  const [paidTimeAgo, setPaidTimeAgo] = useState(getTimeAgo(paidAt));

  useEffect(() => {
    //create timer on component mount

    const timer = setInterval(() => {
      setMessageTimeAgo(getTimeAgo(last_message?.timestamp));
      setPaidTimeAgo(getTimeAgo(paidAt));
      setOpenedTimeAgo(getTimeAgo(openedAt));
      setCreatedTimeAgo(getTimeAgo(createdAt));
      setRelativeTime(getRelativeTime(createdAt));
    }, 1000 * 60);

    // returned function will be called on component unmount
    return () => {
      clearInterval(timer);
    };
  }, [last_message, openedAt, createdAt, paidAt]);

  const onMediaClick = (src, alt, type) => {
    setImageState({ src, alt, type });
    openModal('media-preview');
  };

  const onFinishAddingPaymentMethod = () => {
    closeModal();
    if (selectedConsultation) {
      openModal('confirm-consultation');
    } else {
      openModal('create-consultation');
    }
  };

  const onFinishConfirmingConsultation = () => {
    closeModal();
  };

  const confirmConsultation = consultation => {
    setSelectedConsultation(consultation);
    if (payment.methods.length > 0) {
      if (!modal.isOpen) openModal('confirm-consultation');
    } else {
      if (!modal.isOpen) openModal('add-payment');
    }
  };

  if (pet && clinic && consultation) {
    return (
      <Container>
        <StyledSidebar style={{ height: '100%', overflow: 'scroll' }}>
          <div style={{ padding: '0px 12px 0 42px' }}>
            <TopRow>
              <BackButton onClick={() => history.push(`/pets/${petId}`)} />
            </TopRow>
            <Row style={{ margin: '24px 0 12px', justifyContent: 'inherit' }}>
              <Circle />
              <ConsultationStatus>
                {showConsultationType(consultation)}
              </ConsultationStatus>
            </Row>
            <Row>
              <H1>{summary || 'No summary given'}</H1>
            </Row>
            <Row style={{ paddingBottom: 24 }}>
              <DetailsSection>
                {details || 'No details were recorded for this consultation.'}
              </DetailsSection>
            </Row>
            {(pics || vids) && (
              <Row style={{ margin: '24px 0 12px', justifyContent: 'start' }}>
                {pics &&
                  pics.length > 0 &&
                  pics.map((imageUrl, index) => {
                    return (
                      <ImageThumbnail
                        key={index}
                        style={{ backgroundImage: `url(${imageUrl})` }}
                        onClick={() => onMediaClick(imageUrl, 'Image', 'image')}
                      />
                    );
                  })}
                {vids &&
                  vids.length > 0 &&
                  Object.keys(vids).map(index => {
                    return (
                      <ImageThumbnail
                        key={index}
                        style={{ backgroundImage: `url(${VideoImagePreview})` }}
                        onClick={() =>
                          onMediaClick(vids[index][1], 'Video', 'video')
                        }
                      />
                    );
                  })}
              </Row>
            )}
            <Row
              style={{
                width: '100%',
                padding: '20px 0',
                justifyContent: 'start',
              }}
            >
              <div>
                <Column>
                  <PetAvatar pet={pet.public} size="small" />
                </Column>
              </div>
              <div>
                <Column style={{ paddingLeft: 8 }}>
                  <SmallName>Pet</SmallName>
                  <Text>{pet.public.name}</Text>
                </Column>
              </div>
              <div style={{ paddingLeft: 24 }}>
                <Column>
                  <ClinicImage
                    src={clinic.info.logo || ClinicPlaceHolderLogo}
                    alt={`${formatPossessiveName(clinic.info.name) ||
                      "your clinic's"} logo`}
                  />
                </Column>
              </div>
              <div>
                <Column style={{ paddingLeft: 8 }}>
                  <SmallName>Veterinarian</SmallName>
                  <Text>{clinic.info.name}</Text>
                </Column>
              </div>
            </Row>
            <Separator />
          </div>
          <ActivitySection>
            <Text
              style={{
                fontWeight: 500,
                fontSize: 24,
                padding: '12px 0 0 42px',
              }}
            >
              Activity
            </Text>
          </ActivitySection>
          <TimelineDiv>
            <TimelineContainer>
              {consultation.cancelledAt && (
                <TimelineContent isPast={false}>
                  <Row>
                    <Cancelled consultation={consultation} />
                  </Row>
                </TimelineContent>
              )}
              {consultation.resolvedAt && (
                <TimelineContent isPast={false}>
                  <Row>
                    <Resolved
                      consultation={consultation}
                      clinic={clinic}
                      resolvedVetImage={
                        vets[consultation.vet]?.profilePic || PersonAvatar
                      }
                    />
                  </Row>
                </TimelineContent>
              )}
              {requiresPayment(consultation) && (
                <TimelineContent
                  isPast={state === 'RESOLVED' || state === 'CANCELLED'}
                >
                  <Row state={state}>
                    <Text style={{ width: '80%', fontSize: 18, marginTop: 6 }}>
                      Please confirm your consultation
                    </Text>
                  </Row>
                  <ConfirmDiv
                    style={{
                      justifyContent: 'space-between',
                      padding: '16px 20px',
                    }}
                  >
                    <div>
                      <div style={{ width: '100%' }}>
                        <Text
                          style={{ fontWeight: 500, fontSize: 16, margin: 0 }}
                        >
                          Your card will be authorized for{' '}
                          <span style={{ color: 'hsl(224,76%,57%)' }}>
                            ${consultation.cost}
                          </span>
                        </Text>
                        <Text>All payments are encrypted and secured.</Text>
                      </div>
                    </div>
                    <div
                      style={{ maxWidth: 200, marginTop: 12, display: 'flex' }}
                    >
                      <ConfirmButton
                        onClick={() => confirmConsultation(consultation)}
                      >
                        <span style={{ marginRight: 8 }}>
                          <CircleCheck title="Confirm Payment Method" />
                        </span>
                        Confirm Consultation
                      </ConfirmButton>
                    </div>
                  </ConfirmDiv>
                </TimelineContent>
              )}
              {consultation.scheduledAt && (
                <TimelineContent
                  isPast={
                    state === 'RESOLVED' ||
                    state === 'CANCELLED' ||
                    requiresPayment(consultation)
                  }
                >
                  <Row>
                    <Scheduled
                      consultation={consultation}
                      consultationId={consultationId}
                      vets={vets}
                    />
                  </Row>
                </TimelineContent>
              )}
              {paidAt && transactionId && (
                <TimelineContent
                  isPast={
                    state === 'RESOLVED' ||
                    state === 'CANCELLED' ||
                    state === 'PAID'
                  }
                >
                  <InfoRow>
                    <Text
                      style={{
                        width: '80%',
                        fontSize: 18,
                        margin: '6px 0 12px',
                      }}
                    >
                      You confirmed the appointment
                    </Text>
                    <Text style={{ margin: '6px 0 12px', fontSize: 12 }}>
                      {paidTimeAgo}
                    </Text>
                  </InfoRow>
                </TimelineContent>
              )}
              {consultation.openedAt && (
                <>
                  <TimelineContent
                    isPast={
                      state === 'RESOLVED' ||
                      state === 'PAID' ||
                      state === 'SCHEDULED' ||
                      state === 'CANCELLED'
                    }
                  >
                    <InfoRow state={state}>
                      <Text style={{ width: '80%', fontSize: 18 }}>
                        Your care team has been assigned
                      </Text>
                      <Text style={{ margin: '6px 0 12px', fontSize: 12 }}>
                        {openedTimeAgo}
                      </Text>
                    </InfoRow>
                    {state === 'OPENED' && (
                      <FlexDiv>
                        <div>
                          {consultation.vet ? (
                            <FlexStartDiv>
                              <VetAvatar
                                src={
                                  vets[consultation.vet]?.profilePic ||
                                  PersonAvatar
                                }
                                alt={`${formatPossessiveName(
                                  vets[consultation.vet]?.fname
                                ) || "your vet's"} avatar`}
                              />
                              <LeftDiv>
                                <Text>
                                  {`${capitalizeString(
                                    vets[consultation.vet]?.fname
                                  )} ${capitalizeString(
                                    vets[consultation.vet]?.lname
                                  )}`}
                                </Text>
                                <Label>Veterinarian</Label>
                              </LeftDiv>
                            </FlexStartDiv>
                          ) : null}
                          {consultation.nurse ? (
                            <FlexStartDiv>
                              <VetAvatar
                                src={
                                  vets[consultation.nurse]?.profilePic ||
                                  PersonAvatar
                                }
                                alt={`${formatPossessiveName(
                                  vets[consultation.nurse]?.fname
                                ) || "your nurse's"} avatar`}
                              />
                              <LeftDiv>
                                <Text>
                                  {capitalizeString(
                                    vets[consultation.nurse]?.fname
                                  )}{' '}
                                  {capitalizeString(
                                    vets[consultation.nurse]?.lname
                                  )}
                                </Text>
                                <Label>Nurse</Label>
                              </LeftDiv>
                            </FlexStartDiv>
                          ) : null}
                        </div>
                        <FlexJustifyCenter></FlexJustifyCenter>
                      </FlexDiv>
                    )}
                  </TimelineContent>
                  <TimelineContent
                    isPast={
                      consultation.assignor ||
                      state === 'RESOLVED' ||
                      state === 'PAID' ||
                      state === 'SCHEDULED' ||
                      state === 'CANCELLED'
                    }
                  >
                    <Row>
                      <Opened
                        consultationId={consultationId}
                        consultation={consultation}
                        vets={vets}
                        clinicId={clinicId}
                        clinic={clinic}
                      />
                    </Row>
                  </TimelineContent>
                </>
              )}
              {consultation.last_message &&
                consultation.last_message?.user !== uid && (
                  <LastMessage state={state}>
                    <TimelineContent isPast={consultation.openedAt}>
                      <InfoRow style={{ paddingTop: 12 }}>
                        <Text style={{ width: '80%', fontSize: 18 }}>
                          You have an incoming message from{' '}
                          {capitalizeString(vets[last_message.user]?.fname)}
                        </Text>
                        <Text style={{ margin: '12px 0', fontSize: 12 }}>
                          {messageTimeAgo}
                        </Text>
                      </InfoRow>
                      <Row style={{ display: 'block', margin: 0 }}>
                        {consultation.last_message.text && (
                          <FlexDiv style={{ padding: '16px 20px' }}>
                            <div>
                              <VetAvatar
                                src={
                                  vets[last_message.user]?.profilePic ||
                                  PersonAvatar
                                }
                                alt={`${clinic.info.name ||
                                  "your clinic's"} avatar`}
                              />
                            </div>
                            <div
                              style={{
                                lineHeight: '1.3rem',
                                padding: '0 12px',
                              }}
                            >
                              {consultation.last_message.text}
                            </div>
                          </FlexDiv>
                        )}
                        {consultation.last_message.pic && (
                          <img
                            width="300"
                            src={consultation.last_message.pic}
                            alt={`Media sent from ${
                              vets[consultation.vet]?.fname
                            }`}
                          />
                        )}
                        {consultation.last_message.video && (
                          <video
                            width="300"
                            src={consultation.last_message.video}
                            alt={`Media sent from ${
                              vets[consultation.vet]?.fname
                            }`}
                            controls
                          />
                        )}
                      </Row>
                    </TimelineContent>
                  </LastMessage>
                )}
              {consultation.state === 'NEW' && (
                <TimelineContent
                  isPast={
                    consultation.openedAt ||
                    consultation.assignor ||
                    consultation.last_message ||
                    state === 'PAID' ||
                    state === 'RESOLVED' ||
                    state === 'SCHEDULED' ||
                    state === 'CANCELLED'
                  }
                >
                  <InfoRow>
                    <Text
                      style={{
                        width: '80%',
                        fontSize: 18,
                        margin: '6px 0 12px',
                      }}
                    >
                      Awaiting review and care team assignment
                    </Text>
                    <Text style={{ margin: '6px 0 12px', fontSize: 12 }}>
                      {createdTimeAgo}
                    </Text>
                    {clinic.info.name && clinic.info.logo && (
                      <>
                        <div
                          style={{
                            background: '#ebf0f9',
                            borderRadius: 12,
                            padding: '16px 20px',
                          }}
                        >
                          <div style={{ display: 'flex' }}>
                            <div>
                              <ClinicImage
                                src={clinic.info.logo}
                                alt={`${clinic.info.name ||
                                  "your clinic's"} avatar`}
                              />
                            </div>
                            <div>
                              <IntroText style={{ lineHeight: '1.3rem' }}>
                                Hi, {capitalizeString(user.info.public.fname)}!{' '}
                                <br />
                                Thanks for submitting a consultation request for{' '}
                                {pet.public.name} {relativeTime}. We will
                                contact you within{' '}
                                {clinic.info.resTime || 'a few'} hours during
                                our active hours. If you sent your consultation
                                outside of our active hours, it might take
                                longer to hear back from us.
                              </IntroText>
                              <IntroText>
                                If this is your first time using TeleVet, please
                                make sure to set up your device in advance of
                                the consultation.
                              </IntroText>
                            </div>
                          </div>
                          <EmergencyDiv>
                            For emergencies, please call
                            <strong style={{ fontWeight: 500 }}>
                              {clinic.info.private.phone
                                ? ` ${clinic.info.private.phone}`
                                : ' our office'}
                            </strong>
                          </EmergencyDiv>
                        </div>
                      </>
                    )}
                  </InfoRow>
                </TimelineContent>
              )}
              {consultation.createdAt && (
                <TimelineContent isPast={true}>
                  <Row>
                    <New
                      consultationId={consultationId}
                      consultation={consultation}
                      clinicId={clinicId}
                      clinic={clinic}
                      vets={vets}
                    />
                  </Row>
                </TimelineContent>
              )}
            </TimelineContainer>
          </TimelineDiv>
          <FlexDiv
            style={{
              padding: '12px 20px',
              margin: '0 12px 60px 50px',
              width: 'auto',
            }}
          >
            <div
              style={{
                color: 'hsl(224,76%,27%)',
                fontWeight: 500,
                display: 'flex',
              }}
            >
              <div>
                <ClinicImage
                  src={clinic.info.logo}
                  alt={`${clinic.info.name || "your clinic's"} avatar`}
                />
              </div>
              <div style={{ display: 'block' }}>
                Responsive Hours
                <span className="tooltip">
                  <QuestionMark title="tooltip-icon" />
                  <span className="tooltiptext">
                    {clinic.info.name} typically responds to TeleVet
                    consultations during these times. Be sure to contact the
                    clinic directly for up-to-date hours for in-person
                    appointments.
                  </span>
                </span>
                <IntroDiv>
                  <div>
                    <Text>{clinic.info.hours}</Text>
                    <Text style={{ fontSize: 12 }}>
                      Typically responds within {clinic.info.resTime || 'a few'}{' '}
                      hours
                    </Text>
                  </div>
                </IntroDiv>
              </div>
            </div>
          </FlexDiv>
          {(state === 'PAID' || transactionId) && (
            <MessageButton
              onClick={() => {
                history.push(`/chat/${consultationId}/${clinicId}`);
                if (clinic.info.name)
                  Mixpanel.track(`Chat with ${clinic.info.name}`);
                Mixpanel.track('Chat with clinic');
              }}
            >
              Chat with {clinic.info.name}
            </MessageButton>
          )}
          {hoursSinceConsultationCreated > clinicResponseTimeBuffer &&
            !consultation.resolvedAt &&
            !consultation.cancelledAt && (
              <Row style={{ paddingBottom: 96 }}>
                <CancelButton
                  buttonType="ghost"
                  name="cancel-button"
                  onClick={() => {
                    cancelConsultation(consultation);
                    Mixpanel.track('Cancel consultation clicked');
                  }}
                >
                  Cancel Consultation
                </CancelButton>
              </Row>
            )}
        </StyledSidebar>
        <Content>
          <Chat consultationId={consultationId} clinicName={clinic.info.name} />
        </Content>
        <Modal name="media-preview" size="lg">
          <ModalContent>
            {imageState.type === 'image' && (
              <PreviewImage src={imageState.src} alt={imageState.alt} />
            )}
            {imageState.type === 'video' && (
              <PreviewVideo src={imageState.src} controls>
                Your browser does not support the video tag.
              </PreviewVideo>
            )}
          </ModalContent>
        </Modal>
        <AddPaymentModal
          onCancel={() => closeModal()}
          onFinish={onFinishAddingPaymentMethod}
        />
        <ConfirmConsultationModal
          consultation={selectedConsultation}
          setSelectedConsultation={confirmConsultation}
          onCancel={() => closeModal()}
          onFinish={onFinishConfirmingConsultation}
        />
      </Container>
    );
  } else return null;
};

const EmergencyDiv = styled.div`
  background: hsl(4, 63%, 93%);
  border-radius: 4px;
  color: hsl(351, 82%, 49%);
  padding: 8px;
  margin: 12px 24px;
  font-size: 14px;
  line-height: 1.3rem;
  text-align: center;
`;

const IntroDiv = styled.div`
  display: flex;
  @media (max-width: ${DeviceSizes.tablet}) {
    display: block;
  }
`;

const IntroText = styled(Text)`
  color: ${({ theme }) => theme.palette.black} !important;
  margin: 4px 0;
`;

const LastMessage = styled.div`
  @media (min-width: 960px) {
    display: none;
  }
`;

const MessageButton = styled(ActionButton)`
  margin: 32px 0 32px 50px;
  max-width: 90%;
  width: auto;
  display: block;
  @media (min-width: 961px) {
    display: none;
  }
`;

const TimelineContainer = styled.div``;

const TimelineDiv = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 12px;

  ::after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: ${({ theme }) =>
      hslToHsla(theme.palette.actionButton, 0.3)};
    top: 35px;
    bottom: 48px;
    left: 0;
    margin-left: 0;
  }
`;

const TimelineContent = styled.div`
  padding: 10px 0 10px 32px;
  position: relative;
  background-color: inherit;
  left: 0;

  ::after {
    content: '\\2713';
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.palette.white};
    width: 25px;
    height: 25px;
    left: -11px;
    background-color: ${({ theme, isPast }) =>
      isPast ? 'hsl(0, 0%, 91%)' : theme.palette.actionButton};
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
`;

const Separator = styled.hr`
  border: 1px solid ${({ theme }) => hslToHsla(theme.palette.primary, 0.1)};
`;

const ModalContent = styled.div`
  padding: 2em 1em;
  text-align: center;
`;

const ImageThumbnail = styled.div`
  background-size: cover;
  background-position: center;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  height: 74px;
  margin: 0.5em 1em 0.5em 0;
  width: 74px;
`;

const PreviewImage = styled.img`
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  max-width: 37.5rem;
  width: 100%;
  height: auto;
`;

const PreviewVideo = styled.video`
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  max-width: 37.5rem;
  outline: none;
  width: 100%;
  height: auto;
`;

const Row = styled(Flex)`
  width: 100%;
  text-align: left;
  margin: 4px 0;
  justify-content: space-between;
`;

const InfoRow = styled(Row)`
  p {
    color: rgba(16, 22, 38, 0.7);
  }
`;

const ActivitySection = styled(Row)``;

const TopRow = styled(Row)`
  padding: 20px 0 12px;
`;

const DetailsSection = styled.p`
  color: ${({ theme }) => theme.palette.gray300};
  line-height: 1.3rem;
`;

const SmallName = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  color: ${({ theme }) => theme.palette.gray300};
  padding: 0 0 8px;
`;

const ClinicImage = styled.img`
  border-radius: 4px;
  height: 36px;
  margin-right: ${({ theme }) => theme.spacing.spacingSizes.sm};
  width: 36px;
`;

const ConsultationStatus = styled.span`
  color: ${({ theme }) => theme.palette.actionButton};
`;

const Column = styled(Flex)`
  flex-direction: column;
`;

const StyledSidebar = styled.div`
  flex: 5;
  @media (min-width: 960px) {
    width: 100%;
  }
  @media (max-width: ${DeviceSizes.mobileS}) {
    padding: 32px ${({ theme }) => theme.spacing.spacingSizes.sm} 12px;
  }
  border-right: 1px solid
    ${({ theme }) => hslToHsla(theme.palette.primary, 0.5)};
  padding: 12px 20px 100px;
`;

const Content = styled.div`
  flex: 3;
  /* margin-top:24px; */
  height: 100%;
  min-width: 320px;
  overflow: hidden;
  @media (max-width: 960px) {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media (max-width: ${DeviceSizes.tablet}) {
    flex-wrap: wrap;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    padding-left: 8px;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: hsl(0, 0%, 16%);
    color: ${({ theme }) => theme.palette.white};
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: -560%;

    @media (max-width: 345px) {
      left: 0%;
    }
    margin-left: -60px;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    width: 300px;
    @media (max-width: ${DeviceSizes.mobileL}) {
      width: 200px;
    }
    padding: 14px 20px;
    line-height: 1.3rem;
  }
`;

const Circle = styled.div`
  display: inline-block;
  height: 6px;
  width: 6px;
  border-radius: 20px;
  margin: 0px 8px 0 0;
  background-color: ${({ theme }) => theme.palette.actionButton};
  display: ${({ read }) => (read ? 'none' : 'inherit')};
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.palette.error};
  border: 1px solid ${({ theme }) => theme.palette.error};
  border-radius: 4px;
  padding: 8px 12px;
  margin-left: 50px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.error} !important;
    background: ${({ theme }) => theme.palette.error};
    color: ${({ theme }) => theme.palette.white};
    border: 1px solid ${({ theme }) => theme.palette.error} !important;
  }
`;

const Label = styled(Text)`
  font-size: 12px;
  color: rgba(16, 22, 38, 0.6);
`;

const FlexStartDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 12px;
`;

const FlexJustifyCenter = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const LeftDiv = styled.div`
  margin-left: 10px;
`;

const FlexDiv = styled.div`
  display: flex;
  margin: 24px 0;
  background: #ebf0f9;
  border-radius: 12px;
  width: 100%;
`;

const VetAvatar = styled.img`
  height: 60px;
  border-radius: 30px;
`;

const ConfirmDiv = styled(FlexDiv)`
  @media (min-width: 0px) {
    display: block;
  }
  @media (min-width: 640px) {
    display: flex;
  }
  @media (min-width: ${DeviceSizes.tablet}) {
    display: block;
  }
  @media (min-width: 1200px) {
    display: flex;
  }
`;

const ConfirmButton = styled(ActionButton)`
  padding-top: 0.5rem;
  font-size: 16px;
  @media (max-width: ${DeviceSizes.mobileM}) {
    font-size: 15px;
  }
`;

const mapDispatchToProps = {
  cancelConsultation,
};

const mapStateToProps = state => ({
  consultations: state.consultations,
  clinics: state.clinics,
  pets: state.pets,
  vets: state.vets,
  payment: state.payment,
});

export default connect(mapStateToProps, mapDispatchToProps)(Consultation);
